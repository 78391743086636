.exp-pay-card-style {
    /* padding-left: 1.7rem !important; */
    padding-top: 1rem !important;
    padding-bottom: 1rem !important;
}

.rs-picker-default .rs-btn, .rs-picker-input .rs-btn, .rs-picker-default .rs-picker-toggle, .rs-picker-input .rs-picker-toggle {
    background: #323335 !important;
}

.rs-picker-default .rs-picker-toggle {
    position: relative;
    z-index: 5;
    padding-right: 32px;
    display: inline-block;
    outline: none;
    cursor: pointer;
    color: #575757;
    border: 1px solid #323335;
}

.rs-picker-menu {
    position: absolute;
    text-align: left;
    z-index: 7;
    border-radius: 6px;
    background-color: #323335;
    -webkit-box-shadow: 0 0 10px rgb(0 0 0 / 6%), 0 4px 4px rgb(0 0 0 / 12%);
    box-shadow: 0 0 10px rgb(0 0 0 / 6%), 0 4px 4px rgb(0 0 0 / 12%);
    overflow: hidden;
    -webkit-transition: none;
    transition: none;
}

.rs-picker-check-menu-items div :hover{
    background-color: rgb(249, 75, 15 );
    /*color: black !important*/
}


.rs-picker-check-menu-items div ::selection{
    color: black !important;
    background: #1890ff;
    /*color: black !important*/
}
