html,
body,
div {
  font-family: "Gilroy-Medium ☞";
  font-style: normal;
  font-weight: normal;
  src: local("Gilroy-Regular ☞"), url("Gilroy-Medium.woff") format("woff");
  font-size: 20px;
  line-height: 24px;
  color: white !important;
}

h1,
h2,
h3,
h4,
h5,
h6 {
  color: white !important;
}

@font-face {
  font-family: "Gilroy-Bold ☞";
  font-style: normal;
  font-weight: normal;
  src: local("Gilroy-Bold ☞"), url("Gilroy-Bold.woff") format("woff");
}

@font-face {
  font-family: "Gilroy-Heavy ☞";
  font-style: normal;
  font-weight: normal;
  src: local("Gilroy-Heavy ☞"), url("Gilroy-Heavy.woff") format("woff");
}

@font-face {
  font-family: "Gilroy-Light ☞";
  font-style: normal;
  font-weight: normal;
  src: local("Gilroy-Light ☞"), url("Gilroy-Light.woff") format("woff");
}

@font-face {
  font-family: "Gilroy-Medium ☞";
  font-style: normal;
  font-weight: normal;
  src: local("Gilroy-Medium ☞"), url("Gilroy-Medium.woff") format("woff");
}

@font-face {
  font-family: "Gilroy-Regular ☞";
  font-style: normal;
  font-weight: normal;
  src: local("Gilroy-Regular ☞"), url("Gilroy-Regular.woff") format("woff");
}

.lite {
  font-family: Gilroy-Light ☞;
}

.regular {
  font-family: Gilroy-Medium ☞;
}

.bold {
  font-family: Gilroy-Bold ☞;
}

.heavy {
  font-family: Gilroy-Heavy ☞;
}

.divider-blank {
  margin: 1rem;
}
.divider-down {
  margin-top: 2rem;
}
.divider-horizontal {
  padding-left: 2rem !important;
}

.divider {
  margin: 0.5rem;
}

.btn-cancel-cards {
  top: 1.2rem !important;
  right: 1rem !important;
  box-shadow: none !important;
}

.btn-circle{
  background: #fe5000;
  border-radius: 50px;
  color: #171718;
  padding: 0rem;
  width: 2.4rem;
  height: 2.4rem;
  position: absolute;
  right: 0.5rem;
  bottom: 0.5rem;
  box-shadow: 0px 0px 41px -4px rgba(0,0,0,0.58);
}
.btn-circle:hover {
  background: #171718;
  color: #fe5000;
}
.btn-circle-link{
  background: #fe5000;
  border-radius: 50px;
  color: #171718;
  padding-top: 0.6rem;
  padding-left: 0.5rem;
  width: 2.4rem;
  height: 2.4rem;
  position: absolute;
  right: 0.5rem;
  bottom: 0.5rem;
  box-shadow: 0px 0px 41px -4px rgba(0,0,0,0.58);
}
.btn-circle-link:hover {
  background: #171718;
  color: #fe5000;
}

.nice-dates-navigation,
.nice-dates-day {
  color: white !important;
  font-size: 20px !important;
}

.exp-fixed-bottom-open-host-upload {
  z-index: 10;
  position: fixed;
  bottom: 1.2rem !important;
  right: 1rem !important;
  background: linear-gradient(
    90deg,
    rgba(38, 45, 255, 1) 0%,
    rgba(13, 19, 179, 1) 100%
  );
  color: #060953 !important;
  border-radius: 50%;
  height: 4rem;
  width: 4rem;
}
.exp-fixed-bottom-open-host-upload:hover {
  background: linear-gradient(
    90deg,
    rgb(47, 53, 245) 0%,
    rgb(14, 21, 207) 100%
  );
  color: #050846 !important;
}

.text_map {
  z-index: 1;
  position: fixed;
  top: 2rem;
  left: 10rem;
  right: 10rem;
}
.text_map ul li {
  color: black !important;
  cursor: pointer;
}

.map {
  height: 100%;
  width: 100%;
  position: none;
  left: 0;
  right: 0;
  top: 0;
}

.map_full {
  height: 100vh;
  width: 100%;
  position: fixed;
  left: 0;
  right: 0;
  top: 0;
}

.btn-map {
  color: white !important;
  font-size: 20px !important;
  width: 100%;
  height: 4rem;
  position: fixed !important;
  bottom: 0rem;
  left: 0;
  border-radius: 0px !important;
  z-index: 1;
  bottom: 0 !important;
  position: fixed;
  border-radius: 0 !important;
  background: linear-gradient(
    90deg,
    rgba(38, 45, 255, 1) 0%,
    rgba(13, 19, 179, 1) 100%
  );
}

.orange {
  color: #fe5000 !important;
}

.black {
  color: #171718 !important
}
.red {
  color: #fe0000 !important;
}

.neon_blue {
  color: #262dff !important;
}

.bg-orange {
  background: #fe5000 !important;
}

.bg-negro {
  background: #000000 !important;
}
.bg-transparente {
  background: transparent !important;
}

.bg-orange-gradient {
  background: linear-gradient(
    180deg,
    rgb(241, 97, 30) 0%,
    rgb(243, 37, 37) 100%
  );
}
.bg-neonblue-gradient {
  background: linear-gradient(
    90deg,
    rgba(38, 45, 255, 1) 0%,
    rgba(13, 19, 179, 1) 100%
  );
}

.bg-neonblue-gradient text {
  color: #060953 !important;
  font-size: 12px !important;
}

.bg__tutorial {
  background: url("../images/patterns.png");
  background-size: cover;
}

.bg__tuto1__movil {
  height: 10rem !important;
  background: url("../images/tuto2.jpg");
  background-color: #000000;
  background-size: cover;
  background-position: center;
  top: -7rem;
}

.bg__tuto1 {
  background: url("../images/tuto2.jpg");
  background-color: #000000;
  background-size: cover;
  background-position: center;
  height: 100vh;
}
.bg__tuto2 {
  background: url("../images/chat.jpg");
  background-color: #000000;
  background-size: cover;
  background-position: center;
  height: 100vh;
}
.bg__tuto2__movil {
  height: 10rem !important;
  background: url("../images/chat.jpg");
  background-color: #000000;
  background-size: cover;
  background-position: center;
  top: -7rem;
}

.bg__tuto3 {
  background: url("../images/calendar.jpg");
  background-color: #000000;
  background-size: cover;
  background-position: center;
  height: 100vh;
}
.bg__tuto3__movil {
  height: 10rem !important;
  background: url("../images/calendar.jpg");
  background-color: #000000;
  background-size: cover;
  background-position: center;
  top: -7rem;
}

.bg__tuto4 {
  background: url("../images/tour.jpg");
  background-color: #000000;
  background-size: cover;
  background-position: center;
  height: 100vh;
}
.bg__tuto4__movil {
  height: 10rem !important;
  background: url("../images/tour2.jpg");
  background-color: #000000;
  background-size: cover;
  background-position: center;
  top: -7rem;
}

.orange-dark {
  color: #5c2004 !important;
}

.noBackground {
  background: rgb(7, 7, 7) !important;
  border-radius: 10px !important;
  margin: 1rem;
}
.noBackground:hover {
  background: #2a2b2c !important;
}

.modalConfirmacion {
  width: 100%;
  padding: 20px;
}

.itemCardList {
  display: flex;
}

.itemCardList:hover {
  background: #fe5000;
}

.botonEliminar {
  position: absolute;
  right: 20px;
  top: 15px;
  width: 30px;
  /* margin-right: 10px; */
  /* margin-left: 60px; */
}

.botonEliminar:hover {
  width: 35px;
  cursor: pointer;
}

.bg-guatemala {
  background-color: rgba(102, 68, 6, 0.116) !important;
  color: rgb(129, 129, 190) !important;
}

.bg-salvador {
  background-color: rgba(77, 170, 0, 0.116) !important;
  color: rgb(129, 129, 190) !important;
}

.bg-honduras {
  background-color: rgba(0, 174, 255, 0.116) !important;
  color: rgb(129, 129, 190) !important;
}

.bg-nicaragua {
  background-color: rgba(255, 238, 0, 0.116) !important;
  color: rgb(129, 129, 190) !important;
}

.bg-costarica {
  background-color: rgba(255, 72, 0, 0.116) !important;
  color: rgb(129, 129, 190) !important;
}

.bg-panama {
  background-color: rgba(0, 0, 0, 0.116) !important;
  color: rgb(129, 129, 190) !important;
}

.buutonListCard:hover {
  border-color: #0044ff;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}
.logo {
  height: 50px;
}
.logo_in {
  height: 40px;
  position: fixed;
  left: 3.5rem;
  top: 0.8rem;
  z-index: 3 !important;
}
.logo_in2 {
  height: 40px;
  position: fixed;
  left: 0.8rem;
  top: 0.8rem;
  z-index: 1000;
}
.logo_in3 {
  height: 40px;
  position: fixed;
  left: 3.5rem;
  top: 0.8rem;
  z-index: 2 !important;
}


.card3 {
  background-color: #2a2b2c !important;
  border-radius: 50px !important;
  padding: 0rem !important;
  margin-bottom: 1rem;
  cursor: pointer;
}
.card3:hover {
  background-color: #323335 !important;
}

.card4 {
  border: 4px solid rgba(0, 0, 0, 0.479);
  border-radius: 10px !important;
  padding: 1rem !important;
  cursor: pointer;
  margin: 10px;
}
.card4:hover {
  background-color: #323335 !important;
}

@media only screen and (max-width: 900px) {
  .fc .fc-scroller {
    -webkit-overflow-scrolling: touch;
    position: relative; /* for abs-positioned elements within */
  }
  .fc .fc-scroller-liquid {
    height: 200% !important;
  }
  .fc .fc-scroller-liquid-absolute {
    position: absolute;
    top: 0;
    right: 0;
    left: 0;
    bottom: 0;
  }
  .fc .fc-scroller-harness {
    position: relative;
    overflow: hidden;
    direction: ltr;
    /* hack for chrome computing the scroller's right/left wrong for rtl. undone below... */
    /* TODO: demonstrate in codepen */
  }
  .fc .fc-scroller-harness-liquid {
    height: 100%;
  }
  .fc-direction-rtl .fc-scroller-harness > .fc-scroller {
    /* undo above hack */
    direction: rtl;
  }

  .icon-back {
    position: absolute;
    left: 0;
    right: 0;
    top: 9px;
    bottom: 0;
    color: #fe5000 !important;
  }
  .icon-back-2 {
    position: absolute;
    left: 0.5rem;
    top: 6px;
    bottom: 0;
  }

  .login-text-redes {
    font-size: 16px;
    outline: none !important;
    color: white;
    padding-top: 5;
    position: none;
    bottom: 1rem;
    left: 0;
    right: 0;
  }
  .login-text-redes:hover {
    color: #fe5000 !important;
  }

  .owl-carousel .owl-nav,
  button.owl-prev {
    position: absolute !important;
    margin-left: -1.4rem !important;
  }
  .owl-carousel .owl-nav,
  button.owl-next {
    position: absolute !important;
    margin-right: -1.4rem !important;
  }

  .owl-carousel .owl-nav button.owl-prev,
  .owl-carousel .owl-nav button.owl-next {
    position: absolute !important;
    width: 70px;
    height: 70px;
    font-size: 40px !important;
    z-index: 2;
    color: #fe5000;
    text-transform: uppercase;
    padding: 9px 20px;
    margin-left: 0px;
    margin-right: 0px;
    margin-top: 14rem !important;
    background-color: rgb(14, 14, 14) !important;
    border: 0px solid rgba(231, 231, 231, 0.5);
    border-radius: 50%;
    box-shadow: 0 0.25em 0.5em 0 rgba(0, 0, 0, 0.205);
    text-shadow: 0 0.25em 0.5em rgba(0, 0, 0, 0.1);
    opacity: 1;
    cursor: pointer;
    transition: opacity 150ms ease, border 300ms ease-in-out;
    transform: translateY(-50%);
    line-height: 1;
    outline: none;
  }

  .text_map {
    z-index: 1;
    position: fixed;
    top: 1rem;
    left: 1rem;
    right: 1rem;
  }

  .config {
    margin-top: 1rem !important;
  }

  .config-perfil {
    position: fixed !important;
    padding-top: 1rem;
  }

  .config-menu {
    margin-top: 24rem;
    padding-bottom: 6.5rem;
    background: #141414;
    padding-top: 0rem !important;
    border-radius: 40px 40px 0 0;
    z-index: 1;
    position: absolute;
    bottom: 0;
  }

  .card2 {
    background-color: #2a2b2c !important;
    border-radius: 50px !important;
    height: 7rem !important;
    padding: 0rem !important;
    margin-bottom: 1rem;
    cursor: pointer;
  }
  .card2:hover {
    background-color: #323335 !important;
  }

  .card3 {
    background-color: #0b0c0c !important;
    border-radius: 50px !important;
    height: 3.5rem !important;
    padding: 0rem !important;
    margin-top: 1rem;
    margin-bottom: 0rem;
    cursor: pointer;
  }
  .card3:hover {
    background-color: #323335 !important;
  }

  .switch {
    position: fixed;
    top: 0.8rem;
    left: 0rem;
    border-radius: 50rem;
    width: 40px;
    height: 40px;
  }
}

.exp-chat-open {
  position: absolute !important;
  left: -1rem;
  right: -1rem;
  top: -2rem;
  height: 101vh !important;
}

.cards {
  background-color: #2a2b2c !important;
  border-radius: 10px !important;
  width: 100%;
  
  padding: 0rem !important;
  margin-bottom: 1rem;
}

.config {
  margin-top: 9rem;
}

.card2 {
  background-color: #2a2b2c !important;
  border-radius: 30px !important;
  cursor: pointer;
  padding: 0.2rem;
  margin-bottom: 1rem;
}
.card2:hover {
  background-color: #323335 !important;
}

.icono-paises {
  height: 50px;
  margin-bottom: 0.5rem !important;
}

.icon {
  height: 38px;
  margin-right: 0.3rem;
}

.icon-font {
  position: absolute;
  top: 11.4px;
  left: 0;
  right: 0;
}

.avatar {
  width: 2.7rem !important;
  margin-left: 0.7rem;
}
.avatar_sm {
  position: absolute;
  right: 0.9rem;
}

.page_pay {
  padding-top: 4rem;
  width: 80% !important;
}

.image_picker {
  width: 100%;
  display: flex;
  flex-wrap: wrap;
  margin-left: 2px;
  
}

.image_picker .ip_tarjeta {
  border: 1px solid transparent;
  border-radius: 10px;
  padding: 4px;
  height: 150px;
  width: 110px;
  opacity: 1;
  margin: 5px;
  background: #0e0f0f;

  
}

.image_picker .selected {
  box-shadow: 0 0 0px 0px #fe5000;
  border: 4px solid #fe5000;
  cursor: pointer;
}
.image_picker .ip_tarjeta:hover {
  filter:contrast(2);
  cursor: pointer;
}

.no-events {
  pointer-events: none;
}
.text-titulos-reserva {
  font-size: 28px;
}

.text-instructivo {
  font-size: 16px;
}

.btn-publicada {
  background: green !important;
}

@media only screen and (max-width: 900px) {
  .text-instructivo {
    font-size: 12px;
    line-height: 18px;
  }

  .image_picker .responsive .thumbnail {
    border: 1px solid transparent !important;
    border-radius: 10px !important;
    padding: 5px;
    height: 130px !important;
    width: 110px !important;
    opacity: 1;
    margin: 0;
  }

  .avatar {
    width: 2rem !important;
    height: 2rem !important;
  }

  .page_pay {
    padding-top: 4rem;
    width: 100% !important;
  }
}

.card-titulo {
  font-size: 25px;
  line-height: 1.3rem;
}

.card-titulo-fav {
  font-size: 18px;
  margin-bottom: -0.2rem;
}

.alert__orange {
  height: 2rem !important;
  font-size: 18px !important;
  margin-bottom: 1rem !important;
  margin-left: 1rem !important;
  margin-right: 1rem !important;
  border-radius: 20px !important;
  cursor: pointer;
}

@media only screen and (max-width: 900px) {
  .card-titulo {
    font-size: 18px !important;
    line-height: 0.9rem;
  }

  .alert__orange {
    height: 5rem !important;
    font-size: 18px !important;
  }
}

.padding {
  margin-right: 0.5rem;
  padding-right: 1rem;
  padding-left: 1rem;
}

.padding-planes {
  margin-right: 0.5rem;
  padding-right: 1rem;
  padding-left: 1rem;
  margin-top: -0.9cm !important;
  margin-left: -0.9cm !important;
}

.contraste {
  color: #fe5000 !important;
  background: #fe500021 !important;
  border-radius: 10px !important;
}

.btn_cancel {
  width: 3rem !important;
  height: 3rem !important;
  position: absolute !important;
  border-radius: 50% !important;
  right: 0.3rem;
  top: 0.3rem;
  background: #fa0000 !important;
  z-index: 1 !important;
  color: #921212 !important;
  font-size: 18px !important;
}
.btn_cancel:hover {
  background: #b61c1c !important;
  color: #4d0707 !important;
  box-shadow: 0px 0px 34px 7px rgba(0, 0, 0, 0.75);
}

.payment_banner {
  background: #fa0000 !important;
  color: #ffffff;
  height: 2rem !important;
  display: grid;
  place-content: center;
  padding: 10px;
  border-radius: 4px;
}

.btn_cancel_banco {
  width: 3rem !important;
  height: 3rem !important;
  position: relative;
  left: 24rem;
  top: -3.5rem;
  border-radius: 50% !important;
  background: #fa0000 !important;
  color: #921212 !important;
  z-index: 1 !important;
  font-size: 18px;
}
.btn_cancel_banco:hover {
  background: #b61c1c !important;
  color: #4d0707 !important;
  box-shadow: 0px 0px 34px 7px rgba(0, 0, 0, 0.75);
}

.btn-exp-host {
  height: 3rem;
  position: absolute;
  bottom: 0.5rem;
  background: #0e0f0f;
  color: #262dff !important;
  border-radius: 20px;
  left: 0.5rem;
  right: 0.5rem;
  padding: 0.8rem;
  padding-top: 0.9rem;
}
.btn-exp-host:hover {
  height: 3rem;
  position: absolute;
  bottom: 0.5rem;
  background: #131414;
  color: #262dff !important;
  border-radius: 20px;
  left: 0.5rem;
  right: 0.5rem;
  padding: 0.8rem;
  padding-top: 0.9rem;
  box-shadow: 0px 0px 34px 7px rgba(0, 0, 0, 0.75);
}

.btn-social {
  background: rgb(7, 7, 7) !important;
  border: 0px solid #2a2b2c !important;
  border-radius: 10px !important;
  color: white !important;
  width: 100%;
  height: 3.5rem;
  font-size: 25px;
  outline: none !important;
}
.btn-social:hover {
  background: #2a2b2c !important;
  outline: none !important;
}

.btn-outline {
  background: transparent !important;
  border: 6px solid #2a2b2c !important;
  border-radius: 40px !important;
  width: 100% !important;
  color: white !important;
  height: 3rem !important;
}
.btn-outline:hover,
.btn-outline:focus {
  background: #2a2b2c !important;
}

.btn-outline-add {
  background: transparent !important;
  border: 6px solid #2a2b2c !important;
  border-radius: 40px !important;
  width: 100% !important;
  color: white !important;
  height: 2.3rem !important;
}
.btn-outline-add:hover,
.btn-outline-add:focus {
  background: #2a2b2c !important;
}

.btn-login {
  width: 100% !important;
  position: fixed !important;
  bottom: 0rem;
  left: 0rem;
  right: 0rem;
  height: 3.7rem !important;
  border-radius: 0px !important;
}

.btn-host {
  background: linear-gradient(
    90deg,
    rgba(38, 45, 255, 1) 0%,
    rgba(13, 19, 179, 1) 100%
  ) !important;
  width: 100% !important;
  position: fixed !important;
  bottom: 0rem;
  left: 0rem;
  right: 0rem;
  height: 3.7rem !important;
}
.btn-host:hover {
  background: linear-gradient(
    90deg,
    rgb(22, 29, 221) 0%,
    rgb(6, 10, 138) 100%
  ) !important;
  width: 100% !important;
  position: fixed !important;
  bottom: 0rem;
  left: 0rem;
  right: 0rem;
  height: 3.7rem !important;
}

.btn-send {
  border: 0 solid !important;
  width: 55px;
  height: 55px;
  border-radius: 50% !important;
  padding: 0;
  box-shadow: none !important;
  background: linear-gradient(
    180deg,
    rgb(241, 97, 30) 0%,
    rgb(243, 37, 37) 100%
  );
  z-index: 2 !important;
}
.btn-send:hover,
.btn-send:focus {
  filter: brightness(1.1);
  outline: none;
}

.btn__code {
  height: 4rem !important;
  width: 100%;
  border-radius: 10px !important;
  background: #fe500033;
  color: #fe5000 !important;
}
.btn__code:hover {
  background: #fe5000 !important;
  color: white !important;
}
.btn__code:focus {
  background: #fe5000 !important;
  color: white !important;
}

.btn__add {
  margin-top: -0.6rem;
  margin-bottom: 1rem;
  color: white !important;
  cursor: pointer;
  font-size: 15px;
}
.btn__add:hover {
  color: #fe5000 !important;
}

.btn__bancos {
  height: 4rem !important;
  width: 100%;
  border-radius: 10px !important;
  background: linear-gradient(
    90deg,
    rgba(38, 45, 255, 1) 0%,
    rgba(13, 19, 179, 1) 100%
  );
  color: white !important;
}

.planes-grid-content {
  width: 100%;
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  grid-gap: 20px;
  grid-auto-rows: minmax(100px, auto);
}

.overlay-card-planes {
  width: 100%;
  overflow: hidden;
  position: relative;
  border-radius: 30px !important;
}
.card_planes {
  background: white;
  min-height: 5rem;
  height: auto;
  width: 5rem;
  padding-top: 1.5rem;
  padding-left: 0.5rem;
  padding-bottom: 0.1rem;
  border-radius: 20px;
}
.card_planes text {
  font-size: 65px;
}
.card_planes p {
  font-size: 16px;
  line-height: 15px;
  margin: 0;
}

.calendar {
  padding-top: 4rem;
}


@media only screen and (max-width: 900px) {
  .btn_cancel_banco {
    width: 3rem !important;
    height: 3rem !important;
    position: relative;
    left: 10rem;
    top: -3.5rem;
    background: #fa0000 !important;
    z-index: 1 !important;
  }
  .btn_cancel_banco:hover {
    background: #500606 !important;
    box-shadow: 0px 0px 34px 7px rgba(0, 0, 0, 0.75);
  }

  .card_planes {
    position: absolute;
    left: 0.4rem;
    top: 0.4rem;
    background: white;
    height: 4rem;
    width: 4rem;
    padding-top: 1rem;
    padding-left: 0.5rem;
    border-radius: 20px;
  }
  .card_planes text {
    font-size: 50px;
  }
  .card_planes p {
    font-size: 12px;
    line-height: 15px;
  }

  .btn-login {
    width: 100% !important;
    position: fixed;
    bottom: 0rem;
    left: 0rem;
    right: 0rem;
    height: 3.7rem !important;
  }

  .btn-antigua {
    width: 100% !important;
    position: fixed;
    top: 3.5rem;
    left: 0rem;
    right: 0rem;
    height: 3.7rem !important;
    background-attachment: fixed;
    background-repeat: no-repeat !important;
    background-position: center !important;
    background-size: cover;
    background-image: url(https://muniantigua.gob.gt/legado/assets/fondo5.jpg) !important;
    color: white !important;
    padding: 1rem;
  }

  .calendar {
    padding-top: 4rem;
  }

  .calendar-dates {
    position: fixed !important;
    z-index: 0;
  }
  .calendar-time {
    background: #141414 !important;
    margin-top: 20rem;
    padding-top: 1rem !important;
    padding-bottom: 25rem;
    padding-left: 2rem !important;
    padding-right: 2rem !important;
    border-radius: 40px 40px 0 0;
  }

  .btn-send {
    margin-top: 0.8rem;
    border: 0 solid !important;
    width: 55px;
    height: 55px;
    border-radius: 50% !important;
    padding: 0;
    box-shadow: none !important;
    background: linear-gradient(
      180deg,
      rgb(241, 97, 30) 0%,
      rgb(243, 37, 37) 100%
    );
    z-index: 2 !important;
  }
  .btn-send:hover,
  .btn-send:focus {
    filter: brightness(1.1);
    outline: none;
  }
}

.btn-cancel {
  margin: 1rem !important;
  width: 50px !important;
  height: 50px;
  position: relative;
}
.btn-cancel i {
  font-size: 18px;
  margin-left: -0.1rem;
}

.btn-enviar {
  background: black !important;
  color: #fe5000 !important;
}

.btn-primary-contraste:hover {
  background: #fe5000 !important;
  color: white !important;
}

.ant-upload.ant-upload-select-picture-card {
  position: absolute !important;
  bottom: 6rem;
  right: 1.5rem;
  width: 3.7rem !important;
  height: 3.7rem !important;
  margin-right: 8px;
  margin-bottom: 8px;
  text-align: center;
  vertical-align: top;
  background-color: #242429 !important;
  border: 0px dashed #d9d9d9 !important;
  border-radius: 70px !important;
  cursor: pointer;
  transition: border-color 0.3s;
}
.ant-upload.ant-upload-select-picture-card > .ant-upload {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100%;
  text-align: center;
}
.ant-upload.ant-upload-select-picture-card:hover {
  border-color: #1890ff;
}

.ant-modal-header {
  padding: 16px 24px;
  color: rgba(0, 0, 0, 0.85);
  background: rgb(37, 37, 37) !important;
  border-bottom: 0px solid #f0f0f0 !important;
  border-radius: 20px 20px 0 0 !important;
}

.ant-modal-mask {
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 1000;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.788) !important;
}

.ant-modal-content {
  position: relative;
  background-color: rgb(37, 37, 37) !important;
  background-clip: padding-box;
  border: 0;
  border-radius: 20px !important;
  box-shadow: 0 3px 6px -4px rgba(0, 0, 0, 0.12),
    0 6px 16px 0 rgba(0, 0, 0, 0.08), 0 9px 28px 8px rgba(0, 0, 0, 0.05);
  pointer-events: auto;
}
.ant-modal-close-x {
  display: block;
  width: 76px !important;
  height: 76px !important;
  font-size: 20px !important;
  color: rgb(0, 0, 0);
  font-style: bold !important;
  line-height: 56px;
  text-align: center;
  text-transform: none;
  text-rendering: auto;
}

.ant-modal-footer {
  padding: 20px 16px !important;
  text-align: right;
  background: transparent;
  border-top: 0px solid #f0f0f0 !important;
  border-radius: 0 0 20px 20px !important;
}

.btn-fixed-bottom {
  background: linear-gradient(
    180deg,
    rgb(241, 97, 30) 0%,
    rgb(243, 37, 37) 100%
  ) !important;
  color: white !important;
  font-size: 20px !important;
  width: 100%;
  height: 4rem;
  position: fixed !important;
  bottom: 0rem;
  left: 0;
  border-radius: 0px !important;
}

.btn-metodo-pago {
  /* position: absolute !important;
  left: 0;
  right: 0;
  bottom: 0; */

  height: 3rem;
  max-width: 100%;
}

.add-tarjeta {
  width: 50% !important;
}

.add-tarjeta2 {
  margin: 0rem;
  margin-bottom: 0rem;
}

.btn-app {
  width: 20% !important;
  padding: 0.6rem !important;
  border-radius: 50px;
}

@media only screen and (max-width: 900px) {
  .add-tarjeta {
    width: 100% !important;
  }
  .add-tarjeta2 {
    margin: 0.5rem;
  }

  .ant-upload.ant-upload-select-picture-card {
    bottom: 4rem;
  }

  .btn-metodo-pago {
    position: fixed !important;
    margin-left: 0;
    margin-right: 0;
    margin-bottom: 0;
    height: 4rem;
    border: 0px !important;
    border-radius: 0px !important;
    width: 100% !important;
  }
}

.tutorial {
  z-index: 11 !important;
  background-color: #171718;
}

.btn-primary-navbar {
  background: linear-gradient(
    180deg,
    rgb(241, 97, 30) 0%,
    rgb(243, 37, 37) 100%
  );
  color: white !important;
  width: 6rem;
  font-size: 20px !important;
  border-radius: 50px;
  border: 0px !important;
  padding-left: 1rem;
  padding-right: 1rem;
}
.btn-primary-navbar:hover {
  background: linear-gradient(
    180deg,
    rgb(207, 81, 22) 0%,
    rgb(207, 25, 25) 100%
  );
  color: white !important;
  box-shadow: 0 0.15em 0.5em 0 rgba(0, 0, 0, 0.171);
}
.btn-primary-navbar:focus {
  background: linear-gradient(
    180deg,
    rgb(207, 81, 22) 0%,
    rgb(207, 25, 25) 100%
  );
  color: white !important;
  box-shadow: 0 0.15em 0.5em 0 rgba(0, 0, 0, 0.171);
}

.btn-primary-navbar-host {
  background: linear-gradient(
    90deg,
    rgba(38, 45, 255, 1) 0%,
    rgba(13, 19, 179, 1) 100%
  ) !important;
  color: white !important;
  width: 6rem;
  font-size: 22px !important;
  border-radius: 50px;
  border: 0px !important;
}
.btn-primary-navbar-host:hover {
  background: linear-gradient(
    180deg,
    rgb(207, 81, 22) 0%,
    rgb(207, 25, 25) 100%
  );
  color: white !important;
  box-shadow: 0 0.15em 0.5em 0 rgba(0, 0, 0, 0.171);
}
.btn-primary-navbar-host:focus {
  background: linear-gradient(
    180deg,
    rgb(207, 81, 22) 0%,
    rgb(207, 25, 25) 100%
  );
  color: white !important;
  box-shadow: 0 0.15em 0.5em 0 rgba(0, 0, 0, 0.171);
}

.btn-primary {
  background: #fe5000 !important;
  color: #000000 !important;
  width: 100%;
  font-size: 22px !important;
  border: 0px solid;
  outline: none !important;
}
.btn-primary:hover{
  background: #fa0000 !important;
  color: #171718 !important;
  -webkit-transition: all 0.3s !important;
  -moz-transition: all 0.3s !important;
  -o-transition: all 0.3s !important;
  transition: all 0.3s !important;
  outline: none !important;
}

.btn-black {
  background: transparent !important;
  color: #cacaca !important;
  height: 2.3rem;
  width: 100%;
  font-size: 15px !important;
}
.btn-black:hover,
.btn-black:focus {
  background: transparent !important;
  color: #fe5000 !important;
  -webkit-transition: all 0.3s !important;
  -moz-transition: all 0.3s !important;
  -o-transition: all 0.3s !important;
  transition: all 0.3s !important;
}

.btn-primary2 {
  background: #fe5000 !important;
  color: #000000 !important;

  font-size: 22px !important;
}
.btn-primary2:hover,
.btn-primary2:focus {
  background: #ad4413 !important;
  color: #000000 !important;
  -webkit-transition: all 0.3s !important;
  -moz-transition: all 0.3s !important;
  -o-transition: all 0.3s !important;
  transition: all 0.3s !important;
  -webkit-box-shadow: 0px 64px 154px -2px rgba(0, 0, 0, 0.61) !important;
  -moz-box-shadow: 0px 64px 154px -2px rgba(0, 0, 0, 0.61) !important;
  box-shadow: 0px 64px 154px -2px rgba(0, 0, 0, 0.61) !important;
}
.btn-primary-host {
  background: #262dff !important;
  color: #000000 !important;
  width: 100%;
  font-size: 22px !important;
}
.btn-primary-host:hover,
.btn-primary-host:focus {
  background: #0d13b3 !important;
  color: #000000 !important;
  -webkit-transition: all 0.3s !important;
  -moz-transition: all 0.3s !important;
  -o-transition: all 0.3s !important;
  transition: all 0.3s !important;
  -webkit-box-shadow: 0px 64px 154px -2px rgba(0, 0, 0, 0.61) !important;
  -moz-box-shadow: 0px 64px 154px -2px rgba(0, 0, 0, 0.61) !important;
  box-shadow: 0px 64px 154px -2px rgba(0, 0, 0, 0.61) !important;
}

.btn-secondary {
  background: rgb(12, 12, 12) !important;
  color: white !important;
  width: 100%;
  font-size: 22px !important;
  border-radius: 50px !important;
  border: 0px !important;
}
.btn-secondary:hover,
.btn-secondary:focus {
  background: rgb(34, 34, 34) !important;
  color: white !important;
  -webkit-transition: all 0.3s !important;
  -moz-transition: all 0.3s !important;
  -o-transition: all 0.3s !important;
  transition: all 0.3s !important;
  -webkit-box-shadow: 0px 64px 154px -2px rgba(0, 0, 0, 0.61) !important;
  -moz-box-shadow: 0px 64px 154px -2px rgba(0, 0, 0, 0.61) !important;
  box-shadow: 0px 64px 154px -2px rgba(0, 0, 0, 0.61) !important;
}

@media only screen and (max-width: 900px) {
  .btn-login-first {
    border-radius: 10px !important;
  }

  .btn-primary-navbar {
    background: linear-gradient(
      180deg,
      rgb(241, 97, 30) 0%,
      rgb(243, 37, 37) 100%
    );
    color: white !important;
    width: 6rem;
    font-size: 22px !important;
    border-radius: 50px !important;
    border: 0px !important;
    padding-left: 1rem;
    padding-right: 1rem;
  }
  .btn-primary-navbar:hover {
    background: linear-gradient(
      180deg,
      rgb(207, 81, 22) 0%,
      rgb(207, 25, 25) 100%
    );
    color: white !important;
    box-shadow: 0 0.15em 0.5em 0 rgba(0, 0, 0, 0.171);
  }
  .btn-primary-navbar:focus {
    background: linear-gradient(
      180deg,
      rgb(207, 81, 22) 0%,
      rgb(207, 25, 25) 100%
    );
    color: white !important;
    box-shadow: 0 0.15em 0.5em 0 rgba(0, 0, 0, 0.171);
  }

  .btn-primary-navbar-sm {
    position: absolute;
    right: 1rem;
    background: linear-gradient(
      180deg,
      rgb(241, 97, 30) 0%,
      rgb(243, 37, 37) 100%
    );
    color: white !important;
    width: 2rem;
    height: 2rem;
    border-radius: 50px !important;
    border: 0px !important;
    font-size: 14px !important;
  }
  .btn-primary-navbar-sm:hover {
    background: linear-gradient(
      180deg,
      rgb(207, 81, 22) 0%,
      rgb(207, 25, 25) 100%
    );
    color: white !important;
    box-shadow: 0 0.15em 0.5em 0 rgba(0, 0, 0, 0.171);
  }
  .btn-primary-navbar-sm:focus {
    background: linear-gradient(
      180deg,
      rgb(207, 81, 22) 0%,
      rgb(207, 25, 25) 100%
    );
    color: white !important;
    box-shadow: 0 0.15em 0.5em 0 rgba(0, 0, 0, 0.171);
  }

  .btn-primary {
    background: #fe5000 !important;
    color: #000000 !important;
    width: 100%;
    font-size: 22px !important;
  }
  .btn-primary:hover,
  .btn-primary:focus {
    background: #fa0000 !important;
    color: #411704 !important;
    -webkit-transition: all 0.3s !important;
    -moz-transition: all 0.3s !important;
    -o-transition: all 0.3s !important;
    transition: all 0.3s !important;
    -webkit-box-shadow: 0px 64px 154px -2px rgba(0, 0, 0, 0.61) !important;
    -moz-box-shadow: 0px 64px 154px -2px rgba(0, 0, 0, 0.61) !important;
    box-shadow: 0px 64px 154px -2px rgba(0, 0, 0, 0.61) !important;
  }
  .btn-primary-host {
    background: #262dff !important;
    color: #000000 !important;
    width: 100%;
    font-size: 22px !important;
  }
  .btn-primary-host:hover,
  .btn-primary-host:focus {
    background: #0d13b3 !important;
    color: #000000 !important;
    -webkit-transition: all 0.3s !important;
    -moz-transition: all 0.3s !important;
    -o-transition: all 0.3s !important;
    transition: all 0.3s !important;
    -webkit-box-shadow: 0px 64px 154px -2px rgba(0, 0, 0, 0.61) !important;
    -moz-box-shadow: 0px 64px 154px -2px rgba(0, 0, 0, 0.61) !important;
    box-shadow: 0px 64px 154px -2px rgba(0, 0, 0, 0.61) !important;
  }
}

.card-nft{
  margin-top: 10.3rem;
  position: fixed;
  background-color: black !important;
  border-radius: 30px;
  padding: 1rem;
  padding-top: 0.5rem;
  box-shadow: 0px 0px 34px 7px rgba(0, 0, 0, 0.190);
}

.card-nft-ss{
  margin-top: 10.3rem;
  position: fixed;
  background-color: rgb(78, 8, 94) !important;
  border-radius: 30px;
  padding: 1rem;
  padding-top: 0.5rem;
  box-shadow: 0px 0px 34px 7px rgba(0, 0, 0, 0.190);
  width: 240px;
  background-attachment: fixed;
  background-repeat: no-repeat !important;
  background-position: center !important;
  background-size: cover;
  background-image: url(https://muniantigua.gob.gt/legado/assets/fondo5.jpg) !important;
}
.ss {
  width: 200px;
  border-radius: 30px;
}
.logoAyuntamiento {
  width: 100px;
  margin-top: 2rem;
}
.logoAyuntamiento2 {
  width: 40px;
  margin-top: 0rem;
}

.btn-primary-reserva {
  background: #fe50002a;
  color: #fe5000 !important;

  font-size: 22px !important;
  border-radius: 50px !important;
  border: 0px !important;
  padding-left: 1rem;
  padding-right: 1rem;
}
.btn-primary-reserva:hover,
.btn-primary-reserva:focus {
  background: #fe500046;
  color: #fe5000 !important;
  -webkit-transition: all 0.7s !important;
  -moz-transition: all 0.7s !important;
  -o-transition: all 0.7s !important;
  transition: all 0.7s !important;
}

.btn-primary-login {
  background: #171718;
  color: #fe5000 !important;
  border-radius: 50px !important;
  border: 0px !important;
  padding-left: 1rem;
  padding-right: 1rem;
}
.btn-primary-login:hover,
.btn-primary-login:focus {
  background: #232324;
  color: #ff5100 !important;
  -webkit-transition: all 0.7s !important;
  -moz-transition: all 0.7s !important;
  -o-transition: all 0.7s !important;
  transition: all 0.7s !important;
}
.btn-primary-right {
  position: absolute;
  right: 30%;
  bottom: 2rem;
}
.btn-primary-left {
  position: absolute;
  left: 30%;
  bottom: 2rem;
}

.btn-primary-goback {
  width: 2.7rem;
  background-color: transparent !important;
  border: 3px solid #fe50002d !important;
  border-radius: 50rem;
  color: #fe5000;
}
.btn-primary-reserva-host {
  background: linear-gradient(
    90deg,
    rgba(38, 45, 255, 1) 0%,
    rgba(13, 19, 179, 1) 100%
  );
  color: white !important;
  width: 7rem;
  font-size: 22px !important;
  border-radius: 20px !important;
  border: 0px !important;
  margin-top: -0.4rem !important;
}
.btn-primary-reserva-host:hover,
.btn-primary-reserva-host:focus {
  background: linear-gradient(90deg, rgb(24, 31, 226) 0%, rgb(7, 11, 122) 100%);
  color: white !important;
}

.btn-reserva {
  position: absolute !important;
  margin-bottom: 2.7rem !important;
  right: 0rem !important;
  float: right !important;
  margin-right: 0rem !important;
  height: 3.7rem !important;
}

.btn-lg {
  padding: 0.6rem !important;
  width: 9rem;
  font-size: 27px !important;
}
.btn-lg:hover {
  background: rgb(61, 61, 61) !important;
}

.inputs-lg {
  padding: 0.6rem !important;
  width: 20rem !important;
  font-size: 14px !important;

  background-color: #2a2b2c !important;
  background-clip: padding-box;
  border: 0px solid #ced4da !important;
  border-radius: 10px !important;
  transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
  color: white !important;
}

.inputs-lg-textarea {
  height: 7rem !important;
}

.outline-none {
  outline: none;
}

.page-titulo {
  padding-bottom: 1rem;
}

.page-header-info {
  margin-top: 0rem;
  padding-top: 0rem;
  background-attachment: fixed;
  background-repeat: no-repeat !important;
  background-position: center !important;
  background-size: cover;
  height: 100vh;
  background-image: url(/src/assets/images/patterns.png) !important;
}

.page-header-container {
  margin-left: 8%;
  margin-right: 8%;
}

.page-header-container-nft {
  padding-top: 8% !important;
  margin-left: 8% !important;
  margin-right: 8% !important;
}

.container-gustos {
  margin-left: 3%;
  margin-right: 3%;
}



.page-back-1 {
  position: relative;
  background-attachment: fixed !important;
  background-repeat: no-repeat !important;
  background-position: center !important;
  background-size: cover !important;
  background: url(/src/assets/images/travel3.png);
  background-color: #0f0f0f;
  height: 700px;
  margin-top: -2rem;

  box-shadow: inset 0px -200px 300px -29px rgba(23,23,24,1);
}

.css-gradient-bg {
  background: linear-gradient(125deg, #fe5000, #fa0000, #8ec61d);
  background-size: 600% 600%;

  -webkit-animation: AnimationName 16s ease infinite;
  -moz-animation: AnimationName 16s ease infinite;
  -o-animation: AnimationName 16s ease infinite;
  animation: AnimationName 16s ease infinite;
}

@-webkit-keyframes AnimationName {
  0% {
    background-position: 17% 0%;
  }
  50% {
    background-position: 84% 100%;
  }
  100% {
    background-position: 17% 0%;
  }
}
@-moz-keyframes AnimationName {
  0% {
    background-position: 17% 0%;
  }
  50% {
    background-position: 84% 100%;
  }
  100% {
    background-position: 17% 0%;
  }
}
@-o-keyframes AnimationName {
  0% {
    background-position: 17% 0%;
  }
  50% {
    background-position: 84% 100%;
  }
  100% {
    background-position: 17% 0%;
  }
}
@keyframes AnimationName {
  0% {
    background-position: 17% 0%;
  }
  50% {
    background-position: 84% 100%;
  }
  100% {
    background-position: 17% 0%;
  }
}

.page-parati {
  padding-top: 9.5rem;
  /*top: 9rem !important;
    position: absolute;
    left: 5%;
    right: 5%;*/
}
.notfound {
  width: 30%;
}
.text__tutorial {
  width: 100%;
  padding: 1rem;
}
.text__tutorial p {
  width: 700px;
  position: relative;
  margin: auto;
}
.img__tutos {
  width: 100% !important;
  position: relative;
  margin: auto;
}

.categoria-parati {
  z-index: 1 !important;
  position: absolute;
  margin-top: -3rem !important;
}

.page-tusexperiencias {
  margin-top: 9.5rem !important;
}

.page-calendario {
  padding-top: 8rem;
}

:root {
  --fc-list-event-dot-width: 20px !important;
  --fc-list-event-hover-bg-color: #000000 !important;
}

.fc-theme-standard .fc-list {
  border: 1px solid transparent !important;
  border: 1px solid transparent !important;
}

.fc .fc-list-empty {
  background-color: rgb(8, 8, 8) !important;
  background-color: var(--fc-neutral-bg-color, transparent) !important;
  height: 50% !important;
  display: flex;
  justify-content: center;
  align-items: center; /* vertically aligns fc-list-empty-inner */
  border-radius: 2rem !important;
}
.fc .fc-list-sticky .fc-list-day > * {
  /* the cells */
  position: -webkit-sticky;
  color: black !important;
  position: sticky;
  top: 0;
  background: black !important;
  background: var(
    --fc-page-bg-color,
    #fff
  ); /* for when headers are styled to be transparent and sticky */
}
.fc .fc-list-event-dot {
  display: inline-block;
  box-sizing: content-box;
  width: 0;
  height: 0;
  border: 5px solid #262dff !important;
  border: calc(var(--fc-list-event-dot-width, 10px) / 2) solid
    var(--fc-event-border-color, #262dff) !important;
  border-radius: 5px;
  border-radius: calc(var(--fc-list-event-dot-width, 10px) / 2);
}
.fc .fc-list-day-cushion:after {
  content: "";
  clear: both;
  display: table; /* clear floating */
}
.fc-theme-standard .fc-list-day-cushion {
  background-color: rgba(255, 255, 255, 0.164) !important;
  background-color: var(--fc-neutral-bg-color, rgba(0, 0, 0, 0.226)) !important;
}

.fc .fc-list-table {
  width: 100%;
  border-style: hidden !important; /* kill outer border on theme */
  border-radius: 10rem !important;
}
.fc .fc-list-table tr > * {
  border-left: 0;
  border-right: 0;
  border-style: hidden !important;
}
.fc .fc-list-sticky .fc-list-day > * {
  /* the cells */
  position: -webkit-sticky;
  position: sticky;
  top: 0;
  background: #fff;
  background: var(
    --fc-page-bg-color,
    #fff
  ); /* for when headers are styled to be transparent and sticky */
}
.fc .fc-list-table th {
  padding: 0; /* uses an inner-wrapper instead... */
}
.fc .fc-list-table td,
.fc .fc-list-day-cushion {
  padding: 8px 14px;
}
.fc-direction-ltr .fc-list-day-text,
.fc-direction-rtl .fc-list-day-side-text {
  float: left;
  color: rgb(170, 170, 170);
}
.fc-direction-ltr .fc-list-day-side-text,
.fc-direction-rtl .fc-list-day-text {
  float: right;
  color: rgb(170, 170, 170);
}
/* make the dot closer to the event title */
.fc-direction-ltr .fc-list-table .fc-list-event-graphic {
  padding-right: 0;
}
.fc-direction-rtl .fc-list-table .fc-list-event-graphic {
  padding-left: 0;
}
.fc .fc-list-event.fc-event-forced-url {
  cursor: pointer; /* whole row will seem clickable */
}
.fc .fc-list-event {
  background-color: rgba(0, 0, 0, 0.486) !important;
  border-radius: 2rem !important;
}

.fc .fc-list-event:hover td {
  background-color: black !important;
  background-color: var(--fc-list-event-hover-bg-color, #f5f5f5);
}

@media only screen and (max-width: 900px) {
  .page-header-vr {
    margin-top: -8rem;
    padding-top: 8rem;
    background-attachment: fixed;
    background-repeat: no-repeat;
    background-position: top;
    background-size: 600px;
    height: 700px;
    box-shadow: inset 0px -368px 300px -100px #171718;
  }

  .page-parati {
    padding-top: 13rem;
    /*top: 9rem !important;
    position: absolute;
    left: 5%;
    right: 5%;*/

    background: #171718;
    border-radius: 30px 30px 0 0;
    box-shadow: 0px 9px 46px 38px rgba(23, 23, 24, 1);
  }
  .notfound {
    width: 70%;
  }
  .text__tutorial {
    width: 100%;
  }
  .text__tutorial p {
    width: 100%;
    position: relative;
    margin: 0;
  }
  .img__tutos {
    width: 70% !important;
    position: relative;
    margin: auto;
  }

  .page-tusexperiencias {
    position: absolute;
    top: -4.6rem !important;
    background: #171718;
    border-radius: 30px 30px 0 0;
    box-shadow: 0px 9px 46px 38px rgba(23, 23, 24, 1);
  }

  .categoria-parati {
    z-index: 1 !important;
    position: absolute;
    margin-top: -2.5rem !important;
  }
}

.page-header-first {
  margin-top: 0rem;
  padding-top: 8rem;
  background-attachment: fixed;
  background-repeat: no-repeat;
  background-position: top;
  background-size: cover;
  height: 430px;
  box-shadow: inset 0px -368px 300px -100px #171718;
}

@media only screen and (max-width: 900px) {
  .page-header-first {
    position: fixed;
    top: 0;
    width: 400px;
    padding-top: 2rem;
    height: 100px;
    z-index: -1 !important;
    background: #171718 !important;
  }
  .page-calendario {
    padding-top: 10rem;
  }
}

.btn-primary-menu {
  width: 10rem;
  color: #ffffff !important;
  font-size: 25px !important;
  border-radius: 10px;
}
.btn-primary-menu:focus {
  color: #fe5000 !important;
  text-decoration: none;
}

.btn-primary-menu-first {
  width: 10rem;
  color: #171718 !important;
  padding-bottom: 0.1rem !important;
  font-size: 20px !important;
  padding-top: 0.7rem !important;
  padding-bottom: 0.7rem !important;
  border-radius: 50px;
}
.btn-primary-menu-first:hover,
.btn-primary-menu-first:focus {
  color: #582005 !important;
  text-decoration: none;
}
.btn-primary-menu-first-sm {
  width: 10rem;
  color: #1717188c !important;
  padding-bottom: 0.1rem !important;
  font-size: 20px !important;
  padding-top: 0.3rem !important;
  padding-bottom: 0.7rem !important;
  border-radius: 50px;
}
.btn-primary-menu-first-sm:hover,
.btn-primary-menu-first-sm:focus {
  color: #582005 !important;
  text-decoration: none;
}

.btn-primary-menu-first-chat {
  color: #171718 !important;
  font-size: 25px !important;
  border-radius: 50px;
}
.btn-primary-menu-first-chat:hover,
.btn-primary-menu-first-chat:focus {
  color: #582005 !important;
  text-decoration: none;
}

/*.btn-primary-menu-first {
  width: 10rem;
  color: #cdcdcd !important;
  padding-bottom: 0.1rem !important;
  font-size: 20px !important;
  padding-top: 0.7rem !important;
  padding-bottom: 0.7rem !important;
  border-radius: 50px;
}
.btn-primary-menu-first:hover,
.btn-primary-menu-first:focus {
  color: #fe5000 !important;
  text-decoration: none;
}*/

.btn-primary-menu-first-host {
  width: 10rem;
  color: #cdcdcd !important;
  padding-bottom: 0.1rem !important;
  font-size: 20px !important;
  padding-top: 0.7rem !important;
  padding-bottom: 0.7rem !important;
  border-radius: 10px;
}
.nav-host-active,
.btn-primary-menu-first-host:hover,
.btn-primary-menu-first-host:focus {
  color: #262dff !important;
  text-decoration: none;
}

.btn-primary-menu-first-host-active .btn-primary-menu-circle {
  border-radius: 50px !important;
  background: #e2e6ea;
  width: 60px;
  font-size: 25px !important;
}
.btn-primary-menu-circle:hover,
.btn-primary-menu-circle:focus {
  background: #fe500034;
  color: #fe5000 !important;
  width: 60px;
}

.btn-primary-sidebar {
  color: rgb(56, 56, 56) !important;
  padding-bottom: 0.1rem !important;
  font-size: 24px !important;
  padding-top: 0.8rem !important;
  padding-bottom: 0.8rem !important;
}

.form-map {
  display: block;
  width: 100% !important;
  height: 4rem !important;
  padding: 1.2rem 1rem !important;
  font-size: 1rem;
  background-color: #2a2b2c !important;
  border: 0px solid #ced4da !important;
  border-radius: 100px !important;
  transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
  color: white !important;
  outline: none !important;
}
.form-map:hover,
.form-map:focus {
  background-color: #1d1f20 !important;
  box-shadow: 0 0em 0em 0 rgba(0, 0, 0, 0.171) !important;
}

.form-control {
  display: block;
  width: 100% !important;
  height: 4rem !important;
  padding: 1.2rem 1rem !important;
  font-size: 1rem;
  background-color: #2a2b2c !important;
  background-clip: padding-box;
  border: 0px solid #ced4da !important;
  border-radius: 10px !important;
  transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
  color: white !important;
}
.form-control:hover,
.form-control:focus {
  background-color: #1d1f20 !important;
  box-shadow: 0 0em 0em 0 rgba(0, 0, 0, 0.171) !important;
}

.form-control-textarea {
  display: block;
  width: 100% !important;
  height: 14rem !important;
  padding: 1.2rem 1rem !important;
  font-size: 1rem;
  background-color: #2a2b2c !important;
  background-clip: padding-box;
  border: 0px solid #ced4da !important;
  border-radius: 10px !important;
  transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
  color: white !important;
}
.form-control-textarea:hover,
.form-control-textarea:focus {
  background-color: #1d1f20 !important;
  box-shadow: 0 0em 0em 0 rgba(0, 0, 0, 0.171) !important;
}

.form-control-textarea-host {
  display: block;
  width: 100% !important;
  height: 15rem !important;
  padding: 1.2rem 1rem !important;
  font-size: 1rem;
  background-color: #2a2b2c !important;
  background-clip: padding-box;
  border: 0px solid #ced4da !important;
  border-radius: 10px !important;
  transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
  color: white !important;
}
.form-control-textarea-host:hover,
.form-control-textarea:focus {
  background-color: #1d1f20 !important;
  box-shadow: 0 0em 0em 0 rgba(0, 0, 0, 0.171) !important;
}

.form-control-login {
  height: 4rem !important;
  font-size: 1rem;
  font-weight: 400;
  line-height: 1.5;
  background: #2d3235 !important;
  background-clip: padding-box;
  border: 0px solid #ced4da !important;
  border-radius: 10px !important;
  padding: 0.3rem;
  transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
  color: white !important;
  padding-left: 1rem !important ;
  padding-right: 1.6rem;
  width: 100% !important;
}

.form-control-selector {
  font-size: 1rem;
  height: 4rem !important;
  font-weight: 400;
  line-height: 1.5;
  background: #2a2b2c !important;
  border: 0px solid #ced4da !important;
  border-radius: 10px !important;
  padding-left: 1rem !important;
  transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
  color: rgba(255, 255, 255, 0.411) !important;
}
.form-control-selector:hover {
  background-color: #383e42 !important;
  box-shadow: 0 0em 0em 0 rgba(0, 0, 0, 0.171) !important;
}

.form-control-chat {
  width: 100% !important;
  font-size: 1rem;
  font-weight: 400;
  line-height: 1.5;
  height: 2rem !important;
  background-color: #2d3235 !important;
  border: 0px solid #ced4da !important;
  border-radius: 50px !important;
  transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
  color: white !important;
}

.form-control-chat:hover,
.form-control-chat:focus {
  background-color: #383e42 !important;
  box-shadow: 0 0em 0em 0 rgba(0, 0, 0, 0.171) !important;
}

.form-control-search {
  display: block;
  width: 100% !important;
  height: 2rem !important;
  padding: 1.3rem 3rem !important;
  font-size: 1rem;
  font-weight: 400;
  line-height: 1.5;
  background-color: #17191a;
  background-clip: padding-box;
  border: 0px solid #ced4da !important;
  border-radius: 50px !important;
  transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
}
.form-control-search:hover,
.form-control-search:focus {
  background-color: #1d1f20 !important;
  box-shadow: 0 0em 0em 0 rgba(0, 0, 0, 0.171) !important;
}

@media only screen and (max-width: 900px) {
  .form-control-chat {
    margin-top: 0.8rem;
    margin-left: 1rem;
    font-size: 1rem;
    font-weight: 400;
    line-height: 1.5;
    background-color: #2d3235 !important;
    border: 0px solid #ced4da !important;
    border-radius: 50px !important;
    transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
    color: white !important;
  }

  .form-control-chat:hover,
  .form-control-chat:focus {
    background-color: #383e42 !important;
    box-shadow: 0 0em 0em 0 rgba(0, 0, 0, 0.171) !important;
  }

  .form-control-search-sm {
    display: block;
    width: 100% !important;
    margin-top: -0.4rem !important;
    margin-left: 0.8rem;
    margin-right: 0.8rem;
    font-size: 1rem;
    font-weight: 400;
    line-height: 1.5;
    background: transparent !important;

    border: 0px solid #ced4da !important;
    border-radius: 50px !important;
    transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
  }
  .form-control-search:hover,
  .form-control-search:focus {
    background-color: #2a2d2e !important;
    box-shadow: 0 0em 0em 0 rgba(0, 0, 0, 0.171) !important;
  }
}

.form-control-login-code {
  width: 100% !important;
  font-size: 1rem;
  font-weight: 400;
  line-height: 1.5;
  background-color: #2d3235 !important;
  background-clip: padding-box;
  border: 0px solid #ced4da !important;
  border-radius: 50px !important;
  transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
  color: white !important;
}

.form-control-login-code:hover,
.form-control-login-code:focus {
  background-color: #383e42 !important;
  box-shadow: 0 0em 0em 0 rgba(0, 0, 0, 0.171) !important;
}

.form-control-login-area {
  width: 100px !important;
  font-size: 1rem;
  font-weight: 400;
  line-height: 1.5;
  background-color: transparent !important;
  background-clip: padding-box;
  border: 0px solid #ced4da !important;
  border-radius: 50px !important;
  transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
  color: white !important;
  position: absolute;
  left: 0px;
}

.form-control-login-area:hover,
.form-control-login-area:focus {
  background-color: transparent !important;
  box-shadow: 0 0em 0em 0 rgba(0, 0, 0, 0.171) !important;
}

.container {
  margin-top: 2%;
  max-width: 1300px !important;
}

.container__tuto {
  padding-left: 6rem;
  padding-right: 6rem;
}

@media only screen and (max-width: 900px) {
  .container {
    margin-top: -5rem;
  }

  .page__header {
    height: 7rem !important;
    background-color: white;
  }
}

.nav-vertical {
  width: 10rem;
}

.favorites {
  z-index: 2;
  border-radius: 50px !important;
}

.navbar {
    padding-left: 0.8rem;
    padding-right: 0.8rem;
    margin-top: 2rem !important;
    margin-left: 2rem !important;
    margin-right: 2rem !important;
    padding-top: 0.8rem !important; 
    padding-bottom: 0.8rem !important; 
    border-radius: 50px !important;
    border: 0px solid rgba(255, 255, 255, 0.041) !important;
    background: linear-gradient(175deg, #fe5000, #fa0000);
    z-index: 2 !important;
    box-shadow: 0px 0px 0px 0px rgba(0,0,0,1);
  }
/*.navbar {
  padding-left: 2rem;
  padding-right: 2rem;
  padding-top: 0.8rem !important;
  padding-bottom: 0.8rem !important;
  border-bottom: 0px solid rgba(0, 0, 0, 0.253) !important;
  z-index: 2 !important;
  height: 5rem;
  background: #171718 !important;
}*/
.navbar-info {
  background: #17171893 !important;
}

.navbar-sm {
  padding-top: 1.5rem !important;
  margin-left: 2rem !important;
  margin-right: 1rem !important;
  margin-bottom: 1rem;
  width: 77% !important;
  height: 4.5rem;
  border-radius: 30px !important;
  z-index: 2 !important;
  box-shadow: 0px 0px 104px 0px rgba(0, 0, 0, 0.75);

  background: linear-gradient(175deg, #fe5000, #fa0000) !important;
  bottom: 1rem !important;
  left: 0rem !important;
  right: 1rem !important;

}

.navbar-sm text {
  position: relative !important;
  top: 0.2rem !important;
  left: 1rem !important;
}
.navbar-sm span {
  color: rgba(255, 255, 255, 0.568);
}

.navbar-titles {
  position: fixed !important;
  width: 100%;
  position: absolute;
  left: 0;
  height: 3.7rem;
  background: #171718;
  -webkit-box-shadow: -1px 1px 7px -1px rgba(0, 0, 0, 0.51);
  -moz-box-shadow: -1px 1px 7px -1px rgba(0, 0, 0, 0.51);
  box-shadow: -1px 1px 7px -1px rgba(0, 0, 0, 0.51);
  z-index: 1;
}
.navbar-titles-date {
  width: 100%;
  position: absolute;
  left: 0;
  height: 3.7rem;
  background: #171718;
}

.navbar-titles h5 {
  position: absolute !important;
  left: 4rem !important;
  top: 1.1rem;
}

.navbar-sm-chat {
  width: 100%;
  height: 3.7rem;
  border-radius: 50px;
  padding-top: 1.1rem !important;
  padding-bottom: 0rem !important;
  background: #2a2b2c;
  -webkit-box-shadow: -1px 1px 7px -1px rgba(0, 0, 0, 0.51);
  -moz-box-shadow: -1px 1px 7px -1px rgba(0, 0, 0, 0.51);
  box-shadow: -1px 1px 7px -1px rgba(0, 0, 0, 0.51);
  z-index: 2 !important;
}

.exp-reservada {
  color: white !important;
  font-size: 25px !important;
  height: 3rem;
}
.exp-reservada text {
  font-size: 20px;
}

.exp-fixed-bottom {
  color: #171718;
  position: fixed;
  bottom: 2rem !important;
  right: 2rem !important;
  left: auto !important;
  z-index: 10 !important;
  padding: 0;
}
.exp-fixed-bottom-open {
  position: fixed;
  bottom: 1.5rem !important;
  right: -15rem !important;
  left: auto !important;
}

.exp-fixed-bottom-metodo-pago {
  position: fixed !important;
  bottom: 1rem !important;
  right: -17rem !important;
}

.exp-fixed-bottom-help-old {
  position: fixed !important;
  bottom: 1rem !important;
  right: 1rem !important;
  width: 2.7rem !important;
  height: auto !important;
  border-radius: 50px;
  outline: none !important;
  padding: 0.7rem;
  color: #fe5000;
  background: #171718 !important;
  z-index: 10;
  box-shadow: 0px 0px 9px 0px rgba(0, 0, 0, 0.47);
}
.exp-fixed-bottom-help:hover {
  background-color: #1c1c1e !important;
}
.exp-fixed-bottom-help {
  position: absolute !important;
  left: 1rem;
  bottom: 2rem !important;
  border-radius: 50px;
  outline: none !important;
  padding: 0.7rem;
  color: #fe5000 !important;
  background: #171718 ;
  z-index: 10;
  box-shadow: 0px 0px 9px 0px rgba(0, 0, 0, 0.47);
}
.exp-fixed-bottom-help:hover {
  background-color: #1c1c1e !important;
}

.exp-fixed-top-help {
  position: fixed !important;
  z-index: 10 !important;
  top: 15% !important;
  right: 2rem !important;
  left: 2rem !important;
  width: auto !important;
  border-radius: 50px;
  outline: none !important;
  padding: 1rem;
  background: #441803;
}
.exp-fixed-top-notice {
  position: fixed !important;
  z-index: 10 !important;
  top: 12% !important;
  right: 2rem !important;
  left: 2rem !important;
  width: auto !important;
  border-radius: 50px;
  outline: none !important;
  padding: 1rem;
  background: #441803 !important;
  border-radius: 20px !important;
}

.exp-fixed-bottom-open-host {
  position: fixed;
  bottom: 2rem !important;
  right: -19rem !important;
  left: auto !important;
  background: linear-gradient(
    90deg,
    rgba(38, 45, 255, 1) 0%,
    rgba(13, 19, 179, 1) 100%
  ) !important;
}

.btn-reserva {
  width: 8rem !important;
  border: 0px solid;
  top: 0;
}

.navbar-exp-login {
  padding-top: 0.8rem !important;
  padding-bottom: 0.8rem !important;
  background: #171718;
  position: absolute;
  bottom: 10rem !important;
  align-content: center;
}
.exp-pasos {
  padding: 0.3rem;
  cursor: pointer;
  float: right;
  margin-right: 0rem;
  margin-top: 0rem;
}
.exp-pasos-prev {
  margin-top: 0.2rem;
  margin-left: 1rem;
  float: left;
  display: flex;
  align-items: center;
}
.exp-details {
  width: 40rem;
}
.exp-details text {
  font-size: 16px;
  color: rgba(255, 255, 255, 0.568);
}
.exp-details-about {
  background: #2a2b2c;
  border-radius: 10px;
  padding: 2rem;
}
.exp-galeria {
  position: none !important;
}
.exp-tabs {
  background: #171718;
  z-index: 2 !important;
  margin-top: 0rem;
  display: flex;
  justify-content: center;
  border-radius: 0px 0px 0 0;
}
.exp-review-comments {
  background: #2a2b2c;
  border-radius: 10px;
  padding: 1.5rem;
}

.exp-counter {
  padding: 2rem !important;
}

.exp-fixed-bottom-metodo-pago {
  position: fixed;
  bottom: 2rem !important;
  right: -17rem !important;
  border: 0 solid !important;
  width: 3.7rem;
  height: 3.7rem;
  border-radius: 50% !important;
  padding: 0.8rem;
  background: #fe50002a;
  color: #000000 !important;
  z-index: 2 !important;
  box-shadow: -1px 1px 7px -1px rgba(0, 0, 0, 0.51);
  margin-right: 20.5rem;
}
.exp-fixed-bottom-metodo-pago:focus,
.exp-fixed-bottom-metodo-pago:hover,
.exp-chat:hover,
.exp-chat:focus {
  background: #fe500046;
  color: black !important;
  outline: none;
}

.exp-chat-box {
  background: transparent;
  height: 3.7rem;
  width: 100%;
}

.exp-pay-card {
  padding-left: 1.7rem !important;
  padding-top: 1rem !important;
  padding-bottom: 1rem !important;
}

.counter {
  background: white !important;
}

.navbar-sm-more {
  width: 320px;
  border-radius: 10px;
  height: 3.7rem !important ;
  padding-top: 1.3rem;
  background: rgb(12, 12, 12);
  font-size: 30px !important;
  z-index: 10 !important;
}
.navbar-sm-more text {
  position: relative !important;
  top: 0.2rem !important;
  left: 0.4rem !important;
}
.navbar-sm-more span {
  color: rgba(255, 255, 255, 0.568);
}

.navbar-sm-more-host {
  width: 250px;
  border-radius: 10px;
  height: 3.7rem !important ;
  padding-top: 1rem;
  background: rgb(12, 12, 12);
  font-size: 30px !important;
  z-index: 10 !important;
}
.navbar-sm-more-host text {
  position: relative !important;
  top: 0.2rem !important;
  left: 0.4rem !important;
}
.navbar-sm-more-host span {
  color: rgba(255, 255, 255, 0.568);
}

.fav_fixed {
  position: fixed !important;
}

.page-pagos-host {
  padding-top: 10rem;
}

.exp-tabs-host {
  background: transparent !important;
  margin-right: 0rem;
  z-index: 1 !important;
}

.pagos-balance {
  font-size: 40px;
}

.nft {
  width: 20px;
  position: relative;
  margin-right: 5px;
}
.nft_data {
  background: #0e0e0e;
  padding-top: 0.3rem;
  padding-bottom: 0.3rem;
  padding-left: 0.6rem;
  border-radius: 20px;
  margin-left: 5px;
  margin-right: 5px;
  font-size: 12px;
}

.nft_data_inside {
  background: #0e0e0e;
  padding-top: 0.3rem;
  padding-bottom: 0.3rem;
  padding-left: 0.6rem;
  padding-top: 0.6rem;
  padding-bottom: 0.6rem;
  border-radius: 20px;
  margin-left: 5px;
  margin-right: 5px;
  font-size: 18px;
}

.pagos-balance span {
  font-size: 20px;
}

.card-balance {
  background: linear-gradient(
    90deg,
    rgba(38, 45, 255, 1) 0%,
    rgba(13, 19, 179, 1) 100%
  );
  padding: 1rem;
  padding-top: 0.6rem;
  border-radius: 10px !important;
  height: 10rem !important;
  margin-top: 7rem;
}

.card-balance text {
  font-size: 16px;
  color: #0d13b3;
}

.pagos {
  color: rgba(255, 255, 255, 0.575) !important;
}

.card-bancos {
  background: #101011 !important;
  padding: 1rem;
  padding-top: 1rem;
  border-radius: 10px !important;
  width: 30rem;
  height: auto;
  margin-bottom: 50px;
}

.card-bancos text {
  font-size: 16px;
  color: #080c77;
}

.pagos-graficas {
  width: 20rem !important;
  height: 20rem !important;
}

.pagos-bancos {
  width: 100%;
}

.search-movil {
  opacity: 0;
}

.exp-chat {
  width: 3.7rem;
  height: 3.7rem;
  margin-right: 17rem;
  z-index: 10 !important;
  border-radius: 50px;
}

.search-instant {
  margin-top: -120px;
}

@media only screen and (max-width: 1000px) {
  .search-instant {
    margin-top: -250px;
    padding-bottom: 0.5rem !important;
  }
  .search-movil {
    opacity: 1;
  }
  .card-bancos {
    width: 100%;
  }

  .card-bancos text {
    font-size: 16px;
    color: #0d13b3;
  }

  .pagos-bancos {
    width: 17.5rem;
  }

  .exp-tabs-host {
    background: #171718 !important;
  }

  .pagos-graficas {
    width: 14rem !important;
    height: 14rem !important;
    margin-left: 0.6rem;
    position: relative;
    left: 1rem;
    right: 0;
  }

  .pagos-balance {
    font-size: 40px;
  }
  .pagos-balance span {
    font-size: 20px;
  }

  .page-pagos-host {
    padding-top: 3rem;
  }

  .card-balance {
    width: 100%;
    margin-top: 1rem;
  }

  .pagos {
    color: rgba(255, 255, 255, 0.575) !important;
  }

  .navbar-sm {
    padding-top: 1.5rem !important;
    margin-left: auto;
    margin-right: auto;
    margin-bottom: 0rem;
    width: 100%;
    height: 4.5rem;
    border-radius: 0px;
    background: #141414;
    z-index: 3 !important;
  }

  .navbar-sm-host {
    padding-top: 1.5rem !important;
    margin-left: 1rem;
    margin-right: auto;
    margin-bottom: 1rem;
    width: 250px;
    height: 4.5rem;
    border-radius: 30px;
    background: #171718;
    z-index: 2 !important;
    box-shadow: 0px 0px 104px 0px rgba(0, 0, 0, 0.75);
  }

  .navbar-sm-more {
    border-radius: 0px;
    width: 100%;
    height: 3.7rem !important ;
    padding: 1.2rem;
    background: #171718;
    box-shadow: -1px 1px 7px -1px rgba(0, 0, 0, 0.51);
    z-index: 10 !important;
    margin-bottom: -0.2rem;
  }
  .navbar-sm-more-host {
    border-radius: 0px;
    width: 100%;
    height: 3.7rem !important ;
    padding: 1rem;
    background: #171718;
    box-shadow: -1px 1px 7px -1px rgba(0, 0, 0, 0.51);
    z-index: 10 !important;
    margin-bottom: -0.2rem;
  }
  .navbar-sm-first {
    height: 3.7rem !important ;
    padding: 0.7rem;
    background: #171718;
  }

  .exp-fixed-bottom {
    color: #171718;
    position: fixed;
    right: 0 !important;
    bottom: 0 !important;
    left: 0;
    z-index: 1030;
  }
  .exp-fixed-bottom-open {
    position: fixed;
    bottom: -3rem !important;
    right: -0.1rem !important;
    left: auto !important;
  }

  .exp-fixed-bottom-open-host {
    position: fixed;
    bottom: -3rem !important;
    right: -0.1rem !important;
    left: auto !important;
    background: linear-gradient(
      90deg,
      rgba(38, 45, 255, 1) 0%,
      rgba(13, 19, 179, 1) 100%
    ) !important;
  }

  .exp-fixed-upload {
    display: flex !important;
    justify-content: center !important;
    align-items: center !important;
  }

  .fav_fixed {
    position: fixed !important;
    left: 10rem;
  }

  .navbar-exp-login {
    padding-left: 0.9rem;
    padding-right: 1rem;
    padding-top: 1.2rem !important;
    padding-bottom: 1.2rem !important;
    background: #171718;
    cursor: pointer;
    -webkit-box-shadow: -1px 1px 7px -1px rgba(0, 0, 0, 0.51);
    -moz-box-shadow: -1px 1px 7px -1px rgba(0, 0, 0, 0.51);
    box-shadow: -1px 1px 7px -1px rgba(0, 0, 0, 0.51);
    bottom: 0rem !important;
  }
  .exp-pasos {
    float: right;
    cursor: pointer;
    margin-right: 0rem;
    margin-top: -0rem;
  }
  .exp-pasos-prev {
    border: 0 solid !important;
    width: 40px;
    height: 40px;
    border-radius: 50% !important;
    padding-top: 2px;
    box-shadow: none !important;
    background: #2a2b2c;
    margin-left: 1rem;
    margin-top: 0.8rem;
    z-index: 3 !important;
    font-size: 15px;
    
  }
  .exp-details {
    width: 100%;
  }
  .exp-details text {
    font-size: 16px;
    color: rgba(255, 255, 255, 0.568);
  }
  .exp-galeria {
    position: none !important;
    left: 0rem;
    right: 0rem;
    margin-bottom: 3.5rem;
  }
  .exp-tabs {
    background-color: #171718;
    display: flex;
    justify-content: center;
    margin-left: -0.6rem;
    margin-right: -0.6rem;
    border-radius: 0px 0px 0 0;
    z-index: 10 !important;
  }

  .explora {
    padding-top: 0rem !important;
    padding-bottom: 10rem !important;
  }

  .exp-upload {
    width: 2.7rem;
    height: 2.7rem;
    margin-right: 1rem;
    margin-bottom: 4.2rem;
    position: absolute;
    bottom: 2rem;
  }
  .exp-fixed-bottom-metodo-pago:hover,
  .exp-fixed-bottom-metodo-pago:focus,
  .exp-chat:hover,
  .exp-chat:focus {
    filter: brightness(1.1);
    outline: none;
  }

  .exp-fixed-bottom-metodo-pago {
    bottom: 1rem !important;
    right: -19rem !important;
  }
}

.nav-pills .show > .nav-link {
  background-color: #fe500015 !important;
  color: #fff;
  border-radius: 10px;
  outline: none;
}
.nav-link-active {
  color: #171718 !important;
  background: rgba(0, 0, 0, 0.264);
}
.nav-link-active-sm {
  color: #171718 !important;
}

.nav {
  position: fixed;
  width: 13rem !important;
}
.flex-column {
  width: 10rem;
}
.card-precio {
  font-size: 18px;
}

@media (max-width: 800px) {
  .card-columns {
    -webkit-column-count: 4 !important ;
    -moz-column-count: 4 !important;
    column-count: 4 !important;
    -webkit-column-gap: 1.25rem;
    -moz-column-gap: 1.25rem;
    column-gap: 1.25rem;
    orphans: 1;
    widows: 1;
  }
  .card-columns .card {
    display: inline-block;
    width: 100%;
  }
}

.pointer {
  cursor: pointer;
}
section {
  margin-top: 1rem;
  margin-bottom: 3rem;
}

.calificacion {
  margin-bottom: -4rem !important;
}

.card {
  background-color: transparent !important;
  border: 0px solid !important;
}

.card-border {
  border-radius: 20px !important;
  background-color: #17191a !important;
  height: 450px;
}
.card-border-planes {
  border-radius: 20px !important;
  background-color: #17191a !important;
  height: 400px;
}

.card-border-best {
  border-radius: 20px !important;
  background-color: #17191a !important;
  height: 380px;
}

.border-flag {
  border: 4px solid white;
}

.border-secondary {
  color: rgb(133, 19, 19) !important;
}

.card-etiqueta {
  font-size: 15px;
  color: rgba(0, 0, 0, 0.493) !important;
}

.text-paises {
  line-height: 100px;
}

.card-paises {
  height: 8rem;
}

@media only screen and (max-width: 900px) {
  .nft {
    width: 40px;
    position: relative;
    margin-right: 0px;
  }
  .exp-fixed-bottom-help {
    position: fixed !important;
    bottom: 3rem !important;
    right: 4rem !important;
    left: 4rem !important;
    height: auto !important;
    border-radius: 50px;
    outline: none !important;
    padding: 1rem;
    background: #fe5000;
    color: #171718 !important;
  }
  .exp-fixed-bottom-help:hover {
    background-color: #eb4d04 !important;
  }

  .btn-crypto {
    z-index: 10;
    position: fixed;
    bottom: 1.2rem !important;
    right: 1rem !important;
    background: #171718;
    color: #060953 !important;
    border-radius: 50%;
    height: 4rem;
    width: 4rem;
    box-shadow: 0px 0px 9px 0px rgba(0, 0, 0, 0.47);
  }
  .btn-crypto:hover {
    background-color: #1c1c1e !important;
  }

  .exp-chat {
    width: 3.2rem;
    height: 3.2rem;
    margin-right: 1rem;
    margin-bottom: 4.5rem;
    z-index: 10 !important;
  }

  .exp-upload {
    width: 3.2rem;
    height: 3.2rem;
    margin-right: 1rem;
    margin-bottom: 1.5rem;
  }

  .card-paises {
    height: 3rem;
  }

  .card-paises h1 {
    font-size: 20px !important;
  }
  .text-paises {
    line-height: 15px !important;
  }
  .text-paises-sm {
    display: block !important;
    font-size: 10px;
  }
  .card-border {
    border-radius: 20px !important;
    background-color: #17191a !important;
    height: 250px;
  }
  .card-border-best {
    border-radius: 20px !important;
    background-color: #17191a !important;
    height: 320px;
  }
}

.card-number {
  padding-left: 55px !important;
}

@media only screen and (width: 768px) {
  .card-border {
    border-radius: 20px !important;
    background-color: #17191a !important;
    height: 350px;
  }
}
@media only screen and (max-width: 600px) {
  .rs-drawer-right.rs-drawer-xs {
    width: 100% !important;
  }
}
.card-paises:hover {
  box-shadow: 0 0.15em 0.5em 0 rgba(0, 0, 0, 0.171) !important;
}

.card-footer {
  margin: 0.3rem !important;
  background: #fe500021 !important;
  padding: 0.4rem !important;
  border-radius: 0 0 20px 20px !important;
}

.card-img-overlay {
  position: none;
  background: rgb(0, 0, 0);
  background: linear-gradient(
    0deg,
    rgba(0, 0, 0, 0.788) 5%,
    rgba(0, 0, 0, 0) 74%
  );
  padding-left: 0.6rem;
  padding-right: 0.6rem;
  border-radius: 20px !important;
}

.card-bottom-fav {
  position: absolute;
  bottom: 1%;
  left: 8%;
  right: 8%;
}

.card-bottom {
  position: absolute;
  bottom: 0;
  left: 8%;
  right: 8%;
}

@media only screen and (max-width: 900px) {
  .card-bottom {
    position: absolute;
    bottom: 0%;
    left: 4%;
    right: 4%;
  }
}

.card-icon {
  font-size: 25px;
}

.card-icon:hover {
  color: #f86420 !important;
}

.card-hover {
  width: 100%;
  overflow: hidden;
  position: relative;
  -webkit-box-shadow: 1px 1px 3px 0 rgba(0, 0, 0, 0.3);
  -moz-box-shadow: 1px 1px 3px 0 rgba(0, 0, 0, 0.3);
  box-shadow: 1px 1px 3px 0 rgba(0, 0, 0, 0.3);
  border-radius: 30px !important;
}
.card-hover img {
  width: 100%;
  top: 0;
  position: relative;
  left: 0;
  -webkit-transform: scale(1.1);
  -moz-transform: scale(1.1);
  -ms-transform: scale(1.1);
  -o-transform: scale(1.1);
  transform: scale(1.1);
  -webkit-transition: -webkit-transform 0.3s;
  -moz-transition: -moz-transform 0.3s;
  -o-transition: -o-transform 0.3s;
  transition: transform 0.3s;
}
.card-hover .buttons {
  position: absolute;
  top: 3.5%;
  right: 4.5%;
}

.card-hover .buttons .fa {
  background: rgb(256, 256, 256);
  -webkit-border-radius: 50%;
  -moz-border-radius: 50%;
  border-radius: 50%;
  border: white !important;
  color: rgba(50, 50, 50, 0.9);
  display: inline-block;
  margin: 0 1px;
  line-height: 65px;
  font-size: 25px;
  text-align: center;
  text-decoration: none;
  width: 60px;
  height: 60px;
  -webkit-transition: all 0.3s;
  -moz-transition: all 0.3s;
  -o-transition: all 0.3s;
  transition: all 0.3s;
  opacity: 0;
  padding-right: 0.2rem;
}
.card-hover:hover img {
  filter: contrast(1.04) invert(0.18) saturate(2.38);
}
.card-hover:hover .ovrly {
  opacity: 1;
}
.card-hover:hover .buttons .fa {
  opacity: 1;
}

.card .icon {
  height: 25px;
  fill: #fe5000 !important;
}

.card .place {
  font-size: 12px;
  color: rgba(255, 255, 255, 0.562);
}

.card .avatar {
  height: 55px;
  box-shadow: 0 0.25em 0.5em 0 rgba(0, 0, 0, 0.24);
  
}

.card .avatar:hover {
  box-shadow: 0 0.25em 0.5em 0 rgba(0, 0, 0, 0.459);
}
.card .size-md {
  font-size: 18px !important;
}

.card .size-sm {
  font-size: 9px !important;
  line-height: 10px;
}

.img-paises {
  border-radius: 20px !important;
  margin: 0.3rem;
  width: 206px !important;
}

.text-trend {
  padding: 0.3rem;
  background: transparent !important;
}

.text-white2 {
  color: #cdcdcd;
}

.text-trend .text-size {
  font-size: 16px !important;
  color: #1c2836 !important;
}

.text-grey {
  color: #bbbbbb !important;
}

.orange__gradient {
  background: -webkit-linear-gradient(#fe5000, #fe0000);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}
.neon__blue__gradient {
  background: -webkit-linear-gradient(#262dff, #0d13b3);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}

.text-navbar-sm {
  font-size: 16px;
}

.exp-details-card {
  border: 2px solid rgb(14, 14, 14);
  border-radius: 20px;
  padding: 1rem;
}

.exp-titulo {
  margin-top: 1%;
  line-height: 80px;
  color: white !important;
  text-shadow: 0px 0px 30px rgba(0, 0, 0, 0.61);
}

.exp-header {
  margin-top: -1rem;
  z-index: -2 !important;
}
.exp-header-pic {
  margin-top: -1rem;
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;
  height: 110vh;
}

.title__info {
  font-size: 85px;
  line-height: 4rem;
  color: #f35626;
  background: linear-gradient(
    to right,
    #fe5000 20%,
    #fa0000 40%,
    #0044ff 60%,
    #fe5000 80%
  );
  -webkit-background-clip: text;
  background-clip: text;
  text-fill-color: transparent;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  background-size: 200% auto;
  animation: shine 10s linear infinite;
}

@keyframes shine {
  to {
    background-position: 200% center;
  }
}
.title__secundary {
  font-size: 55px;
  line-height: 3rem;
  color: #f35626;
  background: linear-gradient(to right, #fe5000 20%, #fa0000 40%, #fe5000 80%);
  -webkit-background-clip: text;
  background-clip: text;
  text-fill-color: transparent;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  background-size: 200% auto;
  animation: shine 10s linear infinite;
}

.title__info_bubble {
  background: #fe500023;
  color: #fa0000;
  padding: 0.6rem;
  padding-left: 1rem;
  padding-right: 1rem;
  border-radius: 50px;
  position: relative;
  bottom: 1.6rem;
}

@media only screen and (max-width: 900px) {
  .exp-titulo {
    line-height: 50px;
    color: white !important;
    text-shadow: 0px 0px 30px rgba(0, 0, 0, 0.61);
    top: 10rem;
    position: absolute;
  }
  .exp-titulo p {
    margin-top: 1%;
    font-size: 50px !important;
  }
  .exp-header {
    margin-top: -5rem;
    padding-top: 8rem;
    background-repeat: no-repeat;
    background-position: center;
    background-size: cover;
    height: 800px;
    box-shadow: inset 0px -268px 150px -250px #171718;
  }
}

.exp-contenido {
  background: #171718;
  position: absolute;
  left: 0;
  right: 0;
  margin-top: 90vh;
  border-radius: 30px 30px 0 0;
  box-shadow: 0px 2px 20px 6px rgba(0, 0, 0, 0.31);
  margin-bottom: -1rem;
}
.exp-contenido-open {
  background: #171718;
  position: absolute;
  left: 0;
  right: 0;
  margin-top: 100vh;
  border-radius: 30px 30px 0 0;
  box-shadow: 0px 2px 20px 6px rgba(0, 0, 0, 0.31);
  margin-bottom: -1rem;
}

.exp-contenido2 {
  background: #171718;
  position: absolute;
  left: 0;
  right: 0;
  margin-top: 85vh;
  border-radius: 30px 30px 0 0;
  box-shadow: 0px 2px 20px 6px rgba(0, 0, 0, 0.31);
  margin-bottom: -1rem;
}
.exp-contenido-btn {
  margin-top: 1rem;
  color: rgba(255, 255, 255, 0.459);
  background: rgba(0, 0, 0, 0.26);
  border: 0px;
  border-radius: 50px !important;
  padding: 0.6rem;
  padding-left: 1rem;
  padding-right: 1rem;
  margin-bottom: 1rem;
  margin-left: 0.5rem;
  margin-right: 0.5rem;
}

.exp-container {
  margin-left: 5rem;
  margin-right: 5rem;
}

.exp-active {
  outline: none;
}
.exp-active:hover,
.exp-active:focus {
  outline: none;
}
.exp-next {
  float: right;
  position: relative;
}

.galeria {
  margin-top: -18rem;
}

.card-details {
  padding: 0.5rem;
  float: left;
  height: 4rem;
}

.card-host {
  padding: 0.5rem;
  padding-top: 0.7rem;
  background: #1c28367e !important;
  border-radius: 60px !important;
  float: left;
  height: 4rem;
}
.card-host:hover {
  -webkit-transition: all 0.3s;
  -moz-transition: all 0.3s;
  -o-transition: all 0.3s;
  transition: all 0.3s;
  -webkit-box-shadow: 0px 64px 154px -2px rgba(0, 0, 0, 0.49);
  -moz-box-shadow: 0px 64px 154px -2px rgba(0, 0, 0, 0.49);
  box-shadow: 0px 64px 154px -2px rgba(0, 0, 0, 0.49);
  background: #1c2836 !important;
  text-decoration-line: none;
}
.card-host text {
  font-size: 18px !important;
}
.card-host p {
  font-size: 25px !important;
}

.card-host-datos {
  font-size: 15px !important;
  margin-top: -0.2rem;
}

.img-zoom {
  width: 100%;
  overflow: hidden;
  position: relative;
  -webkit-box-shadow: 1px 1px 3px 0 rgba(0, 0, 0, 0.3);
  -moz-box-shadow: 1px 1px 3px 0 rgba(0, 0, 0, 0.3);
  box-shadow: 1px 1px 3px 0 rgba(0, 0, 0, 0.3);
}
.img-zoom img {
  width: 100%;
  top: 0;
  position: relative;
  left: 0;
  -webkit-transition: -webkit-transform 0.3s;
  -moz-transition: -moz-transform 0.3s;
  -o-transition: -o-transform 0.3s;
  transition: transform 0.3s;
}
.img-zoom .ovrly {
  background: rgba(0, 0, 0, 0.5);
  height: 100%;
  left: 0;
  top: 0;
  position: absolute;
  width: 100%;
  opacity: 0;
  -webkit-transition: all 0.3s;
  -moz-transition: all 0.3s;
  -o-transition: all 0.3s;
  transition: all 0.3s;
}
.img-zoom .buttons {
  position: absolute;
  top: 50%;
  left: 50%;
  -webkit-transform: translate(-50%, -50%);
  -moz-transform: translate(-50%, -50%);
  -ms-transform: translate(-50%, -50%);
  -o-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);
}
.img-zoom .buttons .fa {
  background: rgb(256, 256, 256);
  -webkit-box-shadow: 0 0 1px rgba(0, 0, 0, 0.3);
  -moz-box-shadow: 0 0 1px rgba(0, 0, 0, 0.3);
  box-shadow: 0 0 1px rgba(0, 0, 0, 0.3);
  -webkit-border-radius: 50%;
  -moz-border-radius: 50%;
  border-radius: 50%;
  color: rgba(50, 50, 50, 0.9);
  display: inline-block;
  margin: 0 1px;
  line-height: 40px;
  font-size: 16px;
  text-align: center;
  text-decoration: none;
  width: 40px;
  height: 40px;
  -webkit-transition: all 0.3s;
  -moz-transition: all 0.3s;
  -o-transition: all 0.3s;
  transition: all 0.3s;
  opacity: 0;
}
.img-zoom:hover img {
  -webkit-transform: scale(1.1);
  -moz-transform: scale(1.1);
  -ms-transform: scale(1.1);
  -o-transform: scale(1.1);
  transform: scale(1.1);
}
.img-zoom:hover .ovrly {
  opacity: 1;
}
.img-zoom:hover .buttons .fa {
  opacity: 1;
}

.close-window {
  border: 0 solid !important;
  width: 55px;
  height: 55px;
  border-radius: 50% !important;
  padding: 0;
  box-shadow: none !important;
  background: #2a2b2c;
  z-index: 2 !important;
}
.close-window:hover,
.close-window:focus {
  filter: brightness(1.1);
  outline: none;
}

.counter {
  margin-top: 1rem;
}

.back-window {
  border: 0 solid !important;
  width: 40px;
  height: 40px;
  border-radius: 50% !important;
  padding: 2px;
  box-shadow: none !important;
  background: #2a2b2c;
  margin-left: 1rem;
  margin-top: 0.8rem;
  z-index: 3 !important;
  font-size: 15px;
}
.back-window:hover,
.close-window:focus {
  background: black;
  outline: none;
}
.back-window-book {
  border: 0 solid !important;
  width: 40px;
  height: 40px;
  border-radius: 50% !important;
  padding-top: 2px;
  box-shadow: none !important;
  background: #2a2b2c;
  margin-left: 1rem;
  margin-top: 0.8rem;
  z-index: 3 !important;
  font-size: 15px;
}
.back-window-book:hover,
.close-window-book:focus {
  background: black;
  outline: none;
}

.back-window-navbar {
  border: 0 solid !important;
  width: 40px !important;
  height: 40px !important;
  border-radius: 50% !important;
  padding: 2px;
  box-shadow: none !important;
  background: #222224;
  margin-left: 1rem;
  z-index: 2 !important;
  font-size: 15px;
}
.back-window-navbar:hover,
.close-window:focus {
  filter: brightness(1.1);
  outline: none;
}

.back-window-navbar-logout {
  border: 0 solid !important;
  width: 40px !important;
  height: 40px !important;
  border-radius: 50% !important;
  padding-top: 2px;
  box-shadow: none !important;
  background: #222224;
  margin-left: 1rem;
  z-index: 2 !important;
  font-size: 15px;
  position: fixed;
  right: 1rem;
  top: 0.9rem;
}
.back-window-navbar-logout:hover,
.close-window:focus {
  filter: brightness(1.1);
  outline: none;
}

.back-window-navbar-drawer {
  border: 0 solid !important;
  width: 40px !important;
  height: 40px !important;
  border-radius: 50% !important;
  padding-top: 2px !important;
  box-shadow: none !important;
  background: #2a2b2c;
  margin-left: -0.2rem;
  margin-top: -0.3rem;
  z-index: 2 !important;
  font-size: 15px;
}
.back-window-navbar-drawer:hover,
.close-window:focus {
  filter: brightness(1.1);
  outline: none;
}

.dropdown-avatar {
  border: 0 solid !important;
  width: 55px;
  height: 55px;
  border-radius: 50% !important;
  padding: 0;
  background: #fe5000;
  z-index: 2 !important;
}
.dropdown-avatar:hover,
.dropdown-avatar:focus {
  filter: brightness(1.1);
  outline: none;
}
.dropdown-avatar-in {
  margin-left: 1.5rem;
  margin-top: 0.5rem;
  margin-right: -2rem;
  margin-bottom: 1rem;
}

.dropdown-menu {
  margin-top: 0.6rem !important;
  margin-right: 0rem !important;
  border: 0px solid rgba(255, 255, 255, 0.068) !important;
  border-radius: 30px !important;
  color: #1c2836 !important;
  background-color: #171718 !important;
  padding: 1rem !important;
  box-shadow: 4px 6px 81px 0px rgba(0, 0, 0, 0.54);
  -webkit-box-shadow: 4px 6px 81px 0px rgba(0, 0, 0, 0.54);
  -moz-box-shadow: 4px 6px 81px 0px rgba(0, 0, 0, 0.54);
}
.review {
  position: absolute;
  bottom: 4rem;
}

.icon-font-exp {
  position: absolute;
  bottom: 7rem;
  left: 0;
  right: 0;
}

.photo-edit {
  position: absolute;
  right: 4rem;
  top: 5rem;
  width: 50px;
  height: 50px;
  padding: 0 0 0 0 !important;
  border-radius: 50px;
  font-size: 23px;
  background: #fe5000;
  outline: none;
  overflow: hidden;
}

.photo-edit-host {
  position: absolute;
  right: 4rem;
  top: 5rem;
  width: 50px;
  height: 50px;
  padding: 0 0 0 0 !important;
  background: #262dff !important;
  border-radius: 50px;
  font-size: 23px;
}

@media only screen and (max-width: 900px) {
  .exp-contenido {
    background: #171718;
    position: absolute;
    left: 0;
    right: 0;
    padding-bottom: 5rem !important;
    margin-top: 85vh;
    border-radius: 0;
  }
  .exp-contenido-open {
    background: #171718;
    position: absolute;
    left: 0;
    right: 0;
    padding-bottom: 5rem !important;
    margin-top: 10vh;
    border-radius: 0;
  }

  .photo-edit {
    position: absolute;
    right: 5rem;
    top: 5rem;
    width: 50px;
    height: 50px;
    padding: 0 0 0 0 !important;
  }
}
.dropdown-header {
  font-size: 30px !important;
  color: white !important;
}

.dropdown-divider {
  height: 0;
  margin: 0.5rem 0;
  overflow: hidden;
  border-top: 1px solid black !important;
}

.dropdown-item {
  padding-top: 1rem !important;
  padding-bottom: 1rem !important;
  border-radius: 60px;
  color: rgb(138, 138, 138) !important;
}

.dropdown-item:hover {
  background: #fe50002a;
  color: #fe5000 !important;
}
.dropdown-item:focus {
  background: #0e0f0f !important;
  outline: none !important;
  color: white !important;
}

.PhoneInputInput {
  background-color: transparent !important;
  border: 0px solid !important;
  outline: none;
  /* font-size: 22px !important; */
}

.demo-icon {
  
  margin-right: 4%;
  margin-left: 4%;
  font-size: 20px;
  bottom: 0.2rem;
}
.demo-icon-nft {
  padding-left: auto;
  font-size: 35px;
  color: #fe5000;

}

.demo-icon-exp {
  position: absolute;
  left: 0;
  right: 0;
  bottom: 10px;
}

.demo-icon2 {
  margin-right: 4%;
  margin-left: 4%;
  font-size: 20px;
  bottom: 0.2rem;
}

.demo-icon-social {
  margin-right: 0.4rem;
  margin-left: 0.4rem;
}

.demo-icon-details {
  margin-right: 0rem;
  font-size: 50px;
}

.demo-icon-menu {
  margin-right: 1rem;
  font-size: 20px;
}

.demo-icon-first {
  margin-right: 0.5rem;
}

.demo-icon-incluye {
  font-size: 50px !important;
}

.demo-icon-config {
  font-size: 50px !important;
}

.demo-icon-arrow {
  margin-left: 1rem;
  margin-right: 1rem;
  font-size: 18px;
}

.demo-icon-settings {
  color: #fe5000;
  margin-right: 1.2rem;
}
.demo-icon-settings:hover {
  color: #9e390a;
  margin-right: 1.2rem;
}

.review {
  padding-bottom: 3rem;
  font-size: 50px;
  color: #fe5000;
}
.review i {
  font-size: 20px;
  color: #fe5000;
}

.logout:hover {
  border-radius: 0px 0px 20px 20px;
  -moz-border-radius: 0px 0px 20px 20px;
  -webkit-border-radius: 0px 0px 20px 20px;
  border: 0px solid #000000;
}

.btn__logout {
  background: #171718 !important;
}

.navbar-first {
  position: absolute;
  right: 4rem;
}

.navbar-search {
  position: absolute;
  left: 15rem;
  color: black !important;
  background-color: #171718;
  border-radius: 50px;
}

.link {
  text-decoration: none !important;
}

.perfil-about {
  margin-top: 1rem;
  position: absolute;
  left: 1.5rem;
  right: 1.5rem;
}

.perfil-pais {
  font-size: 18px;
  margin-top: -0.5rem !important;
}

.perfilSettings {
  font-size: 25px;
  display: flex;
  align-items: center;
}

.perfilSidebar {
  margin-top: 4rem;
}

.nav-bottom {
  position: absolute;
  bottom: 0rem;
  left: 0rem;
  right: 0rem;
  padding: 1rem;
  background-color: #111213;
  height: 5rem;
}

.avatar-in {
  border: 0 solid;
  margin: 1rem !important;
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: 3rem;
}

.planes-fav-titulo {
  margin-bottom: 1rem;
}
.planes-fav {
  position: fixed;
  width: 30%;
  right: 3rem;
}

.card-fav {
  margin-bottom: 1rem;
  height: 8rem;
  width: 15rem !important;
}

@media only screen and (max-width: 900px) {
  .perfilSidebar {
    margin-top: 0rem;
    align-items: center;
  }

  .card-fav {
    margin-bottom: 1rem;
    height: auto;
    width: auto !important;
  }

  .planes-fav {
    position: fixed;
    width: 40%;
    right: 0.5rem;
  }

  .explora-titulos {
    margin-top: 8rem;
    margin-bottom: -1rem;
  }

  .sm_nav {
    margin-top: 4rem;
  }

  .demo-icon-config {
    position: absolute;
    top: -0.3rem;

    font-size: 30px !important;
  }

  .fixed {
    position: fixed !important;
    bottom: 1rem;
    right: 1rem;
  }

  .nav-bottom {
    position: absolute;
    bottom: 0;
    padding-top: 0.3rem;
    background-color: #111213;
    height: 5rem;
  }
  .config-menu {
    padding-top: 9.3rem;
  }
}
@media only screen and (max-width: 770px) {
  .explora-titulos {
    margin-top: 3.5rem;
    margin-bottom: -1rem;
  }
  .exp-review-comments {
    width: 100% !important;
  }
  .review-date,
  .review-rating {
    justify-content: center !important;
  }
}

.login-title {
  font-size: 35px !important;
}
.login-title p {
  font-size: 16px;
}
.login-title-code {
  font-size: 20px !important;
}

.login-area {
  margin-top: 1rem !important;
  background: white;
}

.login-logo {
  height: 3rem;
}

.login-text-redes {
  font-size: 16px;
  outline: none !important;
  color: white;
  padding-top: 5;
  position: fixed;
  bottom: 1rem;
  left: 0;
  right: 0;
}
.login-text-redes:hover {
  color: #fe5000 !important;
}

.centrado {
  display: block !important;
  margin-left: auto !important;
  margin-right: auto !important;
}

.modalgateway {
  margin-bottom: 2rem !important;
}

.explora-titulos {
  padding-bottom: 0.6rem;
}
.explora-titulos p {
  font-size: 25px;
  line-height: 1rem;
}
.explora-bests {
  background: linear-gradient(
    180deg,
    rgb(241, 97, 30) 0%,
    rgb(243, 37, 37) 100%
  );
  position: absolute;
  left: 0;
  right: 0;
  padding-left: 1rem;
  padding-top: 1rem;
  border-radius: 40px 40px 0 0;
}
.explora-bests span {
  font-size: 25px !important;
}

.border-top {
  border: 1 solid black !important;
}

.exp-pay-number {
  background-color: #2a2b2c !important;
}

.ant-modal {
  z-index: 1051 !important;
}
.antd-img-crop-modal,
.ant-upload,
.exp-headerr {
  z-index: 1051 !important;
}

.rs-loader-wrapper {
  z-index: 9999;
  /* background: #262dff; */
  opacity: 0.85;
}

.rs-loader-content {
  color: black !important;
  font-size: 25px !important;
  font-weight: bold !important;
}
.rs-loader-spin::after {
  opacity: 1 !important;
  /* color: #262DFF !important; */
  border-color: #262dff transparent transparent !important;
}

.rs-icon-star::before {
  color: red;
}
.rs-progress-circle,
.progress-loader,
.progress-loader-content {
  z-index: 9999;
}
.progress-loader {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
  background: #151515;
  opacity: 0.85;
}
.progress-loader-content {
  width: 250px;
  height: 250px;
}

.progess-loader-text > * {
  color: #ffffff !important;
}

.update-usr-picture .ant-upload-list-item,
.update-usr-picture .ant-upload-list-item {
  display: none;
}

.rs-notification .rs-notification-item-content {
  background: #292d33;
  min-width: 325px;
}
.rs-notification .rs-btn-toolbar {
  display: flex;
}

.rs-notification .rs-notification-description p {
  font-size: 13px !important;
  padding: 20px;
}
.rs-notification .notification-btn {
  background: transparent;
  color: white !important;
  width: 50%;
  font-size: 13px !important;
  border-radius: none;
  border: 0px !important;

  display: inline-block;
  margin-bottom: 0;
  font-weight: 400;
  text-align: center;
  vertical-align: middle;
  cursor: pointer;
  outline: 0 !important;
  white-space: nowrap;
  border: none;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  padding: 8px 12px;
  font-size: 14px;
  line-height: 1.42857143;
  border-radius: 6px;
}

.rs-notification .notification-btn:hover {
  background: #fa0000;
}

.add-favorites-btn::before {
  color: var(--fav-color-btn) !important;
}

.search-draw .rs-drawer-top.rs-drawer-xs {
  height: 150px !important;
}
.rs-timeline-item-content {
  font-size: 16px;
}

.react-datepicker__navigation--next {
  top: 18px !important;
  right: 10px !important;
}
.react-datepicker__navigation--previous {
  top: 18px !important;
  left: 10px !important;
}

.react-photo-gallery--gallery img {
  max-width: 100% !important;
}

.datepicker {
  z-index: 30 !important;
}
.conversationHeaderBack {
  display: none !important;
}

.cs-conversation__last-activity-time {
  font-size: 9px !important;
}
.cs-conversation__info-content {
  font-size: 15px !important;
}
@media only screen and (max-width: 700px) {
  .visibleChat {
    display: inline-block !important;
    width: 100% !important;
  }
  .novisibleChat {
    display: none !important;
  }
  .conversationHeaderBack {
    display: inline-block !important;
  }
  .cs-sidebar.cs-sidebar--left {
    flex-basis: 100% !important;
    max-width: 100% !important;
  }

  /* .chat-header{
    width: 85%;
  } */
  .ModalImageViewFull {
    /* background: yellow; */
    top: 50%;
    transform: translate(-50%, -50%) !important;
    margin: 0 !important;
  }
  .messageInput {
    position: fixed;
    z-index: 99;
    bottom: 0;
    width: 95%;
    background: #000000 !important;
  }

  .messagelist {
    height: 74% !important;
  }
}

.ModalImageView {
  z-index: 999;
  position: absolute;
  width: 90%;
  max-width: 500px;
  height: auto;
  padding: 15px;
  margin: 20px;
  margin-right: 25px;
  background-color: #2a2b2c;
  border-radius: 8px;
}
.ModalImageViewFull {
  /* background: yellow; */
  position: absolute;
  left: 50%;
  transform: translate(-50%, 0);
}

.inputModalIageView {
  width: 80%;
}

.messageItem {
  width: 100%;
  display: flex;
}
.message-item-content {
}

.messageItem-content {
  width: auto;
  max-width: 600px;
  min-width: 150px;
  position: relative;
  border-radius: 8px;
  margin-top: 10px;
  margin-bottom: 10px;
  overflow: hidden;

  cursor: pointer;
}

/* .message-image-content{
  width: 100%;
} */

.message-image {
  width: auto;
  height: auto;
  max-width: 100%;
  image-rendering: pixelated;
  object-fit: contain;
}

.message-text {
  width: 100%;
  color: #ffffff;
  font-size: 20px;
  text-align: start;
  padding: 15px;
  margin-bottom: 5px;
}
.message-footer {
  position: absolute;
  bottom: 0;
  right: 10px;
  font-size: 11px;
  /* margin-top: 10px; */
}
.message-footer-image {
  background-color: #718491;
  border-radius: 25px;
  padding: 5px;
  bottom: 5px;
  opacity: 0.5;
}
.message-no-text {
  padding: 0;
  margin: 0;
}
.message-text-eliminate {
  font-size: 14px;
  font-style: oblique;
}

.message-incoming {
  justify-content: flex-start;
}
.incoming {
  background-color: #202024;
}
.message-outgoing {
  justify-content: flex-end;
}
.outgoing {
  background-color: #fa0000;
}

.message-contextMenu {
  background-color: #202024 !important;
  border-radius: 10px;
  z-index: 99;
}
.message-contextMenu-item {
  padding: 15px;
  font-size: 14px;
  line-height: 14px;
  cursor: pointer;
}
.message-contextMenu-item:hover {
  background-color: #38383d !important;
}

.PhoneInputCountrySelect {
  background-color: #383e42 !important;
}
.formGroup-auth {
  width: 100% !important;
  font-size: 18px !important;
}
.formControl-auth {
  height: 2.5rem !important;
  width: 100% !important;
  font-size: 18px !important;
  min-width: 310px;
}

.owl-carousel .owl-nav,
button.owl-prev {
  position: absolute !important;
  left: 2rem !important;
}
.owl-carousel .owl-nav,
button.owl-next {
  position: absolute !important;
  right: 2rem !important;
}

.owl-carousel .owl-nav button.owl-prev:hover {
  background: white;
  color: #fe5000;
  box-shadow: 0 0.25em 0.5em 0 rgba(0, 0, 0, 0.404);
}

.owl-carousel .owl-nav button.owl-next:hover {
  background: white;
  color: #fe5000;
  box-shadow: 0 0.25em 0.5em 0 rgba(0, 0, 0, 0.404);
}

.owl-carousel .owl-nav button.owl-prev,
.owl-carousel .owl-nav button.owl-next {
  position: absolute !important;
  width: 70px;
  height: 70px;
  font-size: 40px !important;
  top: -8rem !important;
  z-index: 2;
  color: #fe5000;
  text-transform: uppercase;
  padding: 9px 20px;
  margin-left: 0px;
  margin-right: 0px;
  margin-top: 0px;
  background-color: rgb(14, 14, 14) !important;
  border: 0px solid rgba(231, 231, 231, 0.5);
  border-radius: 50%;
  box-shadow: 0 0.25em 0.5em 0 rgba(0, 0, 0, 0.205);
  text-shadow: 0 0.25em 0.5em rgba(0, 0, 0, 0.1);
  opacity: 1;
  cursor: pointer;
  transition: opacity 150ms ease, border 300ms ease-in-out;
  transform: translateY(-50%);
  line-height: 1;
  outline: none;
}

.conversation_empty {
  min-height: 70px;
}

.MuiPickersStaticWrapper-staticWrapperRoot > * {
  width: 100%;
  background-color: #171718;
  color: #fff !important;
  height: 100% !important;
  overflow: hidden;
}

.MuiPickersStaticWrapper-staticWrapperRoot {
  position: absolute;
  right: 2rem;
  left: 0rem;
}

@media only screen and (max-width: 900px) {
  .MuiPickersStaticWrapper-staticWrapperRoot {
    position: absolute;
    right: 0rem;
    left: 1rem;
  }
}

.MuiPickersToolbar-toolbar > *,
.MuiPickersToolbar-toolbar > *,
.MuiToolbar-root {
  background-color: #fe5000 !important;
}

.MuiPickersCalendarHeader-dayLabel > * {
  color: #fff !important;
}

.MuiPickersBasePicker-pickerView {
  width: 100% !important;
  min-width: 100% !important;
  padding: 0 10px !important;
  min-height: 360px !important;
}
.MuiPickersCalendarHeader-dayLabel > *,
.MuiPickersCalendarHeader-daysHeader > *,
.MuiPickersDay-dayDisabled > * {
  color: gray !important;
}

.MuiPickersDay-day {
  color: #fff !important;
  width: 40px !important;
  height: 40px !important;
  border: 3px solid #fe5000 !important;
}
.MuiPickersDay-dayDisabled {
  border: none !important;
}

.MuiPickersCalendarHeader-switchHeader > * {
  max-width: 100% !important;
}
.MuiPickersDay-daySelected {
  background-color: #fe5000 !important;
  border: none !important;
}
.MuiSvgIcon-root {
  fill: #fe5000 !important;
}

.MuiPickersCalendar-week,
.MuiPickersCalendarHeader-daysHeader {
  display: flex !important;
  justify-content: space-around !important;
}

.nice-dates-grid .-disabled {
  color: gray !important;
  opacity: 0.8 !important;
}

.text-white span {
  color: white !important;
}

.notFound_page {
  width: min(100%, 500px);
  height: 100%;
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  grid-gap: 10px;
  grid-auto-rows: minmax(100px, auto);

  margin: 0 auto;
  margin-top: 110px;
}

.notFound_text {
  text-align: center;
}

.column1 {
  display: grid;
  place-content: center;
}

.column2 {
  grid-column: 2 / 5;
}

.notFound_image {
  width: 100%;
}

@media only screen and (max-width: 992px) {
  .notFound_page {
    margin: 0 auto;
  }
}

@media only screen and (max-width: 560px) {
  .notFound_page {
    grid-template-columns: repeat(1, 1fr);
    padding-top: 50px;
  }

  .column2 {
    grid-column: 1;
    display: grid;
    place-content: center;
  }

  .notFound_image {
    width: 400px;
    text-align: center;
  }
}

.schedules-page-content-list {
  margin: 0 50px;
  padding-top: 5rem !important;
}

.schedules-page-content-list-grid {
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  grid-gap: 20px;
  grid-auto-rows: minmax(100px, auto);
}
.schedule-item {
  padding: 2px;
  background-color: transparent;
  border: 5px solid #0c0c0c;
  cursor: pointer;
  position: relative;
  border-radius: 20px;
}
.schedule-item:hover {
  background: #0c0c0c;
  border: 4px solid #fe5000;
  color: black !important;
}

.schedule-item-padding{
  padding: 15px;
}

.schedule-item-loader {
  padding: 15px;
  background-color: transparent;
  border: 5px solid #0c0c0c;
  cursor: pointer;
  position: relative;
  border-radius: 20px;
  height: 100px;
}

.schedule-item-header {
  font-size: 18px;
}

.schedule-item-content {
  margin-top: 10px;
  color: #718491;
  font-size: 13px;
}

.schedule-item-content-repeat {
  font-weight: bold;
  font-size: 14px;
}

.schedule-item-btn {
  width: 30px;
  height: 30px;
  font-size: 11px;
  border-radius: 100%;
  border: 1px solid transparent;

  position: absolute;
  top: 10px;
  right: 10px;
}
@media only screen and (max-width: 900px) {
  .schedules-page-content-list-grid {
    grid-template-columns: repeat(3, 1fr);
  }
  .schedules-page-content-user-grid {
    grid-template-columns: repeat(2, 1fr);
  }
}
@media only screen and (max-width: 700px) {
  .schedules-page-content-list-grid {
    grid-template-columns: repeat(2, 1fr);
  }
}
@media only screen and (max-width: 500px) {
  .schedules-page-content-list-grid {
    grid-template-columns: repeat(2, 1fr);
  }

  .schedules-page-content-list {
    margin: 0 20px;
  }
}

.schedules-drawer-body::-webkit-scrollbar {
  width: 5px; /* Tamaño del scroll en vertical */
  height: 8px; /* Tamaño del scroll en horizontal */
}
.schedules-drawer-body::-webkit-scrollbar-thumb {
  background: #ccc;
  border-radius: 4px;
}
.schedule-neon-btn {
  background-color: #262dff !important;
}
.schedules-form-control-mt20 {
  margin-top: 20px;
}

.schedules-form-control span {
  font-size: 13px;
}

.rs-calendar-table-cell-disabled {
  color: #ccc !important;
}

.schedules-page-content-user-grid {
  display: grid;
  grid-template-columns: repeat(6, 1fr);
  grid-gap: 20px;
  grid-auto-rows: minmax(100px, auto);
  margin-top: 25px;
}

@media only screen and (max-width: 900px) {
  .schedules-page-content-user-grid {
    grid-template-columns: repeat(2, 1fr);
  }
}

.schedules-page-user-back-btn {
  background-color: transparent;
  border: none;
  cursor: pointer;
  margin-right: 20px;
  border-radius: 50%;
  padding: 10px;
}

.schedule-item-year-icon {
  color: #fe0000 !important;
  font-size: 16px;
  text-align: center;
  margin: 15px;
}
.schedule-item-year-name {
  font-size: 20px;
  color: #fe5000 !important;
  text-align: center;
  margin: 15px;
}
.schedule-item-year-name text {
  font-size: 40px;
  text-align: center;
}
.schedule-item-year-name__day {
  font-size: 16px;
  color: #fe5000 !important;
  text-align: center;
  margin: 15px;
}
.schedule-item-year-name__time {
  font-size: 15px;
  color: #fe5000 !important;
  text-align: center;
  margin: 15px;
}

.schedule-item-year-number {
  color: #fe0000 !important;
  font-size: 18px;
  text-align: center;
  margin-top: -10px;
}

.schedule-item-week {
  font-size: 25px;
  text-align: center;
  margin: 15px;
}
.schedule-item-week {
  font-size: 20px;
}
.schedule-item-hover {
  transition: 1 ease all;
}
.schedule-item-active,
.schedule-item-hover:hover {
  border: 5px solid #fe5000;
}
.schedules-no-items {
  font-size: 20px;
  text-align: center;
  margin-top: 100px;

  display: flex;
  flex-direction: column;
  gap: 10px;

  align-items: center;
}

.bact-to-week-btn {
  width: min(100%, 200px);
  padding: 10px;
  background-color: #fe5000;
  border-radius: 4px;
  color: #000000;
  font-weight: bold;
}

.selected-schedule-text {
  text-align: left;
  color: #fe5000 !important;
  font-size: 16px !important;
}
.schedules-page-content-user-grid-4 {
  display: grid;
  grid-template-columns: repeat(7, 1fr);
  grid-gap: 20px;
  grid-auto-rows: minmax(100px, auto);
  margin-top: 25px;
}

.exp_editPopover {
  border-radius: 20px !important;
}

@media only screen and (max-width: 900px) {
  .schedules-page-content-user-grid-4 {
    grid-template-columns: repeat(5, 1fr);
  }
}

@media only screen and (max-width: 700px) {
  .schedules-page-content-user-grid-4 {
    grid-template-columns: repeat(3, 1fr);
    grid-gap: 5px;
  }
  .schedule-item-year-name text {
    font-size: 30px;
    text-align: center;
  }
  .schedule-item-year-name__day {
    font-size: 12px;
    color: #fe5000 !important;
    text-align: center;
    margin: 0px;
  }
  .schedule-item-year-name__time {
    font-size: 10px;
    color: #fe5000 !important;
    text-align: center;
    margin: 1px;
  }
  .selected-schedule-text {
    text-align: center;
    font-size: 15px;
    margin: 15px;
  }
  .schedule-page-subtitle {
    font-size: 10px;
    display: flex;
    justify-content: space-between;
    text-align: center;
  }
}

.ant-popover-inner-content {
  background-color: #181818 !important;
}

.popover-edit-content {
  padding: 10px;
}
.popover-edit-content p {
  padding: 1px;
  font-size: 16px;
  cursor: pointer;
  text-align: center;
}

.popover-edit-content p:hover {
  color: #0044ff;
}

.popover-edit-content p span {
  margin-right: 5px;
}

.green-color {
  color: green;
}

.red-color {
  color: #ff0000 !important;
}

.rs-loader > * {
  color: #fff !important;
}
