/*
 Buttons style by http://nicolasgallagher.com/lab/css3-github-buttons/
 Changed by Afshin Mehrabani
*/
/* overrides extra padding on button elements in Firefox */
@-webkit-keyframes introjspulse {
    0% {
      -webkit-transform: scale(0);
              transform: scale(0);
      opacity: 0; }
    25% {
      -webkit-transform: scale(0);
              transform: scale(0);
      opacity: 0.1; }
    50% {
      -webkit-transform: scale(0.1);
              transform: scale(0.1);
      opacity: 0.3; }
    75% {
      -webkit-transform: scale(0.5);
              transform: scale(0.5);
      opacity: 0.5; }
    100% {
      -webkit-transform: scale(1);
              transform: scale(1);
      opacity: 0; } }
  @keyframes introjspulse {
    0% {
      -webkit-transform: scale(0);
              transform: scale(0);
      opacity: 0; }
    25% {
      -webkit-transform: scale(0);
              transform: scale(0);
      opacity: 0.1; }
    50% {
      -webkit-transform: scale(0.1);
              transform: scale(0.1);
      opacity: 0.3; }
    75% {
      -webkit-transform: scale(0.5);
              transform: scale(0.5);
      opacity: 0.5; }
    100% {
      -webkit-transform: scale(1);
              transform: scale(1);
      opacity: 0; } } 
  
  .introjs-overlay {
    position: absolute;
    -webkit-box-sizing: content-box;
            box-sizing: content-box;
    z-index: 999999;
    opacity: 0;
    -webkit-transition: all 0.3s ease-out;
    -o-transition: all 0.3s ease-out;
    transition: all 0.3s ease-out; }
  
  .introjs-showElement {
    z-index: 9999999 !important; }
  
  tr.introjs-showElement > td {
    z-index: 9999999 !important;
    position: relative; }
  
  tr.introjs-showElement > th {
    z-index: 9999999 !important;
    position: relative; }
  
  .introjs-disableInteraction {
    z-index: 99999999 !important;
    position: absolute;
    background-color: #202020;
    opacity: 0;
    filter: alpha(opacity=0); }
  
  .introjs-relativePosition {
    position: relative; }
  
  .introjs-helperLayer {
    -webkit-box-sizing: content-box;
            box-sizing: content-box;
    position: absolute;
    z-index: 9999998;
    border-radius: 30px;
    -webkit-transition: all 0.3s ease-out;
    -o-transition: all 0.3s ease-out;
    transition: all 0.3s ease-out; }
    .introjs-helperLayer * {
      -webkit-box-sizing: content-box;
              box-sizing: content-box; }
      .introjs-helperLayer *:before {
        -webkit-box-sizing: content-box;
                box-sizing: content-box; }
      .introjs-helperLayer *:after {
        -webkit-box-sizing: content-box;
                box-sizing: content-box; }
  
  .introjs-tooltipReferenceLayer {
    
    -webkit-box-sizing: content-box;
            box-sizing: content-box;
    position: absolute;
    visibility: hidden;
    z-index: 100000000;
    background-color: transparent;
    -webkit-transition: all 0.3s ease-out;
    -o-transition: all 0.3s ease-out;
    transition: all 0.3s ease-out; }
   
  
  .introjs-helperNumberLayer {
  
    color: #9e9e9e;
    text-align: center;
    padding-top: 10px;
    padding-bottom: 10px; }
  
  .introjs-arrow {
    border: 5px solid transparent;
    content: "";
    position: absolute; }
  
  .introjs-arrow.top {
    top: -10px;
    left: 10px;
    border-bottom-color: #636363; }
  
  .introjs-arrow.top-right {
    top: -10px;
    right: 10px;
    border-bottom-color: #ffffff; }
  
  .introjs-arrow.top-middle {
    top: -10px;
    left: 50%;
    margin-left: -5px;
    border-bottom-color: #ffffff; }
  
  .introjs-arrow.right {
    right: -10px;
    top: 10px;
    border-left-color: #ffffff; }
  
  .introjs-arrow.right-bottom {
    bottom: 10px;
    right: -10px;
    border-left-color: #ffffff; }
  
  .introjs-arrow.bottom {
    bottom: -10px;
    left: 10px;
    border-top-color: #ffffff; }
  
  .introjs-arrow.bottom-right {
    bottom: -10px;
    right: 10px;
    border-top-color: #ffffff; }
  
  .introjs-arrow.bottom-middle {
    bottom: -10px;
    left: 50%;
    margin-left: -5px;
    border-top-color: #ffffff; }
  
  .introjs-arrow.left {
    left: -10px;
    top: 10px;
    border-right-color: #ffffff; }
  
  .introjs-arrow.left-bottom {
    left: -10px;
    bottom: 10px;
    border-right-color: #ffffff; }
  
  .introjs-tooltip {
    -webkit-box-sizing: content-box;
            box-sizing: content-box;
    position: absolute;
    visibility: visible;
    background-color: #161616;
    padding: 1rem;
    width: 400px;
    border-radius: 20px;
    -webkit-box-shadow: 0 3px 30px rgba(33, 33, 33, 0.3);
            box-shadow: 0 3px 30px rgba(33, 33, 33, 0.3);
    -webkit-transition: opacity 0.1s ease-out;
    -o-transition: opacity 0.1s ease-out;
    transition: opacity 0.1s ease-out; }

    @media only screen and (max-width: 1200px) {
      .introjs-tooltip {
        width: 550px;
      }
    }

    @media only screen and (max-width: 900px) {
      .introjs-tooltip {
        width: 300px;
      }
    }
  
  .introjs-tooltiptext {
    padding: 20px; }
  
  .introjs-tooltip-title {
    font-size: 8px;
    font-family: 'Gilroy-Bold ☞';
    margin: 0;
    padding: 0;
    font-weight: 700;
    float: left;
    line-height: 32px; }
  
  .introjs-tooltip-header {
    padding-left: 20px;
    padding-right: 20px;
    padding-top: 10px; }
    .introjs-tooltip-header:after {
      content: ".";
      visibility: hidden;
      display: block;
      height: 0;
      clear: both; }
  
  .introjs-tooltipbuttons {
    border-top: 1px solid transparent;
    padding: 10px;
    text-align: right;
    white-space: nowrap; }
    .introjs-tooltipbuttons:after {
      content: "";
      visibility: hidden;
      display: block;
      height: 0;
      clear: both; }
  
  .introjs-button {
    -webkit-box-sizing: content-box;
            box-sizing: content-box;
    position: relative;
    overflow: visible;
    display: inline-block;
    padding: 0.5rem 1rem;
   
    text-decoration: none;
    font-size: 18px;
    font-weight: bold;
    color: #000000;
    white-space: nowrap;
    cursor: pointer;
    outline: none;
    background: #fe5000;
    border-radius: 50px;
    zoom: 1;
    *display: inline; }
    .introjs-button:hover {
      outline: none;
      text-decoration: none;
      background-color: #000000;
      color: #fe5000; }
    .introjs-button:focus {
      outline: none;
      text-decoration: none;
      background-color: #000000;
      color: #fe5000; }
   
  
  .introjs-skipbutton {
    -webkit-box-sizing: content-box;
            box-sizing: content-box;
    color: #616161;
    float: right;
    font-size: 20px;
    cursor: pointer;
    font-weight: bold;
    line-height: 1;
    text-align: center;
    padding: 7px 10px; }
    .introjs-skipbutton:hover, .introjs-skipbutton:focus {
      color: #212121;
      outline: none;
      text-decoration: none; }
  
  .introjs-prevbutton {
    float: left; }
  
  .introjs-nextbutton {
    float: right; }
  
  .introjs-disabled {
    color: #9e9e9e;
    border-color: #bdbdbd;
    -webkit-box-shadow: none;
            box-shadow: none;
    cursor: default;
    background-color: #f4f4f4;
    background-image: none;
    text-decoration: none; }
    .introjs-disabled:hover, .introjs-disabled:focus {
      color: #9e9e9e;
      border-color: #bdbdbd;
      -webkit-box-shadow: none;
              box-shadow: none;
      cursor: default;
      background-color: #f4f4f4;
      background-image: none;
      text-decoration: none; }
  
  .introjs-hidden {
    display: none; }
  
  .introjs-bullets {
    text-align: center;
    padding-top: 10px;
    padding-bottom: 10px; }
    .introjs-bullets ul {
      -webkit-box-sizing: content-box;
              box-sizing: content-box;
      clear: both;
      margin: 0 auto 0;
      padding: 0;
      display: inline-block; }
      .introjs-bullets ul li {
        -webkit-box-sizing: content-box;
                box-sizing: content-box;
        list-style: none;
        float: left;
        margin: 0 2px; }
        .introjs-bullets ul li a {
          -webkit-transition: width 0.1s ease-in;
          -o-transition: width 0.1s ease-in;
          transition: width 0.1s ease-in;
          -webkit-box-sizing: content-box;
                  box-sizing: content-box;
          display: block;
          width: 6px;
          height: 6px;
          background: #ccc;
          border-radius: 10px;
          text-decoration: none;
          cursor: pointer; }
          .introjs-bullets ul li a:hover, .introjs-bullets ul li a:focus {
            width: 15px;
            background: #999;
            text-decoration: none;
            outline: none; }
        .introjs-bullets ul li a.active {
          width: 15px;
          background: #999; }
  
  .introjs-progress {
    -webkit-box-sizing: content-box;
            box-sizing: content-box;
    overflow: hidden;
    height: 10px;
    margin: 10px;
    border-radius: 4px;
    background-color: #e0e0e0; }
  
  .introjs-progressbar {
    -webkit-box-sizing: content-box;
            box-sizing: content-box;
    float: left;
    width: 0%;
    height: 100%;
    font-size: 10px;
    line-height: 10px;
    text-align: center;
    background-color: #08c; }
  
  .introjsFloatingElement {
    position: absolute;
    height: 0;
    width: 0;
    left: 50%;
    top: 50%; }
  
  .introjs-fixedTooltip {
    position: fixed; }
  
  .introjs-hint {
    -webkit-box-sizing: content-box;
            box-sizing: content-box;
    position: absolute;
    background: transparent;
    width: 20px;
    height: 15px;
    cursor: pointer; }
    .introjs-hint:focus {
      border: 0;
      outline: 0; }
    .introjs-hint:hover > .introjs-hint-pulse {
      border: 5px solid rgba(60, 60, 60, 0.57); }
  
  .introjs-hidehint {
    display: none; }
  
  .introjs-fixedhint {
    position: fixed; }
  
  .introjs-hint-pulse {
    -webkit-box-sizing: content-box;
            box-sizing: content-box;
    width: 10px;
    height: 10px;
    border: 5px solid rgba(60, 60, 60, 0.27);
    border-radius: 30px;
    background-color: rgba(136, 136, 136, 0.24);
    z-index: 10;
    position: absolute;
    -webkit-transition: all 0.2s ease-out;
    -o-transition: all 0.2s ease-out;
    transition: all 0.2s ease-out; }
  
  .introjs-hint-no-anim .introjs-hint-dot {
    -webkit-animation: none;
            animation: none; }
  
  .introjs-hint-dot {
    -webkit-box-sizing: content-box;
            box-sizing: content-box;
    border: 10px solid rgba(146, 146, 146, 0.36);
    background: transparent;
    border-radius: 60px;
    height: 50px;
    width: 50px;
    -webkit-animation: introjspulse 3s ease-out;
            animation: introjspulse 3s ease-out;
    -webkit-animation-iteration-count: infinite;
            animation-iteration-count: infinite;
    position: absolute;
    top: -25px;
    left: -25px;
    z-index: 1;
    opacity: 0; }
  