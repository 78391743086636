@font-face {
  font-family: 'fontello';
  src: url('../font/fontello.eot?63475567');
  src: url('../font/fontello.eot?63475567#iefix') format('embedded-opentype'),
       url('../font/fontello.woff2?63475567') format('woff2'),
       url('../font/fontello.woff?63475567') format('woff'),
       url('../font/fontello.ttf?63475567') format('truetype'),
       url('../font/fontello.svg?63475567#fontello') format('svg');
  font-weight: normal;
  font-style: normal;
}
/* Chrome hack: SVG is rendered more smooth in Windozze. 100% magic, uncomment if you need it. */
/* Note, that will break hinting! In other OS-es font will be not as sharp as it could be */
/*
@media screen and (-webkit-min-device-pixel-ratio:0) {
  @font-face {
    font-family: 'fontello';
    src: url('../font/fontello.svg?63475567#fontello') format('svg');
  }
}
*/
 
 [class^="icon-"]:before, [class*=" icon-"]:before {
  font-family: "fontello";
  font-style: normal;
  font-weight: normal;
  speak: never;
 
  display: inline-block;
  text-decoration: inherit;
  width: 1em;
  margin-right: .2em;
  text-align: center;
  /* opacity: .8; */
 
  /* For safety - reset parent styles, that can break glyph codes*/
  font-variant: normal;
  text-transform: none;
 
  /* fix buttons height, for twitter bootstrap */
  line-height: 1em;
 
  /* Animation center compensation - margins should be symmetric */
  /* remove if not needed */
  margin-left: .2em;
 
  /* you can be more comfortable with increased icons size */
  /* font-size: 120%; */
 
  /* Font smoothing. That was taken from TWBS */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
 
  /* Uncomment for 3D effect */
  /* text-shadow: 1px 1px 1px rgba(127, 127, 127, 0.3); */
}
 
.icon-calendar:before { content: '\e800'; } /* '' */
.icon-camera:before { content: '\e801'; } /* '' */
.icon-cancel:before { content: '\e802'; } /* '' */
.icon-card:before { content: '\e803'; } /* '' */
.icon-face:before { content: '\e804'; } /* '' */
.icon-facebook:before { content: '\e805'; } /* '' */
.icon-fav:before { content: '\e806'; } /* '' */
.icon-google:before { content: '\e807'; } /* '' */
.icon-home:before { content: '\e808'; } /* '' */
.icon-home2:before { content: '\e809'; } /* '' */
.icon-details:before { content: '\e80a'; } /* '' */
.icon-left-arrow:before { content: '\e80b'; } /* '' */
.icon-log:before { content: '\e80c'; } /* '' */
.icon-logout:before { content: '\e80d'; } /* '' */
.icon-luggage:before { content: '\e80e'; } /* '' */
.icon-marker:before { content: '\e80f'; } /* '' */
.icon-money:before { content: '\e810'; } /* '' */
.icon-paper-boat:before { content: '\e811'; } /* '' */
.icon-swap:before { content: '\e812'; } /* '' */
.icon-pics:before { content: '\e813'; } /* '' */
.icon-profile:before { content: '\e814'; } /* '' */
.icon-right-arrow:before { content: '\e815'; } /* '' */
.icon-rocket:before { content: '\e816'; } /* '' */
.icon-search:before { content: '\e817'; } /* '' */
.icon-send:before { content: '\e818'; } /* '' */
.icon-secure:before { content: '\e819'; } /* '' */
.icon-settings:before { content: '\e81a'; } /* '' */
.icon-share:before { content: '\e81b'; } /* '' */
.icon-star:before { content: '\e81c'; } /* '' */
.icon-stars:before { content: '\e81d'; } /* '' */
.icon-trend:before { content: '\e81e'; } /* '' */
.icon-time:before { content: '\e81f'; } /* '' */
.icon-vr:before { content: '\e820'; } /* '' */
.icon-calendar2:before { content: '\e821'; } /* '' */
.icon-chat:before { content: '\e822'; } /* '' */
.icon-comment:before { content: '\e823'; } /* '' */
.icon-compass:before { content: '\e824'; } /* '' */
.icon-login:before { content: '\e825'; } /* '' */