.rs-alert-item-content div {
    color: #575757;
}

.rs-drawer-content {
    position: absolute;
    background-color: #171718;
    outline: 0;
    width: 100%;
    height: 100%;
    -webkit-box-shadow: 0 4px 4px rgb(0 0 0 / 12%), 0 0 10px rgb(0 0 0 / 6%);
    box-shadow: 0 4px 4px rgb(0 0 0 / 12%), 0 0 10px rgb(0 0 0 / 6%);
}