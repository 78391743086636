.lenguages {
  background-color: transparent;
  color: #171718;
  border: 0px solid #171718;
  border-radius: 50px;
  padding: 0.6rem;
}
.lenguages img {
  width: 30px;
  margin-top: -0.2rem;
}

@media only screen and (max-width: 900px) {
  .lenguages {
    background-color: #171718;
    border: 4px solid #171718;
    box-shadow: 2px 2px 39px 0px rgba(0,0,0,1);
-webkit-box-shadow: 2px 2px 39px 0px rgba(0,0,0,1);
-moz-box-shadow: 2px 2px 39px 0px rgba(0,0,0,1);
    border-radius: 50px;
    padding: 0rem;
    position: absolute;
    top: 7rem;
    right: -0.1rem;
    width: 170px;
  }
  .lenguages img {
    width: 30px;
    margin-top: -0.2rem;
  }
  .languageOption {
    color: white !important;

  }
}


/* Estilo de los botones */
.languageOption {
  background-color: transparent;
  color: #171718;
  cursor: pointer;
  font-size: 14px;
  margin: 5px;
  text-align: center;
  text-decoration: none;
  transition: all 0.3s ease;
  padding: 0.3rem;
  border-radius: 50px;
}


/* Efecto hover */
.languageOption:hover {
  font-weight: w900;
  border: none;
  color: #171719e0;
}

.languageOption:focus {
  outline: none;
}

/* Estilo del botón seleccionado */
.languageOption.selected {
  color: #f0f0f0;
  font-weight: w700;
  background-color: #171718;
}