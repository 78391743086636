@import url(https://fonts.googleapis.com/css?family=Open+Sans);
@media only screen and (max-width: 2900px) {
  body {
    margin: 0;
    font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
      'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
      sans-serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    background-color: #171718 !important;
  }
}

@media only screen and (max-width: 900px) {

 
}


html,
body,
div {
  font-family: "Gilroy-Medium ☞";
  font-style: normal;
  font-weight: normal;
  src: local("Gilroy-Regular ☞"), url(/static/media/Gilroy-Medium.3a98bbb5.woff) format("woff");
  font-size: 20px;
  line-height: 24px;
  color: white !important;
}

h1,
h2,
h3,
h4,
h5,
h6 {
  color: white !important;
}

@font-face {
  font-family: "Gilroy-Bold ☞";
  font-style: normal;
  font-weight: normal;
  src: local("Gilroy-Bold ☞"), url(/static/media/Gilroy-Bold.29e8a00a.woff) format("woff");
}

@font-face {
  font-family: "Gilroy-Heavy ☞";
  font-style: normal;
  font-weight: normal;
  src: local("Gilroy-Heavy ☞"), url(/static/media/Gilroy-Heavy.76014fe0.woff) format("woff");
}

@font-face {
  font-family: "Gilroy-Light ☞";
  font-style: normal;
  font-weight: normal;
  src: local("Gilroy-Light ☞"), url(/static/media/Gilroy-Light.f08220cc.woff) format("woff");
}

@font-face {
  font-family: "Gilroy-Medium ☞";
  font-style: normal;
  font-weight: normal;
  src: local("Gilroy-Medium ☞"), url(/static/media/Gilroy-Medium.3a98bbb5.woff) format("woff");
}

@font-face {
  font-family: "Gilroy-Regular ☞";
  font-style: normal;
  font-weight: normal;
  src: local("Gilroy-Regular ☞"), url(/static/media/Gilroy-Regular.de88caa6.woff) format("woff");
}

.lite {
  font-family: Gilroy-Light ☞;
}

.regular {
  font-family: Gilroy-Medium ☞;
}

.bold {
  font-family: Gilroy-Bold ☞;
}

.heavy {
  font-family: Gilroy-Heavy ☞;
}

.divider-blank {
  margin: 1rem;
}
.divider-down {
  margin-top: 2rem;
}
.divider-horizontal {
  padding-left: 2rem !important;
}

.divider {
  margin: 0.5rem;
}

.btn-cancel-cards {
  top: 1.2rem !important;
  right: 1rem !important;
  box-shadow: none !important;
}

.btn-circle{
  background: #fe5000;
  border-radius: 50px;
  color: #171718;
  padding: 0rem;
  width: 2.4rem;
  height: 2.4rem;
  position: absolute;
  right: 0.5rem;
  bottom: 0.5rem;
  box-shadow: 0px 0px 41px -4px rgba(0,0,0,0.58);
}
.btn-circle:hover {
  background: #171718;
  color: #fe5000;
}
.btn-circle-link{
  background: #fe5000;
  border-radius: 50px;
  color: #171718;
  padding-top: 0.6rem;
  padding-left: 0.5rem;
  width: 2.4rem;
  height: 2.4rem;
  position: absolute;
  right: 0.5rem;
  bottom: 0.5rem;
  box-shadow: 0px 0px 41px -4px rgba(0,0,0,0.58);
}
.btn-circle-link:hover {
  background: #171718;
  color: #fe5000;
}

.nice-dates-navigation,
.nice-dates-day {
  color: white !important;
  font-size: 20px !important;
}

.exp-fixed-bottom-open-host-upload {
  z-index: 10;
  position: fixed;
  bottom: 1.2rem !important;
  right: 1rem !important;
  background: linear-gradient(
    90deg,
    rgba(38, 45, 255, 1) 0%,
    rgba(13, 19, 179, 1) 100%
  );
  color: #060953 !important;
  border-radius: 50%;
  height: 4rem;
  width: 4rem;
}
.exp-fixed-bottom-open-host-upload:hover {
  background: linear-gradient(
    90deg,
    rgb(47, 53, 245) 0%,
    rgb(14, 21, 207) 100%
  );
  color: #050846 !important;
}

.text_map {
  z-index: 1;
  position: fixed;
  top: 2rem;
  left: 10rem;
  right: 10rem;
}
.text_map ul li {
  color: black !important;
  cursor: pointer;
}

.map {
  height: 100%;
  width: 100%;
  position: none;
  left: 0;
  right: 0;
  top: 0;
}

.map_full {
  height: 100vh;
  width: 100%;
  position: fixed;
  left: 0;
  right: 0;
  top: 0;
}

.btn-map {
  color: white !important;
  font-size: 20px !important;
  width: 100%;
  height: 4rem;
  position: fixed !important;
  bottom: 0rem;
  left: 0;
  border-radius: 0px !important;
  z-index: 1;
  bottom: 0 !important;
  position: fixed;
  border-radius: 0 !important;
  background: linear-gradient(
    90deg,
    rgba(38, 45, 255, 1) 0%,
    rgba(13, 19, 179, 1) 100%
  );
}

.orange {
  color: #fe5000 !important;
}

.black {
  color: #171718 !important
}
.red {
  color: #fe0000 !important;
}

.neon_blue {
  color: #262dff !important;
}

.bg-orange {
  background: #fe5000 !important;
}

.bg-negro {
  background: #000000 !important;
}
.bg-transparente {
  background: transparent !important;
}

.bg-orange-gradient {
  background: linear-gradient(
    180deg,
    rgb(241, 97, 30) 0%,
    rgb(243, 37, 37) 100%
  );
}
.bg-neonblue-gradient {
  background: linear-gradient(
    90deg,
    rgba(38, 45, 255, 1) 0%,
    rgba(13, 19, 179, 1) 100%
  );
}

.bg-neonblue-gradient text {
  color: #060953 !important;
  font-size: 12px !important;
}

.bg__tutorial {
  background: url(/static/media/patterns.deffef66.png);
  background-size: cover;
}

.bg__tuto1__movil {
  height: 10rem !important;
  background: url(/static/media/tuto2.86bc19df.jpg);
  background-color: #000000;
  background-size: cover;
  background-position: center;
  top: -7rem;
}

.bg__tuto1 {
  background: url(/static/media/tuto2.86bc19df.jpg);
  background-color: #000000;
  background-size: cover;
  background-position: center;
  height: 100vh;
}
.bg__tuto2 {
  background: url(/static/media/chat.cbc33fd2.jpg);
  background-color: #000000;
  background-size: cover;
  background-position: center;
  height: 100vh;
}
.bg__tuto2__movil {
  height: 10rem !important;
  background: url(/static/media/chat.cbc33fd2.jpg);
  background-color: #000000;
  background-size: cover;
  background-position: center;
  top: -7rem;
}

.bg__tuto3 {
  background: url(/static/media/calendar.ae3a406c.jpg);
  background-color: #000000;
  background-size: cover;
  background-position: center;
  height: 100vh;
}
.bg__tuto3__movil {
  height: 10rem !important;
  background: url(/static/media/calendar.ae3a406c.jpg);
  background-color: #000000;
  background-size: cover;
  background-position: center;
  top: -7rem;
}

.bg__tuto4 {
  background: url(/static/media/tour.c1866012.jpg);
  background-color: #000000;
  background-size: cover;
  background-position: center;
  height: 100vh;
}
.bg__tuto4__movil {
  height: 10rem !important;
  background: url(/static/media/tour2.3d6cd3b2.jpg);
  background-color: #000000;
  background-size: cover;
  background-position: center;
  top: -7rem;
}

.orange-dark {
  color: #5c2004 !important;
}

.noBackground {
  background: rgb(7, 7, 7) !important;
  border-radius: 10px !important;
  margin: 1rem;
}
.noBackground:hover {
  background: #2a2b2c !important;
}

.modalConfirmacion {
  width: 100%;
  padding: 20px;
}

.itemCardList {
  display: flex;
}

.itemCardList:hover {
  background: #fe5000;
}

.botonEliminar {
  position: absolute;
  right: 20px;
  top: 15px;
  width: 30px;
  /* margin-right: 10px; */
  /* margin-left: 60px; */
}

.botonEliminar:hover {
  width: 35px;
  cursor: pointer;
}

.bg-guatemala {
  background-color: rgba(102, 68, 6, 0.116) !important;
  color: rgb(129, 129, 190) !important;
}

.bg-salvador {
  background-color: rgba(77, 170, 0, 0.116) !important;
  color: rgb(129, 129, 190) !important;
}

.bg-honduras {
  background-color: rgba(0, 174, 255, 0.116) !important;
  color: rgb(129, 129, 190) !important;
}

.bg-nicaragua {
  background-color: rgba(255, 238, 0, 0.116) !important;
  color: rgb(129, 129, 190) !important;
}

.bg-costarica {
  background-color: rgba(255, 72, 0, 0.116) !important;
  color: rgb(129, 129, 190) !important;
}

.bg-panama {
  background-color: rgba(0, 0, 0, 0.116) !important;
  color: rgb(129, 129, 190) !important;
}

.buutonListCard:hover {
  border-color: #0044ff;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}
.logo {
  height: 50px;
}
.logo_in {
  height: 40px;
  position: fixed;
  left: 3.5rem;
  top: 0.8rem;
  z-index: 3 !important;
}
.logo_in2 {
  height: 40px;
  position: fixed;
  left: 0.8rem;
  top: 0.8rem;
  z-index: 1000;
}
.logo_in3 {
  height: 40px;
  position: fixed;
  left: 3.5rem;
  top: 0.8rem;
  z-index: 2 !important;
}


.card3 {
  background-color: #2a2b2c !important;
  border-radius: 50px !important;
  padding: 0rem !important;
  margin-bottom: 1rem;
  cursor: pointer;
}
.card3:hover {
  background-color: #323335 !important;
}

.card4 {
  border: 4px solid rgba(0, 0, 0, 0.479);
  border-radius: 10px !important;
  padding: 1rem !important;
  cursor: pointer;
  margin: 10px;
}
.card4:hover {
  background-color: #323335 !important;
}

@media only screen and (max-width: 900px) {
  .fc .fc-scroller {
    -webkit-overflow-scrolling: touch;
    position: relative; /* for abs-positioned elements within */
  }
  .fc .fc-scroller-liquid {
    height: 200% !important;
  }
  .fc .fc-scroller-liquid-absolute {
    position: absolute;
    top: 0;
    right: 0;
    left: 0;
    bottom: 0;
  }
  .fc .fc-scroller-harness {
    position: relative;
    overflow: hidden;
    direction: ltr;
    /* hack for chrome computing the scroller's right/left wrong for rtl. undone below... */
    /* TODO: demonstrate in codepen */
  }
  .fc .fc-scroller-harness-liquid {
    height: 100%;
  }
  .fc-direction-rtl .fc-scroller-harness > .fc-scroller {
    /* undo above hack */
    direction: rtl;
  }

  .icon-back {
    position: absolute;
    left: 0;
    right: 0;
    top: 9px;
    bottom: 0;
    color: #fe5000 !important;
  }
  .icon-back-2 {
    position: absolute;
    left: 0.5rem;
    top: 6px;
    bottom: 0;
  }

  .login-text-redes {
    font-size: 16px;
    outline: none !important;
    color: white;
    padding-top: 5;
    position: none;
    bottom: 1rem;
    left: 0;
    right: 0;
  }
  .login-text-redes:hover {
    color: #fe5000 !important;
  }

  .owl-carousel .owl-nav,
  button.owl-prev {
    position: absolute !important;
    margin-left: -1.4rem !important;
  }
  .owl-carousel .owl-nav,
  button.owl-next {
    position: absolute !important;
    margin-right: -1.4rem !important;
  }

  .owl-carousel .owl-nav button.owl-prev,
  .owl-carousel .owl-nav button.owl-next {
    position: absolute !important;
    width: 70px;
    height: 70px;
    font-size: 40px !important;
    z-index: 2;
    color: #fe5000;
    text-transform: uppercase;
    padding: 9px 20px;
    margin-left: 0px;
    margin-right: 0px;
    margin-top: 14rem !important;
    background-color: rgb(14, 14, 14) !important;
    border: 0px solid rgba(231, 231, 231, 0.5);
    border-radius: 50%;
    box-shadow: 0 0.25em 0.5em 0 rgba(0, 0, 0, 0.205);
    text-shadow: 0 0.25em 0.5em rgba(0, 0, 0, 0.1);
    opacity: 1;
    cursor: pointer;
    transition: opacity 150ms ease, border 300ms ease-in-out;
    transform: translateY(-50%);
    line-height: 1;
    outline: none;
  }

  .text_map {
    z-index: 1;
    position: fixed;
    top: 1rem;
    left: 1rem;
    right: 1rem;
  }

  .config {
    margin-top: 1rem !important;
  }

  .config-perfil {
    position: fixed !important;
    padding-top: 1rem;
  }

  .config-menu {
    margin-top: 24rem;
    padding-bottom: 6.5rem;
    background: #141414;
    padding-top: 0rem !important;
    border-radius: 40px 40px 0 0;
    z-index: 1;
    position: absolute;
    bottom: 0;
  }

  .card2 {
    background-color: #2a2b2c !important;
    border-radius: 50px !important;
    height: 7rem !important;
    padding: 0rem !important;
    margin-bottom: 1rem;
    cursor: pointer;
  }
  .card2:hover {
    background-color: #323335 !important;
  }

  .card3 {
    background-color: #0b0c0c !important;
    border-radius: 50px !important;
    height: 3.5rem !important;
    padding: 0rem !important;
    margin-top: 1rem;
    margin-bottom: 0rem;
    cursor: pointer;
  }
  .card3:hover {
    background-color: #323335 !important;
  }

  .switch {
    position: fixed;
    top: 0.8rem;
    left: 0rem;
    border-radius: 50rem;
    width: 40px;
    height: 40px;
  }
}

.exp-chat-open {
  position: absolute !important;
  left: -1rem;
  right: -1rem;
  top: -2rem;
  height: 101vh !important;
}

.cards {
  background-color: #2a2b2c !important;
  border-radius: 10px !important;
  width: 100%;
  
  padding: 0rem !important;
  margin-bottom: 1rem;
}

.config {
  margin-top: 9rem;
}

.card2 {
  background-color: #2a2b2c !important;
  border-radius: 30px !important;
  cursor: pointer;
  padding: 0.2rem;
  margin-bottom: 1rem;
}
.card2:hover {
  background-color: #323335 !important;
}

.icono-paises {
  height: 50px;
  margin-bottom: 0.5rem !important;
}

.icon {
  height: 38px;
  margin-right: 0.3rem;
}

.icon-font {
  position: absolute;
  top: 11.4px;
  left: 0;
  right: 0;
}

.avatar {
  width: 2.7rem !important;
  margin-left: 0.7rem;
}
.avatar_sm {
  position: absolute;
  right: 0.9rem;
}

.page_pay {
  padding-top: 4rem;
  width: 80% !important;
}

.image_picker {
  width: 100%;
  display: flex;
  flex-wrap: wrap;
  margin-left: 2px;
  
}

.image_picker .ip_tarjeta {
  border: 1px solid transparent;
  border-radius: 10px;
  padding: 4px;
  height: 150px;
  width: 110px;
  opacity: 1;
  margin: 5px;
  background: #0e0f0f;

  
}

.image_picker .selected {
  box-shadow: 0 0 0px 0px #fe5000;
  border: 4px solid #fe5000;
  cursor: pointer;
}
.image_picker .ip_tarjeta:hover {
  filter:contrast(2);
  cursor: pointer;
}

.no-events {
  pointer-events: none;
}
.text-titulos-reserva {
  font-size: 28px;
}

.text-instructivo {
  font-size: 16px;
}

.btn-publicada {
  background: green !important;
}

@media only screen and (max-width: 900px) {
  .text-instructivo {
    font-size: 12px;
    line-height: 18px;
  }

  .image_picker .responsive .thumbnail {
    border: 1px solid transparent !important;
    border-radius: 10px !important;
    padding: 5px;
    height: 130px !important;
    width: 110px !important;
    opacity: 1;
    margin: 0;
  }

  .avatar {
    width: 2rem !important;
    height: 2rem !important;
  }

  .page_pay {
    padding-top: 4rem;
    width: 100% !important;
  }
}

.card-titulo {
  font-size: 25px;
  line-height: 1.3rem;
}

.card-titulo-fav {
  font-size: 18px;
  margin-bottom: -0.2rem;
}

.alert__orange {
  height: 2rem !important;
  font-size: 18px !important;
  margin-bottom: 1rem !important;
  margin-left: 1rem !important;
  margin-right: 1rem !important;
  border-radius: 20px !important;
  cursor: pointer;
}

@media only screen and (max-width: 900px) {
  .card-titulo {
    font-size: 18px !important;
    line-height: 0.9rem;
  }

  .alert__orange {
    height: 5rem !important;
    font-size: 18px !important;
  }
}

.padding {
  margin-right: 0.5rem;
  padding-right: 1rem;
  padding-left: 1rem;
}

.padding-planes {
  margin-right: 0.5rem;
  padding-right: 1rem;
  padding-left: 1rem;
  margin-top: -0.9cm !important;
  margin-left: -0.9cm !important;
}

.contraste {
  color: #fe5000 !important;
  background: #fe500021 !important;
  border-radius: 10px !important;
}

.btn_cancel {
  width: 3rem !important;
  height: 3rem !important;
  position: absolute !important;
  border-radius: 50% !important;
  right: 0.3rem;
  top: 0.3rem;
  background: #fa0000 !important;
  z-index: 1 !important;
  color: #921212 !important;
  font-size: 18px !important;
}
.btn_cancel:hover {
  background: #b61c1c !important;
  color: #4d0707 !important;
  box-shadow: 0px 0px 34px 7px rgba(0, 0, 0, 0.75);
}

.payment_banner {
  background: #fa0000 !important;
  color: #ffffff;
  height: 2rem !important;
  display: grid;
  place-content: center;
  padding: 10px;
  border-radius: 4px;
}

.btn_cancel_banco {
  width: 3rem !important;
  height: 3rem !important;
  position: relative;
  left: 24rem;
  top: -3.5rem;
  border-radius: 50% !important;
  background: #fa0000 !important;
  color: #921212 !important;
  z-index: 1 !important;
  font-size: 18px;
}
.btn_cancel_banco:hover {
  background: #b61c1c !important;
  color: #4d0707 !important;
  box-shadow: 0px 0px 34px 7px rgba(0, 0, 0, 0.75);
}

.btn-exp-host {
  height: 3rem;
  position: absolute;
  bottom: 0.5rem;
  background: #0e0f0f;
  color: #262dff !important;
  border-radius: 20px;
  left: 0.5rem;
  right: 0.5rem;
  padding: 0.8rem;
  padding-top: 0.9rem;
}
.btn-exp-host:hover {
  height: 3rem;
  position: absolute;
  bottom: 0.5rem;
  background: #131414;
  color: #262dff !important;
  border-radius: 20px;
  left: 0.5rem;
  right: 0.5rem;
  padding: 0.8rem;
  padding-top: 0.9rem;
  box-shadow: 0px 0px 34px 7px rgba(0, 0, 0, 0.75);
}

.btn-social {
  background: rgb(7, 7, 7) !important;
  border: 0px solid #2a2b2c !important;
  border-radius: 10px !important;
  color: white !important;
  width: 100%;
  height: 3.5rem;
  font-size: 25px;
  outline: none !important;
}
.btn-social:hover {
  background: #2a2b2c !important;
  outline: none !important;
}

.btn-outline {
  background: transparent !important;
  border: 6px solid #2a2b2c !important;
  border-radius: 40px !important;
  width: 100% !important;
  color: white !important;
  height: 3rem !important;
}
.btn-outline:hover,
.btn-outline:focus {
  background: #2a2b2c !important;
}

.btn-outline-add {
  background: transparent !important;
  border: 6px solid #2a2b2c !important;
  border-radius: 40px !important;
  width: 100% !important;
  color: white !important;
  height: 2.3rem !important;
}
.btn-outline-add:hover,
.btn-outline-add:focus {
  background: #2a2b2c !important;
}

.btn-login {
  width: 100% !important;
  position: fixed !important;
  bottom: 0rem;
  left: 0rem;
  right: 0rem;
  height: 3.7rem !important;
  border-radius: 0px !important;
}

.btn-host {
  background: linear-gradient(
    90deg,
    rgba(38, 45, 255, 1) 0%,
    rgba(13, 19, 179, 1) 100%
  ) !important;
  width: 100% !important;
  position: fixed !important;
  bottom: 0rem;
  left: 0rem;
  right: 0rem;
  height: 3.7rem !important;
}
.btn-host:hover {
  background: linear-gradient(
    90deg,
    rgb(22, 29, 221) 0%,
    rgb(6, 10, 138) 100%
  ) !important;
  width: 100% !important;
  position: fixed !important;
  bottom: 0rem;
  left: 0rem;
  right: 0rem;
  height: 3.7rem !important;
}

.btn-send {
  border: 0 solid !important;
  width: 55px;
  height: 55px;
  border-radius: 50% !important;
  padding: 0;
  box-shadow: none !important;
  background: linear-gradient(
    180deg,
    rgb(241, 97, 30) 0%,
    rgb(243, 37, 37) 100%
  );
  z-index: 2 !important;
}
.btn-send:hover,
.btn-send:focus {
  filter: brightness(1.1);
  outline: none;
}

.btn__code {
  height: 4rem !important;
  width: 100%;
  border-radius: 10px !important;
  background: #fe500033;
  color: #fe5000 !important;
}
.btn__code:hover {
  background: #fe5000 !important;
  color: white !important;
}
.btn__code:focus {
  background: #fe5000 !important;
  color: white !important;
}

.btn__add {
  margin-top: -0.6rem;
  margin-bottom: 1rem;
  color: white !important;
  cursor: pointer;
  font-size: 15px;
}
.btn__add:hover {
  color: #fe5000 !important;
}

.btn__bancos {
  height: 4rem !important;
  width: 100%;
  border-radius: 10px !important;
  background: linear-gradient(
    90deg,
    rgba(38, 45, 255, 1) 0%,
    rgba(13, 19, 179, 1) 100%
  );
  color: white !important;
}

.planes-grid-content {
  width: 100%;
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  grid-gap: 20px;
  grid-auto-rows: minmax(100px, auto);
}

.overlay-card-planes {
  width: 100%;
  overflow: hidden;
  position: relative;
  border-radius: 30px !important;
}
.card_planes {
  background: white;
  min-height: 5rem;
  height: auto;
  width: 5rem;
  padding-top: 1.5rem;
  padding-left: 0.5rem;
  padding-bottom: 0.1rem;
  border-radius: 20px;
}
.card_planes text {
  font-size: 65px;
}
.card_planes p {
  font-size: 16px;
  line-height: 15px;
  margin: 0;
}

.calendar {
  padding-top: 4rem;
}


@media only screen and (max-width: 900px) {
  .btn_cancel_banco {
    width: 3rem !important;
    height: 3rem !important;
    position: relative;
    left: 10rem;
    top: -3.5rem;
    background: #fa0000 !important;
    z-index: 1 !important;
  }
  .btn_cancel_banco:hover {
    background: #500606 !important;
    box-shadow: 0px 0px 34px 7px rgba(0, 0, 0, 0.75);
  }

  .card_planes {
    position: absolute;
    left: 0.4rem;
    top: 0.4rem;
    background: white;
    height: 4rem;
    width: 4rem;
    padding-top: 1rem;
    padding-left: 0.5rem;
    border-radius: 20px;
  }
  .card_planes text {
    font-size: 50px;
  }
  .card_planes p {
    font-size: 12px;
    line-height: 15px;
  }

  .btn-login {
    width: 100% !important;
    position: fixed;
    bottom: 0rem;
    left: 0rem;
    right: 0rem;
    height: 3.7rem !important;
  }

  .btn-antigua {
    width: 100% !important;
    position: fixed;
    top: 3.5rem;
    left: 0rem;
    right: 0rem;
    height: 3.7rem !important;
    background-attachment: fixed;
    background-repeat: no-repeat !important;
    background-position: center !important;
    background-size: cover;
    background-image: url(https://muniantigua.gob.gt/legado/assets/fondo5.jpg) !important;
    color: white !important;
    padding: 1rem;
  }

  .calendar {
    padding-top: 4rem;
  }

  .calendar-dates {
    position: fixed !important;
    z-index: 0;
  }
  .calendar-time {
    background: #141414 !important;
    margin-top: 20rem;
    padding-top: 1rem !important;
    padding-bottom: 25rem;
    padding-left: 2rem !important;
    padding-right: 2rem !important;
    border-radius: 40px 40px 0 0;
  }

  .btn-send {
    margin-top: 0.8rem;
    border: 0 solid !important;
    width: 55px;
    height: 55px;
    border-radius: 50% !important;
    padding: 0;
    box-shadow: none !important;
    background: linear-gradient(
      180deg,
      rgb(241, 97, 30) 0%,
      rgb(243, 37, 37) 100%
    );
    z-index: 2 !important;
  }
  .btn-send:hover,
  .btn-send:focus {
    filter: brightness(1.1);
    outline: none;
  }
}

.btn-cancel {
  margin: 1rem !important;
  width: 50px !important;
  height: 50px;
  position: relative;
}
.btn-cancel i {
  font-size: 18px;
  margin-left: -0.1rem;
}

.btn-enviar {
  background: black !important;
  color: #fe5000 !important;
}

.btn-primary-contraste:hover {
  background: #fe5000 !important;
  color: white !important;
}

.ant-upload.ant-upload-select-picture-card {
  position: absolute !important;
  bottom: 6rem;
  right: 1.5rem;
  width: 3.7rem !important;
  height: 3.7rem !important;
  margin-right: 8px;
  margin-bottom: 8px;
  text-align: center;
  vertical-align: top;
  background-color: #242429 !important;
  border: 0px dashed #d9d9d9 !important;
  border-radius: 70px !important;
  cursor: pointer;
  transition: border-color 0.3s;
}
.ant-upload.ant-upload-select-picture-card > .ant-upload {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100%;
  text-align: center;
}
.ant-upload.ant-upload-select-picture-card:hover {
  border-color: #1890ff;
}

.ant-modal-header {
  padding: 16px 24px;
  color: rgba(0, 0, 0, 0.85);
  background: rgb(37, 37, 37) !important;
  border-bottom: 0px solid #f0f0f0 !important;
  border-radius: 20px 20px 0 0 !important;
}

.ant-modal-mask {
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 1000;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.788) !important;
}

.ant-modal-content {
  position: relative;
  background-color: rgb(37, 37, 37) !important;
  background-clip: padding-box;
  border: 0;
  border-radius: 20px !important;
  box-shadow: 0 3px 6px -4px rgba(0, 0, 0, 0.12),
    0 6px 16px 0 rgba(0, 0, 0, 0.08), 0 9px 28px 8px rgba(0, 0, 0, 0.05);
  pointer-events: auto;
}
.ant-modal-close-x {
  display: block;
  width: 76px !important;
  height: 76px !important;
  font-size: 20px !important;
  color: rgb(0, 0, 0);
  font-style: bold !important;
  line-height: 56px;
  text-align: center;
  text-transform: none;
  text-rendering: auto;
}

.ant-modal-footer {
  padding: 20px 16px !important;
  text-align: right;
  background: transparent;
  border-top: 0px solid #f0f0f0 !important;
  border-radius: 0 0 20px 20px !important;
}

.btn-fixed-bottom {
  background: linear-gradient(
    180deg,
    rgb(241, 97, 30) 0%,
    rgb(243, 37, 37) 100%
  ) !important;
  color: white !important;
  font-size: 20px !important;
  width: 100%;
  height: 4rem;
  position: fixed !important;
  bottom: 0rem;
  left: 0;
  border-radius: 0px !important;
}

.btn-metodo-pago {
  /* position: absolute !important;
  left: 0;
  right: 0;
  bottom: 0; */

  height: 3rem;
  max-width: 100%;
}

.add-tarjeta {
  width: 50% !important;
}

.add-tarjeta2 {
  margin: 0rem;
  margin-bottom: 0rem;
}

.btn-app {
  width: 20% !important;
  padding: 0.6rem !important;
  border-radius: 50px;
}

@media only screen and (max-width: 900px) {
  .add-tarjeta {
    width: 100% !important;
  }
  .add-tarjeta2 {
    margin: 0.5rem;
  }

  .ant-upload.ant-upload-select-picture-card {
    bottom: 4rem;
  }

  .btn-metodo-pago {
    position: fixed !important;
    margin-left: 0;
    margin-right: 0;
    margin-bottom: 0;
    height: 4rem;
    border: 0px !important;
    border-radius: 0px !important;
    width: 100% !important;
  }
}

.tutorial {
  z-index: 11 !important;
  background-color: #171718;
}

.btn-primary-navbar {
  background: linear-gradient(
    180deg,
    rgb(241, 97, 30) 0%,
    rgb(243, 37, 37) 100%
  );
  color: white !important;
  width: 6rem;
  font-size: 20px !important;
  border-radius: 50px;
  border: 0px !important;
  padding-left: 1rem;
  padding-right: 1rem;
}
.btn-primary-navbar:hover {
  background: linear-gradient(
    180deg,
    rgb(207, 81, 22) 0%,
    rgb(207, 25, 25) 100%
  );
  color: white !important;
  box-shadow: 0 0.15em 0.5em 0 rgba(0, 0, 0, 0.171);
}
.btn-primary-navbar:focus {
  background: linear-gradient(
    180deg,
    rgb(207, 81, 22) 0%,
    rgb(207, 25, 25) 100%
  );
  color: white !important;
  box-shadow: 0 0.15em 0.5em 0 rgba(0, 0, 0, 0.171);
}

.btn-primary-navbar-host {
  background: linear-gradient(
    90deg,
    rgba(38, 45, 255, 1) 0%,
    rgba(13, 19, 179, 1) 100%
  ) !important;
  color: white !important;
  width: 6rem;
  font-size: 22px !important;
  border-radius: 50px;
  border: 0px !important;
}
.btn-primary-navbar-host:hover {
  background: linear-gradient(
    180deg,
    rgb(207, 81, 22) 0%,
    rgb(207, 25, 25) 100%
  );
  color: white !important;
  box-shadow: 0 0.15em 0.5em 0 rgba(0, 0, 0, 0.171);
}
.btn-primary-navbar-host:focus {
  background: linear-gradient(
    180deg,
    rgb(207, 81, 22) 0%,
    rgb(207, 25, 25) 100%
  );
  color: white !important;
  box-shadow: 0 0.15em 0.5em 0 rgba(0, 0, 0, 0.171);
}

.btn-primary {
  background: #fe5000 !important;
  color: #000000 !important;
  width: 100%;
  font-size: 22px !important;
  border: 0px solid;
  outline: none !important;
}
.btn-primary:hover{
  background: #fa0000 !important;
  color: #171718 !important;
  transition: all 0.3s !important;
  outline: none !important;
}

.btn-black {
  background: transparent !important;
  color: #cacaca !important;
  height: 2.3rem;
  width: 100%;
  font-size: 15px !important;
}
.btn-black:hover,
.btn-black:focus {
  background: transparent !important;
  color: #fe5000 !important;
  transition: all 0.3s !important;
}

.btn-primary2 {
  background: #fe5000 !important;
  color: #000000 !important;

  font-size: 22px !important;
}
.btn-primary2:hover,
.btn-primary2:focus {
  background: #ad4413 !important;
  color: #000000 !important;
  transition: all 0.3s !important;
  box-shadow: 0px 64px 154px -2px rgba(0, 0, 0, 0.61) !important;
}
.btn-primary-host {
  background: #262dff !important;
  color: #000000 !important;
  width: 100%;
  font-size: 22px !important;
}
.btn-primary-host:hover,
.btn-primary-host:focus {
  background: #0d13b3 !important;
  color: #000000 !important;
  transition: all 0.3s !important;
  box-shadow: 0px 64px 154px -2px rgba(0, 0, 0, 0.61) !important;
}

.btn-secondary {
  background: rgb(12, 12, 12) !important;
  color: white !important;
  width: 100%;
  font-size: 22px !important;
  border-radius: 50px !important;
  border: 0px !important;
}
.btn-secondary:hover,
.btn-secondary:focus {
  background: rgb(34, 34, 34) !important;
  color: white !important;
  transition: all 0.3s !important;
  box-shadow: 0px 64px 154px -2px rgba(0, 0, 0, 0.61) !important;
}

@media only screen and (max-width: 900px) {
  .btn-login-first {
    border-radius: 10px !important;
  }

  .btn-primary-navbar {
    background: linear-gradient(
      180deg,
      rgb(241, 97, 30) 0%,
      rgb(243, 37, 37) 100%
    );
    color: white !important;
    width: 6rem;
    font-size: 22px !important;
    border-radius: 50px !important;
    border: 0px !important;
    padding-left: 1rem;
    padding-right: 1rem;
  }
  .btn-primary-navbar:hover {
    background: linear-gradient(
      180deg,
      rgb(207, 81, 22) 0%,
      rgb(207, 25, 25) 100%
    );
    color: white !important;
    box-shadow: 0 0.15em 0.5em 0 rgba(0, 0, 0, 0.171);
  }
  .btn-primary-navbar:focus {
    background: linear-gradient(
      180deg,
      rgb(207, 81, 22) 0%,
      rgb(207, 25, 25) 100%
    );
    color: white !important;
    box-shadow: 0 0.15em 0.5em 0 rgba(0, 0, 0, 0.171);
  }

  .btn-primary-navbar-sm {
    position: absolute;
    right: 1rem;
    background: linear-gradient(
      180deg,
      rgb(241, 97, 30) 0%,
      rgb(243, 37, 37) 100%
    );
    color: white !important;
    width: 2rem;
    height: 2rem;
    border-radius: 50px !important;
    border: 0px !important;
    font-size: 14px !important;
  }
  .btn-primary-navbar-sm:hover {
    background: linear-gradient(
      180deg,
      rgb(207, 81, 22) 0%,
      rgb(207, 25, 25) 100%
    );
    color: white !important;
    box-shadow: 0 0.15em 0.5em 0 rgba(0, 0, 0, 0.171);
  }
  .btn-primary-navbar-sm:focus {
    background: linear-gradient(
      180deg,
      rgb(207, 81, 22) 0%,
      rgb(207, 25, 25) 100%
    );
    color: white !important;
    box-shadow: 0 0.15em 0.5em 0 rgba(0, 0, 0, 0.171);
  }

  .btn-primary {
    background: #fe5000 !important;
    color: #000000 !important;
    width: 100%;
    font-size: 22px !important;
  }
  .btn-primary:hover,
  .btn-primary:focus {
    background: #fa0000 !important;
    color: #411704 !important;
    transition: all 0.3s !important;
    box-shadow: 0px 64px 154px -2px rgba(0, 0, 0, 0.61) !important;
  }
  .btn-primary-host {
    background: #262dff !important;
    color: #000000 !important;
    width: 100%;
    font-size: 22px !important;
  }
  .btn-primary-host:hover,
  .btn-primary-host:focus {
    background: #0d13b3 !important;
    color: #000000 !important;
    transition: all 0.3s !important;
    box-shadow: 0px 64px 154px -2px rgba(0, 0, 0, 0.61) !important;
  }
}

.card-nft{
  margin-top: 10.3rem;
  position: fixed;
  background-color: black !important;
  border-radius: 30px;
  padding: 1rem;
  padding-top: 0.5rem;
  box-shadow: 0px 0px 34px 7px rgba(0, 0, 0, 0.190);
}

.card-nft-ss{
  margin-top: 10.3rem;
  position: fixed;
  background-color: rgb(78, 8, 94) !important;
  border-radius: 30px;
  padding: 1rem;
  padding-top: 0.5rem;
  box-shadow: 0px 0px 34px 7px rgba(0, 0, 0, 0.190);
  width: 240px;
  background-attachment: fixed;
  background-repeat: no-repeat !important;
  background-position: center !important;
  background-size: cover;
  background-image: url(https://muniantigua.gob.gt/legado/assets/fondo5.jpg) !important;
}
.ss {
  width: 200px;
  border-radius: 30px;
}
.logoAyuntamiento {
  width: 100px;
  margin-top: 2rem;
}
.logoAyuntamiento2 {
  width: 40px;
  margin-top: 0rem;
}

.btn-primary-reserva {
  background: #fe50002a;
  color: #fe5000 !important;

  font-size: 22px !important;
  border-radius: 50px !important;
  border: 0px !important;
  padding-left: 1rem;
  padding-right: 1rem;
}
.btn-primary-reserva:hover,
.btn-primary-reserva:focus {
  background: #fe500046;
  color: #fe5000 !important;
  transition: all 0.7s !important;
}

.btn-primary-login {
  background: #171718;
  color: #fe5000 !important;
  border-radius: 50px !important;
  border: 0px !important;
  padding-left: 1rem;
  padding-right: 1rem;
}
.btn-primary-login:hover,
.btn-primary-login:focus {
  background: #232324;
  color: #ff5100 !important;
  transition: all 0.7s !important;
}
.btn-primary-right {
  position: absolute;
  right: 30%;
  bottom: 2rem;
}
.btn-primary-left {
  position: absolute;
  left: 30%;
  bottom: 2rem;
}

.btn-primary-goback {
  width: 2.7rem;
  background-color: transparent !important;
  border: 3px solid #fe50002d !important;
  border-radius: 50rem;
  color: #fe5000;
}
.btn-primary-reserva-host {
  background: linear-gradient(
    90deg,
    rgba(38, 45, 255, 1) 0%,
    rgba(13, 19, 179, 1) 100%
  );
  color: white !important;
  width: 7rem;
  font-size: 22px !important;
  border-radius: 20px !important;
  border: 0px !important;
  margin-top: -0.4rem !important;
}
.btn-primary-reserva-host:hover,
.btn-primary-reserva-host:focus {
  background: linear-gradient(90deg, rgb(24, 31, 226) 0%, rgb(7, 11, 122) 100%);
  color: white !important;
}

.btn-reserva {
  position: absolute !important;
  margin-bottom: 2.7rem !important;
  right: 0rem !important;
  float: right !important;
  margin-right: 0rem !important;
  height: 3.7rem !important;
}

.btn-lg {
  padding: 0.6rem !important;
  width: 9rem;
  font-size: 27px !important;
}
.btn-lg:hover {
  background: rgb(61, 61, 61) !important;
}

.inputs-lg {
  padding: 0.6rem !important;
  width: 20rem !important;
  font-size: 14px !important;

  background-color: #2a2b2c !important;
  background-clip: padding-box;
  border: 0px solid #ced4da !important;
  border-radius: 10px !important;
  transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
  color: white !important;
}

.inputs-lg-textarea {
  height: 7rem !important;
}

.outline-none {
  outline: none;
}

.page-titulo {
  padding-bottom: 1rem;
}

.page-header-info {
  margin-top: 0rem;
  padding-top: 0rem;
  background-attachment: fixed;
  background-repeat: no-repeat !important;
  background-position: center !important;
  background-size: cover;
  height: 100vh;
  background-image: url(/static/media/patterns.deffef66.png) !important;
}

.page-header-container {
  margin-left: 8%;
  margin-right: 8%;
}

.page-header-container-nft {
  padding-top: 8% !important;
  margin-left: 8% !important;
  margin-right: 8% !important;
}

.container-gustos {
  margin-left: 3%;
  margin-right: 3%;
}



.page-back-1 {
  position: relative;
  background-attachment: fixed !important;
  background-repeat: no-repeat !important;
  background-position: center !important;
  background-size: cover !important;
  background: url(/static/media/travel3.6d206b25.png);
  background-color: #0f0f0f;
  height: 700px;
  margin-top: -2rem;

  box-shadow: inset 0px -200px 300px -29px rgba(23,23,24,1);
}

.css-gradient-bg {
  background: linear-gradient(125deg, #fe5000, #fa0000, #8ec61d);
  background-size: 600% 600%;
  animation: AnimationName 16s ease infinite;
}
@keyframes AnimationName {
  0% {
    background-position: 17% 0%;
  }
  50% {
    background-position: 84% 100%;
  }
  100% {
    background-position: 17% 0%;
  }
}

.page-parati {
  padding-top: 9.5rem;
  /*top: 9rem !important;
    position: absolute;
    left: 5%;
    right: 5%;*/
}
.notfound {
  width: 30%;
}
.text__tutorial {
  width: 100%;
  padding: 1rem;
}
.text__tutorial p {
  width: 700px;
  position: relative;
  margin: auto;
}
.img__tutos {
  width: 100% !important;
  position: relative;
  margin: auto;
}

.categoria-parati {
  z-index: 1 !important;
  position: absolute;
  margin-top: -3rem !important;
}

.page-tusexperiencias {
  margin-top: 9.5rem !important;
}

.page-calendario {
  padding-top: 8rem;
}

:root {
  --fc-list-event-dot-width: 20px !important;
  --fc-list-event-hover-bg-color: #000000 !important;
}

.fc-theme-standard .fc-list {
  border: 1px solid transparent !important;
  border: 1px solid transparent !important;
}

.fc .fc-list-empty {
  background-color: rgb(8, 8, 8) !important;
  background-color: transparent !important;
  background-color: var(--fc-neutral-bg-color, transparent) !important;
  height: 50% !important;
  display: flex;
  justify-content: center;
  align-items: center; /* vertically aligns fc-list-empty-inner */
  border-radius: 2rem !important;
}
.fc .fc-list-sticky .fc-list-day > * {
  /* the cells */
  position: -webkit-sticky;
  color: black !important;
  position: sticky;
  top: 0;
  background: black !important;
  background: #fff
  ;
  background: var(
    --fc-page-bg-color,
    #fff
  ); /* for when headers are styled to be transparent and sticky */
}
.fc .fc-list-event-dot {
  display: inline-block;
  box-sizing: content-box;
  width: 0;
  height: 0;
  border: 5px solid #262dff !important;
  border: calc(20px / 2) solid
    #262dff !important;
  border: calc(var(--fc-list-event-dot-width, 10px) / 2) solid
    var(--fc-event-border-color, #262dff) !important;
  border-radius: 5px;
  border-radius: calc(20px / 2);
  border-radius: calc(var(--fc-list-event-dot-width, 10px) / 2);
}
.fc .fc-list-day-cushion:after {
  content: "";
  clear: both;
  display: table; /* clear floating */
}
.fc-theme-standard .fc-list-day-cushion {
  background-color: rgba(255, 255, 255, 0.164) !important;
  background-color: rgba(0, 0, 0, 0.226) !important;
  background-color: var(--fc-neutral-bg-color, rgba(0, 0, 0, 0.226)) !important;
}

.fc .fc-list-table {
  width: 100%;
  border-style: hidden !important; /* kill outer border on theme */
  border-radius: 10rem !important;
}
.fc .fc-list-table tr > * {
  border-left: 0;
  border-right: 0;
  border-style: hidden !important;
}
.fc .fc-list-sticky .fc-list-day > * {
  /* the cells */
  position: -webkit-sticky;
  position: sticky;
  top: 0;
  background: #fff;
  background: #fff
  ;
  background: var(
    --fc-page-bg-color,
    #fff
  ); /* for when headers are styled to be transparent and sticky */
}
.fc .fc-list-table th {
  padding: 0; /* uses an inner-wrapper instead... */
}
.fc .fc-list-table td,
.fc .fc-list-day-cushion {
  padding: 8px 14px;
}
.fc-direction-ltr .fc-list-day-text,
.fc-direction-rtl .fc-list-day-side-text {
  float: left;
  color: rgb(170, 170, 170);
}
.fc-direction-ltr .fc-list-day-side-text,
.fc-direction-rtl .fc-list-day-text {
  float: right;
  color: rgb(170, 170, 170);
}
/* make the dot closer to the event title */
.fc-direction-ltr .fc-list-table .fc-list-event-graphic {
  padding-right: 0;
}
.fc-direction-rtl .fc-list-table .fc-list-event-graphic {
  padding-left: 0;
}
.fc .fc-list-event.fc-event-forced-url {
  cursor: pointer; /* whole row will seem clickable */
}
.fc .fc-list-event {
  background-color: rgba(0, 0, 0, 0.486) !important;
  border-radius: 2rem !important;
}

.fc .fc-list-event:hover td {
  background-color: black !important;
  background-color: #000000;
  background-color: var(--fc-list-event-hover-bg-color, #f5f5f5);
}

@media only screen and (max-width: 900px) {
  .page-header-vr {
    margin-top: -8rem;
    padding-top: 8rem;
    background-attachment: fixed;
    background-repeat: no-repeat;
    background-position: top;
    background-size: 600px;
    height: 700px;
    box-shadow: inset 0px -368px 300px -100px #171718;
  }

  .page-parati {
    padding-top: 13rem;
    /*top: 9rem !important;
    position: absolute;
    left: 5%;
    right: 5%;*/

    background: #171718;
    border-radius: 30px 30px 0 0;
    box-shadow: 0px 9px 46px 38px rgba(23, 23, 24, 1);
  }
  .notfound {
    width: 70%;
  }
  .text__tutorial {
    width: 100%;
  }
  .text__tutorial p {
    width: 100%;
    position: relative;
    margin: 0;
  }
  .img__tutos {
    width: 70% !important;
    position: relative;
    margin: auto;
  }

  .page-tusexperiencias {
    position: absolute;
    top: -4.6rem !important;
    background: #171718;
    border-radius: 30px 30px 0 0;
    box-shadow: 0px 9px 46px 38px rgba(23, 23, 24, 1);
  }

  .categoria-parati {
    z-index: 1 !important;
    position: absolute;
    margin-top: -2.5rem !important;
  }
}

.page-header-first {
  margin-top: 0rem;
  padding-top: 8rem;
  background-attachment: fixed;
  background-repeat: no-repeat;
  background-position: top;
  background-size: cover;
  height: 430px;
  box-shadow: inset 0px -368px 300px -100px #171718;
}

@media only screen and (max-width: 900px) {
  .page-header-first {
    position: fixed;
    top: 0;
    width: 400px;
    padding-top: 2rem;
    height: 100px;
    z-index: -1 !important;
    background: #171718 !important;
  }
  .page-calendario {
    padding-top: 10rem;
  }
}

.btn-primary-menu {
  width: 10rem;
  color: #ffffff !important;
  font-size: 25px !important;
  border-radius: 10px;
}
.btn-primary-menu:focus {
  color: #fe5000 !important;
  text-decoration: none;
}

.btn-primary-menu-first {
  width: 10rem;
  color: #171718 !important;
  padding-bottom: 0.1rem !important;
  font-size: 20px !important;
  padding-top: 0.7rem !important;
  padding-bottom: 0.7rem !important;
  border-radius: 50px;
}
.btn-primary-menu-first:hover,
.btn-primary-menu-first:focus {
  color: #582005 !important;
  text-decoration: none;
}
.btn-primary-menu-first-sm {
  width: 10rem;
  color: #1717188c !important;
  padding-bottom: 0.1rem !important;
  font-size: 20px !important;
  padding-top: 0.3rem !important;
  padding-bottom: 0.7rem !important;
  border-radius: 50px;
}
.btn-primary-menu-first-sm:hover,
.btn-primary-menu-first-sm:focus {
  color: #582005 !important;
  text-decoration: none;
}

.btn-primary-menu-first-chat {
  color: #171718 !important;
  font-size: 25px !important;
  border-radius: 50px;
}
.btn-primary-menu-first-chat:hover,
.btn-primary-menu-first-chat:focus {
  color: #582005 !important;
  text-decoration: none;
}

/*.btn-primary-menu-first {
  width: 10rem;
  color: #cdcdcd !important;
  padding-bottom: 0.1rem !important;
  font-size: 20px !important;
  padding-top: 0.7rem !important;
  padding-bottom: 0.7rem !important;
  border-radius: 50px;
}
.btn-primary-menu-first:hover,
.btn-primary-menu-first:focus {
  color: #fe5000 !important;
  text-decoration: none;
}*/

.btn-primary-menu-first-host {
  width: 10rem;
  color: #cdcdcd !important;
  padding-bottom: 0.1rem !important;
  font-size: 20px !important;
  padding-top: 0.7rem !important;
  padding-bottom: 0.7rem !important;
  border-radius: 10px;
}
.nav-host-active,
.btn-primary-menu-first-host:hover,
.btn-primary-menu-first-host:focus {
  color: #262dff !important;
  text-decoration: none;
}

.btn-primary-menu-first-host-active .btn-primary-menu-circle {
  border-radius: 50px !important;
  background: #e2e6ea;
  width: 60px;
  font-size: 25px !important;
}
.btn-primary-menu-circle:hover,
.btn-primary-menu-circle:focus {
  background: #fe500034;
  color: #fe5000 !important;
  width: 60px;
}

.btn-primary-sidebar {
  color: rgb(56, 56, 56) !important;
  padding-bottom: 0.1rem !important;
  font-size: 24px !important;
  padding-top: 0.8rem !important;
  padding-bottom: 0.8rem !important;
}

.form-map {
  display: block;
  width: 100% !important;
  height: 4rem !important;
  padding: 1.2rem 1rem !important;
  font-size: 1rem;
  background-color: #2a2b2c !important;
  border: 0px solid #ced4da !important;
  border-radius: 100px !important;
  transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
  color: white !important;
  outline: none !important;
}
.form-map:hover,
.form-map:focus {
  background-color: #1d1f20 !important;
  box-shadow: 0 0em 0em 0 rgba(0, 0, 0, 0.171) !important;
}

.form-control {
  display: block;
  width: 100% !important;
  height: 4rem !important;
  padding: 1.2rem 1rem !important;
  font-size: 1rem;
  background-color: #2a2b2c !important;
  background-clip: padding-box;
  border: 0px solid #ced4da !important;
  border-radius: 10px !important;
  transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
  color: white !important;
}
.form-control:hover,
.form-control:focus {
  background-color: #1d1f20 !important;
  box-shadow: 0 0em 0em 0 rgba(0, 0, 0, 0.171) !important;
}

.form-control-textarea {
  display: block;
  width: 100% !important;
  height: 14rem !important;
  padding: 1.2rem 1rem !important;
  font-size: 1rem;
  background-color: #2a2b2c !important;
  background-clip: padding-box;
  border: 0px solid #ced4da !important;
  border-radius: 10px !important;
  transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
  color: white !important;
}
.form-control-textarea:hover,
.form-control-textarea:focus {
  background-color: #1d1f20 !important;
  box-shadow: 0 0em 0em 0 rgba(0, 0, 0, 0.171) !important;
}

.form-control-textarea-host {
  display: block;
  width: 100% !important;
  height: 15rem !important;
  padding: 1.2rem 1rem !important;
  font-size: 1rem;
  background-color: #2a2b2c !important;
  background-clip: padding-box;
  border: 0px solid #ced4da !important;
  border-radius: 10px !important;
  transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
  color: white !important;
}
.form-control-textarea-host:hover,
.form-control-textarea:focus {
  background-color: #1d1f20 !important;
  box-shadow: 0 0em 0em 0 rgba(0, 0, 0, 0.171) !important;
}

.form-control-login {
  height: 4rem !important;
  font-size: 1rem;
  font-weight: 400;
  line-height: 1.5;
  background: #2d3235 !important;
  background-clip: padding-box;
  border: 0px solid #ced4da !important;
  border-radius: 10px !important;
  padding: 0.3rem;
  transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
  color: white !important;
  padding-left: 1rem !important ;
  padding-right: 1.6rem;
  width: 100% !important;
}

.form-control-selector {
  font-size: 1rem;
  height: 4rem !important;
  font-weight: 400;
  line-height: 1.5;
  background: #2a2b2c !important;
  border: 0px solid #ced4da !important;
  border-radius: 10px !important;
  padding-left: 1rem !important;
  transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
  color: rgba(255, 255, 255, 0.411) !important;
}
.form-control-selector:hover {
  background-color: #383e42 !important;
  box-shadow: 0 0em 0em 0 rgba(0, 0, 0, 0.171) !important;
}

.form-control-chat {
  width: 100% !important;
  font-size: 1rem;
  font-weight: 400;
  line-height: 1.5;
  height: 2rem !important;
  background-color: #2d3235 !important;
  border: 0px solid #ced4da !important;
  border-radius: 50px !important;
  transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
  color: white !important;
}

.form-control-chat:hover,
.form-control-chat:focus {
  background-color: #383e42 !important;
  box-shadow: 0 0em 0em 0 rgba(0, 0, 0, 0.171) !important;
}

.form-control-search {
  display: block;
  width: 100% !important;
  height: 2rem !important;
  padding: 1.3rem 3rem !important;
  font-size: 1rem;
  font-weight: 400;
  line-height: 1.5;
  background-color: #17191a;
  background-clip: padding-box;
  border: 0px solid #ced4da !important;
  border-radius: 50px !important;
  transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
}
.form-control-search:hover,
.form-control-search:focus {
  background-color: #1d1f20 !important;
  box-shadow: 0 0em 0em 0 rgba(0, 0, 0, 0.171) !important;
}

@media only screen and (max-width: 900px) {
  .form-control-chat {
    margin-top: 0.8rem;
    margin-left: 1rem;
    font-size: 1rem;
    font-weight: 400;
    line-height: 1.5;
    background-color: #2d3235 !important;
    border: 0px solid #ced4da !important;
    border-radius: 50px !important;
    transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
    color: white !important;
  }

  .form-control-chat:hover,
  .form-control-chat:focus {
    background-color: #383e42 !important;
    box-shadow: 0 0em 0em 0 rgba(0, 0, 0, 0.171) !important;
  }

  .form-control-search-sm {
    display: block;
    width: 100% !important;
    margin-top: -0.4rem !important;
    margin-left: 0.8rem;
    margin-right: 0.8rem;
    font-size: 1rem;
    font-weight: 400;
    line-height: 1.5;
    background: transparent !important;

    border: 0px solid #ced4da !important;
    border-radius: 50px !important;
    transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
  }
  .form-control-search:hover,
  .form-control-search:focus {
    background-color: #2a2d2e !important;
    box-shadow: 0 0em 0em 0 rgba(0, 0, 0, 0.171) !important;
  }
}

.form-control-login-code {
  width: 100% !important;
  font-size: 1rem;
  font-weight: 400;
  line-height: 1.5;
  background-color: #2d3235 !important;
  background-clip: padding-box;
  border: 0px solid #ced4da !important;
  border-radius: 50px !important;
  transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
  color: white !important;
}

.form-control-login-code:hover,
.form-control-login-code:focus {
  background-color: #383e42 !important;
  box-shadow: 0 0em 0em 0 rgba(0, 0, 0, 0.171) !important;
}

.form-control-login-area {
  width: 100px !important;
  font-size: 1rem;
  font-weight: 400;
  line-height: 1.5;
  background-color: transparent !important;
  background-clip: padding-box;
  border: 0px solid #ced4da !important;
  border-radius: 50px !important;
  transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
  color: white !important;
  position: absolute;
  left: 0px;
}

.form-control-login-area:hover,
.form-control-login-area:focus {
  background-color: transparent !important;
  box-shadow: 0 0em 0em 0 rgba(0, 0, 0, 0.171) !important;
}

.container {
  margin-top: 2%;
  max-width: 1300px !important;
}

.container__tuto {
  padding-left: 6rem;
  padding-right: 6rem;
}

@media only screen and (max-width: 900px) {
  .container {
    margin-top: -5rem;
  }

  .page__header {
    height: 7rem !important;
    background-color: white;
  }
}

.nav-vertical {
  width: 10rem;
}

.favorites {
  z-index: 2;
  border-radius: 50px !important;
}

.navbar {
    padding-left: 0.8rem;
    padding-right: 0.8rem;
    margin-top: 2rem !important;
    margin-left: 2rem !important;
    margin-right: 2rem !important;
    padding-top: 0.8rem !important; 
    padding-bottom: 0.8rem !important; 
    border-radius: 50px !important;
    border: 0px solid rgba(255, 255, 255, 0.041) !important;
    background: linear-gradient(175deg, #fe5000, #fa0000);
    z-index: 2 !important;
    box-shadow: 0px 0px 0px 0px rgba(0,0,0,1);
  }
/*.navbar {
  padding-left: 2rem;
  padding-right: 2rem;
  padding-top: 0.8rem !important;
  padding-bottom: 0.8rem !important;
  border-bottom: 0px solid rgba(0, 0, 0, 0.253) !important;
  z-index: 2 !important;
  height: 5rem;
  background: #171718 !important;
}*/
.navbar-info {
  background: #17171893 !important;
}

.navbar-sm {
  padding-top: 1.5rem !important;
  margin-left: 2rem !important;
  margin-right: 1rem !important;
  margin-bottom: 1rem;
  width: 77% !important;
  height: 4.5rem;
  border-radius: 30px !important;
  z-index: 2 !important;
  box-shadow: 0px 0px 104px 0px rgba(0, 0, 0, 0.75);

  background: linear-gradient(175deg, #fe5000, #fa0000) !important;
  bottom: 1rem !important;
  left: 0rem !important;
  right: 1rem !important;

}

.navbar-sm text {
  position: relative !important;
  top: 0.2rem !important;
  left: 1rem !important;
}
.navbar-sm span {
  color: rgba(255, 255, 255, 0.568);
}

.navbar-titles {
  position: fixed !important;
  width: 100%;
  position: absolute;
  left: 0;
  height: 3.7rem;
  background: #171718;
  box-shadow: -1px 1px 7px -1px rgba(0, 0, 0, 0.51);
  z-index: 1;
}
.navbar-titles-date {
  width: 100%;
  position: absolute;
  left: 0;
  height: 3.7rem;
  background: #171718;
}

.navbar-titles h5 {
  position: absolute !important;
  left: 4rem !important;
  top: 1.1rem;
}

.navbar-sm-chat {
  width: 100%;
  height: 3.7rem;
  border-radius: 50px;
  padding-top: 1.1rem !important;
  padding-bottom: 0rem !important;
  background: #2a2b2c;
  box-shadow: -1px 1px 7px -1px rgba(0, 0, 0, 0.51);
  z-index: 2 !important;
}

.exp-reservada {
  color: white !important;
  font-size: 25px !important;
  height: 3rem;
}
.exp-reservada text {
  font-size: 20px;
}

.exp-fixed-bottom {
  color: #171718;
  position: fixed;
  bottom: 2rem !important;
  right: 2rem !important;
  left: auto !important;
  z-index: 10 !important;
  padding: 0;
}
.exp-fixed-bottom-open {
  position: fixed;
  bottom: 1.5rem !important;
  right: -15rem !important;
  left: auto !important;
}

.exp-fixed-bottom-metodo-pago {
  position: fixed !important;
  bottom: 1rem !important;
  right: -17rem !important;
}

.exp-fixed-bottom-help-old {
  position: fixed !important;
  bottom: 1rem !important;
  right: 1rem !important;
  width: 2.7rem !important;
  height: auto !important;
  border-radius: 50px;
  outline: none !important;
  padding: 0.7rem;
  color: #fe5000;
  background: #171718 !important;
  z-index: 10;
  box-shadow: 0px 0px 9px 0px rgba(0, 0, 0, 0.47);
}
.exp-fixed-bottom-help:hover {
  background-color: #1c1c1e !important;
}
.exp-fixed-bottom-help {
  position: absolute !important;
  left: 1rem;
  bottom: 2rem !important;
  border-radius: 50px;
  outline: none !important;
  padding: 0.7rem;
  color: #fe5000 !important;
  background: #171718 ;
  z-index: 10;
  box-shadow: 0px 0px 9px 0px rgba(0, 0, 0, 0.47);
}
.exp-fixed-bottom-help:hover {
  background-color: #1c1c1e !important;
}

.exp-fixed-top-help {
  position: fixed !important;
  z-index: 10 !important;
  top: 15% !important;
  right: 2rem !important;
  left: 2rem !important;
  width: auto !important;
  border-radius: 50px;
  outline: none !important;
  padding: 1rem;
  background: #441803;
}
.exp-fixed-top-notice {
  position: fixed !important;
  z-index: 10 !important;
  top: 12% !important;
  right: 2rem !important;
  left: 2rem !important;
  width: auto !important;
  border-radius: 50px;
  outline: none !important;
  padding: 1rem;
  background: #441803 !important;
  border-radius: 20px !important;
}

.exp-fixed-bottom-open-host {
  position: fixed;
  bottom: 2rem !important;
  right: -19rem !important;
  left: auto !important;
  background: linear-gradient(
    90deg,
    rgba(38, 45, 255, 1) 0%,
    rgba(13, 19, 179, 1) 100%
  ) !important;
}

.btn-reserva {
  width: 8rem !important;
  border: 0px solid;
  top: 0;
}

.navbar-exp-login {
  padding-top: 0.8rem !important;
  padding-bottom: 0.8rem !important;
  background: #171718;
  position: absolute;
  bottom: 10rem !important;
  align-content: center;
}
.exp-pasos {
  padding: 0.3rem;
  cursor: pointer;
  float: right;
  margin-right: 0rem;
  margin-top: 0rem;
}
.exp-pasos-prev {
  margin-top: 0.2rem;
  margin-left: 1rem;
  float: left;
  display: flex;
  align-items: center;
}
.exp-details {
  width: 40rem;
}
.exp-details text {
  font-size: 16px;
  color: rgba(255, 255, 255, 0.568);
}
.exp-details-about {
  background: #2a2b2c;
  border-radius: 10px;
  padding: 2rem;
}
.exp-galeria {
  position: none !important;
}
.exp-tabs {
  background: #171718;
  z-index: 2 !important;
  margin-top: 0rem;
  display: flex;
  justify-content: center;
  border-radius: 0px 0px 0 0;
}
.exp-review-comments {
  background: #2a2b2c;
  border-radius: 10px;
  padding: 1.5rem;
}

.exp-counter {
  padding: 2rem !important;
}

.exp-fixed-bottom-metodo-pago {
  position: fixed;
  bottom: 2rem !important;
  right: -17rem !important;
  border: 0 solid !important;
  width: 3.7rem;
  height: 3.7rem;
  border-radius: 50% !important;
  padding: 0.8rem;
  background: #fe50002a;
  color: #000000 !important;
  z-index: 2 !important;
  box-shadow: -1px 1px 7px -1px rgba(0, 0, 0, 0.51);
  margin-right: 20.5rem;
}
.exp-fixed-bottom-metodo-pago:focus,
.exp-fixed-bottom-metodo-pago:hover,
.exp-chat:hover,
.exp-chat:focus {
  background: #fe500046;
  color: black !important;
  outline: none;
}

.exp-chat-box {
  background: transparent;
  height: 3.7rem;
  width: 100%;
}

.exp-pay-card {
  padding-left: 1.7rem !important;
  padding-top: 1rem !important;
  padding-bottom: 1rem !important;
}

.counter {
  background: white !important;
}

.navbar-sm-more {
  width: 320px;
  border-radius: 10px;
  height: 3.7rem !important ;
  padding-top: 1.3rem;
  background: rgb(12, 12, 12);
  font-size: 30px !important;
  z-index: 10 !important;
}
.navbar-sm-more text {
  position: relative !important;
  top: 0.2rem !important;
  left: 0.4rem !important;
}
.navbar-sm-more span {
  color: rgba(255, 255, 255, 0.568);
}

.navbar-sm-more-host {
  width: 250px;
  border-radius: 10px;
  height: 3.7rem !important ;
  padding-top: 1rem;
  background: rgb(12, 12, 12);
  font-size: 30px !important;
  z-index: 10 !important;
}
.navbar-sm-more-host text {
  position: relative !important;
  top: 0.2rem !important;
  left: 0.4rem !important;
}
.navbar-sm-more-host span {
  color: rgba(255, 255, 255, 0.568);
}

.fav_fixed {
  position: fixed !important;
}

.page-pagos-host {
  padding-top: 10rem;
}

.exp-tabs-host {
  background: transparent !important;
  margin-right: 0rem;
  z-index: 1 !important;
}

.pagos-balance {
  font-size: 40px;
}

.nft {
  width: 20px;
  position: relative;
  margin-right: 5px;
}
.nft_data {
  background: #0e0e0e;
  padding-top: 0.3rem;
  padding-bottom: 0.3rem;
  padding-left: 0.6rem;
  border-radius: 20px;
  margin-left: 5px;
  margin-right: 5px;
  font-size: 12px;
}

.nft_data_inside {
  background: #0e0e0e;
  padding-top: 0.3rem;
  padding-bottom: 0.3rem;
  padding-left: 0.6rem;
  padding-top: 0.6rem;
  padding-bottom: 0.6rem;
  border-radius: 20px;
  margin-left: 5px;
  margin-right: 5px;
  font-size: 18px;
}

.pagos-balance span {
  font-size: 20px;
}

.card-balance {
  background: linear-gradient(
    90deg,
    rgba(38, 45, 255, 1) 0%,
    rgba(13, 19, 179, 1) 100%
  );
  padding: 1rem;
  padding-top: 0.6rem;
  border-radius: 10px !important;
  height: 10rem !important;
  margin-top: 7rem;
}

.card-balance text {
  font-size: 16px;
  color: #0d13b3;
}

.pagos {
  color: rgba(255, 255, 255, 0.575) !important;
}

.card-bancos {
  background: #101011 !important;
  padding: 1rem;
  padding-top: 1rem;
  border-radius: 10px !important;
  width: 30rem;
  height: auto;
  margin-bottom: 50px;
}

.card-bancos text {
  font-size: 16px;
  color: #080c77;
}

.pagos-graficas {
  width: 20rem !important;
  height: 20rem !important;
}

.pagos-bancos {
  width: 100%;
}

.search-movil {
  opacity: 0;
}

.exp-chat {
  width: 3.7rem;
  height: 3.7rem;
  margin-right: 17rem;
  z-index: 10 !important;
  border-radius: 50px;
}

.search-instant {
  margin-top: -120px;
}

@media only screen and (max-width: 1000px) {
  .search-instant {
    margin-top: -250px;
    padding-bottom: 0.5rem !important;
  }
  .search-movil {
    opacity: 1;
  }
  .card-bancos {
    width: 100%;
  }

  .card-bancos text {
    font-size: 16px;
    color: #0d13b3;
  }

  .pagos-bancos {
    width: 17.5rem;
  }

  .exp-tabs-host {
    background: #171718 !important;
  }

  .pagos-graficas {
    width: 14rem !important;
    height: 14rem !important;
    margin-left: 0.6rem;
    position: relative;
    left: 1rem;
    right: 0;
  }

  .pagos-balance {
    font-size: 40px;
  }
  .pagos-balance span {
    font-size: 20px;
  }

  .page-pagos-host {
    padding-top: 3rem;
  }

  .card-balance {
    width: 100%;
    margin-top: 1rem;
  }

  .pagos {
    color: rgba(255, 255, 255, 0.575) !important;
  }

  .navbar-sm {
    padding-top: 1.5rem !important;
    margin-left: auto;
    margin-right: auto;
    margin-bottom: 0rem;
    width: 100%;
    height: 4.5rem;
    border-radius: 0px;
    background: #141414;
    z-index: 3 !important;
  }

  .navbar-sm-host {
    padding-top: 1.5rem !important;
    margin-left: 1rem;
    margin-right: auto;
    margin-bottom: 1rem;
    width: 250px;
    height: 4.5rem;
    border-radius: 30px;
    background: #171718;
    z-index: 2 !important;
    box-shadow: 0px 0px 104px 0px rgba(0, 0, 0, 0.75);
  }

  .navbar-sm-more {
    border-radius: 0px;
    width: 100%;
    height: 3.7rem !important ;
    padding: 1.2rem;
    background: #171718;
    box-shadow: -1px 1px 7px -1px rgba(0, 0, 0, 0.51);
    z-index: 10 !important;
    margin-bottom: -0.2rem;
  }
  .navbar-sm-more-host {
    border-radius: 0px;
    width: 100%;
    height: 3.7rem !important ;
    padding: 1rem;
    background: #171718;
    box-shadow: -1px 1px 7px -1px rgba(0, 0, 0, 0.51);
    z-index: 10 !important;
    margin-bottom: -0.2rem;
  }
  .navbar-sm-first {
    height: 3.7rem !important ;
    padding: 0.7rem;
    background: #171718;
  }

  .exp-fixed-bottom {
    color: #171718;
    position: fixed;
    right: 0 !important;
    bottom: 0 !important;
    left: 0;
    z-index: 1030;
  }
  .exp-fixed-bottom-open {
    position: fixed;
    bottom: -3rem !important;
    right: -0.1rem !important;
    left: auto !important;
  }

  .exp-fixed-bottom-open-host {
    position: fixed;
    bottom: -3rem !important;
    right: -0.1rem !important;
    left: auto !important;
    background: linear-gradient(
      90deg,
      rgba(38, 45, 255, 1) 0%,
      rgba(13, 19, 179, 1) 100%
    ) !important;
  }

  .exp-fixed-upload {
    display: flex !important;
    justify-content: center !important;
    align-items: center !important;
  }

  .fav_fixed {
    position: fixed !important;
    left: 10rem;
  }

  .navbar-exp-login {
    padding-left: 0.9rem;
    padding-right: 1rem;
    padding-top: 1.2rem !important;
    padding-bottom: 1.2rem !important;
    background: #171718;
    cursor: pointer;
    box-shadow: -1px 1px 7px -1px rgba(0, 0, 0, 0.51);
    bottom: 0rem !important;
  }
  .exp-pasos {
    float: right;
    cursor: pointer;
    margin-right: 0rem;
    margin-top: -0rem;
  }
  .exp-pasos-prev {
    border: 0 solid !important;
    width: 40px;
    height: 40px;
    border-radius: 50% !important;
    padding-top: 2px;
    box-shadow: none !important;
    background: #2a2b2c;
    margin-left: 1rem;
    margin-top: 0.8rem;
    z-index: 3 !important;
    font-size: 15px;
    
  }
  .exp-details {
    width: 100%;
  }
  .exp-details text {
    font-size: 16px;
    color: rgba(255, 255, 255, 0.568);
  }
  .exp-galeria {
    position: none !important;
    left: 0rem;
    right: 0rem;
    margin-bottom: 3.5rem;
  }
  .exp-tabs {
    background-color: #171718;
    display: flex;
    justify-content: center;
    margin-left: -0.6rem;
    margin-right: -0.6rem;
    border-radius: 0px 0px 0 0;
    z-index: 10 !important;
  }

  .explora {
    padding-top: 0rem !important;
    padding-bottom: 10rem !important;
  }

  .exp-upload {
    width: 2.7rem;
    height: 2.7rem;
    margin-right: 1rem;
    margin-bottom: 4.2rem;
    position: absolute;
    bottom: 2rem;
  }
  .exp-fixed-bottom-metodo-pago:hover,
  .exp-fixed-bottom-metodo-pago:focus,
  .exp-chat:hover,
  .exp-chat:focus {
    filter: brightness(1.1);
    outline: none;
  }

  .exp-fixed-bottom-metodo-pago {
    bottom: 1rem !important;
    right: -19rem !important;
  }
}

.nav-pills .show > .nav-link {
  background-color: #fe500015 !important;
  color: #fff;
  border-radius: 10px;
  outline: none;
}
.nav-link-active {
  color: #171718 !important;
  background: rgba(0, 0, 0, 0.264);
}
.nav-link-active-sm {
  color: #171718 !important;
}

.nav {
  position: fixed;
  width: 13rem !important;
}
.flex-column {
  width: 10rem;
}
.card-precio {
  font-size: 18px;
}

@media (max-width: 800px) {
  .card-columns {
    column-count: 4 !important;
    -webkit-column-gap: 1.25rem;
    -moz-column-gap: 1.25rem;
    grid-column-gap: 1.25rem;
    column-gap: 1.25rem;
    orphans: 1;
    widows: 1;
  }
  .card-columns .card {
    display: inline-block;
    width: 100%;
  }
}

.pointer {
  cursor: pointer;
}
section {
  margin-top: 1rem;
  margin-bottom: 3rem;
}

.calificacion {
  margin-bottom: -4rem !important;
}

.card {
  background-color: transparent !important;
  border: 0px solid !important;
}

.card-border {
  border-radius: 20px !important;
  background-color: #17191a !important;
  height: 450px;
}
.card-border-planes {
  border-radius: 20px !important;
  background-color: #17191a !important;
  height: 400px;
}

.card-border-best {
  border-radius: 20px !important;
  background-color: #17191a !important;
  height: 380px;
}

.border-flag {
  border: 4px solid white;
}

.border-secondary {
  color: rgb(133, 19, 19) !important;
}

.card-etiqueta {
  font-size: 15px;
  color: rgba(0, 0, 0, 0.493) !important;
}

.text-paises {
  line-height: 100px;
}

.card-paises {
  height: 8rem;
}

@media only screen and (max-width: 900px) {
  .nft {
    width: 40px;
    position: relative;
    margin-right: 0px;
  }
  .exp-fixed-bottom-help {
    position: fixed !important;
    bottom: 3rem !important;
    right: 4rem !important;
    left: 4rem !important;
    height: auto !important;
    border-radius: 50px;
    outline: none !important;
    padding: 1rem;
    background: #fe5000;
    color: #171718 !important;
  }
  .exp-fixed-bottom-help:hover {
    background-color: #eb4d04 !important;
  }

  .btn-crypto {
    z-index: 10;
    position: fixed;
    bottom: 1.2rem !important;
    right: 1rem !important;
    background: #171718;
    color: #060953 !important;
    border-radius: 50%;
    height: 4rem;
    width: 4rem;
    box-shadow: 0px 0px 9px 0px rgba(0, 0, 0, 0.47);
  }
  .btn-crypto:hover {
    background-color: #1c1c1e !important;
  }

  .exp-chat {
    width: 3.2rem;
    height: 3.2rem;
    margin-right: 1rem;
    margin-bottom: 4.5rem;
    z-index: 10 !important;
  }

  .exp-upload {
    width: 3.2rem;
    height: 3.2rem;
    margin-right: 1rem;
    margin-bottom: 1.5rem;
  }

  .card-paises {
    height: 3rem;
  }

  .card-paises h1 {
    font-size: 20px !important;
  }
  .text-paises {
    line-height: 15px !important;
  }
  .text-paises-sm {
    display: block !important;
    font-size: 10px;
  }
  .card-border {
    border-radius: 20px !important;
    background-color: #17191a !important;
    height: 250px;
  }
  .card-border-best {
    border-radius: 20px !important;
    background-color: #17191a !important;
    height: 320px;
  }
}

.card-number {
  padding-left: 55px !important;
}

@media only screen and (width: 768px) {
  .card-border {
    border-radius: 20px !important;
    background-color: #17191a !important;
    height: 350px;
  }
}
@media only screen and (max-width: 600px) {
  .rs-drawer-right.rs-drawer-xs {
    width: 100% !important;
  }
}
.card-paises:hover {
  box-shadow: 0 0.15em 0.5em 0 rgba(0, 0, 0, 0.171) !important;
}

.card-footer {
  margin: 0.3rem !important;
  background: #fe500021 !important;
  padding: 0.4rem !important;
  border-radius: 0 0 20px 20px !important;
}

.card-img-overlay {
  position: none;
  background: rgb(0, 0, 0);
  background: linear-gradient(
    0deg,
    rgba(0, 0, 0, 0.788) 5%,
    rgba(0, 0, 0, 0) 74%
  );
  padding-left: 0.6rem;
  padding-right: 0.6rem;
  border-radius: 20px !important;
}

.card-bottom-fav {
  position: absolute;
  bottom: 1%;
  left: 8%;
  right: 8%;
}

.card-bottom {
  position: absolute;
  bottom: 0;
  left: 8%;
  right: 8%;
}

@media only screen and (max-width: 900px) {
  .card-bottom {
    position: absolute;
    bottom: 0%;
    left: 4%;
    right: 4%;
  }
}

.card-icon {
  font-size: 25px;
}

.card-icon:hover {
  color: #f86420 !important;
}

.card-hover {
  width: 100%;
  overflow: hidden;
  position: relative;
  box-shadow: 1px 1px 3px 0 rgba(0, 0, 0, 0.3);
  border-radius: 30px !important;
}
.card-hover img {
  width: 100%;
  top: 0;
  position: relative;
  left: 0;
  transform: scale(1.1);
  transition: transform 0.3s;
}
.card-hover .buttons {
  position: absolute;
  top: 3.5%;
  right: 4.5%;
}

.card-hover .buttons .fa {
  background: rgb(256, 256, 256);
  border-radius: 50%;
  border: white !important;
  color: rgba(50, 50, 50, 0.9);
  display: inline-block;
  margin: 0 1px;
  line-height: 65px;
  font-size: 25px;
  text-align: center;
  text-decoration: none;
  width: 60px;
  height: 60px;
  transition: all 0.3s;
  opacity: 0;
  padding-right: 0.2rem;
}
.card-hover:hover img {
  filter: contrast(1.04) invert(0.18) saturate(2.38);
}
.card-hover:hover .ovrly {
  opacity: 1;
}
.card-hover:hover .buttons .fa {
  opacity: 1;
}

.card .icon {
  height: 25px;
  fill: #fe5000 !important;
}

.card .place {
  font-size: 12px;
  color: rgba(255, 255, 255, 0.562);
}

.card .avatar {
  height: 55px;
  box-shadow: 0 0.25em 0.5em 0 rgba(0, 0, 0, 0.24);
  
}

.card .avatar:hover {
  box-shadow: 0 0.25em 0.5em 0 rgba(0, 0, 0, 0.459);
}
.card .size-md {
  font-size: 18px !important;
}

.card .size-sm {
  font-size: 9px !important;
  line-height: 10px;
}

.img-paises {
  border-radius: 20px !important;
  margin: 0.3rem;
  width: 206px !important;
}

.text-trend {
  padding: 0.3rem;
  background: transparent !important;
}

.text-white2 {
  color: #cdcdcd;
}

.text-trend .text-size {
  font-size: 16px !important;
  color: #1c2836 !important;
}

.text-grey {
  color: #bbbbbb !important;
}

.orange__gradient {
  background: -webkit-linear-gradient(#fe5000, #fe0000);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}
.neon__blue__gradient {
  background: -webkit-linear-gradient(#262dff, #0d13b3);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}

.text-navbar-sm {
  font-size: 16px;
}

.exp-details-card {
  border: 2px solid rgb(14, 14, 14);
  border-radius: 20px;
  padding: 1rem;
}

.exp-titulo {
  margin-top: 1%;
  line-height: 80px;
  color: white !important;
  text-shadow: 0px 0px 30px rgba(0, 0, 0, 0.61);
}

.exp-header {
  margin-top: -1rem;
  z-index: -2 !important;
}
.exp-header-pic {
  margin-top: -1rem;
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;
  height: 110vh;
}

.title__info {
  font-size: 85px;
  line-height: 4rem;
  color: #f35626;
  background: linear-gradient(
    to right,
    #fe5000 20%,
    #fa0000 40%,
    #0044ff 60%,
    #fe5000 80%
  );
  -webkit-background-clip: text;
  background-clip: text;
  text-fill-color: transparent;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  background-size: 200% auto;
  animation: shine 10s linear infinite;
}

@keyframes shine {
  to {
    background-position: 200% center;
  }
}
.title__secundary {
  font-size: 55px;
  line-height: 3rem;
  color: #f35626;
  background: linear-gradient(to right, #fe5000 20%, #fa0000 40%, #fe5000 80%);
  -webkit-background-clip: text;
  background-clip: text;
  text-fill-color: transparent;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  background-size: 200% auto;
  animation: shine 10s linear infinite;
}

.title__info_bubble {
  background: #fe500023;
  color: #fa0000;
  padding: 0.6rem;
  padding-left: 1rem;
  padding-right: 1rem;
  border-radius: 50px;
  position: relative;
  bottom: 1.6rem;
}

@media only screen and (max-width: 900px) {
  .exp-titulo {
    line-height: 50px;
    color: white !important;
    text-shadow: 0px 0px 30px rgba(0, 0, 0, 0.61);
    top: 10rem;
    position: absolute;
  }
  .exp-titulo p {
    margin-top: 1%;
    font-size: 50px !important;
  }
  .exp-header {
    margin-top: -5rem;
    padding-top: 8rem;
    background-repeat: no-repeat;
    background-position: center;
    background-size: cover;
    height: 800px;
    box-shadow: inset 0px -268px 150px -250px #171718;
  }
}

.exp-contenido {
  background: #171718;
  position: absolute;
  left: 0;
  right: 0;
  margin-top: 90vh;
  border-radius: 30px 30px 0 0;
  box-shadow: 0px 2px 20px 6px rgba(0, 0, 0, 0.31);
  margin-bottom: -1rem;
}
.exp-contenido-open {
  background: #171718;
  position: absolute;
  left: 0;
  right: 0;
  margin-top: 100vh;
  border-radius: 30px 30px 0 0;
  box-shadow: 0px 2px 20px 6px rgba(0, 0, 0, 0.31);
  margin-bottom: -1rem;
}

.exp-contenido2 {
  background: #171718;
  position: absolute;
  left: 0;
  right: 0;
  margin-top: 85vh;
  border-radius: 30px 30px 0 0;
  box-shadow: 0px 2px 20px 6px rgba(0, 0, 0, 0.31);
  margin-bottom: -1rem;
}
.exp-contenido-btn {
  margin-top: 1rem;
  color: rgba(255, 255, 255, 0.459);
  background: rgba(0, 0, 0, 0.26);
  border: 0px;
  border-radius: 50px !important;
  padding: 0.6rem;
  padding-left: 1rem;
  padding-right: 1rem;
  margin-bottom: 1rem;
  margin-left: 0.5rem;
  margin-right: 0.5rem;
}

.exp-container {
  margin-left: 5rem;
  margin-right: 5rem;
}

.exp-active {
  outline: none;
}
.exp-active:hover,
.exp-active:focus {
  outline: none;
}
.exp-next {
  float: right;
  position: relative;
}

.galeria {
  margin-top: -18rem;
}

.card-details {
  padding: 0.5rem;
  float: left;
  height: 4rem;
}

.card-host {
  padding: 0.5rem;
  padding-top: 0.7rem;
  background: #1c28367e !important;
  border-radius: 60px !important;
  float: left;
  height: 4rem;
}
.card-host:hover {
  transition: all 0.3s;
  box-shadow: 0px 64px 154px -2px rgba(0, 0, 0, 0.49);
  background: #1c2836 !important;
  -webkit-text-decoration-line: none;
          text-decoration-line: none;
}
.card-host text {
  font-size: 18px !important;
}
.card-host p {
  font-size: 25px !important;
}

.card-host-datos {
  font-size: 15px !important;
  margin-top: -0.2rem;
}

.img-zoom {
  width: 100%;
  overflow: hidden;
  position: relative;
  box-shadow: 1px 1px 3px 0 rgba(0, 0, 0, 0.3);
}
.img-zoom img {
  width: 100%;
  top: 0;
  position: relative;
  left: 0;
  transition: transform 0.3s;
}
.img-zoom .ovrly {
  background: rgba(0, 0, 0, 0.5);
  height: 100%;
  left: 0;
  top: 0;
  position: absolute;
  width: 100%;
  opacity: 0;
  transition: all 0.3s;
}
.img-zoom .buttons {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}
.img-zoom .buttons .fa {
  background: rgb(256, 256, 256);
  box-shadow: 0 0 1px rgba(0, 0, 0, 0.3);
  border-radius: 50%;
  color: rgba(50, 50, 50, 0.9);
  display: inline-block;
  margin: 0 1px;
  line-height: 40px;
  font-size: 16px;
  text-align: center;
  text-decoration: none;
  width: 40px;
  height: 40px;
  transition: all 0.3s;
  opacity: 0;
}
.img-zoom:hover img {
  transform: scale(1.1);
}
.img-zoom:hover .ovrly {
  opacity: 1;
}
.img-zoom:hover .buttons .fa {
  opacity: 1;
}

.close-window {
  border: 0 solid !important;
  width: 55px;
  height: 55px;
  border-radius: 50% !important;
  padding: 0;
  box-shadow: none !important;
  background: #2a2b2c;
  z-index: 2 !important;
}
.close-window:hover,
.close-window:focus {
  filter: brightness(1.1);
  outline: none;
}

.counter {
  margin-top: 1rem;
}

.back-window {
  border: 0 solid !important;
  width: 40px;
  height: 40px;
  border-radius: 50% !important;
  padding: 2px;
  box-shadow: none !important;
  background: #2a2b2c;
  margin-left: 1rem;
  margin-top: 0.8rem;
  z-index: 3 !important;
  font-size: 15px;
}
.back-window:hover,
.close-window:focus {
  background: black;
  outline: none;
}
.back-window-book {
  border: 0 solid !important;
  width: 40px;
  height: 40px;
  border-radius: 50% !important;
  padding-top: 2px;
  box-shadow: none !important;
  background: #2a2b2c;
  margin-left: 1rem;
  margin-top: 0.8rem;
  z-index: 3 !important;
  font-size: 15px;
}
.back-window-book:hover,
.close-window-book:focus {
  background: black;
  outline: none;
}

.back-window-navbar {
  border: 0 solid !important;
  width: 40px !important;
  height: 40px !important;
  border-radius: 50% !important;
  padding: 2px;
  box-shadow: none !important;
  background: #222224;
  margin-left: 1rem;
  z-index: 2 !important;
  font-size: 15px;
}
.back-window-navbar:hover,
.close-window:focus {
  filter: brightness(1.1);
  outline: none;
}

.back-window-navbar-logout {
  border: 0 solid !important;
  width: 40px !important;
  height: 40px !important;
  border-radius: 50% !important;
  padding-top: 2px;
  box-shadow: none !important;
  background: #222224;
  margin-left: 1rem;
  z-index: 2 !important;
  font-size: 15px;
  position: fixed;
  right: 1rem;
  top: 0.9rem;
}
.back-window-navbar-logout:hover,
.close-window:focus {
  filter: brightness(1.1);
  outline: none;
}

.back-window-navbar-drawer {
  border: 0 solid !important;
  width: 40px !important;
  height: 40px !important;
  border-radius: 50% !important;
  padding-top: 2px !important;
  box-shadow: none !important;
  background: #2a2b2c;
  margin-left: -0.2rem;
  margin-top: -0.3rem;
  z-index: 2 !important;
  font-size: 15px;
}
.back-window-navbar-drawer:hover,
.close-window:focus {
  filter: brightness(1.1);
  outline: none;
}

.dropdown-avatar {
  border: 0 solid !important;
  width: 55px;
  height: 55px;
  border-radius: 50% !important;
  padding: 0;
  background: #fe5000;
  z-index: 2 !important;
}
.dropdown-avatar:hover,
.dropdown-avatar:focus {
  filter: brightness(1.1);
  outline: none;
}
.dropdown-avatar-in {
  margin-left: 1.5rem;
  margin-top: 0.5rem;
  margin-right: -2rem;
  margin-bottom: 1rem;
}

.dropdown-menu {
  margin-top: 0.6rem !important;
  margin-right: 0rem !important;
  border: 0px solid rgba(255, 255, 255, 0.068) !important;
  border-radius: 30px !important;
  color: #1c2836 !important;
  background-color: #171718 !important;
  padding: 1rem !important;
  box-shadow: 4px 6px 81px 0px rgba(0, 0, 0, 0.54);
  -webkit-box-shadow: 4px 6px 81px 0px rgba(0, 0, 0, 0.54);
  -moz-box-shadow: 4px 6px 81px 0px rgba(0, 0, 0, 0.54);
}
.review {
  position: absolute;
  bottom: 4rem;
}

.icon-font-exp {
  position: absolute;
  bottom: 7rem;
  left: 0;
  right: 0;
}

.photo-edit {
  position: absolute;
  right: 4rem;
  top: 5rem;
  width: 50px;
  height: 50px;
  padding: 0 0 0 0 !important;
  border-radius: 50px;
  font-size: 23px;
  background: #fe5000;
  outline: none;
  overflow: hidden;
}

.photo-edit-host {
  position: absolute;
  right: 4rem;
  top: 5rem;
  width: 50px;
  height: 50px;
  padding: 0 0 0 0 !important;
  background: #262dff !important;
  border-radius: 50px;
  font-size: 23px;
}

@media only screen and (max-width: 900px) {
  .exp-contenido {
    background: #171718;
    position: absolute;
    left: 0;
    right: 0;
    padding-bottom: 5rem !important;
    margin-top: 85vh;
    border-radius: 0;
  }
  .exp-contenido-open {
    background: #171718;
    position: absolute;
    left: 0;
    right: 0;
    padding-bottom: 5rem !important;
    margin-top: 10vh;
    border-radius: 0;
  }

  .photo-edit {
    position: absolute;
    right: 5rem;
    top: 5rem;
    width: 50px;
    height: 50px;
    padding: 0 0 0 0 !important;
  }
}
.dropdown-header {
  font-size: 30px !important;
  color: white !important;
}

.dropdown-divider {
  height: 0;
  margin: 0.5rem 0;
  overflow: hidden;
  border-top: 1px solid black !important;
}

.dropdown-item {
  padding-top: 1rem !important;
  padding-bottom: 1rem !important;
  border-radius: 60px;
  color: rgb(138, 138, 138) !important;
}

.dropdown-item:hover {
  background: #fe50002a;
  color: #fe5000 !important;
}
.dropdown-item:focus {
  background: #0e0f0f !important;
  outline: none !important;
  color: white !important;
}

.PhoneInputInput {
  background-color: transparent !important;
  border: 0px solid !important;
  outline: none;
  /* font-size: 22px !important; */
}

.demo-icon {
  
  margin-right: 4%;
  margin-left: 4%;
  font-size: 20px;
  bottom: 0.2rem;
}
.demo-icon-nft {
  padding-left: auto;
  font-size: 35px;
  color: #fe5000;

}

.demo-icon-exp {
  position: absolute;
  left: 0;
  right: 0;
  bottom: 10px;
}

.demo-icon2 {
  margin-right: 4%;
  margin-left: 4%;
  font-size: 20px;
  bottom: 0.2rem;
}

.demo-icon-social {
  margin-right: 0.4rem;
  margin-left: 0.4rem;
}

.demo-icon-details {
  margin-right: 0rem;
  font-size: 50px;
}

.demo-icon-menu {
  margin-right: 1rem;
  font-size: 20px;
}

.demo-icon-first {
  margin-right: 0.5rem;
}

.demo-icon-incluye {
  font-size: 50px !important;
}

.demo-icon-config {
  font-size: 50px !important;
}

.demo-icon-arrow {
  margin-left: 1rem;
  margin-right: 1rem;
  font-size: 18px;
}

.demo-icon-settings {
  color: #fe5000;
  margin-right: 1.2rem;
}
.demo-icon-settings:hover {
  color: #9e390a;
  margin-right: 1.2rem;
}

.review {
  padding-bottom: 3rem;
  font-size: 50px;
  color: #fe5000;
}
.review i {
  font-size: 20px;
  color: #fe5000;
}

.logout:hover {
  border-radius: 0px 0px 20px 20px;
  -moz-border-radius: 0px 0px 20px 20px;
  -webkit-border-radius: 0px 0px 20px 20px;
  border: 0px solid #000000;
}

.btn__logout {
  background: #171718 !important;
}

.navbar-first {
  position: absolute;
  right: 4rem;
}

.navbar-search {
  position: absolute;
  left: 15rem;
  color: black !important;
  background-color: #171718;
  border-radius: 50px;
}

.link {
  text-decoration: none !important;
}

.perfil-about {
  margin-top: 1rem;
  position: absolute;
  left: 1.5rem;
  right: 1.5rem;
}

.perfil-pais {
  font-size: 18px;
  margin-top: -0.5rem !important;
}

.perfilSettings {
  font-size: 25px;
  display: flex;
  align-items: center;
}

.perfilSidebar {
  margin-top: 4rem;
}

.nav-bottom {
  position: absolute;
  bottom: 0rem;
  left: 0rem;
  right: 0rem;
  padding: 1rem;
  background-color: #111213;
  height: 5rem;
}

.avatar-in {
  border: 0 solid;
  margin: 1rem !important;
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: 3rem;
}

.planes-fav-titulo {
  margin-bottom: 1rem;
}
.planes-fav {
  position: fixed;
  width: 30%;
  right: 3rem;
}

.card-fav {
  margin-bottom: 1rem;
  height: 8rem;
  width: 15rem !important;
}

@media only screen and (max-width: 900px) {
  .perfilSidebar {
    margin-top: 0rem;
    align-items: center;
  }

  .card-fav {
    margin-bottom: 1rem;
    height: auto;
    width: auto !important;
  }

  .planes-fav {
    position: fixed;
    width: 40%;
    right: 0.5rem;
  }

  .explora-titulos {
    margin-top: 8rem;
    margin-bottom: -1rem;
  }

  .sm_nav {
    margin-top: 4rem;
  }

  .demo-icon-config {
    position: absolute;
    top: -0.3rem;

    font-size: 30px !important;
  }

  .fixed {
    position: fixed !important;
    bottom: 1rem;
    right: 1rem;
  }

  .nav-bottom {
    position: absolute;
    bottom: 0;
    padding-top: 0.3rem;
    background-color: #111213;
    height: 5rem;
  }
  .config-menu {
    padding-top: 9.3rem;
  }
}
@media only screen and (max-width: 770px) {
  .explora-titulos {
    margin-top: 3.5rem;
    margin-bottom: -1rem;
  }
  .exp-review-comments {
    width: 100% !important;
  }
  .review-date,
  .review-rating {
    justify-content: center !important;
  }
}

.login-title {
  font-size: 35px !important;
}
.login-title p {
  font-size: 16px;
}
.login-title-code {
  font-size: 20px !important;
}

.login-area {
  margin-top: 1rem !important;
  background: white;
}

.login-logo {
  height: 3rem;
}

.login-text-redes {
  font-size: 16px;
  outline: none !important;
  color: white;
  padding-top: 5;
  position: fixed;
  bottom: 1rem;
  left: 0;
  right: 0;
}
.login-text-redes:hover {
  color: #fe5000 !important;
}

.centrado {
  display: block !important;
  margin-left: auto !important;
  margin-right: auto !important;
}

.modalgateway {
  margin-bottom: 2rem !important;
}

.explora-titulos {
  padding-bottom: 0.6rem;
}
.explora-titulos p {
  font-size: 25px;
  line-height: 1rem;
}
.explora-bests {
  background: linear-gradient(
    180deg,
    rgb(241, 97, 30) 0%,
    rgb(243, 37, 37) 100%
  );
  position: absolute;
  left: 0;
  right: 0;
  padding-left: 1rem;
  padding-top: 1rem;
  border-radius: 40px 40px 0 0;
}
.explora-bests span {
  font-size: 25px !important;
}

.border-top {
  border: 1 solid black !important;
}

.exp-pay-number {
  background-color: #2a2b2c !important;
}

.ant-modal {
  z-index: 1051 !important;
}
.antd-img-crop-modal,
.ant-upload,
.exp-headerr {
  z-index: 1051 !important;
}

.rs-loader-wrapper {
  z-index: 9999;
  /* background: #262dff; */
  opacity: 0.85;
}

.rs-loader-content {
  color: black !important;
  font-size: 25px !important;
  font-weight: bold !important;
}
.rs-loader-spin::after {
  opacity: 1 !important;
  /* color: #262DFF !important; */
  border-color: #262dff transparent transparent !important;
}

.rs-icon-star::before {
  color: red;
}
.rs-progress-circle,
.progress-loader,
.progress-loader-content {
  z-index: 9999;
}
.progress-loader {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
  background: #151515;
  opacity: 0.85;
}
.progress-loader-content {
  width: 250px;
  height: 250px;
}

.progess-loader-text > * {
  color: #ffffff !important;
}

.update-usr-picture .ant-upload-list-item,
.update-usr-picture .ant-upload-list-item {
  display: none;
}

.rs-notification .rs-notification-item-content {
  background: #292d33;
  min-width: 325px;
}
.rs-notification .rs-btn-toolbar {
  display: flex;
}

.rs-notification .rs-notification-description p {
  font-size: 13px !important;
  padding: 20px;
}
.rs-notification .notification-btn {
  background: transparent;
  color: white !important;
  width: 50%;
  font-size: 13px !important;
  border-radius: none;
  border: 0px !important;

  display: inline-block;
  margin-bottom: 0;
  font-weight: 400;
  text-align: center;
  vertical-align: middle;
  cursor: pointer;
  outline: 0 !important;
  white-space: nowrap;
  border: none;
  -webkit-user-select: none;
  -ms-user-select: none;
  user-select: none;
  padding: 8px 12px;
  font-size: 14px;
  line-height: 1.42857143;
  border-radius: 6px;
}

.rs-notification .notification-btn:hover {
  background: #fa0000;
}

.add-favorites-btn::before {
  color: var(--fav-color-btn) !important;
}

.search-draw .rs-drawer-top.rs-drawer-xs {
  height: 150px !important;
}
.rs-timeline-item-content {
  font-size: 16px;
}

.react-datepicker__navigation--next {
  top: 18px !important;
  right: 10px !important;
}
.react-datepicker__navigation--previous {
  top: 18px !important;
  left: 10px !important;
}

.react-photo-gallery--gallery img {
  max-width: 100% !important;
}

.datepicker {
  z-index: 30 !important;
}
.conversationHeaderBack {
  display: none !important;
}

.cs-conversation__last-activity-time {
  font-size: 9px !important;
}
.cs-conversation__info-content {
  font-size: 15px !important;
}
@media only screen and (max-width: 700px) {
  .visibleChat {
    display: inline-block !important;
    width: 100% !important;
  }
  .novisibleChat {
    display: none !important;
  }
  .conversationHeaderBack {
    display: inline-block !important;
  }
  .cs-sidebar.cs-sidebar--left {
    flex-basis: 100% !important;
    max-width: 100% !important;
  }

  /* .chat-header{
    width: 85%;
  } */
  .ModalImageViewFull {
    /* background: yellow; */
    top: 50%;
    transform: translate(-50%, -50%) !important;
    margin: 0 !important;
  }
  .messageInput {
    position: fixed;
    z-index: 99;
    bottom: 0;
    width: 95%;
    background: #000000 !important;
  }

  .messagelist {
    height: 74% !important;
  }
}

.ModalImageView {
  z-index: 999;
  position: absolute;
  width: 90%;
  max-width: 500px;
  height: auto;
  padding: 15px;
  margin: 20px;
  margin-right: 25px;
  background-color: #2a2b2c;
  border-radius: 8px;
}
.ModalImageViewFull {
  /* background: yellow; */
  position: absolute;
  left: 50%;
  transform: translate(-50%, 0);
}

.inputModalIageView {
  width: 80%;
}

.messageItem {
  width: 100%;
  display: flex;
}
.message-item-content {
}

.messageItem-content {
  width: auto;
  max-width: 600px;
  min-width: 150px;
  position: relative;
  border-radius: 8px;
  margin-top: 10px;
  margin-bottom: 10px;
  overflow: hidden;

  cursor: pointer;
}

/* .message-image-content{
  width: 100%;
} */

.message-image {
  width: auto;
  height: auto;
  max-width: 100%;
  -ms-interpolation-mode: nearest-neighbor;
      image-rendering: -moz-crisp-edges;
      image-rendering: pixelated;
  object-fit: contain;
}

.message-text {
  width: 100%;
  color: #ffffff;
  font-size: 20px;
  text-align: start;
  padding: 15px;
  margin-bottom: 5px;
}
.message-footer {
  position: absolute;
  bottom: 0;
  right: 10px;
  font-size: 11px;
  /* margin-top: 10px; */
}
.message-footer-image {
  background-color: #718491;
  border-radius: 25px;
  padding: 5px;
  bottom: 5px;
  opacity: 0.5;
}
.message-no-text {
  padding: 0;
  margin: 0;
}
.message-text-eliminate {
  font-size: 14px;
  font-style: oblique;
}

.message-incoming {
  justify-content: flex-start;
}
.incoming {
  background-color: #202024;
}
.message-outgoing {
  justify-content: flex-end;
}
.outgoing {
  background-color: #fa0000;
}

.message-contextMenu {
  background-color: #202024 !important;
  border-radius: 10px;
  z-index: 99;
}
.message-contextMenu-item {
  padding: 15px;
  font-size: 14px;
  line-height: 14px;
  cursor: pointer;
}
.message-contextMenu-item:hover {
  background-color: #38383d !important;
}

.PhoneInputCountrySelect {
  background-color: #383e42 !important;
}
.formGroup-auth {
  width: 100% !important;
  font-size: 18px !important;
}
.formControl-auth {
  height: 2.5rem !important;
  width: 100% !important;
  font-size: 18px !important;
  min-width: 310px;
}

.owl-carousel .owl-nav,
button.owl-prev {
  position: absolute !important;
  left: 2rem !important;
}
.owl-carousel .owl-nav,
button.owl-next {
  position: absolute !important;
  right: 2rem !important;
}

.owl-carousel .owl-nav button.owl-prev:hover {
  background: white;
  color: #fe5000;
  box-shadow: 0 0.25em 0.5em 0 rgba(0, 0, 0, 0.404);
}

.owl-carousel .owl-nav button.owl-next:hover {
  background: white;
  color: #fe5000;
  box-shadow: 0 0.25em 0.5em 0 rgba(0, 0, 0, 0.404);
}

.owl-carousel .owl-nav button.owl-prev,
.owl-carousel .owl-nav button.owl-next {
  position: absolute !important;
  width: 70px;
  height: 70px;
  font-size: 40px !important;
  top: -8rem !important;
  z-index: 2;
  color: #fe5000;
  text-transform: uppercase;
  padding: 9px 20px;
  margin-left: 0px;
  margin-right: 0px;
  margin-top: 0px;
  background-color: rgb(14, 14, 14) !important;
  border: 0px solid rgba(231, 231, 231, 0.5);
  border-radius: 50%;
  box-shadow: 0 0.25em 0.5em 0 rgba(0, 0, 0, 0.205);
  text-shadow: 0 0.25em 0.5em rgba(0, 0, 0, 0.1);
  opacity: 1;
  cursor: pointer;
  transition: opacity 150ms ease, border 300ms ease-in-out;
  transform: translateY(-50%);
  line-height: 1;
  outline: none;
}

.conversation_empty {
  min-height: 70px;
}

.MuiPickersStaticWrapper-staticWrapperRoot > * {
  width: 100%;
  background-color: #171718;
  color: #fff !important;
  height: 100% !important;
  overflow: hidden;
}

.MuiPickersStaticWrapper-staticWrapperRoot {
  position: absolute;
  right: 2rem;
  left: 0rem;
}

@media only screen and (max-width: 900px) {
  .MuiPickersStaticWrapper-staticWrapperRoot {
    position: absolute;
    right: 0rem;
    left: 1rem;
  }
}

.MuiPickersToolbar-toolbar > *,
.MuiPickersToolbar-toolbar > *,
.MuiToolbar-root {
  background-color: #fe5000 !important;
}

.MuiPickersCalendarHeader-dayLabel > * {
  color: #fff !important;
}

.MuiPickersBasePicker-pickerView {
  width: 100% !important;
  min-width: 100% !important;
  padding: 0 10px !important;
  min-height: 360px !important;
}
.MuiPickersCalendarHeader-dayLabel > *,
.MuiPickersCalendarHeader-daysHeader > *,
.MuiPickersDay-dayDisabled > * {
  color: gray !important;
}

.MuiPickersDay-day {
  color: #fff !important;
  width: 40px !important;
  height: 40px !important;
  border: 3px solid #fe5000 !important;
}
.MuiPickersDay-dayDisabled {
  border: none !important;
}

.MuiPickersCalendarHeader-switchHeader > * {
  max-width: 100% !important;
}
.MuiPickersDay-daySelected {
  background-color: #fe5000 !important;
  border: none !important;
}
.MuiSvgIcon-root {
  fill: #fe5000 !important;
}

.MuiPickersCalendar-week,
.MuiPickersCalendarHeader-daysHeader {
  display: flex !important;
  justify-content: space-around !important;
}

.nice-dates-grid .-disabled {
  color: gray !important;
  opacity: 0.8 !important;
}

.text-white span {
  color: white !important;
}

.notFound_page {
  width: min(100%, 500px);
  height: 100%;
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  grid-gap: 10px;
  grid-auto-rows: minmax(100px, auto);

  margin: 0 auto;
  margin-top: 110px;
}

.notFound_text {
  text-align: center;
}

.column1 {
  display: grid;
  place-content: center;
}

.column2 {
  grid-column: 2 / 5;
}

.notFound_image {
  width: 100%;
}

@media only screen and (max-width: 992px) {
  .notFound_page {
    margin: 0 auto;
  }
}

@media only screen and (max-width: 560px) {
  .notFound_page {
    grid-template-columns: repeat(1, 1fr);
    padding-top: 50px;
  }

  .column2 {
    grid-column: 1;
    display: grid;
    place-content: center;
  }

  .notFound_image {
    width: 400px;
    text-align: center;
  }
}

.schedules-page-content-list {
  margin: 0 50px;
  padding-top: 5rem !important;
}

.schedules-page-content-list-grid {
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  grid-gap: 20px;
  grid-auto-rows: minmax(100px, auto);
}
.schedule-item {
  padding: 2px;
  background-color: transparent;
  border: 5px solid #0c0c0c;
  cursor: pointer;
  position: relative;
  border-radius: 20px;
}
.schedule-item:hover {
  background: #0c0c0c;
  border: 4px solid #fe5000;
  color: black !important;
}

.schedule-item-padding{
  padding: 15px;
}

.schedule-item-loader {
  padding: 15px;
  background-color: transparent;
  border: 5px solid #0c0c0c;
  cursor: pointer;
  position: relative;
  border-radius: 20px;
  height: 100px;
}

.schedule-item-header {
  font-size: 18px;
}

.schedule-item-content {
  margin-top: 10px;
  color: #718491;
  font-size: 13px;
}

.schedule-item-content-repeat {
  font-weight: bold;
  font-size: 14px;
}

.schedule-item-btn {
  width: 30px;
  height: 30px;
  font-size: 11px;
  border-radius: 100%;
  border: 1px solid transparent;

  position: absolute;
  top: 10px;
  right: 10px;
}
@media only screen and (max-width: 900px) {
  .schedules-page-content-list-grid {
    grid-template-columns: repeat(3, 1fr);
  }
  .schedules-page-content-user-grid {
    grid-template-columns: repeat(2, 1fr);
  }
}
@media only screen and (max-width: 700px) {
  .schedules-page-content-list-grid {
    grid-template-columns: repeat(2, 1fr);
  }
}
@media only screen and (max-width: 500px) {
  .schedules-page-content-list-grid {
    grid-template-columns: repeat(2, 1fr);
  }

  .schedules-page-content-list {
    margin: 0 20px;
  }
}

.schedules-drawer-body::-webkit-scrollbar {
  width: 5px; /* Tamaño del scroll en vertical */
  height: 8px; /* Tamaño del scroll en horizontal */
}
.schedules-drawer-body::-webkit-scrollbar-thumb {
  background: #ccc;
  border-radius: 4px;
}
.schedule-neon-btn {
  background-color: #262dff !important;
}
.schedules-form-control-mt20 {
  margin-top: 20px;
}

.schedules-form-control span {
  font-size: 13px;
}

.rs-calendar-table-cell-disabled {
  color: #ccc !important;
}

.schedules-page-content-user-grid {
  display: grid;
  grid-template-columns: repeat(6, 1fr);
  grid-gap: 20px;
  grid-auto-rows: minmax(100px, auto);
  margin-top: 25px;
}

@media only screen and (max-width: 900px) {
  .schedules-page-content-user-grid {
    grid-template-columns: repeat(2, 1fr);
  }
}

.schedules-page-user-back-btn {
  background-color: transparent;
  border: none;
  cursor: pointer;
  margin-right: 20px;
  border-radius: 50%;
  padding: 10px;
}

.schedule-item-year-icon {
  color: #fe0000 !important;
  font-size: 16px;
  text-align: center;
  margin: 15px;
}
.schedule-item-year-name {
  font-size: 20px;
  color: #fe5000 !important;
  text-align: center;
  margin: 15px;
}
.schedule-item-year-name text {
  font-size: 40px;
  text-align: center;
}
.schedule-item-year-name__day {
  font-size: 16px;
  color: #fe5000 !important;
  text-align: center;
  margin: 15px;
}
.schedule-item-year-name__time {
  font-size: 15px;
  color: #fe5000 !important;
  text-align: center;
  margin: 15px;
}

.schedule-item-year-number {
  color: #fe0000 !important;
  font-size: 18px;
  text-align: center;
  margin-top: -10px;
}

.schedule-item-week {
  font-size: 25px;
  text-align: center;
  margin: 15px;
}
.schedule-item-week {
  font-size: 20px;
}
.schedule-item-hover {
  transition: 1 ease all;
}
.schedule-item-active,
.schedule-item-hover:hover {
  border: 5px solid #fe5000;
}
.schedules-no-items {
  font-size: 20px;
  text-align: center;
  margin-top: 100px;

  display: flex;
  flex-direction: column;
  grid-gap: 10px;
  gap: 10px;

  align-items: center;
}

.bact-to-week-btn {
  width: min(100%, 200px);
  padding: 10px;
  background-color: #fe5000;
  border-radius: 4px;
  color: #000000;
  font-weight: bold;
}

.selected-schedule-text {
  text-align: left;
  color: #fe5000 !important;
  font-size: 16px !important;
}
.schedules-page-content-user-grid-4 {
  display: grid;
  grid-template-columns: repeat(7, 1fr);
  grid-gap: 20px;
  grid-auto-rows: minmax(100px, auto);
  margin-top: 25px;
}

.exp_editPopover {
  border-radius: 20px !important;
}

@media only screen and (max-width: 900px) {
  .schedules-page-content-user-grid-4 {
    grid-template-columns: repeat(5, 1fr);
  }
}

@media only screen and (max-width: 700px) {
  .schedules-page-content-user-grid-4 {
    grid-template-columns: repeat(3, 1fr);
    grid-gap: 5px;
  }
  .schedule-item-year-name text {
    font-size: 30px;
    text-align: center;
  }
  .schedule-item-year-name__day {
    font-size: 12px;
    color: #fe5000 !important;
    text-align: center;
    margin: 0px;
  }
  .schedule-item-year-name__time {
    font-size: 10px;
    color: #fe5000 !important;
    text-align: center;
    margin: 1px;
  }
  .selected-schedule-text {
    text-align: center;
    font-size: 15px;
    margin: 15px;
  }
  .schedule-page-subtitle {
    font-size: 10px;
    display: flex;
    justify-content: space-between;
    text-align: center;
  }
}

.ant-popover-inner-content {
  background-color: #181818 !important;
}

.popover-edit-content {
  padding: 10px;
}
.popover-edit-content p {
  padding: 1px;
  font-size: 16px;
  cursor: pointer;
  text-align: center;
}

.popover-edit-content p:hover {
  color: #0044ff;
}

.popover-edit-content p span {
  margin-right: 5px;
}

.green-color {
  color: green;
}

.red-color {
  color: #ff0000 !important;
}

.rs-loader > * {
  color: #fff !important;
}

/* 

    * Name: Animatism
    * Vesion: 1.5
    * Type: CSS
    * Author: Probtemplates
    * Author's Email: ssarazanaqvi@gmail.com

*/
Body {
  font-family: "Open Sans", sans-serif;
}
.btn01 {
  width: 100%;
  overflow: hidden;
  position: relative;
  box-shadow: 1px 1px 3px 0 rgba(0, 0, 0, 0.3);
}
.btn01 img {
  position: relative;
  width: 100%;
  top: 0;
  left: 0;
}
.btn01 .ovrly {
  background: rgba(0, 0, 0, 0.5);
  height: 100%;
  left: 0;
  top: -100%;
  width: 100%;
  position: absolute;
  transition: all 0.5s;
}
.btn01 .buttons {
  position: absolute;
  top: -100%;
  left: 50%;
  transition: all 0.5s;
  transform: translate(-50%, -50%);
}
.btn01 .buttons .fa {
  margin: 0 1px;
  background: rgb(256, 256, 256);
  box-shadow: 0 0 1px rgba(0, 0, 0, 0.3);
  border-radius: 50%;
  color: rgba(50, 50, 50, 0.9);
  display: inline-block;
  line-height: 40px;
  font-size: 16px;
  text-align: center;
  text-decoration: none;
  width: 40px;
  height: 40px;
}
.btn01:hover .buttons {
  top: 50%;
}
.btn01:hover .ovrly {
  top: 0;
}
.btn02 {
  width: 100%;
  overflow: hidden;
  position: relative;
  box-shadow: 1px 1px 3px 0 rgba(0, 0, 0, 0.3);
}
.btn02 img {
  position: relative;
  width: 100%;
  top: 0;
  left: 0;
}
.btn02 .ovrly {
  position: absolute;
  background: rgba(0, 0, 0, 0.5);
  height: 100%;
  left: -100%;
  top: 0;
  width: 100%;
  transition: all 0.5s;
}
.btn02 .buttons {
  position: absolute;
  top: 50%;
  left: -100%;
  transition: all 0.5s;
  transform: translate(-50%, -50%);
}
.btn02 .buttons .fa {
  margin: 0 1px;
  background: rgba(256, 256, 256, 0.9);
  box-shadow: 0 0 1px rgba(0, 0, 0, 0.3);
  border-radius: 50%;
  color: rgba(50, 50, 50, 0.9);
  display: inline-block;
  line-height: 40px;
  font-size: 16px;
  text-align: center;
  text-decoration: none;
  width: 40px;
  height: 40px;
}
.btn02:hover .buttons {
  left: 50%;
}
.btn02:hover .ovrly {
  left: 0;
}
.btn03 {
  width: 100%;
  overflow: hidden;
  position: relative;
  box-shadow: 1px 1px 3px 0 rgba(0, 0, 0, 0.3);
}
.btn03 img {
  position: relative;
  width: 100%;
  top: 0;
  left: 0;
}
.btn03 .ovrly {
  background: rgba(0, 0, 0, 0.5);
  height: 100%;
  left: 0;
  top: 100%;
  width: 100%;
  position: absolute;
  transition: all 0.5s;
}
.btn03 .buttons {
  position: absolute;
  bottom: -100%;
  left: 50%;
  transition: all 0.5s;
  transform: translate(-50%, 50%);
}
.btn03 .buttons .fa {
  margin: 0 1px;
  background: rgb(256, 256, 256);
  box-shadow: 0 0 1px rgba(0, 0, 0, 0.3);
  border-radius: 50%;
  color: rgba(50, 50, 50, 0.9);
  display: inline-block;
  line-height: 40px;
  font-size: 16px;
  text-align: center;
  text-decoration: none;
  width: 40px;
  height: 40px;
}
.btn03:hover .buttons {
  bottom: 50%;
}
.btn03:hover .ovrly {
  top: 0;
}
.btn04 {
  width: 100%;
  overflow: hidden;
  position: relative;
  box-shadow: 1px 1px 3px 0 rgba(0, 0, 0, 0.3);
}
.btn04 img {
  position: relative;
  width: 100%;
  top: 0;
  left: 0;
}
.btn04 .ovrly {
  position: absolute;
  background: rgba(0, 0, 0, 0.5);
  height: 100%;
  left: 100%;
  top: 0;
  width: 100%;
  transition: all 0.5s;
}
.btn04 .buttons {
  position: absolute;
  top: 50%;
  right: -100%;
  transition: all 0.5s;
  transform: translate(50%, -50%);
}
.btn04 .buttons .fa {
  margin: 0 1px;
  background: rgba(256, 256, 256, 0.9);
  box-shadow: 0 0 1px rgba(0, 0, 0, 0.3);
  border-radius: 50%;
  color: rgba(50, 50, 50, 0.9);
  display: inline-block;
  line-height: 40px;
  font-size: 16px;
  text-align: center;
  text-decoration: none;
  width: 40px;
  height: 40px;
}
.btn04:hover .buttons {
  right: 50%;
}
.btn04:hover .ovrly {
  left: 0;
}
.btn05 {
  width: 100%;
  overflow: hidden;
  position: relative;
  box-shadow: 1px 1px 3px 0 rgba(0, 0, 0, 0.3);
}
.btn05 img {
  width: 100%;
  position: relative;
  top: 0;
  left: 0;
}
.btn05 .ovrly {
  background: rgba(0, 0, 0, 0.5);
  height: 100%;
  left: 0;
  position: absolute;
  top: 0;
  width: 100%;
  opacity: 0;
  transition: all 0.3s;
}
.btn05 .buttons {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}
.btn05 .buttons .fa {
  background: rgb(256, 256, 256);
  box-shadow: 0 0 1px rgba(0, 0, 0, 0.3);
  border-radius: 50%;
  display: inline-block;
  line-height: 40px;
  font-size: 16px;
  text-align: center;
  text-decoration: none;
  width: 40px;
  height: 40px;
  opacity: 0;
  position: relative;
  transition: all 0.3s, color 0.1s 0.3s;
  color: transparent;
}
.btn05 .buttons .fa-link {
  left: -10px;
}
.btn05 .buttons .fa-search {
  left: 10px;
}
.btn05:hover .buttons .fa {
  opacity: 1;
  left: 0;
  color: rgba(50, 50, 50, 0.9);
}
.btn05:hover .ovrly {
  opacity: 1;
}
.btn06 {
  width: 100%;
  overflow: hidden;
  position: relative;
  box-shadow: 1px 1px 3px 0 rgba(0, 0, 0, 0.3);
}
.btn06 img {
  width: 100%;
  position: relative;
  top: 0;
  left: 0;
}
.btn06 .ovrly {
  background: rgba(0, 0, 0, 0.5);
  height: 100%;
  left: 0;
  position: absolute;
  top: 0;
  width: 100%;
  opacity: 0;
  transition: all 0.3s;
}
.btn06 .buttons {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}
.btn06 .buttons .fa {
  background: rgb(256, 256, 256);
  box-shadow: 0 0 1px rgba(0, 0, 0, 0.3);
  border-radius: 50%;
  display: inline-block;
  line-height: 40px;
  font-size: 16px;
  text-align: center;
  text-decoration: none;
  width: 40px;
  height: 40px;
  opacity: 0;
  position: relative;
  transition: all 0.3s, color 0.1s 0.3s;
  color: transparent;
}
.btn06 .buttons .fa-link {
  left: 22px;
}
.btn06 .buttons .fa-search {
  left: -22px;
}
.btn06:hover .buttons .fa {
  opacity: 1;
  left: 0;
  color: rgba(50, 50, 50, 0.9);
}
.btn06:hover .ovrly {
  opacity: 1;
}
.btn07 {
  width: 100%;
  overflow: hidden;
  position: relative;
  box-shadow: 1px 1px 3px 0 rgba(0, 0, 0, 0.3);
}
.btn07 img {
  width: 100%;
  position: relative;
  top: 0;
  left: 0;
}
.btn07 .ovrly {
  background: rgba(0, 0, 0, 0.5);
  height: 100%;
  left: 0;
  position: absolute;
  top: 0;
  width: 100%;
  opacity: 0;
  transition: all 0.3s;
}
.btn07 .buttons {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}
.btn07 .buttons .fa {
  background: rgb(256, 256, 256);
  box-shadow: 0 0 1px rgba(0, 0, 0, 0.3);
  border-radius: 50%;
  display: inline-block;
  line-height: 40px;
  font-size: 16px;
  text-align: center;
  text-decoration: none;
  width: 40px;
  height: 40px;
  opacity: 0;
  transform: scale(1.5);
  position: relative;
  transition: transform 0.3s, opacity 0.3s;
  color: transparent;
}
.btn07:hover .buttons .fa {
  opacity: 1;
  transform: scale(1);
  color: rgba(50, 50, 50, 0.9);
}
.btn07:hover .ovrly {
  opacity: 1;
}
.btn08 {
  width: 100%;
  overflow: hidden;
  position: relative;
  box-shadow: 1px 1px 3px 0 rgba(0, 0, 0, 0.3);
}
.btn08 img {
  width: 100%;
  position: relative;
  top: 0;
  left: 0;
}
.btn08 .ovrly {
  background: rgba(0, 0, 0, 0.5);
  height: 100%;
  left: 0;
  position: absolute;
  top: 0;
  width: 100%;
  opacity: 0;
  transition: all 0.3s;
}
.btn08 .buttons {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}
.btn08 .buttons .fa {
  background: rgb(256, 256, 256);
  box-shadow: 0 0 1px rgba(0, 0, 0, 0.3);
  border-radius: 50%;
  display: inline-block;
  line-height: 40px;
  font-size: 16px;
  text-align: center;
  text-decoration: none;
  width: 40px;
  height: 40px;
  opacity: 0;
  transform: scale(0);
  position: relative;
  transition: transform 0.3s, opacity 0.3s;
  color: transparent;
}
.btn08:hover .buttons .fa {
  opacity: 1;
  transform: scale(1);
  color: rgba(50, 50, 50, 0.9);
}
.btn08:hover .ovrly {
  opacity: 1;
}
.btn09 {
  width: 100%;
  overflow: hidden;
  position: relative;
  box-shadow: 1px 1px 3px 0 rgba(0, 0, 0, 0.3);
}
.btn09 img {
  width: 100%;
  position: relative;
  top: 0;
  left: 0;
}
.btn09 .ovrly {
  background: rgba(0, 0, 0, 0.5);
  height: 100%;
  left: 0;
  position: absolute;
  top: 0;
  width: 100%;
  opacity: 0;
  transition: all 0.3s;
}
.btn09 .buttons {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}
.btn09 .buttons .fa {
  background: rgb(256, 256, 256);
  box-shadow: 0 0 1px rgba(0, 0, 0, 0.3);
  border-radius: 50%;
  display: inline-block;
  line-height: 40px;
  font-size: 16px;
  text-align: center;
  text-decoration: none;
  width: 40px;
  height: 40px;
  position: relative;
  transition: transform 0.3s;
  color: rgba(50, 50, 50, 0.9);
}
.btn09 .buttons .fa-link {
  transform-origin: 165% 50%;
  transform: rotateZ(0deg) scale(0);
}
.btn09 .buttons .fa-search {
  transform: scale(0);
}
.btn09:hover .buttons .fa-link {
  transform: rotateZ(360deg) scale(1);
}
.btn09:hover .buttons .fa-search {
  transform: scale(1);
}
.btn09:hover .ovrly {
  opacity: 1;
}
.btn10 {
  width: 100%;
  overflow: hidden;
  position: relative;
  box-shadow: 1px 1px 3px 0 rgba(0, 0, 0, 0.3);
}
.btn10 img {
  width: 100%;
  position: relative;
  top: 0;
  left: 0;
}
.btn10 .ovrly {
  background: rgba(0, 0, 0, 0.5);
  height: 100%;
  left: 0;
  position: absolute;
  top: 0;
  width: 100%;
  opacity: 0;
  transition: all 0.3s;
}
.btn10 .buttons {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}
.btn10 .buttons .fa {
  background: rgb(256, 256, 256);
  box-shadow: 0 0 1px rgba(0, 0, 0, 0.3);
  border-radius: 50%;
  display: inline-block;
  line-height: 40px;
  font-size: 16px;
  text-align: center;
  text-decoration: none;
  width: 40px;
  height: 40px;
  position: relative;
  transition: transform 0.3s;
  color: rgba(50, 50, 50, 0.9);
}
.btn10 .buttons .fa-link {
  transform: scale(0);
}
.btn10 .buttons .fa-search {
  transform-origin: -65% 50%;
  transform: rotateZ(0deg) scale(0);
}
.btn10:hover .buttons .fa-link {
  transform: scale(1);
}
.btn10:hover .buttons .fa-search {
  transform: rotateZ(360deg) scale(1);
}
.btn10:hover .ovrly {
  opacity: 1;
}
.btn11 {
  width: 100%;
  overflow: hidden;
  position: relative;
  box-shadow: 1px 1px 3px 0 rgba(0, 0, 0, 0.3);
}
.btn11 img {
  width: 100%;
  position: relative;
  top: 0;
  left: 0;
}
.btn11 .ovrly {
  background: rgba(0, 0, 0, 0.5);
  height: 100%;
  left: 0;
  position: absolute;
  top: 0;
  width: 100%;
  opacity: 0;
  transition: all 0.3s;
}
.btn11 .buttons {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}
.btn11 .buttons .fa {
  background: rgb(256, 256, 256);
  box-shadow: 0 0 1px rgba(0, 0, 0, 0.3);
  border-radius: 50%;
  display: inline-block;
  line-height: 40px;
  font-size: 16px;
  text-align: center;
  text-decoration: none;
  width: 40px;
  height: 40px;
  position: relative;
  transform: rotateZ(360deg) scale(0);
  transition: transform 0.3s;
  color: rgba(50, 50, 50, 0.9);
}
.btn11 .buttons .fa-link {
  transform-origin: 105% 50%;
}
.btn11 .buttons .fa-search {
  transform-origin: -5% 50%;
}
.btn11:hover .buttons .fa {
  transform: rotateZ(0deg) scale(1);
}
.btn11:hover .ovrly {
  opacity: 1;
}
.btn12 {
  width: 100%;
  overflow: hidden;
  position: relative;
  box-shadow: 1px 1px 3px 0 rgba(0, 0, 0, 0.3);
}
.btn12 img {
  width: 100%;
  position: relative;
  top: 0;
  left: 0;
}
.btn12 .ovrly {
  background: rgba(0, 0, 0, 0.5);
  height: 100%;
  left: 0;
  position: absolute;
  top: 0;
  width: 100%;
  opacity: 0;
  transition: all 0.3s;
}
.btn12 .buttons {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}
.btn12 .buttons .fa {
  background-color: transparent;
  border-radius: 50%;
  display: inline-block;
  line-height: 40px;
  font-size: 16px;
  text-align: center;
  text-decoration: none;
  width: 40px;
  height: 40px;
  position: relative;
  transition: all 0.3s;
  color: transparent;
}
.btn12:hover .buttons .fa {
  color: rgba(50, 50, 50, 0.9);
  box-shadow: 0 0 1px rgba(0, 0, 0, 0.3), 0 0 5px 25px #fff inset;
}
.btn12:hover .ovrly {
  opacity: 1;
}
.btn13 {
  width: 100%;
  overflow: hidden;
  position: relative;
  box-shadow: 1px 1px 3px 0 rgba(0, 0, 0, 0.3);
}
.btn13 img {
  width: 100%;
  position: relative;
  top: 0;
  left: 0;
}
.btn13 .ovrly {
  background: rgba(0, 0, 0, 0.5);
  height: 100%;
  left: 0;
  position: absolute;
  top: 0;
  width: 100%;
  opacity: 0;
  transition: all 0.3s;
}
.btn13 .buttons {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}
.btn13 .buttons .fa {
  background: rgb(256, 256, 256);
  box-shadow: 0 0 1px rgba(0, 0, 0, 0.3);
  border-radius: 50%;
  display: inline-block;
  line-height: 40px;
  font-size: 16px;
  text-align: center;
  text-decoration: none;
  width: 40px;
  height: 40px;
  position: relative;
  transform: rotateZ(180deg);
  transition: transform 0.3s;
  color: rgba(50, 50, 50, 0.9);
}
.btn13 .buttons .fa-link {
  transform-origin: -190% 50%;
}
.btn13 .buttons .fa-search {
  transform-origin: 300% 50%;
}
.btn13:hover .buttons .fa {
  transform: rotateZ(0deg);
}
.btn13:hover .ovrly {
  opacity: 1;
}
.btn14 {
  width: 100%;
  overflow: hidden;
  position: relative;
  box-shadow: 1px 1px 3px 0 rgba(0, 0, 0, 0.3);
}
.btn14 img {
  width: 100%;
  position: relative;
  top: 0;
  left: 0;
}
.btn14 .ovrly {
  background: rgba(0, 0, 0, 0.5);
  height: 100%;
  left: 0;
  position: absolute;
  top: 0;
  width: 100%;
  opacity: 0;
  transition: all 0.3s;
}
.btn14 .buttons {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}
.btn14 .buttons .fa {
  background: rgb(256, 256, 256);
  box-shadow: 0 0 1px rgba(0, 0, 0, 0.3);
  border-radius: 50%;
  display: inline-block;
  line-height: 40px;
  font-size: 16px;
  text-align: center;
  text-decoration: none;
  width: 40px;
  height: 40px;
  position: relative;
  transform: rotateZ(180deg);
  transition: transform 0.3s;
  color: rgba(50, 50, 50, 0.9);
}
.btn14 .buttons .fa-link {
  transform-origin: 50% -110%;
}
.btn14 .buttons .fa-search {
  transform-origin: 50% 210%;
}
.btn14:hover .buttons .fa {
  transform: rotateZ(0deg);
}
.btn14:hover .ovrly {
  opacity: 1;
}
.btn15 {
  width: 100%;
  overflow: hidden;
  position: relative;
  box-shadow: 1px 1px 3px 0 rgba(0, 0, 0, 0.3);
}
.btn15 img {
  width: 100%;
  position: relative;
  top: 0;
  left: 0;
}
.btn15 .ovrly {
  background: rgba(0, 0, 0, 0.5);
  height: 100%;
  left: 0;
  position: absolute;
  top: 0;
  width: 100%;
  opacity: 0;
  transition: all 0.3s;
}
.btn15 .buttons {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}
.btn15 .buttons .fa {
  background: rgb(256, 256, 256);
  box-shadow: 0 0 1px rgba(0, 0, 0, 0.3);
  border-radius: 50%;
  display: inline-block;
  line-height: 40px;
  font-size: 16px;
  text-align: center;
  text-decoration: none;
  width: 40px;
  height: 40px;
  position: relative;
  transition: all 0.3s;
  color: rgba(50, 50, 50, 0.9);
}
.btn15 .buttons .fa-link {
  left: -215%;
}
.btn15 .buttons .fa-search {
  right: -215%;
}
.btn15:hover .buttons .fa-link {
  left: 0;
}
.btn15:hover .buttons .fa-search {
  right: 0;
}
.btn15:hover .ovrly {
  opacity: 1;
}
.btn16 {
  width: 100%;
  overflow: hidden;
  position: relative;
  box-shadow: 1px 1px 3px 0 rgba(0, 0, 0, 0.3);
}
.btn16 img {
  width: 100%;
  position: relative;
  top: 0;
  left: 0;
}
.btn16 .ovrly {
  background: rgba(0, 0, 0, 0.5);
  height: 100%;
  left: 0;
  position: absolute;
  top: 0;
  width: 100%;
  opacity: 0;
  transition: all 0.3s;
}
.btn16 .buttons {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}
.btn16 .buttons .fa {
  background: rgb(256, 256, 256);
  box-shadow: 0 0 1px rgba(0, 0, 0, 0.3);
  border-radius: 50%;
  display: inline-block;
  line-height: 40px;
  font-size: 16px;
  text-align: center;
  text-decoration: none;
  width: 40px;
  height: 40px;
  position: relative;
  transition: all 0.3s;
  color: rgba(50, 50, 50, 0.9);
}
.btn16 .buttons .fa-link {
  top: -360%;
}
.btn16 .buttons .fa-search {
  bottom: -360%;
}
.btn16:hover .buttons .fa-link {
  top: 0;
}
.btn16:hover .buttons .fa-search {
  bottom: 0;
}
.btn16:hover .ovrly {
  opacity: 1;
}
.btn17 {
  width: 100%;
  overflow: hidden;
  position: relative;
  box-shadow: 1px 1px 3px 0 rgba(0, 0, 0, 0.3);
}
.btn17 img {
  width: 100%;
  position: relative;
  top: 0;
  left: 0;
}
.btn17 .ovrly {
  background: rgba(0, 0, 0, 0.5);
  height: 100%;
  left: 0;
  position: absolute;
  top: 0;
  width: 100%;
  opacity: 0;
  transition: all 0.3s;
}
.btn17 .buttons {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}
.btn17 .buttons .fa {
  background: rgb(256, 256, 256);
  box-shadow: 0 0 1px rgba(0, 0, 0, 0.3);
  display: inline-block;
  line-height: 40px;
  font-size: 16px;
  text-align: center;
  text-decoration: none;
  width: 40px;
  height: 40px;
  opacity: 0;
  transform: rotateZ(360deg);
  position: relative;
  transition: transform 0.3s, opacity 0.3s;
  color: rgba(50, 50, 50, 0.9);
}
.btn17:hover .buttons .fa {
  opacity: 1;
  transform: rotateZ(0deg);
}
.btn17:hover .ovrly {
  opacity: 1;
}
.btn18 {
  width: 100%;
  overflow: hidden;
  position: relative;
  box-shadow: 1px 1px 3px 0 rgba(0, 0, 0, 0.3);
}
.btn18 img {
  width: 100%;
  position: relative;
  top: 0;
  left: 0;
}
.btn18 .ovrly {
  background: rgba(0, 0, 0, 0.5);
  height: 100%;
  left: 0;
  position: absolute;
  top: 0;
  width: 100%;
  opacity: 0;
  transition: all 0.3s;
}
.btn18 .buttons {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}
.btn18 .buttons .fa {
  background: rgb(256, 256, 256);
  box-shadow: 0 0 1px rgba(0, 0, 0, 0.3);
  display: inline-block;
  line-height: 40px;
  font-size: 16px;
  text-align: center;
  text-decoration: none;
  width: 40px;
  height: 40px;
  opacity: 0;
  transform: rotateZ(-360deg);
  position: relative;
  transition: transform 0.3s, opacity 0.3s;
  color: rgba(50, 50, 50, 0.9);
}
.btn18:hover .buttons .fa {
  opacity: 1;
  transform: rotateZ(0deg);
}
.btn18:hover .ovrly {
  opacity: 1;
}
.btn19 {
  width: 100%;
  overflow: hidden;
  position: relative;
  box-shadow: 1px 1px 3px 0 rgba(0, 0, 0, 0.3);
}
.btn19 img {
  width: 100%;
  position: relative;
  top: 0;
  left: 0;
}
.btn19 .ovrly {
  background: rgba(0, 0, 0, 0.5);
  height: 100%;
  left: 0;
  position: absolute;
  top: 0;
  width: 100%;
  opacity: 0;
  transition: all 0.3s;
}
.btn19 .buttons {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}
.btn19 .buttons .fa {
  background: rgb(256, 256, 256);
  box-shadow: 0 0 1px rgba(0, 0, 0, 0.3);
  border-radius: 0;
  display: inline-block;
  line-height: 40px;
  font-size: 16px;
  text-align: center;
  text-decoration: none;
  width: 40px;
  height: 40px;
  opacity: 0;
  position: relative;
  transition: all 0.3s;
  color: rgba(50, 50, 50, 0.9);
}
.btn19:hover .buttons .fa {
  opacity: 1;
  border-radius: 50%;
}
.btn19:hover .ovrly {
  opacity: 1;
}
.btn20 {
  width: 100%;
  overflow: hidden;
  position: relative;
  box-shadow: 1px 1px 3px 0 rgba(0, 0, 0, 0.3);
}
.btn20 img {
  width: 100%;
  position: relative;
  top: 0;
  left: 0;
}
.btn20 .ovrly {
  background: rgba(0, 0, 0, 0.5);
  height: 100%;
  left: 0;
  position: absolute;
  top: 0;
  width: 100%;
  opacity: 0;
  transition: all 0.3s;
}
.btn20 .buttons {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}
.btn20 .buttons .fa {
  background: rgb(256, 256, 256);
  box-shadow: 0 0 1px rgba(0, 0, 0, 0.3);
  border-radius: 50%;
  display: inline-block;
  line-height: 40px;
  font-size: 16px;
  text-align: center;
  text-decoration: none;
  width: 40px;
  height: 40px;
  opacity: 0;
  position: relative;
  transition: all 0.3s;
  color: rgba(50, 50, 50, 0.9);
}
.btn20:hover .buttons .fa {
  opacity: 1;
  border-radius: 0;
}
.btn20:hover .ovrly {
  opacity: 1;
}
.btn21 {
  width: 100%;
  overflow: hidden;
  position: relative;
  box-shadow: 1px 1px 3px 0 rgba(0, 0, 0, 0.3);
}
.btn21 img {
  width: 100%;
  position: relative;
  top: 0;
  left: 0;
}
.btn21 .ovrly {
  background: rgba(0, 0, 0, 0.5);
  height: 100%;
  left: 0;
  position: absolute;
  top: 0;
  width: 100%;
  opacity: 0;
  transition: all 0.3s;
}
.btn21 .buttons {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}
.btn21 .buttons .fa {
  background: rgb(256, 256, 256);
  box-shadow: 0 0 1px rgba(0, 0, 0, 0.3);
  display: inline-block;
  line-height: 40px;
  font-size: 16px;
  text-align: center;
  text-decoration: none;
  width: 40px;
  height: 40px;
  transform: scaleX(0);
  position: relative;
  transition: transform 0.3s;
  color: rgba(50, 50, 50, 0.9);
}
.btn21:hover .buttons .fa {
  transform: scaleX(1);
}
.btn21:hover .ovrly {
  opacity: 1;
}
.btn22 {
  width: 100%;
  overflow: hidden;
  position: relative;
  box-shadow: 1px 1px 3px 0 rgba(0, 0, 0, 0.3);
}
.btn22 img {
  width: 100%;
  position: relative;
  top: 0;
  left: 0;
}
.btn22 .ovrly {
  background: rgba(0, 0, 0, 0.5);
  height: 100%;
  left: 0;
  position: absolute;
  top: 0;
  width: 100%;
  opacity: 0;
  transition: all 0.3s;
}
.btn22 .buttons {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}
.btn22 .buttons .fa {
  background: rgb(256, 256, 256);
  box-shadow: 0 0 1px rgba(0, 0, 0, 0.3);
  display: inline-block;
  line-height: 40px;
  font-size: 16px;
  text-align: center;
  text-decoration: none;
  width: 40px;
  height: 40px;
  transform: scaleY(0);
  position: relative;
  transition: transform 0.3s;
  color: rgba(50, 50, 50, 0.9);
}
.btn22:hover .buttons .fa {
  transform: scaleY(1);
}
.btn22:hover .ovrly {
  opacity: 1;
}
.btn23 {
  width: 100%;
  overflow: hidden;
  position: relative;
  box-shadow: 1px 1px 3px 0 rgba(0, 0, 0, 0.3);
}
.btn23 img {
  width: 100%;
  position: relative;
  top: 0;
  left: 0;
}
.btn23 .ovrly {
  background: rgba(0, 0, 0, 0.5);
  height: 100%;
  left: 0;
  position: absolute;
  top: 0;
  width: 100%;
  opacity: 0;
  transition: all 0.3s;
}
.btn23 .buttons {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}
.btn23 .buttons .fa {
  background: rgb(256, 256, 256);
  box-shadow: 0 0 1px rgba(0, 0, 0, 0.3);
  -webkt-border-radius: 50%;
  border-radius: 50%;
  display: inline-block;
  line-height: 40px;
  font-size: 16px;
  text-align: center;
  text-decoration: none;
  width: 40px;
  height: 40px;
  position: relative;
  top: -360%;
  transition: all 0.3s cubic-bezier(0, 1.135, 0.73, 1.405);
  color: rgba(50, 50, 50, 0.9);
}
.btn23 .buttons .fa-search {
  transition-delay: 0.1s;
}
.btn23:hover .buttons .fa {
  top: 0;
}
.btn23:hover .ovrly {
  opacity: 1;
}
.btn24 {
  width: 100%;
  overflow: hidden;
  position: relative;
  box-shadow: 1px 1px 3px 0 rgba(0, 0, 0, 0.3);
}
.btn24 img {
  width: 100%;
  position: relative;
  top: 0;
  left: 0;
}
.btn24 .ovrly {
  background: rgba(0, 0, 0, 0.5);
  height: 100%;
  left: 0;
  position: absolute;
  top: 0;
  width: 100%;
  opacity: 0;
  transition: all 0.3s;
}
.btn24 .buttons {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}
.btn24 .buttons .fa {
  background: rgb(256, 256, 256);
  box-shadow: 0 0 1px rgba(0, 0, 0, 0.3);
  -webkt-border-radius: 50%;
  border-radius: 50%;
  display: inline-block;
  line-height: 40px;
  font-size: 16px;
  text-align: center;
  text-decoration: none;
  width: 40px;
  height: 40px;
  position: relative;
  bottom: -360%;
  transition: all 0.3s cubic-bezier(0, 1.135, 0.73, 1.405);
  color: rgba(50, 50, 50, 0.9);
}
.btn24 .buttons .fa-search {
  transition-delay: 0.1s;
}
.btn24:hover .buttons .fa {
  bottom: 0;
}
.btn24:hover .ovrly {
  opacity: 1;
}
.btn25 {
  width: 100%;
  overflow: hidden;
  position: relative;
  box-shadow: 1px 1px 3px 0 rgba(0, 0, 0, 0.3);
}
.btn25 img {
  width: 100%;
  position: relative;
  top: 0;
  left: 0;
}
.btn25 .ovrly {
  background: rgba(0, 0, 0, 0.5);
  height: 100%;
  left: 0;
  position: absolute;
  top: 0;
  width: 100%;
  opacity: 0;
  transition: all 0.3s;
}
.btn25 .buttons {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}
.btn25 .buttons .fa {
  background: rgb(256, 256, 256);
  box-shadow: 0 0 1px rgba(0, 0, 0, 0.3);
  -webkt-border-radius: 50%;
  border-radius: 50%;
  display: inline-block;
  line-height: 40px;
  font-size: 16px;
  text-align: center;
  text-decoration: none;
  width: 40px;
  height: 40px;
  opacity: 0;
  transform: scale(5);
  position: relative;
  transition: transform 0.3s, opacity 0.3s;
  color: rgba(50, 50, 50, 0.9);
}
.btn25 .buttons .fa-search {
  transition-delay: 0.1s;
}
.btn25:hover .buttons .fa {
  opacity: 1;
  transform: scale(1);
}
.btn25:hover .ovrly {
  opacity: 1;
}
.btn26 {
  width: 100%;
  overflow: hidden;
  position: relative;
  box-shadow: 1px 1px 3px 0 rgba(0, 0, 0, 0.3);
}
.btn26 img {
  margin: 0;
  padding: 0;
  position: relative;
  top: 0;
  width: 100%;
  left: 0;
}
.btn26 .ovrly {
  position: absolute;
  background: rgba(0, 0, 0, 0.5);
  height: 100%;
  left: 0;
  top: 0;
  width: 100%;
  opacity: 0;
  transition: all 0.3s;
}
.btn26 .buttons {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}
.btn26 .buttons .fa {
  transform: scale(0);
  opacity: 0;
  background: rgb(256, 256, 256);
  box-shadow: 0 0 1px rgba(0, 0, 0, 0.3);
  border-radius: 50%;
  display: inline-block;
  line-height: 40px;
  font-size: 16px;
  text-align: center;
  text-decoration: none;
  width: 40px;
  height: 40px;
  margin: 0 1px;
  color: rgba(50, 50, 50, 0.9);
  transition: all 0.3s cubic-bezier(0, 1.135, 0.73, 1.405);
}
.btn26 .buttons .fa-search {
  transition-delay: 0.1s;
}
.btn26:hover .buttons .fa {
  transform: scale(1);
  opacity: 1;
}
.btn26:hover .ovrly {
  opacity: 1;
}
.ovrly01 {
  width: 100%;
  overflow: hidden;
  position: relative;
  box-shadow: 1px 1px 3px 0 rgba(0, 0, 0, 0.3);
}
.ovrly01 img {
  position: relative;
  width: 100%;
  top: 0;
  left: 0;
}
.ovrly01 .ovrlyT {
  background: rgba(0, 0, 0, 0.25);
  height: 100%;
  left: 0;
  position: absolute;
  top: -100%;
  width: 100%;
  transition: all 0.3s;
}
.ovrly01 .ovrlyB {
  background: rgba(0, 0, 0, 0.25);
  height: 100%;
  left: 0;
  position: absolute;
  top: 100%;
  width: 100%;
  transition: all 0.3s;
}
.ovrly01 .buttons {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}
.ovrly01 .buttons .fa {
  background: rgb(256, 256, 256);
  box-shadow: 0 0 1px rgba(0, 0, 0, 0.3);
  border-radius: 50%;
  color: rgba(50, 50, 50, 0.9);
  display: inline-block;
  margin: 0 1px;
  line-height: 40px;
  font-size: 16px;
  text-align: center;
  text-decoration: none;
  width: 40px;
  height: 40px;
  transition: all 0.2s 0.3s;
  opacity: 0;
}
.ovrly01:hover .ovrlyT {
  top: 0;
}
.ovrly01:hover .ovrlyB {
  top: 0;
}
.ovrly01:hover .buttons .fa {
  opacity: 1;
}
.ovrly02 {
  width: 100%;
  overflow: hidden;
  position: relative;
  box-shadow: 1px 1px 3px 0 rgba(0, 0, 0, 0.3);
}
.ovrly02 img {
  position: relative;
  width: 100%;
  top: 0;
  left: 0;
}
.ovrly02 .ovrlyL {
  background: rgba(0, 0, 0, 0.25);
  height: 100%;
  left: -100%;
  position: absolute;
  top: 0;
  width: 100%;
  transition: all 0.3s;
}
.ovrly02 .ovrlyR {
  background: rgba(0, 0, 0, 0.25);
  height: 100%;
  left: 100%;
  position: absolute;
  top: 0;
  width: 100%;
  transition: all 0.3s;
}
.ovrly02 .buttons {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}
.ovrly02 .buttons .fa {
  background: rgb(256, 256, 256);
  box-shadow: 0 0 1px rgba(0, 0, 0, 0.3);
  border-radius: 50%;
  color: rgba(50, 50, 50, 0.9);
  display: inline-block;
  margin: 0 1px;
  line-height: 40px;
  font-size: 16px;
  text-align: center;
  text-decoration: none;
  width: 40px;
  height: 40px;
  transition: all 0.2s 0.3s;
  opacity: 0;
}
.ovrly02:hover .ovrlyL {
  left: 0;
}
.ovrly02:hover .ovrlyR {
  left: 0;
}
.ovrly02:hover .buttons .fa {
  opacity: 1;
}
.ovrly03 {
  width: 100%;
  overflow: hidden;
  position: relative;
  box-shadow: 1px 1px 3px 0 rgba(0, 0, 0, 0.3);
}
.ovrly03 img {
  position: relative;
  width: 100%;
  top: 0;
  left: 0;
}
.ovrly03 .ovrlyT {
  background: rgba(0, 0, 0, 0.25);
  height: 100%;
  left: -100%;
  position: absolute;
  top: -100%;
  width: 100%;
  transition: all 0.3s;
}
.ovrly03 .ovrlyB {
  background: rgba(0, 0, 0, 0.25);
  height: 100%;
  left: 100%;
  position: absolute;
  top: 100%;
  width: 100%;
  transition: all 0.3s;
}
.ovrly03 .buttons {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}
.ovrly03 .buttons .fa {
  background: rgb(256, 256, 256);
  box-shadow: 0 0 1px rgba(0, 0, 0, 0.3);
  border-radius: 50%;
  color: rgba(50, 50, 50, 0.9);
  display: inline-block;
  margin: 0 1px;
  line-height: 40px;
  font-size: 16px;
  text-align: center;
  text-decoration: none;
  width: 40px;
  height: 40px;
  transition: all 0.2s 0.3s;
  opacity: 0;
}
.ovrly03:hover .ovrlyT {
  left: 0;
  top: 0;
}
.ovrly03:hover .ovrlyB {
  left: 0;
  top: 0;
}
.ovrly03:hover .buttons .fa {
  opacity: 1;
}
.ovrly04 {
  width: 100%;
  overflow: hidden;
  position: relative;
  box-shadow: 1px 1px 3px 0 rgba(0, 0, 0, 0.3);
}
.ovrly04 img {
  position: relative;
  width: 100%;
  top: 0;
  left: 0;
}
.ovrly04 .ovrlyT {
  background: rgba(0, 0, 0, 0.25);
  height: 100%;
  left: 100%;
  position: absolute;
  top: -100%;
  width: 100%;
  transition: all 0.3s;
}
.ovrly04 .ovrlyB {
  background: rgba(0, 0, 0, 0.25);
  height: 100%;
  left: -100%;
  position: absolute;
  top: 100%;
  width: 100%;
  transition: all 0.3s;
}
.ovrly04 .buttons {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}
.ovrly04 .buttons .fa {
  background: rgb(256, 256, 256);
  box-shadow: 0 0 1px rgba(0, 0, 0, 0.3);
  border-radius: 50%;
  color: rgba(50, 50, 50, 0.9);
  display: inline-block;
  margin: 0 1px;
  line-height: 40px;
  font-size: 16px;
  text-align: center;
  text-decoration: none;
  width: 40px;
  height: 40px;
  transition: all 0.2s 0.3s;
  opacity: 0;
}
.ovrly04:hover .ovrlyT {
  left: 0;
  top: 0;
}
.ovrly04:hover .ovrlyB {
  left: 0;
  top: 0;
}
.ovrly04:hover .buttons .fa {
  opacity: 1;
}
.ovrly05 {
  width: 100%;
  overflow: hidden;
  position: relative;
  box-shadow: 1px 1px 3px 0 rgba(0, 0, 0, 0.3);
}
.ovrly05 img {
  position: relative;
  width: 100%;
  top: 0;
  left: 0;
}
.ovrly05 .ovrlyT {
  background: rgba(0, 0, 0, 0.5);
  width: 100%;
  height: 50%;
  left: 0;
  top: -50%;
  position: absolute;
  transition: all 0.2s;
}
.ovrly05 .ovrlyB {
  background: rgba(0, 0, 0, 0.5);
  width: 100%;
  height: 50%;
  left: 0;
  position: absolute;
  top: 100%;
  transition: all 0.2s;
}
.ovrly05 .buttons {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}
.ovrly05 .buttons .fa {
  background: rgb(256, 256, 256);
  box-shadow: 0 0 1px rgba(0, 0, 0, 0.3);
  border-radius: 50%;
  color: rgba(50, 50, 50, 0.9);
  display: inline-block;
  margin: 0 1px;
  line-height: 40px;
  font-size: 16px;
  text-align: center;
  text-decoration: none;
  width: 40px;
  height: 40px;
  transition: all 0.2s 0.3s;
  opacity: 0;
}
.ovrly05:hover .ovrlyT {
  left: 0;
  top: 0;
}
.ovrly05:hover .ovrlyB {
  left: 0;
  top: 50%;
}
.ovrly05:hover .buttons .fa {
  opacity: 1;
}
.ovrly06 {
  width: 100%;
  overflow: hidden;
  position: relative;
  box-shadow: 1px 1px 3px 0 rgba(0, 0, 0, 0.3);
}
.ovrly06 img {
  position: relative;
  width: 100%;
  top: 0;
  left: 0;
}
.ovrly06 .ovrlyL {
  background: rgba(0, 0, 0, 0.5);
  width: 50%;
  height: 100%;
  left: -50%;
  top: 0;
  position: absolute;
  transition: all 0.2s;
}
.ovrly06 .ovrlyR {
  background: rgba(0, 0, 0, 0.5);
  width: 50%;
  height: 100%;
  left: 100%;
  position: absolute;
  top: 0;
  transition: all 0.2s;
}
.ovrly06 .buttons {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}
.ovrly06 .buttons .fa {
  background: rgb(256, 256, 256);
  box-shadow: 0 0 1px rgba(0, 0, 0, 0.3);
  border-radius: 50%;
  color: rgba(50, 50, 50, 0.9);
  display: inline-block;
  margin: 0 1px;
  line-height: 40px;
  font-size: 16px;
  text-align: center;
  text-decoration: none;
  width: 40px;
  height: 40px;
  transition: all 0.2s 0.3s;
  opacity: 0;
}
.ovrly06:hover .ovrlyL {
  left: 0;
  top: 0;
}
.ovrly06:hover .ovrlyR {
  left: 50%;
  top: 0;
}
.ovrly06:hover .buttons .fa {
  opacity: 1;
}
.ovrly07 {
  width: 100%;
  overflow: hidden;
  position: relative;
  box-shadow: 1px 1px 3px 0 rgba(0, 0, 0, 0.3);
}
.ovrly07 img {
  position: relative;
  width: 100%;
  top: 0;
  left: 0;
}
.ovrly07 .ovrly {
  background: rgba(0, 0, 0, 0.5);
  height: 100%;
  left: -195%;
  position: absolute;
  width: 170%;
  top: 0;
  transform: skewX(-45deg);
  transition: all 0.3s;
}
.ovrly07 .buttons {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}
.ovrly07 .buttons .fa {
  background: rgb(256, 256, 256);
  box-shadow: 0 0 1px rgba(0, 0, 0, 0.3);
  border-radius: 50%;
  color: rgba(50, 50, 50, 0.9);
  display: inline-block;
  margin: 0 1px;
  line-height: 40px;
  font-size: 16px;
  text-align: center;
  text-decoration: none;
  width: 40px;
  height: 40px;
  transition: all 0.2s 0.3s;
  opacity: 0;
}
.ovrly07:hover .ovrly {
  left: -35%;
}
.ovrly07:hover .buttons .fa {
  opacity: 1;
}
.ovrly08 {
  width: 100%;
  overflow: hidden;
  position: relative;
  box-shadow: 1px 1px 3px 0 rgba(0, 0, 0, 0.3);
}
.ovrly08 img {
  position: relative;
  width: 100%;
  top: 0;
  left: 0;
}
.ovrly08 .ovrly {
  background: rgba(0, 0, 0, 0.5);
  height: 100%;
  left: 125%;
  width: 170%;
  top: 0;
  position: absolute;
  transform: skewX(45deg);
  transition: all 0.3s;
}
.ovrly08 .buttons {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}
.ovrly08 .buttons .fa {
  background: rgb(256, 256, 256);
  box-shadow: 0 0 1px rgba(0, 0, 0, 0.3);
  border-radius: 50%;
  color: rgba(50, 50, 50, 0.9);
  display: inline-block;
  margin: 0 1px;
  line-height: 40px;
  font-size: 16px;
  text-align: center;
  text-decoration: none;
  width: 40px;
  height: 40px;
  transition: all 0.2s 0.3s;
  opacity: 0;
}
.ovrly08:hover .ovrly {
  left: -35%;
}
.ovrly08:hover .buttons .fa {
  opacity: 1;
}
.ovrly09 {
  width: 100%;
  overflow: hidden;
  position: relative;
  box-shadow: 1px 1px 3px 0 rgba(0, 0, 0, 0.3);
}
.ovrly09 img {
  position: relative;
  width: 100%;
  top: 0;
  left: 0;
}
.ovrly09 .ovrly {
  background: rgba(0, 0, 0, 0.5);
  height: 100%;
  left: 125%;
  width: 170%;
  position: absolute;
  top: 0;
  transform: skewX(-45deg);
  transition: all 0.3s;
}
.ovrly09 .buttons {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}
.ovrly09 .buttons .fa {
  background: rgb(256, 256, 256);
  box-shadow: 0 0 1px rgba(0, 0, 0, 0.3);
  border-radius: 50%;
  color: rgba(50, 50, 50, 0.9);
  display: inline-block;
  margin: 0 1px;
  line-height: 40px;
  font-size: 16px;
  text-align: center;
  text-decoration: none;
  width: 40px;
  height: 40px;
  transition: all 0.2s 0.3s;
  opacity: 0;
}
.ovrly09:hover .ovrly {
  left: -35%;
}
.ovrly09:hover .buttons .fa {
  opacity: 1;
}
.ovrly10 {
  width: 100%;
  overflow: hidden;
  position: relative;
  box-shadow: 1px 1px 3px 0 rgba(0, 0, 0, 0.3);
}
.ovrly10 img {
  position: relative;
  width: 100%;
  top: 0;
  left: 0;
}
.ovrly10 .ovrly {
  background: rgba(0, 0, 0, 0.5);
  height: 100%;
  left: -195%;
  width: 170%;
  top: 0;
  position: absolute;
  transform: skewX(45deg);
  transition: all 0.3s;
}
.ovrly10 .buttons {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}
.ovrly10 .buttons .fa {
  background: rgb(256, 256, 256);
  box-shadow: 0 0 1px rgba(0, 0, 0, 0.3);
  border-radius: 50%;
  color: rgba(50, 50, 50, 0.9);
  display: inline-block;
  margin: 0 1px;
  line-height: 40px;
  font-size: 16px;
  text-align: center;
  text-decoration: none;
  width: 40px;
  height: 40px;
  transition: all 0.2s 0.3s;
  opacity: 0;
}
.ovrly10:hover .ovrly {
  left: -35%;
}
.ovrly10:hover .buttons .fa {
  opacity: 1;
}
.ovrly11 {
  width: 100%;
  overflow: hidden;
  position: relative;
  box-shadow: 1px 1px 3px 0 rgba(0, 0, 0, 0.3);
}
.ovrly11 img {
  position: relative;
  width: 100%;
  top: 0;
  left: 0;
}
.ovrly11 .ovrly {
  position: absolute;
  background: rgba(0, 0, 0, 0.5);
  height: 100%;
  width: 100%;
  left: 0;
  top: -100%;
  transition: all 0.3s cubic-bezier(0.815, 1.65, 0.4, 0.68);
}
.ovrly11 .buttons {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}
.ovrly11 .buttons .fa {
  background: rgb(256, 256, 256);
  box-shadow: 0 0 1px rgba(0, 0, 0, 0.3);
  border-radius: 50%;
  color: rgba(50, 50, 50, 0.9);
  display: inline-block;
  margin: 0 1px;
  line-height: 40px;
  font-size: 16px;
  text-align: center;
  text-decoration: none;
  width: 40px;
  height: 40px;
  transition: all 0.2s 0.3s;
  opacity: 0;
}
.ovrly11:hover .ovrly {
  top: 0;
}
.ovrly11:hover .buttons .fa {
  opacity: 1;
}
.ovrly12 {
  width: 100%;
  overflow: hidden;
  position: relative;
  box-shadow: 1px 1px 3px 0 rgba(0, 0, 0, 0.3);
}
.ovrly12 img {
  position: relative;
  width: 100%;
  top: 0;
  left: 0;
}
.ovrly12 .ovrly {
  position: absolute;
  background: rgba(0, 0, 0, 0.5);
  height: 100%;
  left: 0;
  top: 0;
  transform: scale(0);
  width: 100%;
  transition: transform 0.3s cubic-bezier(0.815, 1.65, 0.4, 0.68);
}
.ovrly12 .buttons {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}
.ovrly12 .buttons .fa {
  background: rgb(256, 256, 256);
  box-shadow: 0 0 1px rgba(0, 0, 0, 0.3);
  border-radius: 50%;
  color: rgba(50, 50, 50, 0.9);
  display: inline-block;
  margin: 0 1px;
  line-height: 40px;
  font-size: 16px;
  text-align: center;
  text-decoration: none;
  width: 40px;
  height: 40px;
  transition: all 0.2s 0.3s;
  opacity: 0;
}
.ovrly12:hover .ovrly {
  transform: scale(1);
}
.ovrly12:hover .buttons .fa {
  opacity: 1;
}
.ovrly13 {
  width: 100%;
  overflow: hidden;
  position: relative;
  box-shadow: 1px 1px 3px 0 rgba(0, 0, 0, 0.3);
}
.ovrly13 img {
  position: relative;
  width: 100%;
  top: 0;
  left: 0;
}
.ovrly13 .ovrly {
  position: absolute;
  background: rgba(0, 0, 0, 0.5);
  height: 100%;
  left: 0;
  top: 0;
  transform: scaleX(0);
  width: 100%;
  transition: transform 0.3s cubic-bezier(0.815, 1.65, 0.4, 0.68);
}
.ovrly13 .buttons {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}
.ovrly13 .buttons .fa {
  background: rgb(256, 256, 256);
  box-shadow: 0 0 1px rgba(0, 0, 0, 0.3);
  border-radius: 50%;
  color: rgba(50, 50, 50, 0.9);
  display: inline-block;
  margin: 0 1px;
  line-height: 40px;
  font-size: 16px;
  text-align: center;
  text-decoration: none;
  width: 40px;
  height: 40px;
  transition: all 0.2s 0.3s;
  opacity: 0;
}
.ovrly13:hover .ovrly {
  transform: scaleX(1);
}
.ovrly13:hover .buttons .fa {
  opacity: 1;
}
.ovrly14 {
  width: 100%;
  overflow: hidden;
  position: relative;
  box-shadow: 1px 1px 3px 0 rgba(0, 0, 0, 0.3);
}
.ovrly14 img {
  position: relative;
  width: 100%;
  top: 0;
  left: 0;
}
.ovrly14 .ovrly {
  position: absolute;
  background: rgba(0, 0, 0, 0.5);
  height: 100%;
  left: 0;
  top: 0;
  transform: scaleY(0);
  width: 100%;
  transition: transform 0.3s cubic-bezier(0.815, 1.65, 0.4, 0.68);
}
.ovrly14 .buttons {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}
.ovrly14 .buttons .fa {
  background: rgb(256, 256, 256);
  box-shadow: 0 0 1px rgba(0, 0, 0, 0.3);
  border-radius: 50%;
  color: rgba(50, 50, 50, 0.9);
  display: inline-block;
  margin: 0 1px;
  line-height: 40px;
  font-size: 16px;
  text-align: center;
  text-decoration: none;
  width: 40px;
  height: 40px;
  transition: all 0.2s 0.3s;
  opacity: 0;
}
.ovrly14:hover .ovrly {
  transform: scaleY(1);
}
.ovrly14:hover .buttons .fa {
  opacity: 1;
}
.ovrly15 {
  width: 100%;
  overflow: hidden;
  position: relative;
  box-shadow: 1px 1px 3px 0 rgba(0, 0, 0, 0.3);
}
.ovrly15 img {
  position: relative;
  width: 100%;
  top: 0;
  left: 0;
}
.ovrly15 .ovrly {
  position: absolute;
  background: linear-gradient(
    to bottom,
    rgba(0, 0, 0, 0.7) 0%,
    rgba(0, 0, 0, 0) 100%
  );
  height: 100%;
  width: 100%;
  left: 0;
  top: -100%;
  transition: all 0.2s;
}
.ovrly15 .buttons {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}
.ovrly15 .buttons .fa {
  background: rgb(256, 256, 256);
  box-shadow: 0 0 1px rgba(0, 0, 0, 0.3);
  border-radius: 50%;
  color: rgba(50, 50, 50, 0.9);
  display: inline-block;
  margin: 0 1px;
  line-height: 40px;
  font-size: 16px;
  text-align: center;
  text-decoration: none;
  width: 40px;
  height: 40px;
  transition: all 0.2s 0.3s;
  opacity: 0;
}
.ovrly15:hover .ovrly {
  top: 0;
}
.ovrly15:hover .buttons .fa {
  opacity: 1;
}
.ovrly16 {
  width: 100%;
  overflow: hidden;
  position: relative;
  box-shadow: 1px 1px 3px 0 rgba(0, 0, 0, 0.3);
}
.ovrly16 img {
  position: relative;
  width: 100%;
  top: 0;
  left: 0;
}
.ovrly16 .ovrly {
  position: absolute;
  background: linear-gradient(
    to right,
    rgba(0, 0, 0, 0) 0%,
    rgba(0, 0, 0, 0.7) 100%
  );
  height: 100%;
  left: 100%;
  top: 0;
  width: 100%;
  transition: all 0.3s;
}
.ovrly16 .buttons {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}
.ovrly16 .buttons .fa {
  background: rgb(256, 256, 256);
  box-shadow: 0 0 1px rgba(0, 0, 0, 0.3);
  border-radius: 50%;
  color: rgba(50, 50, 50, 0.9);
  display: inline-block;
  margin: 0 1px;
  line-height: 40px;
  font-size: 16px;
  text-align: center;
  text-decoration: none;
  width: 40px;
  height: 40px;
  transition: all 0.2s 0.3s;
  opacity: 0;
}
.ovrly16:hover .ovrly {
  left: 0;
}
.ovrly16:hover .buttons .fa {
  opacity: 1;
}
.ovrly17 {
  width: 100%;
  overflow: hidden;
  position: relative;
  box-shadow: 1px 1px 3px 0 rgba(0, 0, 0, 0.3);
}
.ovrly17 img {
  position: relative;
  width: 100%;
  top: 0;
  left: 0;
}
.ovrly17 .ovrly {
  position: absolute;
  background: linear-gradient(
    to bottom,
    rgba(0, 0, 0, 0) 0%,
    rgba(0, 0, 0, 0.7) 100%
  );
  height: 100%;
  width: 100%;
  left: 0;
  top: 100%;
  transition: all 0.2s;
}
.ovrly17 .buttons {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}
.ovrly17 .buttons .fa {
  background: rgb(256, 256, 256);
  box-shadow: 0 0 1px rgba(0, 0, 0, 0.3);
  border-radius: 50%;
  color: rgba(50, 50, 50, 0.9);
  display: inline-block;
  margin: 0 1px;
  line-height: 40px;
  font-size: 16px;
  text-align: center;
  text-decoration: none;
  width: 40px;
  height: 40px;
  transition: all 0.2s 0.3s;
  opacity: 0;
}
.ovrly17:hover .ovrly {
  top: 0;
}
.ovrly17:hover .buttons .fa {
  opacity: 1;
}
.ovrly18 {
  width: 100%;
  overflow: hidden;
  position: relative;
  box-shadow: 1px 1px 3px 0 rgba(0, 0, 0, 0.3);
}
.ovrly18 img {
  position: relative;
  width: 100%;
  top: 0;
  left: 0;
}
.ovrly18 .ovrly {
  position: absolute;
  background: linear-gradient(
    to right,
    rgba(0, 0, 0, 0.7) 0%,
    rgba(0, 0, 0, 0) 100%
  );
  height: 100%;
  left: -100%;
  top: 0;
  width: 100%;
  transition: all 0.3s;
}
.ovrly18 .buttons {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}
.ovrly18 .buttons .fa {
  background: rgb(256, 256, 256);
  box-shadow: 0 0 1px rgba(0, 0, 0, 0.3);
  border-radius: 50%;
  color: rgba(50, 50, 50, 0.9);
  display: inline-block;
  margin: 0 1px;
  line-height: 40px;
  font-size: 16px;
  text-align: center;
  text-decoration: none;
  width: 40px;
  height: 40px;
  transition: all 0.2s 0.3s;
  opacity: 0;
}
.ovrly18:hover .ovrly {
  left: 0;
}
.ovrly18:hover .buttons .fa {
  opacity: 1;
}
.ovrly19 {
  width: 100%;
  overflow: hidden;
  position: relative;
  box-shadow: 1px 1px 3px 0 rgba(0, 0, 0, 0.3);
}
.ovrly19 img {
  position: relative;
  width: 100%;
  top: 0;
  left: 0;
}
.ovrly19 .ovrly {
  position: absolute;
  background: rgba(0, 0, 0, 0.5);
  height: 100%;
  left: 0;
  top: 0;
  width: 100%;
  opacity: 0;
  transform: perspective(400px) rotateX(-90deg);
  transform-origin: top;
  transition: all 0.3s;
}
.ovrly19 .buttons {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}
.ovrly19 .buttons .fa {
  background: rgb(256, 256, 256);
  box-shadow: 0 0 1px rgba(0, 0, 0, 0.3);
  border-radius: 50%;
  color: rgba(50, 50, 50, 0.9);
  display: inline-block;
  margin: 0 1px;
  line-height: 40px;
  font-size: 16px;
  text-align: center;
  text-decoration: none;
  width: 40px;
  height: 40px;
  transition: all 0.2s 0.3s;
  opacity: 0;
}
.ovrly19:hover .ovrly {
  opacity: 1;
  transform: perspective(400px) rotateX(0deg);
}
.ovrly19:hover .buttons .fa {
  opacity: 1;
}
.ovrly20 {
  width: 100%;
  overflow: hidden;
  position: relative;
  box-shadow: 1px 1px 3px 0 rgba(0, 0, 0, 0.3);
}
.ovrly20 img {
  position: relative;
  width: 100%;
  top: 0;
  left: 0;
}
.ovrly20 .ovrly {
  position: absolute;
  background: rgba(0, 0, 0, 0.5);
  height: 100%;
  left: 0;
  top: 0;
  width: 100%;
  opacity: 0;
  transform: perspective(400px) rotateY(-90deg);
  transform-origin: right;
  transition: all 0.3s;
}
.ovrly20 .buttons {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}
.ovrly20 .buttons .fa {
  background: rgb(256, 256, 256);
  box-shadow: 0 0 1px rgba(0, 0, 0, 0.3);
  border-radius: 50%;
  color: rgba(50, 50, 50, 0.9);
  display: inline-block;
  margin: 0 1px;
  line-height: 40px;
  font-size: 16px;
  text-align: center;
  text-decoration: none;
  width: 40px;
  height: 40px;
  transition: all 0.2s 0.3s;
  opacity: 0;
}
.ovrly20:hover .ovrly {
  opacity: 1;
  transform: perspective(400px) rotateY(0deg);
}
.ovrly20:hover .buttons .fa {
  opacity: 1;
}
.ovrly21 {
  width: 100%;
  overflow: hidden;
  position: relative;
  box-shadow: 1px 1px 3px 0 rgba(0, 0, 0, 0.3);
}
.ovrly21 img {
  position: relative;
  width: 100%;
  top: 0;
  left: 0;
}
.ovrly21 .ovrly {
  position: absolute;
  background: rgba(0, 0, 0, 0.5);
  height: 100%;
  left: 0;
  top: 0;
  width: 100%;
  opacity: 0;
  transform: perspective(400px) rotateX(90deg);
  transform-origin: bottom;
  transition: all 0.3s;
}
.ovrly21 .buttons {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}
.ovrly21 .buttons .fa {
  background: rgb(256, 256, 256);
  box-shadow: 0 0 1px rgba(0, 0, 0, 0.3);
  border-radius: 50%;
  color: rgba(50, 50, 50, 0.9);
  display: inline-block;
  margin: 0 1px;
  line-height: 40px;
  font-size: 16px;
  text-align: center;
  text-decoration: none;
  width: 40px;
  height: 40px;
  transition: all 0.2s 0.3s;
  opacity: 0;
}
.ovrly21:hover .ovrly {
  opacity: 1;
  transform: perspective(400px) rotateX(0deg);
}
.ovrly21:hover .buttons .fa {
  opacity: 1;
}
.ovrly22 {
  width: 100%;
  overflow: hidden;
  position: relative;
  box-shadow: 1px 1px 3px 0 rgba(0, 0, 0, 0.3);
}
.ovrly22 img {
  position: relative;
  width: 100%;
  top: 0;
  left: 0;
}
.ovrly22 .ovrly {
  position: absolute;
  background: rgba(0, 0, 0, 0.5);
  height: 100%;
  left: 0;
  top: 0;
  width: 100%;
  opacity: 0;
  transform: perspective(400px) rotateY(90deg);
  transform-origin: left;
  transition: all 0.3s;
}
.ovrly22 .buttons {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}
.ovrly22 .buttons .fa {
  background: rgb(256, 256, 256);
  box-shadow: 0 0 1px rgba(0, 0, 0, 0.3);
  border-radius: 50%;
  color: rgba(50, 50, 50, 0.9);
  display: inline-block;
  margin: 0 1px;
  line-height: 40px;
  font-size: 16px;
  text-align: center;
  text-decoration: none;
  width: 40px;
  height: 40px;
  transition: all 0.2s 0.3s;
  opacity: 0;
}
.ovrly22:hover .ovrly {
  opacity: 1;
  transform: perspective(400px) rotateY(0deg);
}
.ovrly22:hover .buttons .fa {
  opacity: 1;
}
.ovrly23 {
  width: 100%;
  overflow: hidden;
  position: relative;
  box-shadow: 1px 1px 3px 0 rgba(0, 0, 0, 0.3);
}
.ovrly23 img {
  position: relative;
  width: 100%;
  top: 0;
  left: 0;
}
.ovrly23 .ovrly {
  position: absolute;
  background: rgba(0, 0, 0, 0.5);
  height: 100%;
  left: 0;
  top: 0;
  width: 100%;
  opacity: 0;
  transform: scale(0.5) rotateZ(180deg);
  transition: all 0.3s;
}
.ovrly23 .buttons {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}
.ovrly23 .buttons .fa {
  background: rgb(256, 256, 256);
  box-shadow: 0 0 1px rgba(0, 0, 0, 0.3);
  border-radius: 50%;
  color: rgba(50, 50, 50, 0.9);
  display: inline-block;
  margin: 0 1px;
  line-height: 40px;
  font-size: 16px;
  text-align: center;
  text-decoration: none;
  width: 40px;
  height: 40px;
  transition: all 0.2s 0.3s;
  opacity: 0;
}
.ovrly23:hover .ovrly {
  opacity: 1;
  transform: scale(1) rotateZ(0deg);
}
.ovrly23:hover .buttons .fa {
  opacity: 1;
}
.ovrly24 {
  width: 100%;
  overflow: hidden;
  position: relative;
  box-shadow: 1px 1px 3px 0 rgba(0, 0, 0, 0.3);
}
.ovrly24 img {
  position: relative;
  width: 100%;
  top: 0;
  left: 0;
}
.ovrly24 .ovrly {
  position: absolute;
  height: 100%;
  left: 0;
  top: 0;
  width: 100%;
  transition: all 0.3s;
}
.ovrly24 .buttons {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}
.ovrly24 .buttons .fa {
  background: rgb(256, 256, 256);
  box-shadow: 0 0 1px rgba(0, 0, 0, 0.3);
  border-radius: 50%;
  color: rgba(50, 50, 50, 0.9);
  display: inline-block;
  margin: 0 1px;
  line-height: 40px;
  font-size: 16px;
  text-align: center;
  text-decoration: none;
  width: 40px;
  height: 40px;
  transition: all 0.2s 0.3s;
  opacity: 0;
}
.ovrly24:hover .ovrly {
  box-shadow: 0 0 10px 100px rgba(0, 0, 0, 0.5) inset;
}
.ovrly24:hover .buttons .fa {
  opacity: 1;
}
.ovrly25 {
  width: 100%;
  overflow: hidden;
  position: relative;
  box-shadow: 1px 1px 3px 0 rgba(0, 0, 0, 0.3);
}
.ovrly25 img {
  position: relative;
  width: 100%;
  top: 0;
  left: 0;
}
.ovrly25 .ovrlyT {
  position: absolute;
  background: rgba(0, 0, 0, 0.25);
  height: 100%;
  left: 0;
  top: 0;
  width: 100%;
  transform: scale(0);
  transition: all 0.3s;
}
.ovrly25 .ovrlyB {
  position: absolute;
  background: rgba(0, 0, 0, 0.25);
  height: 100%;
  left: 0;
  top: 0;
  width: 100%;
  transform: scale(0);
  transition: all 0.3s 0.2s;
}
.ovrly25 .buttons {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}
.ovrly25 .buttons .fa {
  background: rgb(256, 256, 256);
  box-shadow: 0 0 1px rgba(0, 0, 0, 0.3);
  border-radius: 50%;
  color: rgba(50, 50, 50, 0.9);
  display: inline-block;
  margin: 0 1px;
  line-height: 40px;
  font-size: 16px;
  text-align: center;
  text-decoration: none;
  width: 40px;
  height: 40px;
  transition: all 0.2s 0.3s;
  opacity: 0;
}
.ovrly25:hover .ovrlyT {
  transform: scale(1);
}
.ovrly25:hover .ovrlyB {
  transform: scale(1);
}
.ovrly25:hover .buttons .fa {
  opacity: 1;
}
.ovrly26 {
  width: 100%;
  overflow: hidden;
  position: relative;
  box-shadow: 1px 1px 3px 0 rgba(0, 0, 0, 0.3);
}
.ovrly26 img {
  position: relative;
  width: 100%;
  top: 0;
  left: 0;
}
.ovrly26 .ovrly {
  border-radius: 50%;
  background: rgba(0, 0, 0, 0.5);
  height: 100%;
  position: absolute;
  width: 100%;
  left: -50%;
  top: -50%;
  transform: scale(0);
  transition: all 0.4s;
}
.ovrly26 .buttons {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}
.ovrly26 .buttons .fa {
  background: rgb(256, 256, 256);
  box-shadow: 0 0 1px rgba(0, 0, 0, 0.3);
  border-radius: 50%;
  color: rgba(50, 50, 50, 0.9);
  display: inline-block;
  margin: 0 1px;
  line-height: 40px;
  font-size: 16px;
  text-align: center;
  text-decoration: none;
  width: 40px;
  height: 40px;
  transition: all 0.2s 0.3s;
  opacity: 0;
}
.ovrly26:hover .ovrly {
  transform: scale(3);
}
.ovrly26:hover .buttons .fa {
  opacity: 1;
}
.ovrly27 {
  width: 100%;
  overflow: hidden;
  position: relative;
  box-shadow: 1px 1px 3px 0 rgba(0, 0, 0, 0.3);
}
.ovrly27 img {
  position: relative;
  width: 100%;
  top: 0;
  left: 0;
}
.ovrly27 .ovrly {
  border-radius: 50%;
  background: rgba(0, 0, 0, 0.5);
  height: 100%;
  position: absolute;
  width: 100%;
  left: 50%;
  top: -50%;
  transform: scale(0);
  transition: all 0.4s;
}
.ovrly27 .buttons {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}
.ovrly27 .buttons .fa {
  background: rgb(256, 256, 256);
  box-shadow: 0 0 1px rgba(0, 0, 0, 0.3);
  border-radius: 50%;
  color: rgba(50, 50, 50, 0.9);
  display: inline-block;
  margin: 0 1px;
  line-height: 40px;
  font-size: 16px;
  text-align: center;
  text-decoration: none;
  width: 40px;
  height: 40px;
  transition: all 0.2s 0.3s;
  opacity: 0;
}
.ovrly27:hover .ovrly {
  transform: scale(3);
}
.ovrly27:hover .buttons .fa {
  opacity: 1;
}
.ovrly28 {
  width: 100%;
  overflow: hidden;
  position: relative;
  box-shadow: 1px 1px 3px 0 rgba(0, 0, 0, 0.3);
}
.ovrly28 img {
  position: relative;
  width: 100%;
  top: 0;
  left: 0;
}
.ovrly28 .ovrly {
  border-radius: 50%;
  background: rgba(0, 0, 0, 0.5);
  height: 100%;
  position: absolute;
  width: 100%;
  left: 50%;
  top: 50%;
  transform: scale(0);
  transition: all 0.4s;
}
.ovrly28 .buttons {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}
.ovrly28 .buttons .fa {
  background: rgb(256, 256, 256);
  box-shadow: 0 0 1px rgba(0, 0, 0, 0.3);
  border-radius: 50%;
  color: rgba(50, 50, 50, 0.9);
  display: inline-block;
  margin: 0 1px;
  line-height: 40px;
  font-size: 16px;
  text-align: center;
  text-decoration: none;
  width: 40px;
  height: 40px;
  transition: all 0.2s 0.3s;
  opacity: 0;
}
.ovrly28:hover .ovrly {
  transform: scale(3);
}
.ovrly28:hover .buttons .fa {
  opacity: 1;
}
.ovrly29 {
  width: 100%;
  overflow: hidden;
  position: relative;
  box-shadow: 1px 1px 3px 0 rgba(0, 0, 0, 0.3);
}
.ovrly29 img {
  position: relative;
  width: 100%;
  top: 0;
  left: 0;
}
.ovrly29 .ovrly {
  border-radius: 50%;
  background: rgba(0, 0, 0, 0.5);
  height: 100%;
  position: absolute;
  width: 100%;
  left: -50%;
  top: 50%;
  transform: scale(0);
  transition: all 0.4s;
}
.ovrly29 .buttons {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}
.ovrly29 .buttons .fa {
  background: rgb(256, 256, 256);
  box-shadow: 0 0 1px rgba(0, 0, 0, 0.3);
  border-radius: 50%;
  color: rgba(50, 50, 50, 0.9);
  display: inline-block;
  margin: 0 1px;
  line-height: 40px;
  font-size: 16px;
  text-align: center;
  text-decoration: none;
  width: 40px;
  height: 40px;
  transition: all 0.2s 0.3s;
  opacity: 0;
}
.ovrly29:hover .ovrly {
  transform: scale(3);
}
.ovrly29:hover .buttons .fa {
  opacity: 1;
}
.ovrly30 {
  width: 100%;
  overflow: hidden;
  position: relative;
  box-shadow: 1px 1px 3px 0 rgba(0, 0, 0, 0.3);
}
.ovrly30 img {
  position: relative;
  width: 100%;
  top: 0;
  left: 0;
}
.ovrly30 .ovrly {
  position: absolute;
  background: rgba(0, 0, 0, 0.5);
  height: 100%;
  width: 100%;
  left: 0;
  top: 0;
  transform: rotateZ(180deg);
  transform-origin: center top;
  transition: all 0.3s;
}
.ovrly30 .buttons {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}
.ovrly30 .buttons .fa {
  background: rgb(256, 256, 256);
  box-shadow: 0 0 1px rgba(0, 0, 0, 0.3);
  border-radius: 50%;
  color: rgba(50, 50, 50, 0.9);
  display: inline-block;
  margin: 0 1px;
  line-height: 40px;
  font-size: 16px;
  text-align: center;
  text-decoration: none;
  width: 40px;
  height: 40px;
  transition: all 0.2s 0.3s;
  opacity: 0;
}
.ovrly30:hover .ovrly {
  transform: rotateZ(0deg);
}
.ovrly30:hover .buttons .fa {
  opacity: 1;
}
.ovrly31 {
  width: 100%;
  overflow: hidden;
  position: relative;
  box-shadow: 1px 1px 3px 0 rgba(0, 0, 0, 0.3);
}
.ovrly31 img {
  position: relative;
  width: 100%;
  top: 0;
  left: 0;
}
.ovrly31 .ovrly {
  position: absolute;
  background: rgba(0, 0, 0, 0.5);
  height: 100%;
  width: 100%;
  left: 0;
  top: 0;
  transform: rotateZ(-180deg);
  transform-origin: center bottom;
  transition: all 0.3s;
}
.ovrly31 .buttons {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}
.ovrly31 .buttons .fa {
  background: rgb(256, 256, 256);
  box-shadow: 0 0 1px rgba(0, 0, 0, 0.3);
  border-radius: 50%;
  color: rgba(50, 50, 50, 0.9);
  display: inline-block;
  margin: 0 1px;
  line-height: 40px;
  font-size: 16px;
  text-align: center;
  text-decoration: none;
  width: 40px;
  height: 40px;
  transition: all 0.2s 0.3s;
  opacity: 0;
}
.ovrly31:hover .ovrly {
  transform: rotateZ(0deg);
}
.ovrly31:hover .buttons .fa {
  opacity: 1;
}
.dtl01 {
  width: 100%;
  position: relative;
  box-shadow: 1px 1px 3px 0 rgba(0, 0, 0, 0.3);
}
.dtl01 img {
  position: relative;
  width: 100%;
  top: 0;
  left: 0;
  transition: all 0.3s;
}
.dtl01 .dtl {
  position: absolute;
  background: #333;
  width: 100%;
  height: 100%;
  top: 0;
  padding: 0 15px 0 15px;
  left: -70%;
  opacity: 0;
  z-index: 1;
  overflow: auto;
  visibility: hidden;
  transition: all 0.3s 0.15s;
}
.dtl01 .dtl h3 {
  color: #fff;
  font-size: 16px;
  font-weight: normal;
  margin-top: 20px;
  margin-bottom: 10px;
  text-decoration: underline;
}
.dtl01 .dtl p {
  font-size: 14px;
  margin-bottom: 10px;
  line-height: 1.2;
  color: #9d9c9c;
  text-indent: 10px;
}
.dtl01 .dtl a {
  background: #fff;
  border: 1px solid #ccc;
  border-radius: 2px;
  color: #333;
  float: right;
  font-size: 13px;
  padding: 2px 5px;
  text-align: center;
  text-decoration: none;
}
.dtl01 .dtl a:hover {
  background: #e6e6e6;
  border: 1px solid #adadad;
}
.dtl01:hover img {
  transform: scale(0);
  opacity: 0;
}
.dtl01:hover .dtl {
  left: 0;
  opacity: 1;
  visibility: visible;
}
.dtl02 {
  width: 100%;
  position: relative;
  box-shadow: 1px 1px 3px 0 rgba(0, 0, 0, 0.3);
}
.dtl02 img {
  position: relative;
  width: 100%;
  top: 0;
  left: 0;
  transition: all 0.3s;
}
.dtl02 .dtl {
  position: absolute;
  background: #333;
  width: 100%;
  height: 100%;
  top: -70%;
  padding: 0 15px 0 15px;
  left: 0;
  opacity: 0;
  z-index: 1;
  overflow: auto;
  visibility: hidden;
  transition: all 0.3s 0.15s;
}
.dtl02 .dtl h3 {
  color: #fff;
  font-size: 16px;
  font-weight: normal;
  margin-top: 20px;
  margin-bottom: 10px;
  text-decoration: underline;
}
.dtl02 .dtl p {
  font-size: 14px;
  margin-bottom: 10px;
  line-height: 1.2;
  color: #9d9c9c;
  text-indent: 10px;
}
.dtl02 .dtl a {
  background: #fff;
  border: 1px solid #ccc;
  border-radius: 2px;
  color: #333;
  float: right;
  font-size: 13px;
  padding: 2px 5px;
  text-align: center;
  text-decoration: none;
}
.dtl02 .dtl a:hover {
  background: #e6e6e6;
  border: 1px solid #adadad;
}
.dtl02:hover img {
  transform: scale(0);
  opacity: 0;
}
.dtl02:hover .dtl {
  top: 0;
  opacity: 1;
  visibility: visible;
}
.dtl03 {
  width: 100%;
  position: relative;
  box-shadow: 1px 1px 3px 0 rgba(0, 0, 0, 0.3);
}
.dtl03 img {
  position: relative;
  width: 100%;
  top: 0;
  left: 0;
  transition: all 0.3s;
}
.dtl03 .dtl {
  position: absolute;
  background: #333;
  width: 100%;
  height: 100%;
  top: 0;
  padding: 0 15px 0 15px;
  left: 70%;
  opacity: 0;
  z-index: 1;
  overflow: auto;
  visibility: hidden;
  transition: all 0.3s 0.15s;
}
.dtl03 .dtl h3 {
  color: #fff;
  font-size: 16px;
  font-weight: normal;
  margin-top: 20px;
  margin-bottom: 10px;
  text-decoration: underline;
}
.dtl03 .dtl p {
  font-size: 14px;
  margin-bottom: 10px;
  line-height: 1.2;
  color: #9d9c9c;
  text-indent: 10px;
}
.dtl03 .dtl a {
  background: #fff;
  border: 1px solid #ccc;
  border-radius: 2px;
  color: #333;
  float: right;
  font-size: 13px;
  padding: 2px 5px;
  text-align: center;
  text-decoration: none;
}
.dtl03 .dtl a:hover {
  background: #e6e6e6;
  border: 1px solid #adadad;
}
.dtl03:hover img {
  transform: scale(0);
  opacity: 0;
}
.dtl03:hover .dtl {
  left: 0;
  opacity: 1;
  visibility: visible;
}
.dtl04 {
  width: 100%;
  position: relative;
  box-shadow: 1px 1px 3px 0 rgba(0, 0, 0, 0.3);
}
.dtl04 img {
  position: relative;
  width: 100%;
  top: 0;
  left: 0;
  transition: all 0.3s;
}
.dtl04 .dtl {
  position: absolute;
  background: #333;
  width: 100%;
  height: 100%;
  top: 70%;
  padding: 0 15px 0 15px;
  left: 0;
  opacity: 0;
  z-index: 1;
  overflow: auto;
  visibility: hidden;
  transition: all 0.3s 0.15s;
}
.dtl04 .dtl h3 {
  color: #fff;
  font-size: 16px;
  font-weight: normal;
  margin-top: 20px;
  margin-bottom: 10px;
  text-decoration: underline;
}
.dtl04 .dtl p {
  font-size: 14px;
  margin-bottom: 10px;
  line-height: 1.2;
  color: #9d9c9c;
  text-indent: 10px;
}
.dtl04 .dtl a {
  background: #fff;
  border: 1px solid #ccc;
  border-radius: 2px;
  color: #333;
  float: right;
  font-size: 13px;
  padding: 2px 5px;
  text-align: center;
  text-decoration: none;
}
.dtl04 .dtl a:hover {
  background: #e6e6e6;
  border: 1px solid #adadad;
}
.dtl04:hover img {
  transform: scale(0);
  opacity: 0;
}
.dtl04:hover .dtl {
  top: 0;
  opacity: 1;
  visibility: visible;
}
.dtl05 {
  width: 100%;
  position: relative;
  overflow: hidden;
  box-shadow: 1px 1px 3px 0 rgba(0, 0, 0, 0.3);
}
.dtl05 img {
  position: relative;
  width: 100%;
  top: 0;
  left: 0;
  transition: all 0.3s;
}
.dtl05 .dtl {
  position: absolute;
  background: #333;
  width: 100%;
  height: 100%;
  top: 0;
  padding: 0 15px 0 15px;
  left: -100%;
  z-index: 1;
  overflow: auto;
  visibility: hidden;
  transition: all 0.3s;
}
.dtl05 .dtl h3 {
  color: #fff;
  font-size: 16px;
  font-weight: normal;
  margin-top: 20px;
  margin-bottom: 10px;
  text-decoration: underline;
}
.dtl05 .dtl p {
  font-size: 14px;
  margin-bottom: 10px;
  line-height: 1.2;
  color: #9d9c9c;
  text-indent: 10px;
}
.dtl05 .dtl a {
  background: #fff;
  border: 1px solid #ccc;
  border-radius: 2px;
  color: #333;
  float: right;
  font-size: 13px;
  padding: 2px 5px;
  text-align: center;
  text-decoration: none;
}
.dtl05 .dtl a:hover {
  background: #e6e6e6;
  border: 1px solid #adadad;
}
.dtl05:hover img {
  left: 100%;
}
.dtl05:hover .dtl {
  left: 0;
  opacity: 1;
  visibility: visible;
}
.dtl06 {
  width: 100%;
  position: relative;
  overflow: hidden;
  box-shadow: 1px 1px 3px 0 rgba(0, 0, 0, 0.3);
}
.dtl06 img {
  position: relative;
  width: 100%;
  top: 0;
  left: 0;
  transition: all 0.3s;
}
.dtl06 .dtl {
  position: absolute;
  background: #333;
  width: 100%;
  height: 100%;
  top: -100%;
  padding: 0 15px 0 15px;
  left: 0;
  z-index: 1;
  overflow: auto;
  visibility: hidden;
  transition: all 0.3s;
}
.dtl06 .dtl h3 {
  color: #fff;
  font-size: 16px;
  font-weight: normal;
  margin-top: 20px;
  margin-bottom: 10px;
  text-decoration: underline;
}
.dtl06 .dtl p {
  font-size: 14px;
  margin-bottom: 10px;
  line-height: 1.2;
  color: #9d9c9c;
  text-indent: 10px;
}
.dtl06 .dtl a {
  background: #fff;
  border: 1px solid #ccc;
  border-radius: 2px;
  color: #333;
  float: right;
  font-size: 13px;
  padding: 2px 5px;
  text-align: center;
  text-decoration: none;
}
.dtl06 .dtl a:hover {
  background: #e6e6e6;
  border: 1px solid #adadad;
}
.dtl06:hover img {
  top: 100%;
}
.dtl06:hover .dtl {
  top: 0;
  opacity: 1;
  visibility: visible;
}
.dtl07 {
  width: 100%;
  position: relative;
  overflow: hidden;
  box-shadow: 1px 1px 3px 0 rgba(0, 0, 0, 0.3);
}
.dtl07 img {
  position: relative;
  width: 100%;
  top: 0;
  left: 0;
  transition: all 0.3s;
}
.dtl07 .dtl {
  position: absolute;
  background: #333;
  width: 100%;
  height: 100%;
  top: 0;
  padding: 0 15px 0 15px;
  left: 100%;
  z-index: 1;
  overflow: auto;
  visibility: hidden;
  transition: all 0.3s;
}
.dtl07 .dtl h3 {
  color: #fff;
  font-size: 16px;
  font-weight: normal;
  margin-top: 20px;
  margin-bottom: 10px;
  text-decoration: underline;
}
.dtl07 .dtl p {
  font-size: 14px;
  margin-bottom: 10px;
  line-height: 1.2;
  color: #9d9c9c;
  text-indent: 10px;
}
.dtl07 .dtl a {
  background: #fff;
  border: 1px solid #ccc;
  border-radius: 2px;
  color: #333;
  float: right;
  font-size: 13px;
  padding: 2px 5px;
  text-align: center;
  text-decoration: none;
}
.dtl07 .dtl a:hover {
  background: #e6e6e6;
  border: 1px solid #adadad;
}
.dtl07:hover img {
  left: -100%;
}
.dtl07:hover .dtl {
  left: 0;
  opacity: 1;
  visibility: visible;
}
.dtl08 {
  width: 100%;
  position: relative;
  overflow: hidden;
  box-shadow: 1px 1px 3px 0 rgba(0, 0, 0, 0.3);
}
.dtl08 img {
  position: relative;
  width: 100%;
  top: 0;
  left: 0;
  transition: all 0.3s;
}
.dtl08 .dtl {
  position: absolute;
  background: #333;
  width: 100%;
  height: 100%;
  top: 100%;
  padding: 0 15px 0 15px;
  left: 0;
  z-index: 1;
  overflow: auto;
  visibility: hidden;
  transition: all 0.3s;
}
.dtl08 .dtl h3 {
  color: #fff;
  font-size: 16px;
  font-weight: normal;
  margin-top: 20px;
  margin-bottom: 10px;
  text-decoration: underline;
}
.dtl08 .dtl p {
  font-size: 14px;
  margin-bottom: 10px;
  line-height: 1.2;
  color: #9d9c9c;
  text-indent: 10px;
}
.dtl08 .dtl a {
  background: #fff;
  border: 1px solid #ccc;
  border-radius: 2px;
  color: #333;
  float: right;
  font-size: 13px;
  padding: 2px 5px;
  text-align: center;
  text-decoration: none;
}
.dtl08 .dtl a:hover {
  background: #e6e6e6;
  border: 1px solid #adadad;
}
.dtl08:hover img {
  top: -100%;
}
.dtl08:hover .dtl {
  top: 0;
  opacity: 1;
  visibility: visible;
}
.dtl09 {
  width: 100%;
  position: relative;
  overflow: hidden;
  box-shadow: 1px 1px 3px 0 rgba(0, 0, 0, 0.3);
}
.dtl09 img {
  position: relative;
  width: 100%;
  top: 0;
  left: 0;
  transition: all 0.3s;
}
.dtl09 .dtl {
  position: absolute;
  background: #333;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  padding: 0 15px;
  visibility: hidden;
  transition: all 0.3s 0.15s;
  transform: scale(0) rotateZ(180deg);
}
.dtl09 .dtl h3 {
  color: #fff;
  font-size: 16px;
  font-weight: normal;
  margin-top: 20px;
  margin-bottom: 10px;
  text-decoration: underline;
}
.dtl09 .dtl p {
  font-size: 14px;
  margin-bottom: 10px;
  line-height: 1.2;
  color: #9d9c9c;
  text-indent: 10px;
}
.dtl09 .dtl a {
  background: #fff;
  border: 1px solid #ccc;
  border-radius: 2px;
  color: #333;
  float: right;
  font-size: 13px;
  padding: 2px 5px;
  text-align: center;
  text-decoration: none;
}
.dtl09 .dtl a:hover {
  background: #e6e6e6;
  border: 1px solid #adadad;
}
.dtl09:hover img {
  transform: scale(0);
  opacity: 0;
}
.dtl09:hover .dtl {
  visibility: visible;
  transform: scale(1) rotateZ(0deg);
}
.dtl10 {
  width: 100%;
  position: relative;
  overflow: hidden;
  box-shadow: 1px 1px 3px 0 rgba(0, 0, 0, 0.3);
}
.dtl10 img {
  position: relative;
  width: 100%;
  top: 0;
  left: 0;
  transition: all 0.3s;
}
.dtl10 .dtl {
  background: #333;
  width: 100%;
  position: absolute;
  height: 100%;
  top: 0;
  left: -100%;
  padding: 0 15px;
  visibility: hidden;
  transition: all 0.3s;
  transform: scale(0.7) rotateZ(180deg);
}
.dtl10 .dtl h3 {
  color: #fff;
  font-size: 16px;
  font-weight: normal;
  margin-top: 20px;
  margin-bottom: 10px;
  text-decoration: underline;
}
.dtl10 .dtl p {
  font-size: 14px;
  margin-bottom: 10px;
  line-height: 1.2;
  color: #9d9c9c;
  text-indent: 10px;
}
.dtl10 .dtl a {
  background: #fff;
  border: 1px solid #ccc;
  border-radius: 2px;
  color: #333;
  float: right;
  font-size: 13px;
  padding: 2px 5px;
  text-align: center;
  text-decoration: none;
}
.dtl10 .dtl a:hover {
  background: #e6e6e6;
  border: 1px solid #adadad;
}
.dtl10:hover img {
  left: 100%;
  transform: scale(0.7) rotateZ(180deg);
}
.dtl10:hover .dtl {
  visibility: visible;
  left: 0;
  transform: scale(1) rotateZ(0deg);
}
.dtl11 {
  width: 100%;
  position: relative;
  overflow: hidden;
  box-shadow: 1px 1px 3px 0 rgba(0, 0, 0, 0.3);
}
.dtl11 img {
  position: relative;
  width: 100%;
  top: 0;
  left: 0;
  transition: all 0.3s;
}
.dtl11 .dtl {
  background: #333;
  width: 100%;
  position: absolute;
  height: 100%;
  top: 0;
  left: 0;
  opacity: 0;
  padding: 0 15px;
  visibility: hidden;
  transition: all 0.3s;
}
.dtl11 .dtl h3 {
  color: #fff;
  font-size: 16px;
  font-weight: normal;
  margin-top: 20px;
  margin-bottom: 10px;
  text-decoration: underline;
}
.dtl11 .dtl p {
  font-size: 14px;
  margin-bottom: 10px;
  line-height: 1.2;
  color: #9d9c9c;
  text-indent: 10px;
}
.dtl11 .dtl a {
  background: #fff;
  border: 1px solid #ccc;
  border-radius: 2px;
  color: #333;
  float: right;
  font-size: 13px;
  padding: 2px 5px;
  text-align: center;
  text-decoration: none;
}
.dtl11 .dtl a:hover {
  background: #e6e6e6;
  border: 1px solid #adadad;
}
.dtl11:hover img {
  opacity: 0;
}
.dtl11:hover .dtl {
  visibility: visible;
  opacity: 1;
}
.dtl12 {
  width: 100%;
  position: relative;
  overflow: hidden;
  box-shadow: 1px 1px 3px 0 rgba(0, 0, 0, 0.3);
}
.dtl12 img {
  position: relative;
  width: 100%;
  top: 0;
  left: 0;
  transition: all 0.3s;
}
.dtl12 .dtl {
  background: #333;
  width: 100%;
  position: absolute;
  height: 100%;
  top: 0;
  left: 0;
  opacity: 0;
  padding: 0 15px;
  visibility: hidden;
  transition: all 0.3s;
}
.dtl12 .dtl h3 {
  color: #fff;
  font-size: 16px;
  font-weight: normal;
  margin-top: 20px;
  margin-bottom: 10px;
  text-decoration: underline;
}
.dtl12 .dtl p {
  font-size: 14px;
  margin-bottom: 10px;
  line-height: 1.2;
  color: #9d9c9c;
  text-indent: 10px;
}
.dtl12 .dtl a {
  background: #fff;
  border: 1px solid #ccc;
  border-radius: 2px;
  color: #333;
  float: right;
  font-size: 13px;
  padding: 2px 5px;
  text-align: center;
  text-decoration: none;
}
.dtl12 .dtl a:hover {
  background: #e6e6e6;
  border: 1px solid #adadad;
}
.dtl12:hover img {
  opacity: 0;
  transform: scale(5);
}
.dtl12:hover .dtl {
  visibility: visible;
  opacity: 1;
}
.dtl13 {
  width: 100%;
  position: relative;
  box-shadow: 1px 1px 3px 0 rgba(0, 0, 0, 0.3);
}
.dtl13 img {
  position: relative;
  width: 100%;
  top: 0;
  left: 0;
}
.dtl13 .dtl {
  background: #333;
  width: 100%;
  position: absolute;
  height: 100%;
  top: 0;
  left: 0;
  opacity: 0;
  padding: 0 15px;
  visibility: hidden;
  transition: all 0.3s;
}
.dtl13 .dtl h3 {
  color: #fff;
  font-size: 16px;
  font-weight: normal;
  margin-top: 20px;
  margin-bottom: 10px;
  text-decoration: underline;
}
.dtl13 .dtl p {
  font-size: 14px;
  margin-bottom: 10px;
  line-height: 1.2;
  color: #9d9c9c;
  text-indent: 10px;
}
.dtl13 .dtl a {
  background: #fff;
  border: 1px solid #ccc;
  border-radius: 2px;
  color: #333;
  float: right;
  font-size: 13px;
  padding: 2px 5px;
  text-align: center;
  text-decoration: none;
}
.dtl13 .dtl a:hover {
  background: #e6e6e6;
  border: 1px solid #adadad;
}
.dtl13:hover .dtl {
  visibility: visible;
  opacity: 1;
  left: 25px;
  top: 25px;
}
.dtl14 {
  width: 100%;
  position: relative;
  box-shadow: 1px 1px 3px 0 rgba(0, 0, 0, 0.3);
}
.dtl14 img {
  position: relative;
  width: 100%;
  top: 0;
  left: 0;
}
.dtl14 .dtl {
  background: #333;
  width: 100%;
  position: absolute;
  height: 100%;
  top: 0;
  left: 0;
  opacity: 0;
  padding: 0 15px;
  visibility: hidden;
  transition: all 0.3s;
}
.dtl14 .dtl h3 {
  color: #fff;
  font-size: 16px;
  font-weight: normal;
  margin-top: 20px;
  margin-bottom: 10px;
  text-decoration: underline;
}
.dtl14 .dtl p {
  font-size: 14px;
  margin-bottom: 10px;
  line-height: 1.2;
  color: #9d9c9c;
  text-indent: 10px;
}
.dtl14 .dtl a {
  background: #fff;
  border: 1px solid #ccc;
  border-radius: 2px;
  color: #333;
  float: right;
  font-size: 13px;
  padding: 2px 5px;
  text-align: center;
  text-decoration: none;
}
.dtl14 .dtl a:hover {
  background: #e6e6e6;
  border: 1px solid #adadad;
}
.dtl14:hover .dtl {
  visibility: visible;
  opacity: 1;
  left: -25px;
  top: 25px;
}
.dtl15 {
  width: 100%;
  position: relative;
  box-shadow: 1px 1px 3px 0 rgba(0, 0, 0, 0.3);
}
.dtl15 img {
  position: relative;
  width: 100%;
  top: 0;
  left: 0;
}
.dtl15 .dtl {
  background: #333;
  width: 100%;
  position: absolute;
  height: 100%;
  top: 0;
  left: 0;
  opacity: 0;
  padding: 0 15px;
  visibility: hidden;
  transition: all 0.3s;
}
.dtl15 .dtl h3 {
  color: #fff;
  font-size: 16px;
  font-weight: normal;
  margin-top: 20px;
  margin-bottom: 10px;
  text-decoration: underline;
}
.dtl15 .dtl p {
  font-size: 14px;
  margin-bottom: 10px;
  line-height: 1.2;
  color: #9d9c9c;
  text-indent: 10px;
}
.dtl15 .dtl a {
  background: #fff;
  border: 1px solid #ccc;
  border-radius: 2px;
  color: #333;
  float: right;
  font-size: 13px;
  padding: 2px 5px;
  text-align: center;
  text-decoration: none;
}
.dtl15 .dtl a:hover {
  background: #e6e6e6;
  border: 1px solid #adadad;
}
.dtl15:hover .dtl {
  visibility: visible;
  opacity: 1;
  left: -25px;
  top: -25px;
}
.dtl16 {
  width: 100%;
  position: relative;
  box-shadow: 1px 1px 3px 0 rgba(0, 0, 0, 0.3);
}
.dtl16 img {
  position: relative;
  width: 100%;
  top: 0;
  left: 0;
}
.dtl16 .dtl {
  background: #333;
  width: 100%;
  position: absolute;
  height: 100%;
  top: 0;
  left: 0;
  opacity: 0;
  padding: 0 15px;
  visibility: hidden;
  transition: all 0.3s;
}
.dtl16 .dtl h3 {
  color: #fff;
  font-size: 16px;
  font-weight: normal;
  margin-top: 20px;
  margin-bottom: 10px;
  text-decoration: underline;
}
.dtl16 .dtl p {
  font-size: 14px;
  margin-bottom: 10px;
  line-height: 1.2;
  color: #9d9c9c;
  text-indent: 10px;
}
.dtl16 .dtl a {
  background: #fff;
  border: 1px solid #ccc;
  border-radius: 2px;
  color: #333;
  float: right;
  font-size: 13px;
  padding: 2px 5px;
  text-align: center;
  text-decoration: none;
}
.dtl16 .dtl a:hover {
  background: #e6e6e6;
  border: 1px solid #adadad;
}
.dtl16:hover .dtl {
  visibility: visible;
  opacity: 1;
  left: 25px;
  top: -25px;
}
.dtl17 {
  width: 100%;
  position: relative;
  overflow: hidden;
  box-shadow: 1px 1px 3px 0 rgba(0, 0, 0, 0.3);
}
.dtl17 img {
  position: relative;
  width: 100%;
  top: 0;
  left: 0;
  transition: all 0.3s;
}
.dtl17 .dtl {
  background: #333;
  width: 100%;
  position: absolute;
  height: 100%;
  top: 0;
  left: -100%;
  opacity: 0;
  padding: 0 15px;
  visibility: hidden;
  transition: all 0.3s;
}
.dtl17 .dtl h3 {
  color: #fff;
  font-size: 16px;
  font-weight: normal;
  margin-top: 20px;
  margin-bottom: 10px;
  text-decoration: underline;
}
.dtl17 .dtl p {
  font-size: 14px;
  margin-bottom: 10px;
  line-height: 1.2;
  color: #9d9c9c;
  text-indent: 10px;
}
.dtl17 .dtl a {
  background: #fff;
  border: 1px solid #ccc;
  border-radius: 2px;
  color: #333;
  float: right;
  font-size: 13px;
  padding: 2px 5px;
  text-align: center;
  text-decoration: none;
}
.dtl17 .dtl a:hover {
  background: #e6e6e6;
  border: 1px solid #adadad;
}
.dtl17:hover .dtl {
  visibility: visible;
  opacity: 1;
  left: 0;
}
.dtl17:hover img {
  opacity: 0;
}
.dtl18 {
  width: 100%;
  position: relative;
  overflow: hidden;
  box-shadow: 1px 1px 3px 0 rgba(0, 0, 0, 0.3);
}
.dtl18 img {
  position: relative;
  width: 100%;
  top: 0;
  left: 0;
  transition: all 0.3s;
}
.dtl18 .dtl {
  background: #333;
  width: 100%;
  position: absolute;
  height: 100%;
  top: -100%;
  left: 0;
  opacity: 0;
  padding: 0 15px;
  visibility: hidden;
  transition: all 0.3s;
}
.dtl18 .dtl h3 {
  color: #fff;
  font-size: 16px;
  font-weight: normal;
  margin-top: 20px;
  margin-bottom: 10px;
  text-decoration: underline;
}
.dtl18 .dtl p {
  font-size: 14px;
  margin-bottom: 10px;
  line-height: 1.2;
  color: #9d9c9c;
  text-indent: 10px;
}
.dtl18 .dtl a {
  background: #fff;
  border: 1px solid #ccc;
  border-radius: 2px;
  color: #333;
  float: right;
  font-size: 13px;
  padding: 2px 5px;
  text-align: center;
  text-decoration: none;
}
.dtl18 .dtl a:hover {
  background: #e6e6e6;
  border: 1px solid #adadad;
}
.dtl18:hover .dtl {
  visibility: visible;
  opacity: 1;
  top: 0;
}
.dtl18:hover img {
  opacity: 0;
}
.dtl19 {
  width: 100%;
  position: relative;
  overflow: hidden;
  box-shadow: 1px 1px 3px 0 rgba(0, 0, 0, 0.3);
}
.dtl19 img {
  position: relative;
  width: 100%;
  top: 0;
  left: 0;
  transition: all 0.3s;
}
.dtl19 .dtl {
  background: #333;
  width: 100%;
  position: absolute;
  height: 100%;
  top: 0;
  left: 100%;
  opacity: 0;
  padding: 0 15px;
  visibility: hidden;
  transition: all 0.3s;
}
.dtl19 .dtl h3 {
  color: #fff;
  font-size: 16px;
  font-weight: normal;
  margin-top: 20px;
  margin-bottom: 10px;
  text-decoration: underline;
}
.dtl19 .dtl p {
  font-size: 14px;
  margin-bottom: 10px;
  line-height: 1.2;
  color: #9d9c9c;
  text-indent: 10px;
}
.dtl19 .dtl a {
  background: #fff;
  border: 1px solid #ccc;
  border-radius: 2px;
  color: #333;
  float: right;
  font-size: 13px;
  padding: 2px 5px;
  text-align: center;
  text-decoration: none;
}
.dtl19 .dtl a:hover {
  background: #e6e6e6;
  border: 1px solid #adadad;
}
.dtl19:hover .dtl {
  visibility: visible;
  opacity: 1;
  left: 0;
}
.dtl19:hover img {
  opacity: 0;
}
.dtl20 {
  width: 100%;
  position: relative;
  overflow: hidden;
  box-shadow: 1px 1px 3px 0 rgba(0, 0, 0, 0.3);
}
.dtl20 img {
  position: relative;
  width: 100%;
  top: 0;
  left: 0;
  transition: all 0.3s;
}
.dtl20 .dtl {
  background: #333;
  width: 100%;
  position: absolute;
  height: 100%;
  top: 100%;
  left: 0;
  opacity: 0;
  padding: 0 15px;
  visibility: hidden;
  transition: all 0.3s;
}
.dtl20 .dtl h3 {
  color: #fff;
  font-size: 16px;
  font-weight: normal;
  margin-top: 20px;
  margin-bottom: 10px;
  text-decoration: underline;
}
.dtl20 .dtl p {
  font-size: 14px;
  margin-bottom: 10px;
  line-height: 1.2;
  color: #9d9c9c;
  text-indent: 10px;
}
.dtl20 .dtl a {
  background: #fff;
  border: 1px solid #ccc;
  border-radius: 2px;
  color: #333;
  float: right;
  font-size: 13px;
  padding: 2px 5px;
  text-align: center;
  text-decoration: none;
}
.dtl20 .dtl a:hover {
  background: #e6e6e6;
  border: 1px solid #adadad;
}
.dtl20:hover .dtl {
  visibility: visible;
  opacity: 1;
  top: 0;
}
.dtl20:hover img {
  opacity: 0;
}
.dtl21 {
  width: 100%;
  position: relative;
  box-shadow: 1px 1px 3px 0 rgba(0, 0, 0, 0.3);
}
.dtl21 img {
  position: relative;
  width: 100%;
  top: 0;
  left: 0;
  transition: all 0.3s;
}
.dtl21 .dtl {
  background: #333;
  width: 100%;
  position: absolute;
  height: 100%;
  top: 0;
  left: 0;
  visibility: hidden;
  padding: 0 15px;
  transform: perspective(600px) rotateY(90deg);
  transition: all 0.3s;
}
.dtl21 .dtl h3,
.dtl21 .dtl p,
.dtl21 .dtl a {
  left: 50px;
  opacity: 0;
  position: relative;
  transition: all 0.3s 0.3s;
}
.dtl21 .dtl h3 {
  color: #fff;
  font-size: 16px;
  font-weight: normal;
  margin-top: 20px;
  margin-bottom: 10px;
  text-decoration: underline;
}
.dtl21 .dtl p {
  font-size: 14px;
  margin-bottom: 10px;
  line-height: 1.2;
  color: #9d9c9c;
  text-indent: 10px;
}
.dtl21 .dtl a {
  background: #fff;
  border: 1px solid #ccc;
  border-radius: 2px;
  color: #333;
  float: right;
  font-size: 13px;
  padding: 2px 5px;
  text-align: center;
  text-decoration: none;
}
.dtl21 .dtl a:hover {
  background: #e6e6e6;
  border: 1px solid #adadad;
}
.dtl21:hover .dtl h3,
.dtl21:hover .dtl p,
.dtl21:hover .dtl a {
  left: 0;
  opacity: 1;
}
.dtl21:hover .dtl {
  visibility: visible;
  transform: perspective(600px) rotateY(0deg);
}
.dtl22 {
  width: 100%;
  position: relative;
  box-shadow: 1px 1px 3px 0 rgba(0, 0, 0, 0.3);
}
.dtl22 img {
  position: relative;
  width: 100%;
  top: 0;
  left: 0;
  transition: all 0.3s;
}
.dtl22 .dtl {
  background: #333;
  width: 100%;
  position: absolute;
  height: 100%;
  top: 0;
  left: 0;
  visibility: hidden;
  padding: 0 15px;
  transform: perspective(600px) rotateX(-90deg);
  transition: all 0.3s;
}
.dtl22 .dtl h3,
.dtl22 .dtl p,
.dtl22 .dtl a {
  top: 50px;
  opacity: 0;
  position: relative;
  transition: all 0.3s 0.3s;
}
.dtl22 .dtl h3 {
  color: #fff;
  font-size: 16px;
  font-weight: normal;
  margin-top: 20px;
  margin-bottom: 10px;
  text-decoration: underline;
}
.dtl22 .dtl p {
  font-size: 14px;
  margin-bottom: 10px;
  line-height: 1.2;
  color: #9d9c9c;
  text-indent: 10px;
}
.dtl22 .dtl a {
  background: #fff;
  border: 1px solid #ccc;
  border-radius: 2px;
  color: #333;
  float: right;
  font-size: 13px;
  padding: 2px 5px;
  text-align: center;
  text-decoration: none;
}
.dtl22 .dtl a:hover {
  background: #e6e6e6;
  border: 1px solid #adadad;
}
.dtl22:hover .dtl h3,
.dtl22:hover .dtl p,
.dtl22:hover .dtl a {
  top: 0;
  opacity: 1;
}
.dtl22:hover .dtl {
  visibility: visible;
  transform: perspective(600px) rotateX(0deg);
}
.dtl23 {
  width: 100%;
  position: relative;
  overflow: hidden;
  box-shadow: 1px 1px 3px 0 rgba(0, 0, 0, 0.3);
}
.dtl23 img {
  position: relative;
  width: 100%;
  top: 0;
  left: 0;
  transition: all 0.3s;
}
.dtl23 .dtl {
  background: #333;
  width: 100%;
  position: absolute;
  height: 100%;
  top: 0;
  left: 0;
  opacity: 0;
  visibility: hidden;
  padding: 0 15px;
  transition: all 0.3s;
}
.dtl23 .dtl h3,
.dtl23 .dtl p,
.dtl23 .dtl a {
  opacity: 0;
  position: relative;
  transition: all 0.3s 0.3s;
}
.dtl23 .dtl h3 {
  left: -100px;
  color: #fff;
  font-size: 16px;
  font-weight: normal;
  margin-top: 20px;
  margin-bottom: 10px;
  text-decoration: underline;
}
.dtl23 .dtl p {
  left: 100px;
  font-size: 14px;
  margin-bottom: 10px;
  line-height: 1.2;
  color: #9d9c9c;
  text-indent: 10px;
}
.dtl23 .dtl a {
  left: 100px;
  background: #fff;
  border: 1px solid #ccc;
  border-radius: 2px;
  color: #333;
  float: right;
  font-size: 13px;
  padding: 2px 5px;
  text-align: center;
  text-decoration: none;
}
.dtl23 .dtl a:hover {
  background: #e6e6e6;
  border: 1px solid #adadad;
}
.dtl23:hover .dtl h3,
.dtl23:hover .dtl p,
.dtl23:hover .dtl a {
  left: 0;
  opacity: 1;
}
.dtl23:hover .dtl {
  visibility: visible;
  opacity: 1;
}
.dtl24 {
  width: 100%;
  position: relative;
  overflow: hidden;
  box-shadow: 1px 1px 3px 0 rgba(0, 0, 0, 0.3);
}
.dtl24 img {
  position: relative;
  width: 100%;
  top: 0;
  left: 0;
  transition: all 0.3s;
}
.dtl24 .dtl {
  background: #333;
  width: 100%;
  position: absolute;
  height: 100%;
  top: 0;
  left: 0;
  opacity: 0;
  visibility: hidden;
  padding: 0 15px;
  transition: all 0.3s;
}
.dtl24 .dtl h3,
.dtl24 .dtl p,
.dtl24 .dtl a {
  opacity: 0;
  position: relative;
  transition: all 0.3s 0.3s;
}
.dtl24 .dtl h3 {
  top: -100px;
  color: #fff;
  font-size: 16px;
  font-weight: normal;
  margin-top: 20px;
  margin-bottom: 10px;
  text-decoration: underline;
}
.dtl24 .dtl p {
  top: 100px;
  font-size: 14px;
  margin-bottom: 10px;
  line-height: 1.2;
  color: #9d9c9c;
  text-indent: 10px;
}
.dtl24 .dtl a {
  top: 100px;
  background: #fff;
  border: 1px solid #ccc;
  border-radius: 2px;
  color: #333;
  float: right;
  font-size: 13px;
  padding: 2px 5px;
  text-align: center;
  text-decoration: none;
}
.dtl24 .dtl a:hover {
  background: #e6e6e6;
  border: 1px solid #adadad;
}
.dtl24:hover .dtl h3,
.dtl24:hover .dtl p,
.dtl24:hover .dtl a {
  top: 0;
  opacity: 1;
}
.dtl24:hover .dtl {
  visibility: visible;
  opacity: 1;
}
.dtl25 {
  width: 100%;
  position: relative;
  overflow: hidden;
  box-shadow: 1px 1px 3px 0 rgba(0, 0, 0, 0.3);
}
.dtl25 img {
  position: relative;
  width: 100%;
  top: 0;
  left: 0;
  transition: all 0.3s;
}
.dtl25 .dtl {
  background: rgba(51, 51, 51, 0.5);
  width: 100%;
  position: absolute;
  height: 100%;
  top: 0;
  left: 0;
  opacity: 0;
  visibility: hidden;
  transition: all 0.3s;
}
.dtl25 .dtl h3,
.dtl25 .dtl p,
.dtl25 .dtl a {
  position: relative;
  top: -100%;
  transition: all 0.3s;
}
.dtl25 .dtl h3 {
  padding: 10px 15px;
  width: 100%;
  background: #333;
  color: #fff;
  font-size: 16px;
  font-weight: normal;
  margin-top: 20px;
  margin-bottom: 10px;
  text-decoration: underline;
  transition-delay: 0.2s;
}
.dtl25 .dtl p {
  padding: 0 15px;
  font-size: 14px;
  margin-bottom: 10px;
  line-height: 1.2;
  color: #fff;
  text-indent: 10px;
  transition-delay: 0.1s;
}
.dtl25 .dtl a {
  margin-right: 15px;
  background: #fff;
  border: 1px solid #ccc;
  border-radius: 2px;
  color: #333;
  float: right;
  font-size: 13px;
  padding: 2px 5px;
  text-align: center;
  text-decoration: none;
}
.dtl25 .dtl a:hover {
  background: #e6e6e6;
  border: 1px solid #adadad;
}
.dtl25:hover .dtl h3,
.dtl25:hover .dtl p,
.dtl25:hover .dtl a {
  top: 0;
}
.dtl25:hover .dtl {
  visibility: visible;
  opacity: 1;
}
.dtl26 {
  width: 100%;
  position: relative;
  overflow: hidden;
  box-shadow: 1px 1px 3px 0 rgba(0, 0, 0, 0.3);
}
.dtl26 img {
  position: relative;
  width: 100%;
  top: 0;
  left: 0;
  transition: all 0.3s;
}
.dtl26 .dtl {
  width: 100%;
  position: absolute;
  height: 100%;
  top: 0;
  left: 0;
  opacity: 0;
  visibility: hidden;
  transition: all 0.3s;
}
.dtl26 .dtl h3,
.dtl26 .dtl p,
.dtl26 .dtl a {
  position: relative;
  top: 100%;
  transition: all 0.3s;
}
.dtl26 .dtl h3 {
  padding: 10px 15px;
  width: 100%;
  color: #fff;
  font-weight: normal;
  margin-top: 160px;
  margin-bottom: -20px;
}
.dtl26 .dtl p {
  padding: 0 8px;
  font-size: 14px;
  margin-bottom: 10px;
  line-height: 1.2;
  color: #fff;
  text-indent: 10px;
  transition-delay: 0.1s;
}
.dtl26 .dtl a {
  margin-right: 15px;
  background: #fff;
  border: 1px solid #ccc;
  border-radius: 2px;
  color: #333;
  float: right;
  font-size: 13px;
  padding: 2px 5px;
  text-align: center;
  transition-delay: 0.2s;
  text-decoration: none;
}
.dtl26 .dtl a:hover {
  background: #e6e6e6;
  border: 1px solid #adadad;
}
.dtl26:hover .dtl h3,
.dtl26:hover .dtl p,
.dtl26:hover .dtl a {
  top: 0;
}
.dtl26:hover .dtl {
  visibility: visible;
  opacity: 1;
}
.dtl27 {
  width: 100%;
  position: relative;
  overflow: hidden;
  box-shadow: 1px 1px 3px 0 rgba(0, 0, 0, 0.3);
}
.dtl27 img {
  position: relative;
  width: 100%;
  top: 0;
  left: 0;
  transition: all 0.3s;
}
.dtl27 .dtl {
  background: rgba(51, 51, 51, 0.5);
  width: 100%;
  position: absolute;
  height: 100%;
  top: 0;
  left: 0;
  opacity: 0;
  visibility: hidden;
  transition: all 0.3s;
}
.dtl27 .dtl h3,
.dtl27 .dtl p,
.dtl27 .dtl a {
  position: relative;
  transition: all 0.3s;
}
.dtl27 .dtl h3 {
  padding: 10px 15px;
  width: 100%;
  left: -100%;
  background: #333;
  color: #fff;
  font-size: 16px;
  font-weight: normal;
  margin-top: 20px;
  margin-bottom: 10px;
  text-decoration: underline;
}
.dtl27 .dtl p {
  padding: 0 15px;
  font-size: 14px;
  margin-bottom: 10px;
  line-height: 1.2;
  color: #fff;
  top: 30px;
  text-indent: 10px;
}
.dtl27 .dtl a {
  margin-right: 15px;
  background: #fff;
  border: 1px solid #ccc;
  border-radius: 2px;
  color: #333;
  top: 30px;
  float: right;
  font-size: 13px;
  padding: 2px 5px;
  text-align: center;
  text-decoration: none;
}
.dtl27 .dtl a:hover {
  background: #e6e6e6;
  border: 1px solid #adadad;
}
.dtl27:hover .dtl h3 {
  left: 0;
}
.dtl27:hover .dtl p,
.dtl27:hover .dtl a {
  top: 0;
}
.dtl27:hover .dtl {
  visibility: visible;
  opacity: 1;
}
.dtl28 {
  width: 100%;
  position: relative;
  overflow: hidden;
  box-shadow: 1px 1px 3px 0 rgba(0, 0, 0, 0.3);
}
.dtl28 img {
  position: relative;
  width: 100%;
  top: 0;
  left: 0;
  transition: all 0.3s;
}
.dtl28 .dtl {
  background: rgba(51, 51, 51, 0.5);
  width: 100%;
  position: absolute;
  height: 100%;
  top: 0;
  left: 0;
  opacity: 0;
  visibility: hidden;
  transition: all 0.3s;
}
.dtl28 .dtl h3,
.dtl28 .dtl p,
.dtl28 .dtl a {
  position: relative;
  transition: all 0.3s;
}
.dtl28 .dtl h3 {
  padding: 10px 15px;
  width: 100%;
  left: -100%;
  background: #333;
  color: #fff;
  font-size: 16px;
  font-weight: normal;
  margin-top: 20px;
  margin-bottom: 10px;
  text-decoration: underline;
}
.dtl28 .dtl p {
  padding: 0 15px;
  font-size: 14px;
  margin-bottom: 10px;
  line-height: 1.2;
  color: #fff;
  left: 100%;
  text-indent: 10px;
}
.dtl28 .dtl a {
  margin-right: 15px;
  background: #fff;
  border: 1px solid #ccc;
  border-radius: 2px;
  color: #333;
  left: 100%;
  float: right;
  font-size: 13px;
  padding: 2px 5px;
  text-align: center;
  text-decoration: none;
}
.dtl28 .dtl a:hover {
  background: #e6e6e6;
  border: 1px solid #adadad;
}
.dtl28:hover .dtl p,
.dtl28:hover .dtl a,
.dtl28:hover .dtl h3 {
  left: 0;
}
.dtl28:hover .dtl {
  visibility: visible;
  opacity: 1;
}
.dtl29 {
  width: 100%;
  position: relative;
  overflow: hidden;
  box-shadow: 1px 1px 3px 0 rgba(0, 0, 0, 0.3);
}
.dtl29 img {
  position: relative;
  width: 100%;
  top: 0;
  left: 0;
  transition: all 0.3s;
}
.dtl29 .dtl {
  background: rgba(51, 51, 51, 0.5);
  width: 100%;
  position: absolute;
  height: 100%;
  top: 0;
  left: 0;
  opacity: 0;
  visibility: hidden;
  transition: all 0.3s;
}
.dtl29 .dtl h3,
.dtl29 .dtl p,
.dtl29 .dtl a {
  position: relative;
  transform: scale(1.5);
  opacity: 0;
  transition: all 0.3s;
}
.dtl29 .dtl h3 {
  padding: 10px 15px;
  width: 100%;
  background: #333;
  color: #fff;
  font-size: 16px;
  font-weight: normal;
  margin-top: 20px;
  margin-bottom: 10px;
  text-decoration: underline;
}
.dtl29 .dtl p {
  padding: 0 15px;
  font-size: 14px;
  margin-bottom: 10px;
  line-height: 1.2;
  color: #fff;
  transition-delay: 0.1s;
  text-indent: 10px;
}
.dtl29 .dtl a {
  margin-right: 15px;
  background: #fff;
  border: 1px solid #ccc;
  border-radius: 2px;
  color: #333;
  float: right;
  transition-delay: 0.2s;
  font-size: 13px;
  padding: 2px 5px;
  text-align: center;
  text-decoration: none;
}
.dtl29 .dtl a:hover {
  background: #e6e6e6;
  border: 1px solid #adadad;
}
.dtl29:hover .dtl p,
.dtl29:hover .dtl a,
.dtl29:hover .dtl h3 {
  opacity: 1;
  transform: scale(1);
}
.dtl29:hover .dtl {
  visibility: visible;
  opacity: 1;
}
.dtl30 {
  width: 100%;
  position: relative;
  overflow: hidden;
  box-shadow: 1px 1px 3px 0 rgba(0, 0, 0, 0.3);
}
.dtl30 img {
  position: relative;
  width: 100%;
  top: 0;
  left: 0;
  transition: all 0.3s;
}
.dtl30 .dtl {
  background: rgba(51, 51, 51, 0.5);
  width: 100%;
  position: absolute;
  height: 100%;
  top: 0;
  left: 0;
  opacity: 0;
  visibility: hidden;
  transition: all 0.3s;
}
.dtl30 .dtl h3,
.dtl30 .dtl p,
.dtl30 .dtl a {
  position: relative;
  transform: scale(0);
  opacity: 0;
  transition: all 0.3s;
}
.dtl30 .dtl h3 {
  padding: 10px 15px;
  width: 100%;
  background: #333;
  color: #fff;
  font-size: 16px;
  font-weight: normal;
  margin-top: 20px;
  margin-bottom: 10px;
  text-decoration: underline;
}
.dtl30 .dtl p {
  padding: 0 15px;
  font-size: 14px;
  margin-bottom: 10px;
  line-height: 1.2;
  color: #fff;
  transition-delay: 0.1s;
  text-indent: 10px;
}
.dtl30 .dtl a {
  margin-right: 15px;
  background: #fff;
  border: 1px solid #ccc;
  border-radius: 2px;
  color: #333;
  float: right;
  transition-delay: 0.2s;
  font-size: 13px;
  padding: 2px 5px;
  text-align: center;
  text-decoration: none;
}
.dtl30 .dtl a:hover {
  background: #e6e6e6;
  border: 1px solid #adadad;
}
.dtl30:hover .dtl p,
.dtl30:hover .dtl a,
.dtl30:hover .dtl h3 {
  opacity: 1;
  transform: scale(1);
}
.dtl30:hover .dtl {
  visibility: visible;
  opacity: 1;
}
.dtl31 {
  width: 100%;
  position: relative;
  overflow: hidden;
  box-shadow: 1px 1px 3px 0 rgba(0, 0, 0, 0.3);
}
.dtl31 img {
  position: relative;
  width: 100%;
  top: 0;
  left: 0;
  transition: all 0.3s;
}
.dtl31 .dtl {
  background: rgba(51, 51, 51, 0.5);
  width: 100%;
  position: absolute;
  height: 100%;
  top: 0;
  left: 0;
  opacity: 0;
  visibility: hidden;
  transition: all 0.3s;
}
.dtl31 .dtl h3 {
  padding: 10px 15px;
  width: 100%;
  background: #333;
  color: #fff;
  font-size: 16px;
  font-weight: normal;
  margin-top: 20px;
  margin-bottom: 10px;
  text-decoration: underline;
}
.dtl31 .dtl p {
  padding: 0 15px;
  font-size: 14px;
  margin-bottom: 10px;
  line-height: 1.2;
  color: #fff;
  text-indent: 10px;
}
.dtl31 .dtl a {
  margin-right: 15px;
  background: #fff;
  border: 1px solid #ccc;
  border-radius: 2px;
  color: #333;
  float: right;
  font-size: 13px;
  padding: 2px 5px;
  text-align: center;
  text-decoration: none;
}
.dtl31 .dtl a:hover {
  background: #e6e6e6;
  border: 1px solid #adadad;
}
.dtl31:hover img {
  transform: scale(1.1);
}
.dtl31:hover .dtl {
  visibility: visible;
  opacity: 1;
}
.dtl32 {
  width: 100%;
  position: relative;
  overflow: hidden;
  box-shadow: 1px 1px 3px 0 rgba(0, 0, 0, 0.3);
}
.dtl32 img {
  position: relative;
  width: 100%;
  top: 0;
  left: 0;
  transform: scale(1.1);
  transition: all 0.3s;
}
.dtl32 .dtl {
  background: rgba(51, 51, 51, 0.5);
  width: 100%;
  position: absolute;
  height: 100%;
  top: 0;
  left: 0;
  opacity: 0;
  visibility: hidden;
  transition: all 0.3s;
}
.dtl32 .dtl h3 {
  padding: 10px 15px;
  width: 100%;
  background: #333;
  color: #fff;
  font-size: 16px;
  font-weight: normal;
  margin-top: 20px;
  margin-bottom: 10px;
  text-decoration: underline;
}
.dtl32 .dtl p {
  padding: 0 15px;
  font-size: 14px;
  margin-bottom: 10px;
  line-height: 1.2;
  color: #fff;
  text-indent: 10px;
}
.dtl32 .dtl a {
  margin-right: 15px;
  background: #fff;
  border: 1px solid #ccc;
  border-radius: 2px;
  color: #333;
  float: right;
  font-size: 13px;
  padding: 2px 5px;
  text-align: center;
  text-decoration: none;
}
.dtl32 .dtl a:hover {
  background: #e6e6e6;
  border: 1px solid #adadad;
}
.dtl32:hover img {
  transform: scale(1);
}
.dtl32:hover .dtl {
  visibility: visible;
  opacity: 1;
}
.cptn01 {
  width: 100%;
  position: relative;
  overflow: hidden;
  box-shadow: 1px 1px 3px 0 rgba(0, 0, 0, 0.3);
}
.cptn01 img {
  width: 100%;
  top: 0;
  left: 0;
  position: relative;
  transition: all 0.3s;
}
.cptn01 .cptn {
  background: #333;
  width: 100%;
  height: 30%;
  top: -30%;
  position: absolute;
  left: 0;
  overflow: auto;
  padding: 0 15px;
  transition: all 0.3s;
}
.cptn01 .cptn div {
  float: left;
  height: 100%;
}
.cptn01 .cptn .fa {
  -wibkit-box-sizing: content-box;
  box-sizing: content-box;
  transition: all 0.3s;
  border: 2px solid #fff;
  -wibkit-border-radius: 50%;
  border-radius: 50%;
  color: #fff;
  display: block;
  float: right;
  height: 30px;
  line-height: 30px;
  text-align: center;
  text-decoration: none;
  width: 30px;
  margin-top: 10px;
  opacity: 0.3;
}
.cptn01 .cptn .fa:hover {
  opacity: 1;
}
.cptn01 .cptn h3 {
  color: #fff;
  margin-top: 10px;
  margin-bottom: 0;
  font-size: 16px;
  font-weight: normal;
}
.cptn01 .cptn p {
  color: #6a6a6a;
  font-size: 14px;
}
.cptn01:hover img {
  top: 30%;
}
.cptn01:hover .cptn {
  top: 0;
}
.cptn02 {
  width: 100%;
  position: relative;
  overflow: hidden;
  box-shadow: 1px 1px 3px 0 rgba(0, 0, 0, 0.3);
}
.cptn02 img {
  width: 100%;
  right: 0;
  top: 0;
  position: relative;
  transition: all 0.3s;
}
.cptn02 .cptn {
  background: #333;
  width: 40%;
  height: 100%;
  top: 0;
  transition: all 0.3s;
  position: absolute;
  right: -40%;
  overflow: auto;
  padding: 0 10px;
}
.cptn02 .cptn .fa {
  -wibkit-box-sizing: content-box;
  box-sizing: content-box;
  transition: all 0.3s;
  border: 2px solid #fff;
  -wibkit-border-radius: 50%;
  border-radius: 50%;
  color: #fff;
  display: block;
  height: 30px;
  position: relative;
  top: 30%;
  transform: translateY(-50%);
  line-height: 30px;
  margin: auto;
  text-align: center;
  text-decoration: none;
  width: 30px;
  opacity: 0.3;
}
.cptn02 .cptn .fa:hover {
  opacity: 1;
}
.cptn02 .cptn h3 {
  color: #fff;
  margin-top: 10px;
  margin-bottom: 0;
  font-size: 16px;
  font-weight: normal;
}
.cptn02 .cptn p {
  color: #6a6a6a;
  font-size: 14px;
}
.cptn02:hover img {
  right: 40%;
}
.cptn02:hover .cptn {
  right: 0;
}
.cptn03 {
  width: 100%;
  position: relative;
  overflow: hidden;
  box-shadow: 1px 1px 3px 0 rgba(0, 0, 0, 0.3);
}
.cptn03 img {
  width: 100%;
  left: 0;
  bottom: 0;
  position: relative;
  transition: all 0.3s;
}
.cptn03 .cptn {
  background: #333;
  width: 100%;
  height: 30%;
  bottom: -30%;
  position: absolute;
  left: 0;
  overflow: auto;
  padding: 0 15px;
  transition: all 0.3s;
}
.cptn03 .cptn div {
  float: left;
  height: 100%;
}
.cptn03 .cptn .fa {
  -wibkit-box-sizing: content-box;
  box-sizing: content-box;
  transition: all 0.3s;
  border: 2px solid #fff;
  -wibkit-border-radius: 50%;
  border-radius: 50%;
  color: #fff;
  display: block;
  float: right;
  height: 30px;
  line-height: 30px;
  text-align: center;
  text-decoration: none;
  width: 30px;
  margin-top: 10px;
  opacity: 0.3;
}
.cptn03 .cptn .fa:hover {
  opacity: 1;
}
.cptn03 .cptn h3 {
  color: #fff;
  margin-top: 10px;
  margin-bottom: 0;
  font-size: 16px;
  font-weight: normal;
}
.cptn03 .cptn p {
  color: #6a6a6a;
  font-size: 14px;
}
.cptn03:hover img {
  bottom: 30%;
}
.cptn03:hover .cptn {
  bottom: 0;
}
.cptn04 {
  width: 100%;
  position: relative;
  overflow: hidden;
  box-shadow: 1px 1px 3px 0 rgba(0, 0, 0, 0.3);
}
.cptn04 img {
  width: 100%;
  left: 0;
  top: 0;
  position: relative;
  transition: all 0.3s;
}
.cptn04 .cptn {
  background: #333;
  width: 40%;
  height: 100%;
  top: 0;
  transition: all 0.3s;
  position: absolute;
  left: -40%;
  overflow: auto;
  padding: 0 10px;
}
.cptn04 .cptn .fa {
  -wibkit-box-sizing: content-box;
  box-sizing: content-box;
  transition: all 0.3s;
  border: 2px solid #fff;
  -wibkit-border-radius: 50%;
  border-radius: 50%;
  color: #fff;
  display: block;
  height: 30px;
  position: relative;
  top: 30%;
  transform: translateY(-50%);
  line-height: 30px;
  margin: auto;
  text-align: center;
  text-decoration: none;
  width: 30px;
  opacity: 0.3;
}
.cptn04 .cptn .fa:hover {
  opacity: 1;
}
.cptn04 .cptn h3 {
  color: #fff;
  margin-top: 10px;
  margin-bottom: 0;
  font-size: 16px;
  font-weight: normal;
}
.cptn04 .cptn p {
  color: #6a6a6a;
  font-size: 14px;
}
.cptn04:hover img {
  left: 40%;
}
.cptn04:hover .cptn {
  left: 0;
}
.cptn05 {
  width: 100%;
  position: relative;
  box-shadow: 1px 1px 3px 0 rgba(0, 0, 0, 0.3);
}
.cptn05 img {
  width: 100%;
  left: 0;
  top: 0;
  z-index: 1;
  position: relative;
  transition: all 0.3s;
}
.cptn05 .cptn {
  background: #333;
  width: 100%;
  height: 30%;
  top: 0;
  position: absolute;
  left: 0;
  overflow: auto;
  padding: 0 15px;
  transition: all 0.3s;
}
.cptn05 .cptn div {
  float: left;
  height: 100%;
}
.cptn05 .cptn .fa {
  -wibkit-box-sizing: content-box;
  box-sizing: content-box;
  transition: all 0.3s;
  border: 2px solid #fff;
  -wibkit-border-radius: 50%;
  border-radius: 50%;
  color: #fff;
  display: block;
  float: right;
  height: 30px;
  line-height: 30px;
  text-align: center;
  text-decoration: none;
  width: 30px;
  margin-top: 10px;
  opacity: 0.3;
}
.cptn05 .cptn .fa:hover {
  opacity: 1;
}
.cptn05 .cptn h3 {
  color: #fff;
  margin-top: 10px;
  margin-bottom: 0;
  font-size: 16px;
  font-weight: normal;
}
.cptn05 .cptn p {
  color: #6a6a6a;
  font-size: 14px;
}
.cptn05:hover img {
  top: 30%;
}
.cptn06 {
  width: 100%;
  position: relative;
  box-shadow: 1px 1px 3px 0 rgba(0, 0, 0, 0.3);
}
.cptn06 img {
  width: 100%;
  left: 0;
  bottom: 0;
  z-index: 1;
  position: relative;
  transition: all 0.3s;
}
.cptn06 .cptn {
  background: #333;
  width: 100%;
  height: 30%;
  bottom: 0;
  position: absolute;
  left: 0;
  overflow: auto;
  padding: 0 15px;
  transition: all 0.3s;
}
.cptn06 .cptn div {
  float: left;
  height: 100%;
}
.cptn06 .cptn .fa {
  -wibkit-box-sizing: content-box;
  box-sizing: content-box;
  transition: all 0.3s;
  border: 2px solid #fff;
  -wibkit-border-radius: 50%;
  border-radius: 50%;
  color: #fff;
  display: block;
  float: right;
  height: 30px;
  line-height: 30px;
  text-align: center;
  text-decoration: none;
  width: 30px;
  margin-top: 10px;
  opacity: 0.3;
}
.cptn06 .cptn .fa:hover {
  opacity: 1;
}
.cptn06 .cptn h3 {
  color: #fff;
  margin-top: 10px;
  margin-bottom: 0;
  font-size: 16px;
  font-weight: normal;
}
.cptn06 .cptn p {
  color: #6a6a6a;
  font-size: 14px;
}
.cptn06:hover img {
  bottom: 30%;
}
.cptn07 {
  width: 100%;
  position: relative;
  overflow: hidden;
  box-shadow: 1px 1px 3px 0 rgba(0, 0, 0, 0.3);
}
.cptn07 img {
  width: 100%;
  left: 0;
  bottom: 0;
  position: relative;
  transition: all 0.3s;
}
.cptn07 .cptn {
  background: #333;
  width: 100%;
  height: 30%;
  top: 0;
  position: absolute;
  left: 0;
  transform-origin: top;
  transform: perspective(600px) rotateX(-90deg);
  overflow: auto;
  padding: 0 15px;
  opacity: 0;
  transition: all 0.3s;
}
.cptn07 .cptn div {
  float: left;
  height: 100%;
}
.cptn07 .cptn .fa {
  -wibkit-box-sizing: content-box;
  box-sizing: content-box;
  transition: all 0.3s;
  border: 2px solid #fff;
  -wibkit-border-radius: 50%;
  border-radius: 50%;
  color: #fff;
  display: block;
  float: right;
  height: 30px;
  line-height: 30px;
  text-align: center;
  text-decoration: none;
  width: 30px;
  margin-top: 10px;
  opacity: 0.3;
}
.cptn07 .cptn .fa:hover {
  opacity: 1;
}
.cptn07 .cptn h3 {
  color: #fff;
  margin-top: 10px;
  margin-bottom: 0;
  font-size: 16px;
  font-weight: normal;
}
.cptn07 .cptn p {
  color: #6a6a6a;
  font-size: 14px;
}
.cptn07:hover .cptn {
  opacity: 1;
  transform: perspective(600px) rotateX(0deg);
}
.cptn08 {
  width: 100%;
  position: relative;
  overflow: hidden;
  box-shadow: 1px 1px 3px 0 rgba(0, 0, 0, 0.3);
}
.cptn08 img {
  width: 100%;
  left: 0;
  top: 0;
  position: relative;
}
.cptn08 .cptn {
  background: #333;
  width: 40%;
  height: 100%;
  top: 0;
  opacity: 0;
  transform-origin: right;
  transform: perspective(600px) rotateY(-90deg);
  transition: all 0.3s;
  position: absolute;
  right: 0;
  overflow: auto;
  padding: 0 10px;
}
.cptn08 .cptn .fa {
  -wibkit-box-sizing: content-box;
  box-sizing: content-box;
  transition: all 0.3s;
  border: 2px solid #fff;
  -wibkit-border-radius: 50%;
  border-radius: 50%;
  color: #fff;
  display: block;
  height: 30px;
  position: relative;
  top: 30%;
  transform: translateY(-50%);
  line-height: 30px;
  margin: auto;
  text-align: center;
  text-decoration: none;
  width: 30px;
  opacity: 0.3;
}
.cptn08 .cptn .fa:hover {
  opacity: 1;
}
.cptn08 .cptn h3 {
  color: #fff;
  margin-top: 10px;
  margin-bottom: 0;
  font-size: 16px;
  font-weight: normal;
}
.cptn08 .cptn p {
  color: #6a6a6a;
  font-size: 14px;
}
.cptn08:hover .cptn {
  opacity: 1;
  transform: perspective(600px) rotateY(0deg);
}
.cptn09 {
  width: 100%;
  position: relative;
  overflow: hidden;
  box-shadow: 1px 1px 3px 0 rgba(0, 0, 0, 0.3);
}
.cptn09 img {
  width: 100%;
  left: 0;
  bottom: 0;
  position: relative;
  transition: all 0.3s;
}
.cptn09 .cptn {
  background: #333;
  width: 100%;
  height: 30%;
  bottom: 0;
  position: absolute;
  left: 0;
  transform-origin: bottom;
  transform: perspective(600px) rotateX(90deg);
  overflow: auto;
  padding: 0 15px;
  opacity: 0;
  transition: all 0.3s;
}
.cptn09 .cptn div {
  float: left;
  height: 100%;
}
.cptn09 .cptn .fa {
  -wibkit-box-sizing: content-box;
  box-sizing: content-box;
  transition: all 0.3s;
  border: 2px solid #fff;
  -wibkit-border-radius: 50%;
  border-radius: 50%;
  color: #fff;
  display: block;
  float: right;
  height: 30px;
  line-height: 30px;
  text-align: center;
  text-decoration: none;
  width: 30px;
  margin-top: 10px;
  opacity: 0.3;
}
.cptn09 .cptn .fa:hover {
  opacity: 1;
}
.cptn09 .cptn h3 {
  color: #fff;
  margin-top: 10px;
  margin-bottom: 0;
  font-size: 16px;
  font-weight: normal;
}
.cptn09 .cptn p {
  color: #6a6a6a;
  font-size: 14px;
}
.cptn09:hover .cptn {
  opacity: 1;
  transform: perspective(600px) rotateX(0deg);
}
.cptn10 {
  width: 100%;
  position: relative;
  overflow: hidden;
  box-shadow: 1px 1px 3px 0 rgba(0, 0, 0, 0.3);
}
.cptn10 img {
  width: 100%;
  left: 0;
  top: 0;
  position: relative;
}
.cptn10 .cptn {
  background: #333;
  width: 40%;
  height: 100%;
  top: 0;
  opacity: 0;
  transform-origin: left;
  transform: perspective(600px) rotateY(90deg);
  transition: all 0.3s;
  position: absolute;
  left: 0;
  overflow: auto;
  padding: 0 10px;
}
.cptn10 .cptn .fa {
  -wibkit-box-sizing: content-box;
  box-sizing: content-box;
  transition: all 0.3s;
  border: 2px solid #fff;
  -wibkit-border-radius: 50%;
  border-radius: 50%;
  color: #fff;
  display: block;
  height: 30px;
  position: relative;
  top: 30%;
  transform: translateY(-50%);
  line-height: 30px;
  margin: auto;
  text-align: center;
  text-decoration: none;
  width: 30px;
  opacity: 0.3;
}
.cptn10 .cptn .fa:hover {
  opacity: 1;
}
.cptn10 .cptn h3 {
  color: #fff;
  margin-top: 10px;
  margin-bottom: 0;
  font-size: 16px;
  font-weight: normal;
}
.cptn10 .cptn p {
  color: #6a6a6a;
  font-size: 14px;
}
.cptn10:hover .cptn {
  opacity: 1;
  transform: perspective(600px) rotateY(0deg);
}
.cptn11 {
  width: 100%;
  position: relative;
  overflow: hidden;
  box-shadow: 1px 1px 3px 0 rgba(0, 0, 0, 0.3);
}
.cptn11 img {
  width: 100%;
  left: 0;
  bottom: 0;
  position: relative;
  transition: all 0.3s;
}
.cptn11 .ovrly {
  width: 100%;
  height: 100%;
  top: -100%;
  left: 0;
  position: absolute;
  background: rgba(0, 0, 0, 0.5);
  transition: all 0.3s;
}
.cptn11 .ovrly .cptn {
  background: #333;
  width: 100%;
  height: 30%;
  bottom: 10px;
  position: absolute;
  left: 0;
  overflow: auto;
  padding: 0 15px;
}
.cptn11 .ovrly .cptn div {
  float: left;
  height: 100%;
}
.cptn11 .ovrly .cptn .fa {
  -wibkit-box-sizing: content-box;
  box-sizing: content-box;
  transition: all 0.3s;
  border: 2px solid #fff;
  -wibkit-border-radius: 50%;
  border-radius: 50%;
  color: #fff;
  display: block;
  float: right;
  height: 30px;
  line-height: 30px;
  text-align: center;
  text-decoration: none;
  width: 30px;
  margin-top: 10px;
  opacity: 0.3;
}
.cptn11 .ovrly .cptn .fa:hover {
  opacity: 1;
}
.cptn11 .ovrly .cptn h3 {
  color: #fff;
  margin-top: 10px;
  margin-bottom: 0;
  font-size: 16px;
  font-weight: normal;
}
.cptn11 .ovrly .cptn p {
  color: #6a6a6a;
  font-size: 14px;
}
.cptn11:hover .ovrly {
  top: 0;
}
.cptn12 {
  width: 100%;
  position: relative;
  overflow: hidden;
  box-shadow: 1px 1px 3px 0 rgba(0, 0, 0, 0.3);
}
.cptn12 img {
  width: 100%;
  left: 0;
  bottom: 0;
  position: relative;
  transition: all 0.3s;
}
.cptn12 .ovrly {
  width: 100%;
  height: 100%;
  top: 0;
  left: 100%;
  position: absolute;
  background: rgba(0, 0, 0, 0.5);
  transition: all 0.3s;
}
.cptn12 .ovrly .cptn {
  background: #333;
  width: 40%;
  height: 100%;
  top: 0;
  position: absolute;
  left: 10px;
  overflow: auto;
  padding: 0 10px;
}
.cptn12 .ovrly .cptn .fa {
  -wibkit-box-sizing: content-box;
  box-sizing: content-box;
  transition: all 0.3s;
  border: 2px solid #fff;
  -wibkit-border-radius: 50%;
  border-radius: 50%;
  color: #fff;
  display: block;
  height: 30px;
  position: relative;
  top: 30%;
  transform: translateY(-50%);
  line-height: 30px;
  margin: auto;
  text-align: center;
  text-decoration: none;
  width: 30px;
  opacity: 0.3;
}
.cptn12 .ovrly .cptn .fa:hover {
  opacity: 1;
}
.cptn12 .ovrly .cptn h3 {
  color: #fff;
  margin-top: 10px;
  margin-bottom: 0;
  font-size: 16px;
  font-weight: normal;
}
.cptn12 .ovrly .cptn p {
  color: #6a6a6a;
  font-size: 14px;
}
.cptn12:hover .ovrly {
  left: 0;
}
.cptn13 {
  width: 100%;
  position: relative;
  overflow: hidden;
  box-shadow: 1px 1px 3px 0 rgba(0, 0, 0, 0.3);
}
.cptn13 img {
  width: 100%;
  left: 0;
  bottom: 0;
  position: relative;
  transition: all 0.3s;
}
.cptn13 .ovrly {
  width: 100%;
  height: 100%;
  top: 100%;
  left: 0;
  position: absolute;
  background: rgba(0, 0, 0, 0.5);
  transition: all 0.3s;
}
.cptn13 .ovrly .cptn {
  background: #333;
  width: 100%;
  height: 30%;
  top: 10px;
  position: absolute;
  left: 0;
  overflow: auto;
  padding: 0 15px;
}
.cptn13 .ovrly .cptn div {
  float: left;
  height: 100%;
}
.cptn13 .ovrly .cptn .fa {
  -wibkit-box-sizing: content-box;
  box-sizing: content-box;
  transition: all 0.3s;
  border: 2px solid #fff;
  -wibkit-border-radius: 50%;
  border-radius: 50%;
  color: #fff;
  display: block;
  float: right;
  height: 30px;
  line-height: 30px;
  text-align: center;
  text-decoration: none;
  width: 30px;
  margin-top: 10px;
  opacity: 0.3;
}
.cptn13 .ovrly .cptn .fa:hover {
  opacity: 1;
}
.cptn13 .ovrly .cptn h3 {
  color: #fff;
  margin-top: 10px;
  margin-bottom: 0;
  font-size: 16px;
  font-weight: normal;
}
.cptn13 .ovrly .cptn p {
  color: #6a6a6a;
  font-size: 14px;
}
.cptn13:hover .ovrly {
  top: 0;
}
.cptn14 {
  width: 100%;
  position: relative;
  overflow: hidden;
  box-shadow: 1px 1px 3px 0 rgba(0, 0, 0, 0.3);
}
.cptn14 img {
  width: 100%;
  left: 0;
  bottom: 0;
  position: relative;
  transition: all 0.3s;
}
.cptn14 .ovrly {
  width: 100%;
  height: 100%;
  top: 0;
  left: -100%;
  position: absolute;
  background: rgba(0, 0, 0, 0.5);
  transition: all 0.3s;
}
.cptn14 .ovrly .cptn {
  background: #333;
  width: 40%;
  height: 100%;
  top: 0;
  position: absolute;
  right: 10px;
  overflow: auto;
  padding: 0 10px;
}
.cptn14 .ovrly .cptn .fa {
  -wibkit-box-sizing: content-box;
  box-sizing: content-box;
  transition: all 0.3s;
  border: 2px solid #fff;
  -wibkit-border-radius: 50%;
  border-radius: 50%;
  color: #fff;
  display: block;
  height: 30px;
  position: relative;
  top: 30%;
  transform: translateY(-50%);
  line-height: 30px;
  margin: auto;
  text-align: center;
  text-decoration: none;
  width: 30px;
  opacity: 0.3;
}
.cptn14 .ovrly .cptn .fa:hover {
  opacity: 1;
}
.cptn14 .ovrly .cptn h3 {
  color: #fff;
  margin-top: 10px;
  margin-bottom: 0;
  font-size: 16px;
  font-weight: normal;
}
.cptn14 .ovrly .cptn p {
  color: #6a6a6a;
  font-size: 14px;
}
.cptn14:hover .ovrly {
  left: 0;
}
.cptn15 {
  width: 100%;
  position: relative;
  overflow: hidden;
  box-shadow: 1px 1px 3px 0 rgba(0, 0, 0, 0.3);
}
.cptn15 img {
  width: 100%;
  left: 0;
  bottom: 0;
  position: relative;
  transition: all 0.3s;
}
.cptn15 .cptn {
  background: #333;
  width: 100%;
  height: 30%;
  bottom: 10px;
  position: absolute;
  left: 0;
  overflow: auto;
  padding: 0 15px;
  transform: scale(0.5);
  opacity: 0;
  transition: all 0.3s;
}
.cptn15 .cptn div {
  float: left;
  height: 100%;
}
.cptn15 .cptn .fa {
  -wibkit-box-sizing: content-box;
  box-sizing: content-box;
  transition: all 0.3s;
  border: 2px solid #fff;
  -wibkit-border-radius: 50%;
  border-radius: 50%;
  color: #fff;
  display: block;
  float: right;
  height: 30px;
  line-height: 30px;
  text-align: center;
  text-decoration: none;
  width: 30px;
  margin-top: 10px;
  opacity: 0.3;
}
.cptn15 .cptn .fa:hover {
  opacity: 1;
}
.cptn15 .cptn h3 {
  color: #fff;
  margin-top: 10px;
  margin-bottom: 0;
  font-size: 16px;
  font-weight: normal;
}
.cptn15 .cptn p {
  color: #6a6a6a;
  font-size: 14px;
}
.cptn15:hover .cptn {
  transform: scale(1);
  opacity: 1;
}
.cptn16 {
  width: 100%;
  position: relative;
  overflow: hidden;
  box-shadow: 1px 1px 3px 0 rgba(0, 0, 0, 0.3);
}
.cptn16 img {
  width: 100%;
  left: 0;
  bottom: 0;
  position: relative;
  transition: all 0.3s;
}
.cptn16 .cptn {
  background: #333;
  width: 100%;
  height: 30%;
  bottom: 10px;
  position: absolute;
  left: 0;
  overflow: auto;
  padding: 0 15px;
  transform: scale(1.5);
  opacity: 0;
  transition: all 0.3s;
}
.cptn16 .cptn div {
  float: left;
  height: 100%;
}
.cptn16 .cptn .fa {
  -wibkit-box-sizing: content-box;
  box-sizing: content-box;
  transition: all 0.3s;
  border: 2px solid #fff;
  -wibkit-border-radius: 50%;
  border-radius: 50%;
  color: #fff;
  display: block;
  float: right;
  height: 30px;
  line-height: 30px;
  text-align: center;
  text-decoration: none;
  width: 30px;
  margin-top: 10px;
  opacity: 0.3;
}
.cptn16 .cptn .fa:hover {
  opacity: 1;
}
.cptn16 .cptn h3 {
  color: #fff;
  margin-top: 10px;
  margin-bottom: 0;
  font-size: 16px;
  font-weight: normal;
}
.cptn16 .cptn p {
  color: #6a6a6a;
  font-size: 14px;
}
.cptn16:hover .cptn {
  transform: scale(1);
  opacity: 1;
}
.cptn17 {
  width: 100%;
  position: relative;
  overflow: hidden;
  box-shadow: 1px 1px 3px 0 rgba(0, 0, 0, 0.3);
}
.cptn17 img {
  width: 100%;
  left: 0;
  bottom: 0;
  position: relative;
  transition: all 0.3s;
}
.cptn17 .cptn {
  background: #333;
  width: 100%;
  height: 30%;
  bottom: 10px;
  position: absolute;
  left: -50%;
  overflow: auto;
  padding: 0 15px;
  opacity: 0;
  transition: all 0.3s;
}
.cptn17 .cptn div {
  float: left;
  height: 100%;
}
.cptn17 .cptn .fa {
  -wibkit-box-sizing: content-box;
  box-sizing: content-box;
  transition: all 0.3s;
  border: 2px solid #fff;
  -wibkit-border-radius: 50%;
  border-radius: 50%;
  color: #fff;
  display: block;
  float: right;
  height: 30px;
  line-height: 30px;
  text-align: center;
  text-decoration: none;
  width: 30px;
  margin-top: 10px;
  opacity: 0.3;
}
.cptn17 .cptn .fa:hover {
  opacity: 1;
}
.cptn17 .cptn h3 {
  color: #fff;
  margin-top: 10px;
  margin-bottom: 0;
  font-size: 16px;
  font-weight: normal;
}
.cptn17 .cptn p {
  color: #6a6a6a;
  font-size: 14px;
}
.cptn17:hover .cptn {
  left: 0;
  opacity: 1;
}
.cptn18 {
  width: 100%;
  position: relative;
  overflow: hidden;
  box-shadow: 1px 1px 3px 0 rgba(0, 0, 0, 0.3);
}
.cptn18 img {
  width: 100%;
  left: 0;
  bottom: 0;
  position: relative;
  transition: all 0.3s;
}
.cptn18 .cptn {
  background: #333;
  width: 100%;
  height: 30%;
  bottom: -30%;
  position: absolute;
  left: 0;
  overflow: auto;
  padding: 0 15px;
  opacity: 0;
  transition: all 0.3s;
}
.cptn18 .cptn div {
  float: left;
  height: 100%;
}
.cptn18 .cptn .fa {
  -wibkit-box-sizing: content-box;
  box-sizing: content-box;
  transition: all 0.3s;
  border: 2px solid #fff;
  -wibkit-border-radius: 50%;
  border-radius: 50%;
  color: #fff;
  display: block;
  float: right;
  height: 30px;
  line-height: 30px;
  text-align: center;
  text-decoration: none;
  width: 30px;
  margin-top: 10px;
  opacity: 0.3;
}
.cptn18 .cptn .fa:hover {
  opacity: 1;
}
.cptn18 .cptn h3 {
  color: #fff;
  margin-top: 10px;
  margin-bottom: 0;
  font-size: 16px;
  font-weight: normal;
}
.cptn18 .cptn p {
  color: #6a6a6a;
  font-size: 14px;
}
.cptn18:hover .cptn {
  bottom: 10px;
  opacity: 1;
}
.image01 {
  width: 100%;
  overflow: hidden;
  position: relative;
  box-shadow: 1px 1px 3px 0 rgba(0, 0, 0, 0.3);
}
.image01 img {
  width: 100%;
  top: 0;
  position: relative;
  left: 0;
  transform: scale(1.1);
  transition: transform 0.3s;
}
.image01 .ovrly {
  background: rgba(0, 0, 0, 0.5);
  height: 100%;
  left: 0;
  top: 0;
  position: absolute;
  width: 100%;
  opacity: 0;
  transition: all 0.3s;
}
.image01 .buttons {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}
.image01 .buttons .fa {
  background: rgb(256, 256, 256);
  box-shadow: 0 0 1px rgba(0, 0, 0, 0.3);
  border-radius: 50%;
  color: rgba(50, 50, 50, 0.9);
  display: inline-block;
  margin: 0 1px;
  line-height: 40px;
  font-size: 16px;
  text-align: center;
  text-decoration: none;
  width: 40px;
  height: 40px;
  transition: all 0.3s;
  opacity: 0;
}
.image01:hover img {
  filter: blur(2px);
  transform: scale(1);
}
.image01:hover .ovrly {
  opacity: 1;
}
.image01:hover .buttons .fa {
  opacity: 1;
}
.image02 {
  width: 100%;
  overflow: hidden;
  position: relative;
  box-shadow: 1px 1px 3px 0 rgba(0, 0, 0, 0.3);
}
.image02 img {
  width: 100%;
  top: 0;
  position: relative;
  left: 0;
  transform: scale(1.1);
  transition: transform 0.3s;
}
.image02 .buttons {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}
.image02 .buttons .fa {
  background: rgb(256, 256, 256);
  box-shadow: 0 0 1px rgba(0, 0, 0, 0.3);
  border-radius: 50%;
  color: rgba(50, 50, 50, 0.9);
  display: inline-block;
  margin: 0 1px;
  line-height: 40px;
  font-size: 16px;
  text-align: center;
  text-decoration: none;
  width: 40px;
  height: 40px;
  transition: all 0.3s;
  opacity: 0;
}
.image02:hover img {
  filter: grayscale(100%);
}
.image02:hover .ovrly {
  opacity: 1;
}
.image02:hover .buttons .fa {
  opacity: 1;
}
.image03 {
  width: 100%;
  overflow: hidden;
  position: relative;
  box-shadow: 1px 1px 3px 0 rgba(0, 0, 0, 0.3);
}
.image03 img {
  width: 100%;
  top: 0;
  position: relative;
  left: 0;
  transition: transform 0.3s;
}
.image03 .ovrly {
  background: rgba(0, 0, 0, 0.5);
  height: 100%;
  left: 0;
  top: 0;
  position: absolute;
  width: 100%;
  opacity: 0;
  transition: all 0.3s;
}
.image03 .buttons {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}
.image03 .buttons .fa {
  background: rgb(256, 256, 256);
  box-shadow: 0 0 1px rgba(0, 0, 0, 0.3);
  border-radius: 50%;
  color: rgba(50, 50, 50, 0.9);
  display: inline-block;
  margin: 0 1px;
  line-height: 40px;
  font-size: 16px;
  text-align: center;
  text-decoration: none;
  width: 40px;
  height: 40px;
  transition: all 0.3s;
  opacity: 0;
}
.image03:hover img {
  transform: scale(1.1);
}
.image03:hover .ovrly {
  opacity: 1;
}
.image03:hover .buttons .fa {
  opacity: 1;
}

.image04 {
  width: 100%;
  overflow: hidden;
  position: relative;
  border-radius: 30px !important;
}

@media only screen and (max-width: 900px) {
  .image04 {
    width: 100%;
    overflow: hidden;
    position: relative;
    box-shadow: -1px 13px 56px -12px rgba(0, 0, 0, 0.42);
    border-radius: 30px !important;
  }
}

.image04 img {
  width: 100%;
  top: 0;
  position: relative;
  left: 0;
  transform: scale(1.2);
  transition: transform 0.3s;
  border-radius: 30px !important;
}
.image04 .ovrly {
  background: rgba(0, 0, 0, 0.5);
  height: 100%;
  left: 0;
  top: 0;
  position: absolute;
  width: 100%;
  opacity: 0;
  transition: all 0.3s;
  border-radius: 30px !important;
}
.image04 .buttons {
  position: absolute;
  top: 3.5%;
  right: 4.5%;
}
.image04 .buttons .fa {
  background: rgb(256, 256, 256);
  border-radius: 50%;
  border: white !important;
  color: rgba(50, 50, 50, 0.9);
  display: inline-block;
  margin: 0 1px;
  line-height: 65px;
  font-size: 25px;
  text-align: center;
  text-decoration: none;
  width: 60px;
  height: 60px;
  transition: all 0.3s;
  opacity: 0;
  padding-right: 0.2rem;
}
.image04:hover {
  transition: all 0.3s;
  border-radius: 30px !important;
}

.image04:hover img {
  transform: scale(1);
  border-radius: 30px !important;
}

.image04:hover .ovrly {
  opacity: 1;
}

.image04:hover .buttons .fa {
  opacity: 1;
}
.image05 {
  width: 100%;
  overflow: hidden;
  position: relative;
  box-shadow: 1px 1px 3px 0 rgba(0, 0, 0, 0.3);
}
.image05 .imgB {
  width: 100%;
  top: 0;
  left: 0;
  position: relative;
  opacity: 1;
}
.image05 .imgT {
  width: 100%;
  top: 0;
  left: 0;
  position: absolute;
  transition: all 0.3s;
  opacity: 0.5;
}
.image05 .ovrly {
  background: rgba(0, 0, 0, 0.5);
  height: 100%;
  left: 0;
  top: 0;
  position: absolute;
  width: 100%;
  opacity: 0;
  transition: all 0.3s;
}
.image05 .buttons {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}
.image05 .buttons .fa {
  background: rgb(256, 256, 256);
  box-shadow: 0 0 1px rgba(0, 0, 0, 0.3);
  border-radius: 50%;
  color: rgba(50, 50, 50, 0.9);
  display: inline-block;
  margin: 0 1px;
  line-height: 40px;
  font-size: 16px;
  text-align: center;
  text-decoration: none;
  width: 40px;
  height: 40px;
  transition: all 0.3s;
  opacity: 0;
}
.image05:hover .imgT {
  opacity: 0;
  transform: scale(5);
}
.image05:hover .ovrly {
  opacity: 1;
}
.image05:hover .buttons .fa {
  opacity: 1;
}
.image06 {
  width: 100%;
  overflow: hidden;
  position: relative;
  box-shadow: 1px 1px 3px 0 rgba(0, 0, 0, 0.3);
}
.image06 .imgB {
  width: 100%;
  top: 0;
  left: 0;
  position: relative;
  opacity: 1;
}
.image06 .imgT {
  width: 100%;
  top: 0;
  left: 0;
  position: absolute;
  transform: scale(5);
  transition: all 0.3s;
  opacity: 0;
}
.image06 .ovrly {
  background: rgba(0, 0, 0, 0.5);
  height: 100%;
  left: 0;
  top: 0;
  position: absolute;
  width: 100%;
  opacity: 0;
  transition: all 0.3s;
}
.image06 .buttons {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}
.image06 .buttons .fa {
  background: rgb(256, 256, 256);
  box-shadow: 0 0 1px rgba(0, 0, 0, 0.3);
  border-radius: 50%;
  color: rgba(50, 50, 50, 0.9);
  display: inline-block;
  margin: 0 1px;
  line-height: 40px;
  font-size: 16px;
  text-align: center;
  text-decoration: none;
  width: 40px;
  height: 40px;
  transition: all 0.3s;
  opacity: 0;
}
.image06:hover .imgT {
  opacity: 0.5;
  transform: scale(1);
}
.image06:hover .ovrly {
  opacity: 1;
}
.image06:hover .buttons .fa {
  opacity: 1;
}
.sBtn01 {
  width: 100%;
  overflow: hidden;
  position: relative;
  box-shadow: 1px 1px 3px 0 rgba(0, 0, 0, 0.3);
}
.sBtn01 img {
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  position: relative;
  transition: all 0.3s;
}
.sBtn01 .list-container {
  width: 35px;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
  overflow: auto;
  background: #fff;
  transition: all 0.3s;
  transform: perspective(400px) rotateY(90deg);
  transform-origin: left;
}
.sBtn01 .list-container .btn-list {
  padding: 0;
  margin: 0;
  background: #fff;
  list-style-type: none;
  position: relative;
  top: 50%;
  transform: translateY(-50%);
}
.sBtn01 .list-container .btn-list li .fa {
  background: #fff;
  color: #a1a1a1;
  display: block;
  height: 30px;
  line-height: 30px;
  text-align: center;
  text-decoration: none;
  width: 100%;
  transition: all 0.3s;
}
.sBtn01 .list-container .btn-list li .fa:hover {
  color: #fff;
}
.sBtn01 .list-container .btn-list li .fa-facebook:hover {
  background: #3b5998;
}
.sBtn01 .list-container .btn-list li .fa-twitter:hover {
  background: #55acee;
}
.sBtn01 .list-container .btn-list li .fa-plus:hover {
  background: #dd4b39;
}
.sBtn01 .list-container .btn-list li .fa-linkedin:hover {
  background: #0976b4;
}
.sBtn01 .list-container .btn-list li .fa-dribbble:hover {
  background: #ea4c89;
}
.sBtn01 .list-container .btn-list li .fa-pinterest-p:hover {
  background: #cc2127;
}
.sBtn01:hover .list-container {
  transform: perspective(400px) rotateY(0deg);
}
.sBtn01:hover img {
  left: 10px;
}
.sBtn02 {
  width: 100%;
  overflow: hidden;
  position: relative;
  box-shadow: 1px 1px 3px 0 rgba(0, 0, 0, 0.3);
}
.sBtn02 img {
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  position: relative;
  transition: all 0.3s;
}
.sBtn02 .list-container {
  width: 35px;
  height: 100%;
  position: absolute;
  top: 0;
  right: 0;
  overflow: auto;
  background: #fff;
  transition: all 0.3s;
  transform: perspective(400px) rotateY(-90deg);
  transform-origin: right;
}
.sBtn02 .list-container .btn-list {
  padding: 0;
  margin: 0;
  background: #fff;
  list-style-type: none;
  position: relative;
  top: 50%;
  transform: translateY(-50%);
}
.sBtn02 .list-container .btn-list li .fa {
  background: #fff;
  color: #a1a1a1;
  display: block;
  height: 30px;
  line-height: 30px;
  text-align: center;
  text-decoration: none;
  width: 100%;
  transition: all 0.3s;
}
.sBtn02 .list-container .btn-list li .fa:hover {
  color: #fff;
}
.sBtn02 .list-container .btn-list li .fa-facebook:hover {
  background: #3b5998;
}
.sBtn02 .list-container .btn-list li .fa-twitter:hover {
  background: #55acee;
}
.sBtn02 .list-container .btn-list li .fa-plus:hover {
  background: #dd4b39;
}
.sBtn02 .list-container .btn-list li .fa-linkedin:hover {
  background: #0976b4;
}
.sBtn02 .list-container .btn-list li .fa-dribbble:hover {
  background: #ea4c89;
}
.sBtn02 .list-container .btn-list li .fa-pinterest-p:hover {
  background: #cc2127;
}
.sBtn02:hover .list-container {
  transform: perspective(400px) rotateY(0deg);
}
.sBtn02:hover img {
  left: -10px;
}
.sBtn05 {
  width: 100%;
  overflow: hidden;
  position: relative;
  box-shadow: 1px 1px 3px 0 rgba(0, 0, 0, 0.3);
}
.sBtn05 img {
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  position: relative;
  transition: all 0.3s;
}
.sBtn05 .list-container {
  width: 100%;
  height: 40px;
  position: absolute;
  top: 0;
  left: 0;
  overflow: auto;
  background: #fff;
  transition: all 0.3s;
  transform: perspective(400px) rotateX(-90deg);
  transform-origin: top;
}
.sBtn05 .list-container .btn-list {
  padding: 0;
  margin: 0;
  background: #fff;
  list-style-type: none;
  position: relative;
  display: block;
  text-align: center;
}
.sBtn05 .list-container .btn-list li {
  display: inline-block;
}
.sBtn05 .list-container .btn-list li .fa {
  background: #fff;
  color: #a1a1a1;
  display: inline-block;
  height: 100%;
  line-height: 40px;
  text-align: center;
  text-decoration: none;
  width: 35px;
  transition: all 0.3s;
}
.sBtn05 .list-container .btn-list li .fa:hover {
  color: #fff;
}
.sBtn05 .list-container .btn-list li .fa-facebook:hover {
  background: #3b5998;
}
.sBtn05 .list-container .btn-list li .fa-twitter:hover {
  background: #55acee;
}
.sBtn05 .list-container .btn-list li .fa-plus:hover {
  background: #dd4b39;
}
.sBtn05 .list-container .btn-list li .fa-linkedin:hover {
  background: #0976b4;
}
.sBtn05 .list-container .btn-list li .fa-dribbble:hover {
  background: #ea4c89;
}
.sBtn05 .list-container .btn-list li .fa-pinterest-p:hover {
  background: #cc2127;
}
.sBtn05:hover .list-container {
  transform: perspective(400px) rotateX(0deg);
}
.sBtn05:hover img {
  top: 10px;
}
.sBtn06 {
  width: 100%;
  overflow: hidden;
  position: relative;
  box-shadow: 1px 1px 3px 0 rgba(0, 0, 0, 0.3);
}
.sBtn06 img {
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  position: relative;
  transition: all 0.3s;
}
.sBtn06 .list-container {
  width: 100%;
  height: 40px;
  position: absolute;
  bottom: 0;
  left: 0;
  overflow: auto;
  background: #fff;
  transition: all 0.3s;
  transform: perspective(400px) rotateX(90deg);
  transform-origin: bottom;
}
.sBtn06 .list-container .btn-list {
  padding: 0;
  margin: 0;
  background: #fff;
  list-style-type: none;
  position: relative;
  display: block;
  text-align: center;
}
.sBtn06 .list-container .btn-list li {
  display: inline-block;
}
.sBtn06 .list-container .btn-list li .fa {
  background: #fff;
  color: #a1a1a1;
  display: inline-block;
  height: 100%;
  line-height: 40px;
  text-align: center;
  text-decoration: none;
  width: 35px;
  transition: all 0.3s;
}
.sBtn06 .list-container .btn-list li .fa:hover {
  color: #fff;
}
.sBtn06 .list-container .btn-list li .fa-facebook:hover {
  background: #3b5998;
}
.sBtn06 .list-container .btn-list li .fa-twitter:hover {
  background: #55acee;
}
.sBtn06 .list-container .btn-list li .fa-plus:hover {
  background: #dd4b39;
}
.sBtn06 .list-container .btn-list li .fa-linkedin:hover {
  background: #0976b4;
}
.sBtn06 .list-container .btn-list li .fa-dribbble:hover {
  background: #ea4c89;
}
.sBtn06 .list-container .btn-list li .fa-pinterest-p:hover {
  background: #cc2127;
}
.sBtn06:hover .list-container {
  transform: perspective(400px) rotateX(0deg);
}
.sBtn06:hover img {
  top: -10px;
}
/* 

    * Name: Animatia
    * Vesion: 2.0
    * Type: CSS
    * Author: Pixel_Factory
    * Author's Email: h.nafees.anwar@gmail.com

*/

#loadOvrlay {
  background-color: #e8e8e8;
  position: fixed;
  top: 0px;
  left: 0px;
  width: 100%;
  height: 100%;
  z-index: 2000;
}
.header {
  width: 100%;
  height: 100vh;
  position: relative;
  background: #6a8d9d;
  background: linear-gradient(to right, #29b6f6 50%, #4fc3f7 50%);
}
.heading {
  text-align: center;
  color: #fff;
  font-family: "" Raleway ", sans-serif";
  position: relative;
  top: 30%;
  transform: translateY(-50%);
}
.heading h1 {
  margin: 0;
}
.content {
  background: #f5f5f5;
}
.section-container {
  margin-top: 10px;
  margin-bottom: 10px;
  border: 1px solid #29b6f6;
  background: #fff;
}
.section {
  border-bottom: 1px solid #d8d8d8;
}
.sec-header {
  border-bottom: 1px solid #d8d8d8;
}
.sec-header h1 {
  color: #6a8d9d;
  padding: 20px 0;
  text-align: center;
  font-family: "" Raleway ", sans-serif";
  margin: 0;
}
.items-container {
  padding: 0;
}
.item {
  padding-top: 15px;
  padding-bottom: 15px;
}
.code-link {
  background-color: #f5f5f5;
  border: 1px solid rgba(0, 0, 0, 0.15);
  border-radius: 2px;
  color: #6a8d9d;
  display: block;
  font-family: "'Raleway', sans-serif";
  font-size: 13px;
  font-weight: bold;
  margin: 10px 0;
  padding: 3px 0;
  text-align: center;
  text-decoration: none;
  width: 70px;
}
.code-link:hover {
  text-decoration: none;
  background-color: #f8f8f8;
  border: 1px solid #c6c6c6;
  box-shadow: 0 1px 1px rgba(0, 0, 0, 0.15);
  color: #6a8d9d;
}
.code-link:active {
  outline: 0 none;
  text-decoration: none;
}
.footer {
  width: 100%;
  height: 40px;
  background: #6a8d9d;
  background: linear-gradient(to right, #29b6f6 50%, #4fc3f7 50%);
}
.footer p {
  color: #fff;
  text-align: center;
  font-size: 29px;
  margin: 10px 20px;
}

@font-face {
font-family: "uicons-bold-rounded";
src: url(/static/media/uicons-bold-rounded.887f7759.eot#iefix) format("embedded-opentype"),
url(/static/media/uicons-bold-rounded.d83d7401.woff2) format("woff2"),
url(/static/media/uicons-bold-rounded.3666aad0.woff) format("woff");
}

    i[class^="fi-br-"]:before, i[class*=" fi-br-"]:before, span[class^="fi-br-"]:before, span[class*="fi-br-"]:before {
font-family: uicons-bold-rounded !important;
font-style: normal;
font-weight: normal !important;
font-feature-settings: normal;
font-variant: normal;
text-transform: none;
line-height: 1;
-webkit-font-smoothing: antialiased;
-moz-osx-font-smoothing: grayscale;
}

        .fi-br-add:before {
    content: "\f101";
    }
        .fi-br-address-book:before {
    content: "\f102";
    }
        .fi-br-alarm-clock:before {
    content: "\f103";
    }
        .fi-br-align-center:before {
    content: "\f104";
    }
        .fi-br-align-justify:before {
    content: "\f105";
    }
        .fi-br-align-left:before {
    content: "\f106";
    }
        .fi-br-align-right:before {
    content: "\f107";
    }
        .fi-br-ambulance:before {
    content: "\f108";
    }
        .fi-br-angle-double-left:before {
    content: "\f109";
    }
        .fi-br-angle-double-right:before {
    content: "\f10a";
    }
        .fi-br-angle-double-small-left:before {
    content: "\f10b";
    }
        .fi-br-angle-double-small-right:before {
    content: "\f10c";
    }
        .fi-br-angle-down:before {
    content: "\f10d";
    }
        .fi-br-angle-left:before {
    content: "\f10e";
    }
        .fi-br-angle-right:before {
    content: "\f10f";
    }
        .fi-br-angle-small-down:before {
    content: "\f110";
    }
        .fi-br-angle-small-left:before {
    content: "\f111";
    }
        .fi-br-angle-small-right:before {
    content: "\f112";
    }
        .fi-br-angle-small-up:before {
    content: "\f113";
    }
        .fi-br-angle-up:before {
    content: "\f114";
    }
        .fi-br-apple:before {
    content: "\f115";
    }
        .fi-br-apps-add:before {
    content: "\f116";
    }
        .fi-br-apps-delete:before {
    content: "\f117";
    }
        .fi-br-apps-sort:before {
    content: "\f118";
    }
        .fi-br-apps:before {
    content: "\f119";
    }
        .fi-br-archive:before {
    content: "\f11a";
    }
        .fi-br-arrow-down:before {
    content: "\f11b";
    }
        .fi-br-arrow-from-bottom:before {
    content: "\f11c";
    }
        .fi-br-arrow-left:before {
    content: "\f11d";
    }
        .fi-br-arrow-right:before {
    content: "\f11e";
    }
        .fi-br-arrow-small-down:before {
    content: "\f11f";
    }
        .fi-br-arrow-small-left:before {
    content: "\f120";
    }
        .fi-br-arrow-small-right:before {
    content: "\f121";
    }
        .fi-br-arrow-small-up:before {
    content: "\f122";
    }
        .fi-br-arrow-up:before {
    content: "\f123";
    }
        .fi-br-asterik:before {
    content: "\f124";
    }
        .fi-br-at:before {
    content: "\f125";
    }
        .fi-br-backpack:before {
    content: "\f126";
    }
        .fi-br-badge:before {
    content: "\f127";
    }
        .fi-br-balloons:before {
    content: "\f128";
    }
        .fi-br-ban:before {
    content: "\f129";
    }
        .fi-br-band-aid:before {
    content: "\f12a";
    }
        .fi-br-bank:before {
    content: "\f12b";
    }
        .fi-br-barber-shop:before {
    content: "\f12c";
    }
        .fi-br-baseball:before {
    content: "\f12d";
    }
        .fi-br-basketball:before {
    content: "\f12e";
    }
        .fi-br-bed:before {
    content: "\f12f";
    }
        .fi-br-beer:before {
    content: "\f130";
    }
        .fi-br-bell-ring:before {
    content: "\f131";
    }
        .fi-br-bell-school:before {
    content: "\f132";
    }
        .fi-br-bell:before {
    content: "\f133";
    }
        .fi-br-bike:before {
    content: "\f134";
    }
        .fi-br-billiard:before {
    content: "\f135";
    }
        .fi-br-bold:before {
    content: "\f136";
    }
        .fi-br-book-alt:before {
    content: "\f137";
    }
        .fi-br-book:before {
    content: "\f138";
    }
        .fi-br-bookmark:before {
    content: "\f139";
    }
        .fi-br-bowling:before {
    content: "\f13a";
    }
        .fi-br-box-alt:before {
    content: "\f13b";
    }
        .fi-br-box:before {
    content: "\f13c";
    }
        .fi-br-bread-slice:before {
    content: "\f13d";
    }
        .fi-br-briefcase:before {
    content: "\f13e";
    }
        .fi-br-broom:before {
    content: "\f13f";
    }
        .fi-br-browser:before {
    content: "\f140";
    }
        .fi-br-brush:before {
    content: "\f141";
    }
        .fi-br-bug:before {
    content: "\f142";
    }
        .fi-br-building:before {
    content: "\f143";
    }
        .fi-br-bulb:before {
    content: "\f144";
    }
        .fi-br-butterfly:before {
    content: "\f145";
    }
        .fi-br-cake-birthday:before {
    content: "\f146";
    }
        .fi-br-cake-wedding:before {
    content: "\f147";
    }
        .fi-br-calculator:before {
    content: "\f148";
    }
        .fi-br-calendar:before {
    content: "\f149";
    }
        .fi-br-call-history:before {
    content: "\f14a";
    }
        .fi-br-call-incoming:before {
    content: "\f14b";
    }
        .fi-br-call-missed:before {
    content: "\f14c";
    }
        .fi-br-call-outgoing:before {
    content: "\f14d";
    }
        .fi-br-camera:before {
    content: "\f14e";
    }
        .fi-br-camping:before {
    content: "\f14f";
    }
        .fi-br-car:before {
    content: "\f150";
    }
        .fi-br-caret-down:before {
    content: "\f151";
    }
        .fi-br-caret-left:before {
    content: "\f152";
    }
        .fi-br-caret-right:before {
    content: "\f153";
    }
        .fi-br-caret-up:before {
    content: "\f154";
    }
        .fi-br-carrot:before {
    content: "\f155";
    }
        .fi-br-chart-connected:before {
    content: "\f156";
    }
        .fi-br-chart-histogram:before {
    content: "\f157";
    }
        .fi-br-chart-network:before {
    content: "\f158";
    }
        .fi-br-chart-pie-alt:before {
    content: "\f159";
    }
        .fi-br-chart-pie:before {
    content: "\f15a";
    }
        .fi-br-chart-pyramid:before {
    content: "\f15b";
    }
        .fi-br-chart-set-theory:before {
    content: "\f15c";
    }
        .fi-br-chart-tree:before {
    content: "\f15d";
    }
        .fi-br-chat-arrow-down:before {
    content: "\f15e";
    }
        .fi-br-chat-arrow-grow:before {
    content: "\f15f";
    }
        .fi-br-check:before {
    content: "\f160";
    }
        .fi-br-checkbox:before {
    content: "\f161";
    }
        .fi-br-cheese:before {
    content: "\f162";
    }
        .fi-br-chess-piece:before {
    content: "\f163";
    }
        .fi-br-child-head:before {
    content: "\f164";
    }
        .fi-br-circle-small:before {
    content: "\f165";
    }
        .fi-br-circle:before {
    content: "\f166";
    }
        .fi-br-clip:before {
    content: "\f167";
    }
        .fi-br-clock:before {
    content: "\f168";
    }
        .fi-br-cloud-check:before {
    content: "\f169";
    }
        .fi-br-cloud-disabled:before {
    content: "\f16a";
    }
        .fi-br-cloud-download:before {
    content: "\f16b";
    }
        .fi-br-cloud-share:before {
    content: "\f16c";
    }
        .fi-br-cloud-upload:before {
    content: "\f16d";
    }
        .fi-br-cloud:before {
    content: "\f16e";
    }
        .fi-br-clouds:before {
    content: "\f16f";
    }
        .fi-br-cocktail:before {
    content: "\f170";
    }
        .fi-br-coffee:before {
    content: "\f171";
    }
        .fi-br-comment-alt:before {
    content: "\f172";
    }
        .fi-br-comment-check:before {
    content: "\f173";
    }
        .fi-br-comment-heart:before {
    content: "\f174";
    }
        .fi-br-comment-info:before {
    content: "\f175";
    }
        .fi-br-comment-user:before {
    content: "\f176";
    }
        .fi-br-comment:before {
    content: "\f177";
    }
        .fi-br-comments:before {
    content: "\f178";
    }
        .fi-br-compress-alt:before {
    content: "\f179";
    }
        .fi-br-compress:before {
    content: "\f17a";
    }
        .fi-br-computer:before {
    content: "\f17b";
    }
        .fi-br-confetti:before {
    content: "\f17c";
    }
        .fi-br-cookie:before {
    content: "\f17d";
    }
        .fi-br-copy-alt:before {
    content: "\f17e";
    }
        .fi-br-copy:before {
    content: "\f17f";
    }
        .fi-br-copyright:before {
    content: "\f180";
    }
        .fi-br-cow:before {
    content: "\f181";
    }
        .fi-br-cream:before {
    content: "\f182";
    }
        .fi-br-credit-card:before {
    content: "\f183";
    }
        .fi-br-croissant:before {
    content: "\f184";
    }
        .fi-br-cross-circle:before {
    content: "\f185";
    }
        .fi-br-cross-small:before {
    content: "\f186";
    }
        .fi-br-cross:before {
    content: "\f187";
    }
        .fi-br-crown:before {
    content: "\f188";
    }
        .fi-br-cube:before {
    content: "\f189";
    }
        .fi-br-cupcake:before {
    content: "\f18a";
    }
        .fi-br-cursor-finger:before {
    content: "\f18b";
    }
        .fi-br-cursor-plus:before {
    content: "\f18c";
    }
        .fi-br-cursor-text-alt:before {
    content: "\f18d";
    }
        .fi-br-cursor-text:before {
    content: "\f18e";
    }
        .fi-br-cursor:before {
    content: "\f18f";
    }
        .fi-br-dart:before {
    content: "\f190";
    }
        .fi-br-dashboard:before {
    content: "\f191";
    }
        .fi-br-data-transfer:before {
    content: "\f192";
    }
        .fi-br-database:before {
    content: "\f193";
    }
        .fi-br-delete:before {
    content: "\f194";
    }
        .fi-br-diamond:before {
    content: "\f195";
    }
        .fi-br-dice:before {
    content: "\f196";
    }
        .fi-br-diploma:before {
    content: "\f197";
    }
        .fi-br-disco-ball:before {
    content: "\f198";
    }
        .fi-br-disk:before {
    content: "\f199";
    }
        .fi-br-doctor:before {
    content: "\f19a";
    }
        .fi-br-document-signed:before {
    content: "\f19b";
    }
        .fi-br-document:before {
    content: "\f19c";
    }
        .fi-br-dollar:before {
    content: "\f19d";
    }
        .fi-br-download:before {
    content: "\f19e";
    }
        .fi-br-drink-alt:before {
    content: "\f19f";
    }
        .fi-br-drumstick:before {
    content: "\f1a0";
    }
        .fi-br-duplicate:before {
    content: "\f1a1";
    }
        .fi-br-e-learning:before {
    content: "\f1a2";
    }
        .fi-br-earnings:before {
    content: "\f1a3";
    }
        .fi-br-edit-alt:before {
    content: "\f1a4";
    }
        .fi-br-edit:before {
    content: "\f1a5";
    }
        .fi-br-envelope-ban:before {
    content: "\f1a6";
    }
        .fi-br-envelope-download:before {
    content: "\f1a7";
    }
        .fi-br-envelope-marker:before {
    content: "\f1a8";
    }
        .fi-br-envelope-open:before {
    content: "\f1a9";
    }
        .fi-br-envelope-plus:before {
    content: "\f1aa";
    }
        .fi-br-envelope:before {
    content: "\f1ab";
    }
        .fi-br-euro:before {
    content: "\f1ac";
    }
        .fi-br-exclamation:before {
    content: "\f1ad";
    }
        .fi-br-expand:before {
    content: "\f1ae";
    }
        .fi-br-eye-crossed:before {
    content: "\f1af";
    }
        .fi-br-eye-dropper:before {
    content: "\f1b0";
    }
        .fi-br-eye:before {
    content: "\f1b1";
    }
        .fi-br-feather:before {
    content: "\f1b2";
    }
        .fi-br-ferris-wheel:before {
    content: "\f1b3";
    }
        .fi-br-file-add:before {
    content: "\f1b4";
    }
        .fi-br-file-ai:before {
    content: "\f1b5";
    }
        .fi-br-file-check:before {
    content: "\f1b6";
    }
        .fi-br-file-delete:before {
    content: "\f1b7";
    }
        .fi-br-file-eps:before {
    content: "\f1b8";
    }
        .fi-br-file-gif:before {
    content: "\f1b9";
    }
        .fi-br-file-music:before {
    content: "\f1ba";
    }
        .fi-br-file-psd:before {
    content: "\f1bb";
    }
        .fi-br-file:before {
    content: "\f1bc";
    }
        .fi-br-fill:before {
    content: "\f1bd";
    }
        .fi-br-film:before {
    content: "\f1be";
    }
        .fi-br-filter:before {
    content: "\f1bf";
    }
        .fi-br-fingerprint:before {
    content: "\f1c0";
    }
        .fi-br-fish:before {
    content: "\f1c1";
    }
        .fi-br-flag:before {
    content: "\f1c2";
    }
        .fi-br-flame:before {
    content: "\f1c3";
    }
        .fi-br-flip-horizontal:before {
    content: "\f1c4";
    }
        .fi-br-flower-bouquet:before {
    content: "\f1c5";
    }
        .fi-br-flower-tulip:before {
    content: "\f1c6";
    }
        .fi-br-flower:before {
    content: "\f1c7";
    }
        .fi-br-folder-add:before {
    content: "\f1c8";
    }
        .fi-br-folder:before {
    content: "\f1c9";
    }
        .fi-br-following:before {
    content: "\f1ca";
    }
        .fi-br-football:before {
    content: "\f1cb";
    }
        .fi-br-form:before {
    content: "\f1cc";
    }
        .fi-br-forward:before {
    content: "\f1cd";
    }
        .fi-br-fox:before {
    content: "\f1ce";
    }
        .fi-br-frown:before {
    content: "\f1cf";
    }
        .fi-br-ftp:before {
    content: "\f1d0";
    }
        .fi-br-gallery:before {
    content: "\f1d1";
    }
        .fi-br-gamepad:before {
    content: "\f1d2";
    }
        .fi-br-gas-pump:before {
    content: "\f1d3";
    }
        .fi-br-gem:before {
    content: "\f1d4";
    }
        .fi-br-gift:before {
    content: "\f1d5";
    }
        .fi-br-glass-cheers:before {
    content: "\f1d6";
    }
        .fi-br-glasses:before {
    content: "\f1d7";
    }
        .fi-br-globe-alt:before {
    content: "\f1d8";
    }
        .fi-br-globe:before {
    content: "\f1d9";
    }
        .fi-br-golf:before {
    content: "\f1da";
    }
        .fi-br-graduation-cap:before {
    content: "\f1db";
    }
        .fi-br-graphic-tablet:before {
    content: "\f1dc";
    }
        .fi-br-grid-alt:before {
    content: "\f1dd";
    }
        .fi-br-grid:before {
    content: "\f1de";
    }
        .fi-br-guitar:before {
    content: "\f1df";
    }
        .fi-br-gym:before {
    content: "\f1e0";
    }
        .fi-br-hamburger:before {
    content: "\f1e1";
    }
        .fi-br-hand-holding-heart:before {
    content: "\f1e2";
    }
        .fi-br-hastag:before {
    content: "\f1e3";
    }
        .fi-br-hat-birthday:before {
    content: "\f1e4";
    }
        .fi-br-head-side-thinking:before {
    content: "\f1e5";
    }
        .fi-br-headphones:before {
    content: "\f1e6";
    }
        .fi-br-headset:before {
    content: "\f1e7";
    }
        .fi-br-heart-arrow:before {
    content: "\f1e8";
    }
        .fi-br-heart:before {
    content: "\f1e9";
    }
        .fi-br-home-location-alt:before {
    content: "\f1ea";
    }
        .fi-br-home-location:before {
    content: "\f1eb";
    }
        .fi-br-home:before {
    content: "\f1ec";
    }
        .fi-br-hourglass-end:before {
    content: "\f1ed";
    }
        .fi-br-hourglass:before {
    content: "\f1ee";
    }
        .fi-br-ice-cream:before {
    content: "\f1ef";
    }
        .fi-br-ice-skate:before {
    content: "\f1f0";
    }
        .fi-br-id-badge:before {
    content: "\f1f1";
    }
        .fi-br-inbox:before {
    content: "\f1f2";
    }
        .fi-br-incognito:before {
    content: "\f1f3";
    }
        .fi-br-indent:before {
    content: "\f1f4";
    }
        .fi-br-infinity:before {
    content: "\f1f5";
    }
        .fi-br-info:before {
    content: "\f1f6";
    }
        .fi-br-interactive:before {
    content: "\f1f7";
    }
        .fi-br-interlining:before {
    content: "\f1f8";
    }
        .fi-br-interrogation:before {
    content: "\f1f9";
    }
        .fi-br-italic:before {
    content: "\f1fa";
    }
        .fi-br-jpg:before {
    content: "\f1fb";
    }
        .fi-br-key:before {
    content: "\f1fc";
    }
        .fi-br-keyboard:before {
    content: "\f1fd";
    }
        .fi-br-kite:before {
    content: "\f1fe";
    }
        .fi-br-label:before {
    content: "\f1ff";
    }
        .fi-br-laptop:before {
    content: "\f200";
    }
        .fi-br-lasso:before {
    content: "\f201";
    }
        .fi-br-laugh:before {
    content: "\f202";
    }
        .fi-br-layers:before {
    content: "\f203";
    }
        .fi-br-layout-fluid:before {
    content: "\f204";
    }
        .fi-br-leaf:before {
    content: "\f205";
    }
        .fi-br-letter-case:before {
    content: "\f206";
    }
        .fi-br-life-ring:before {
    content: "\f207";
    }
        .fi-br-line-width:before {
    content: "\f208";
    }
        .fi-br-link:before {
    content: "\f209";
    }
        .fi-br-lipstick:before {
    content: "\f20a";
    }
        .fi-br-list-check:before {
    content: "\f20b";
    }
        .fi-br-list:before {
    content: "\f20c";
    }
        .fi-br-location-alt:before {
    content: "\f20d";
    }
        .fi-br-lock-alt:before {
    content: "\f20e";
    }
        .fi-br-lock:before {
    content: "\f20f";
    }
        .fi-br-luggage-rolling:before {
    content: "\f210";
    }
        .fi-br-magic-wand:before {
    content: "\f211";
    }
        .fi-br-makeup-brush:before {
    content: "\f212";
    }
        .fi-br-man-head:before {
    content: "\f213";
    }
        .fi-br-map-marker-cross:before {
    content: "\f214";
    }
        .fi-br-map-marker-home:before {
    content: "\f215";
    }
        .fi-br-map-marker-minus:before {
    content: "\f216";
    }
        .fi-br-map-marker-plus:before {
    content: "\f217";
    }
        .fi-br-map-marker:before {
    content: "\f218";
    }
        .fi-br-map:before {
    content: "\f219";
    }
        .fi-br-marker-time:before {
    content: "\f21a";
    }
        .fi-br-marker:before {
    content: "\f21b";
    }
        .fi-br-mars-double:before {
    content: "\f21c";
    }
        .fi-br-mars:before {
    content: "\f21d";
    }
        .fi-br-mask-carnival:before {
    content: "\f21e";
    }
        .fi-br-medicine:before {
    content: "\f21f";
    }
        .fi-br-megaphone:before {
    content: "\f220";
    }
        .fi-br-meh:before {
    content: "\f221";
    }
        .fi-br-menu-burger:before {
    content: "\f222";
    }
        .fi-br-menu-dots-vertical:before {
    content: "\f223";
    }
        .fi-br-menu-dots:before {
    content: "\f224";
    }
        .fi-br-microphone-alt:before {
    content: "\f225";
    }
        .fi-br-microphone:before {
    content: "\f226";
    }
        .fi-br-minus-small:before {
    content: "\f227";
    }
        .fi-br-minus:before {
    content: "\f228";
    }
        .fi-br-mobile:before {
    content: "\f229";
    }
        .fi-br-mode-landscape:before {
    content: "\f22a";
    }
        .fi-br-mode-portrait:before {
    content: "\f22b";
    }
        .fi-br-money:before {
    content: "\f22c";
    }
        .fi-br-moon:before {
    content: "\f22d";
    }
        .fi-br-mountains:before {
    content: "\f22e";
    }
        .fi-br-mouse:before {
    content: "\f22f";
    }
        .fi-br-mug-alt:before {
    content: "\f230";
    }
        .fi-br-music-alt:before {
    content: "\f231";
    }
        .fi-br-music:before {
    content: "\f232";
    }
        .fi-br-navigation:before {
    content: "\f233";
    }
        .fi-br-network-cloud:before {
    content: "\f234";
    }
        .fi-br-network:before {
    content: "\f235";
    }
        .fi-br-notebook:before {
    content: "\f236";
    }
        .fi-br-opacity:before {
    content: "\f237";
    }
        .fi-br-package:before {
    content: "\f238";
    }
        .fi-br-paint-brush:before {
    content: "\f239";
    }
        .fi-br-palette:before {
    content: "\f23a";
    }
        .fi-br-paper-plane:before {
    content: "\f23b";
    }
        .fi-br-password:before {
    content: "\f23c";
    }
        .fi-br-pause:before {
    content: "\f23d";
    }
        .fi-br-paw:before {
    content: "\f23e";
    }
        .fi-br-pencil:before {
    content: "\f23f";
    }
        .fi-br-pharmacy:before {
    content: "\f240";
    }
        .fi-br-phone-call:before {
    content: "\f241";
    }
        .fi-br-phone-cross:before {
    content: "\f242";
    }
        .fi-br-phone-pause:before {
    content: "\f243";
    }
        .fi-br-phone-slash:before {
    content: "\f244";
    }
        .fi-br-physics:before {
    content: "\f245";
    }
        .fi-br-picture:before {
    content: "\f246";
    }
        .fi-br-ping-pong:before {
    content: "\f247";
    }
        .fi-br-pizza-slice:before {
    content: "\f248";
    }
        .fi-br-plane:before {
    content: "\f249";
    }
        .fi-br-play-alt:before {
    content: "\f24a";
    }
        .fi-br-play:before {
    content: "\f24b";
    }
        .fi-br-playing-cards:before {
    content: "\f24c";
    }
        .fi-br-plus-small:before {
    content: "\f24d";
    }
        .fi-br-plus:before {
    content: "\f24e";
    }
        .fi-br-poker-chip:before {
    content: "\f24f";
    }
        .fi-br-portrait:before {
    content: "\f250";
    }
        .fi-br-pound:before {
    content: "\f251";
    }
        .fi-br-power:before {
    content: "\f252";
    }
        .fi-br-presentation:before {
    content: "\f253";
    }
        .fi-br-print:before {
    content: "\f254";
    }
        .fi-br-protractor:before {
    content: "\f255";
    }
        .fi-br-pulse:before {
    content: "\f256";
    }
        .fi-br-pyramid:before {
    content: "\f257";
    }
        .fi-br-quote-right:before {
    content: "\f258";
    }
        .fi-br-rainbow:before {
    content: "\f259";
    }
        .fi-br-raindrops:before {
    content: "\f25a";
    }
        .fi-br-rec:before {
    content: "\f25b";
    }
        .fi-br-receipt:before {
    content: "\f25c";
    }
        .fi-br-record-vinyl:before {
    content: "\f25d";
    }
        .fi-br-rectabgle-vertical:before {
    content: "\f25e";
    }
        .fi-br-rectangle-horizontal:before {
    content: "\f25f";
    }
        .fi-br-rectangle-panoramic:before {
    content: "\f260";
    }
        .fi-br-recycle:before {
    content: "\f261";
    }
        .fi-br-redo-alt:before {
    content: "\f262";
    }
        .fi-br-redo:before {
    content: "\f263";
    }
        .fi-br-reflect:before {
    content: "\f264";
    }
        .fi-br-refresh:before {
    content: "\f265";
    }
        .fi-br-resize:before {
    content: "\f266";
    }
        .fi-br-resources:before {
    content: "\f267";
    }
        .fi-br-rewind:before {
    content: "\f268";
    }
        .fi-br-rhombus:before {
    content: "\f269";
    }
        .fi-br-rings-wedding:before {
    content: "\f26a";
    }
        .fi-br-road:before {
    content: "\f26b";
    }
        .fi-br-rocket:before {
    content: "\f26c";
    }
        .fi-br-room-service:before {
    content: "\f26d";
    }
        .fi-br-rotate-right:before {
    content: "\f26e";
    }
        .fi-br-rugby:before {
    content: "\f26f";
    }
        .fi-br-sad:before {
    content: "\f270";
    }
        .fi-br-salad:before {
    content: "\f271";
    }
        .fi-br-scale:before {
    content: "\f272";
    }
        .fi-br-school-bus:before {
    content: "\f273";
    }
        .fi-br-school:before {
    content: "\f274";
    }
        .fi-br-scissors:before {
    content: "\f275";
    }
        .fi-br-screen:before {
    content: "\f276";
    }
        .fi-br-search-alt:before {
    content: "\f277";
    }
        .fi-br-search-heart:before {
    content: "\f278";
    }
        .fi-br-search:before {
    content: "\f279";
    }
        .fi-br-settings-sliders:before {
    content: "\f27a";
    }
        .fi-br-settings:before {
    content: "\f27b";
    }
        .fi-br-share:before {
    content: "\f27c";
    }
        .fi-br-shield-check:before {
    content: "\f27d";
    }
        .fi-br-shield-exclamation:before {
    content: "\f27e";
    }
        .fi-br-shield-interrogation:before {
    content: "\f27f";
    }
        .fi-br-shield-plus:before {
    content: "\f280";
    }
        .fi-br-shield:before {
    content: "\f281";
    }
        .fi-br-ship-side:before {
    content: "\f282";
    }
        .fi-br-ship:before {
    content: "\f283";
    }
        .fi-br-shop:before {
    content: "\f284";
    }
        .fi-br-shopping-bag-add:before {
    content: "\f285";
    }
        .fi-br-shopping-bag:before {
    content: "\f286";
    }
        .fi-br-shopping-cart-add:before {
    content: "\f287";
    }
        .fi-br-shopping-cart-check:before {
    content: "\f288";
    }
        .fi-br-shopping-cart:before {
    content: "\f289";
    }
        .fi-br-shuffle:before {
    content: "\f28a";
    }
        .fi-br-sign-in-alt:before {
    content: "\f28b";
    }
        .fi-br-sign-in:before {
    content: "\f28c";
    }
        .fi-br-sign-out-alt:before {
    content: "\f28d";
    }
        .fi-br-sign-out:before {
    content: "\f28e";
    }
        .fi-br-signal-alt-1:before {
    content: "\f28f";
    }
        .fi-br-signal-alt-2:before {
    content: "\f290";
    }
        .fi-br-signal-alt:before {
    content: "\f291";
    }
        .fi-br-skateboard:before {
    content: "\f292";
    }
        .fi-br-smartphone:before {
    content: "\f293";
    }
        .fi-br-smile-wink:before {
    content: "\f294";
    }
        .fi-br-smile:before {
    content: "\f295";
    }
        .fi-br-snowflake:before {
    content: "\f296";
    }
        .fi-br-soap:before {
    content: "\f297";
    }
        .fi-br-soup:before {
    content: "\f298";
    }
        .fi-br-spa:before {
    content: "\f299";
    }
        .fi-br-speaker:before {
    content: "\f29a";
    }
        .fi-br-sphere:before {
    content: "\f29b";
    }
        .fi-br-spinner-alt:before {
    content: "\f29c";
    }
        .fi-br-spinner:before {
    content: "\f29d";
    }
        .fi-br-square-root:before {
    content: "\f29e";
    }
        .fi-br-square:before {
    content: "\f29f";
    }
        .fi-br-star-octogram:before {
    content: "\f2a0";
    }
        .fi-br-star:before {
    content: "\f2a1";
    }
        .fi-br-stats:before {
    content: "\f2a2";
    }
        .fi-br-stethoscope:before {
    content: "\f2a3";
    }
        .fi-br-sticker:before {
    content: "\f2a4";
    }
        .fi-br-stop:before {
    content: "\f2a5";
    }
        .fi-br-stopwatch:before {
    content: "\f2a6";
    }
        .fi-br-subtitles:before {
    content: "\f2a7";
    }
        .fi-br-sun:before {
    content: "\f2a8";
    }
        .fi-br-sunrise:before {
    content: "\f2a9";
    }
        .fi-br-surfing:before {
    content: "\f2aa";
    }
        .fi-br-sword:before {
    content: "\f2ab";
    }
        .fi-br-syringe:before {
    content: "\f2ac";
    }
        .fi-br-tablet:before {
    content: "\f2ad";
    }
        .fi-br-target:before {
    content: "\f2ae";
    }
        .fi-br-taxi:before {
    content: "\f2af";
    }
        .fi-br-tennis:before {
    content: "\f2b0";
    }
        .fi-br-terrace:before {
    content: "\f2b1";
    }
        .fi-br-test-tube:before {
    content: "\f2b2";
    }
        .fi-br-test:before {
    content: "\f2b3";
    }
        .fi-br-text-check:before {
    content: "\f2b4";
    }
        .fi-br-text:before {
    content: "\f2b5";
    }
        .fi-br-thermometer-half:before {
    content: "\f2b6";
    }
        .fi-br-thumbs-down:before {
    content: "\f2b7";
    }
        .fi-br-thumbs-up:before {
    content: "\f2b8";
    }
        .fi-br-thumbtack:before {
    content: "\f2b9";
    }
        .fi-br-ticket:before {
    content: "\f2ba";
    }
        .fi-br-time-add:before {
    content: "\f2bb";
    }
        .fi-br-time-check:before {
    content: "\f2bc";
    }
        .fi-br-time-delete:before {
    content: "\f2bd";
    }
        .fi-br-time-fast:before {
    content: "\f2be";
    }
        .fi-br-time-forward-sixty:before {
    content: "\f2bf";
    }
        .fi-br-time-forward-ten:before {
    content: "\f2c0";
    }
        .fi-br-time-forward:before {
    content: "\f2c1";
    }
        .fi-br-time-half-past:before {
    content: "\f2c2";
    }
        .fi-br-time-oclock:before {
    content: "\f2c3";
    }
        .fi-br-time-past:before {
    content: "\f2c4";
    }
        .fi-br-time-quarter-past:before {
    content: "\f2c5";
    }
        .fi-br-time-quarter-to:before {
    content: "\f2c6";
    }
        .fi-br-time-twenty-four:before {
    content: "\f2c7";
    }
        .fi-br-tool-crop:before {
    content: "\f2c8";
    }
        .fi-br-tool-marquee:before {
    content: "\f2c9";
    }
        .fi-br-tooth:before {
    content: "\f2ca";
    }
        .fi-br-tornado:before {
    content: "\f2cb";
    }
        .fi-br-train-side:before {
    content: "\f2cc";
    }
        .fi-br-train:before {
    content: "\f2cd";
    }
        .fi-br-transform:before {
    content: "\f2ce";
    }
        .fi-br-trash:before {
    content: "\f2cf";
    }
        .fi-br-treatment:before {
    content: "\f2d0";
    }
        .fi-br-tree-christmas:before {
    content: "\f2d1";
    }
        .fi-br-tree:before {
    content: "\f2d2";
    }
        .fi-br-triangle:before {
    content: "\f2d3";
    }
        .fi-br-trophy:before {
    content: "\f2d4";
    }
        .fi-br-truck-side:before {
    content: "\f2d5";
    }
        .fi-br-umbrella:before {
    content: "\f2d6";
    }
        .fi-br-underline:before {
    content: "\f2d7";
    }
        .fi-br-undo-alt:before {
    content: "\f2d8";
    }
        .fi-br-undo:before {
    content: "\f2d9";
    }
        .fi-br-unlock:before {
    content: "\f2da";
    }
        .fi-br-upload:before {
    content: "\f2db";
    }
        .fi-br-usb-pendrive:before {
    content: "\f2dc";
    }
        .fi-br-user-add:before {
    content: "\f2dd";
    }
        .fi-br-user-delete:before {
    content: "\f2de";
    }
        .fi-br-user-remove:before {
    content: "\f2df";
    }
        .fi-br-user-time:before {
    content: "\f2e0";
    }
        .fi-br-user:before {
    content: "\f2e1";
    }
        .fi-br-utensils:before {
    content: "\f2e2";
    }
        .fi-br-vector-alt:before {
    content: "\f2e3";
    }
        .fi-br-vector:before {
    content: "\f2e4";
    }
        .fi-br-venus-double:before {
    content: "\f2e5";
    }
        .fi-br-venus-mars:before {
    content: "\f2e6";
    }
        .fi-br-venus:before {
    content: "\f2e7";
    }
        .fi-br-video-camera:before {
    content: "\f2e8";
    }
        .fi-br-volleyball:before {
    content: "\f2e9";
    }
        .fi-br-volume:before {
    content: "\f2ea";
    }
        .fi-br-wheelchair:before {
    content: "\f2eb";
    }
        .fi-br-wifi-alt:before {
    content: "\f2ec";
    }
        .fi-br-wind:before {
    content: "\f2ed";
    }
        .fi-br-woman-head:before {
    content: "\f2ee";
    }
        .fi-br-world:before {
    content: "\f2ef";
    }
        .fi-br-yen:before {
    content: "\f2f0";
    }
        .fi-br-zoom-in:before {
    content: "\f2f1";
    }
        .fi-br-zoom-out:before {
    content: "\f2f2";
    }

.lenguages {
  background-color: transparent;
  color: #171718;
  border: 0px solid #171718;
  border-radius: 50px;
  padding: 0.6rem;
}
.lenguages img {
  width: 30px;
  margin-top: -0.2rem;
}

@media only screen and (max-width: 900px) {
  .lenguages {
    background-color: #171718;
    border: 4px solid #171718;
    box-shadow: 2px 2px 39px 0px rgba(0,0,0,1);
-webkit-box-shadow: 2px 2px 39px 0px rgba(0,0,0,1);
-moz-box-shadow: 2px 2px 39px 0px rgba(0,0,0,1);
    border-radius: 50px;
    padding: 0rem;
    position: absolute;
    top: 7rem;
    right: -0.1rem;
    width: 170px;
  }
  .lenguages img {
    width: 30px;
    margin-top: -0.2rem;
  }
  .languageOption {
    color: white !important;

  }
}


/* Estilo de los botones */
.languageOption {
  background-color: transparent;
  color: #171718;
  cursor: pointer;
  font-size: 14px;
  margin: 5px;
  text-align: center;
  text-decoration: none;
  transition: all 0.3s ease;
  padding: 0.3rem;
  border-radius: 50px;
}


/* Efecto hover */
.languageOption:hover {
  font-weight: w900;
  border: none;
  color: #171719e0;
}

.languageOption:focus {
  outline: none;
}

/* Estilo del botón seleccionado */
.languageOption.selected {
  color: #f0f0f0;
  font-weight: w700;
  background-color: #171718;
}
@font-face {
  font-family: 'fontello';
  src: url(/static/media/fontello.cc1e5140.eot);
  src: url(/static/media/fontello.cc1e5140.eot#iefix) format('embedded-opentype'),
       url(/static/media/fontello.63b6a474.woff2) format('woff2'),
       url(/static/media/fontello.76571386.woff) format('woff'),
       url(/static/media/fontello.14acfcac.ttf) format('truetype'),
       url(/static/media/fontello.93bf4c9a.svg#fontello) format('svg');
  font-weight: normal;
  font-style: normal;
}
/* Chrome hack: SVG is rendered more smooth in Windozze. 100% magic, uncomment if you need it. */
/* Note, that will break hinting! In other OS-es font will be not as sharp as it could be */
/*
@media screen and (-webkit-min-device-pixel-ratio:0) {
  @font-face {
    font-family: 'fontello';
    src: url('../font/fontello.svg?63475567#fontello') format('svg');
  }
}
*/
 
 [class^="icon-"]:before, [class*=" icon-"]:before {
  font-family: "fontello";
  font-style: normal;
  font-weight: normal;
  speak: never;
 
  display: inline-block;
  text-decoration: inherit;
  width: 1em;
  margin-right: .2em;
  text-align: center;
  /* opacity: .8; */
 
  /* For safety - reset parent styles, that can break glyph codes*/
  font-feature-settings: normal;
  font-variant: normal;
  text-transform: none;
 
  /* fix buttons height, for twitter bootstrap */
  line-height: 1em;
 
  /* Animation center compensation - margins should be symmetric */
  /* remove if not needed */
  margin-left: .2em;
 
  /* you can be more comfortable with increased icons size */
  /* font-size: 120%; */
 
  /* Font smoothing. That was taken from TWBS */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
 
  /* Uncomment for 3D effect */
  /* text-shadow: 1px 1px 1px rgba(127, 127, 127, 0.3); */
}
 
.icon-calendar:before { content: '\e800'; } /* '' */
.icon-camera:before { content: '\e801'; } /* '' */
.icon-cancel:before { content: '\e802'; } /* '' */
.icon-card:before { content: '\e803'; } /* '' */
.icon-face:before { content: '\e804'; } /* '' */
.icon-facebook:before { content: '\e805'; } /* '' */
.icon-fav:before { content: '\e806'; } /* '' */
.icon-google:before { content: '\e807'; } /* '' */
.icon-home:before { content: '\e808'; } /* '' */
.icon-home2:before { content: '\e809'; } /* '' */
.icon-details:before { content: '\e80a'; } /* '' */
.icon-left-arrow:before { content: '\e80b'; } /* '' */
.icon-log:before { content: '\e80c'; } /* '' */
.icon-logout:before { content: '\e80d'; } /* '' */
.icon-luggage:before { content: '\e80e'; } /* '' */
.icon-marker:before { content: '\e80f'; } /* '' */
.icon-money:before { content: '\e810'; } /* '' */
.icon-paper-boat:before { content: '\e811'; } /* '' */
.icon-swap:before { content: '\e812'; } /* '' */
.icon-pics:before { content: '\e813'; } /* '' */
.icon-profile:before { content: '\e814'; } /* '' */
.icon-right-arrow:before { content: '\e815'; } /* '' */
.icon-rocket:before { content: '\e816'; } /* '' */
.icon-search:before { content: '\e817'; } /* '' */
.icon-send:before { content: '\e818'; } /* '' */
.icon-secure:before { content: '\e819'; } /* '' */
.icon-settings:before { content: '\e81a'; } /* '' */
.icon-share:before { content: '\e81b'; } /* '' */
.icon-star:before { content: '\e81c'; } /* '' */
.icon-stars:before { content: '\e81d'; } /* '' */
.icon-trend:before { content: '\e81e'; } /* '' */
.icon-time:before { content: '\e81f'; } /* '' */
.icon-vr:before { content: '\e820'; } /* '' */
.icon-calendar2:before { content: '\e821'; } /* '' */
.icon-chat:before { content: '\e822'; } /* '' */
.icon-comment:before { content: '\e823'; } /* '' */
.icon-compass:before { content: '\e824'; } /* '' */
.icon-login:before { content: '\e825'; } /* '' */
.rs-alert-item-content div {
    color: #575757;
}

.rs-drawer-content {
    position: absolute;
    background-color: #171718;
    outline: 0;
    width: 100%;
    height: 100%;
    box-shadow: 0 4px 4px rgb(0 0 0 / 12%), 0 0 10px rgb(0 0 0 / 6%);
}
.rs-alert-item-content div {
    color: #575757;
}
.exp-pay-card-style {
    /* padding-left: 1.7rem !important; */
    padding-top: 1rem !important;
    padding-bottom: 1rem !important;
}

.rs-picker-default .rs-btn, .rs-picker-input .rs-btn, .rs-picker-default .rs-picker-toggle, .rs-picker-input .rs-picker-toggle {
    background: #323335 !important;
}

.rs-picker-default .rs-picker-toggle {
    position: relative;
    z-index: 5;
    padding-right: 32px;
    display: inline-block;
    outline: none;
    cursor: pointer;
    color: #575757;
    border: 1px solid #323335;
}

.rs-picker-menu {
    position: absolute;
    text-align: left;
    z-index: 7;
    border-radius: 6px;
    background-color: #323335;
    box-shadow: 0 0 10px rgb(0 0 0 / 6%), 0 4px 4px rgb(0 0 0 / 12%);
    overflow: hidden;
    transition: none;
}

.rs-picker-check-menu-items div :hover{
    background-color: rgb(249, 75, 15 );
    /*color: black !important*/
}


.rs-picker-check-menu-items div ::selection{
    color: black !important;
    background: #1890ff;
    /*color: black !important*/
}

.react-datepicker__navigation-icon::before, .react-datepicker__year-read-view--down-arrow,
.react-datepicker__month-read-view--down-arrow,
.react-datepicker__month-year-read-view--down-arrow {
  border-color: #fe5000 !important;
  border-style: solid;
  border-width: 3px 3px 0 0;
  content: "";
  display: block;
  height: 9px;
  position: absolute;
  top: 6px;
  width: 9px;

}

.react-datepicker-popper[data-placement^="bottom"] .react-datepicker__triangle, .react-datepicker-popper[data-placement^="top"] .react-datepicker__triangle {
  margin-left: -4px;
  position: absolute;
}

.react-datepicker-popper[data-placement^="bottom"] .react-datepicker__triangle::before, .react-datepicker-popper[data-placement^="top"] .react-datepicker__triangle::before, .react-datepicker-popper[data-placement^="bottom"] .react-datepicker__triangle::after, .react-datepicker-popper[data-placement^="top"] .react-datepicker__triangle::after {
  box-sizing: content-box;
  position: absolute;
  border: 8px solid transparent;
  height: 0;
  width: 1px;
  content: "";
  z-index: -1;
  border-width: 8px;
  left: -8px;
}

.react-datepicker-popper[data-placement^="bottom"] .react-datepicker__triangle::before, .react-datepicker-popper[data-placement^="top"] .react-datepicker__triangle::before {
  border-bottom-color: #aeaeae;
}

.react-datepicker-popper[data-placement^="bottom"] .react-datepicker__triangle {
  top: 0;
  margin-top: -8px;
}

.react-datepicker-popper[data-placement^="bottom"] .react-datepicker__triangle::before, .react-datepicker-popper[data-placement^="bottom"] .react-datepicker__triangle::after {
  border-top: none;
  border-bottom-color: #f0f0f0;
}

.react-datepicker-popper[data-placement^="bottom"] .react-datepicker__triangle::after {
  top: 0;
}

.react-datepicker-popper[data-placement^="bottom"] .react-datepicker__triangle::before {
  top: -1px;
  border-bottom-color: #aeaeae;
}

.react-datepicker-popper[data-placement^="top"] .react-datepicker__triangle {
  bottom: 0;
  margin-bottom: -8px;
}

.react-datepicker-popper[data-placement^="top"] .react-datepicker__triangle::before, .react-datepicker-popper[data-placement^="top"] .react-datepicker__triangle::after {
  border-bottom: none;
  border-top-color: #fff;
}

.react-datepicker-popper[data-placement^="top"] .react-datepicker__triangle::after {
  bottom: 0;
}

.react-datepicker-popper[data-placement^="top"] .react-datepicker__triangle::before {
  bottom: -1px;
  border-top-color: #aeaeae;
}

.react-datepicker-wrapper {
  display: inline-block;
  padding: 0;
  border: 0;
}

.react-datepicker {
  font-size: 0.8rem;
  background-color: black;
  color: #000;
  border: 0px solid rgba(0, 0, 0, 0.336) !important;
  border-radius: 1rem;
  display: inline-block;
  position: relative;
  position: absolute;
  left: 2rem;
  right: 0;

}

.react-datepicker--time-only .react-datepicker__triangle {
  left: 35px;
}

.react-datepicker--time-only .react-datepicker__time-container {
  border-left: 0;
}

.react-datepicker--time-only .react-datepicker__time,
.react-datepicker--time-only .react-datepicker__time-box {
  border-bottom-left-radius: 0.3rem;
  border-bottom-right-radius: 0.3rem;
}

.react-datepicker__triangle {
  position: absolute;
  left: 50px;
}

.react-datepicker-popper {
  z-index: 1;
}

.react-datepicker-popper[data-placement^="bottom"] {
  padding-top: 10px;
}

.react-datepicker-popper[data-placement="bottom-end"] .react-datepicker__triangle, .react-datepicker-popper[data-placement="top-end"] .react-datepicker__triangle {
  left: auto;
  right: 50px;
}

.react-datepicker-popper[data-placement^="top"] {
  padding-bottom: 10px;
}

.react-datepicker-popper[data-placement^="right"] {
  padding-left: 8px;
}

.react-datepicker-popper[data-placement^="right"] .react-datepicker__triangle {
  left: auto;
  right: 42px;
}

.react-datepicker-popper[data-placement^="left"] {
  padding-right: 8px;
}

.react-datepicker-popper[data-placement^="left"] .react-datepicker__triangle {
  left: 42px;
  right: auto;
}

.react-datepicker__header {
  text-align: center;
  background-color: #0000008e !important;
  border-radius: 1rem 1rem 0 0 !important;
  padding: 8px 0;
  position: relative;
  height: 5rem !important;
}

.react-datepicker__header--time {
  padding-bottom: 8px;
  padding-left: 5px;
  padding-right: 5px;
}

.react-datepicker__header--time:not(.react-datepicker__header--time--only) {
  border-top-left-radius: 0;
}

.react-datepicker__header:not(.react-datepicker__header--has-time-select) {
  border-top-right-radius: 0.3rem;
}

.react-datepicker__year-dropdown-container--select,
.react-datepicker__month-dropdown-container--select,
.react-datepicker__month-year-dropdown-container--select,
.react-datepicker__year-dropdown-container--scroll,
.react-datepicker__month-dropdown-container--scroll,
.react-datepicker__month-year-dropdown-container--scroll {
  display: inline-block;
  margin: 0 2px;
}

.react-datepicker__current-month,
.react-datepicker-time__header,
.react-datepicker-year-header {
  margin-top: 0;
  color: #000;
  font-weight: bold;
  font-size: 0.944rem;
}

.react-datepicker-time__header {
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
}

.react-datepicker__navigation {
  align-items: center;
  background: none;
  color: #fe5000 !important;
  display: flex;
  justify-content: center;
  text-align: center;
  cursor: pointer;
  position: absolute;
  top: 2px;
  padding: 0;
  border: none;
  z-index: 1;
  height: 32px;
  width: 32px;
  text-indent: -999em;
  overflow: hidden;
}

.react-datepicker__navigation--previous {
  left: 2px;
  color: #fe5000 !important;
}

.react-datepicker__navigation--next {
  right: 2px;
  color: #fe5000 !important;
}

.react-datepicker__navigation--next--with-time:not(.react-datepicker__navigation--next--with-today-button) {
  right: 85px;
  color: #fe5000 !important;
}

.react-datepicker__navigation--years {
  position: relative;
  top: 0;
  display: block;
  margin-left: auto;
  margin-right: auto;
  
}

.react-datepicker__navigation--years-previous {
  top: 4px;
}

.react-datepicker__navigation--years-upcoming {
  top: -4px;
}

.react-datepicker__navigation:hover *::before {
  border-color: #a6a6a6;
}

.react-datepicker__navigation-icon {
  position: relative;
  top: -1px;
  font-size: 20px;
}

.react-datepicker__navigation-icon--next {
  left: -2px;
}

.react-datepicker__navigation-icon--next::before {
  transform: rotate(45deg);
  left: -7px;
}

.react-datepicker__navigation-icon--previous {
  right: -2px;
}

.react-datepicker__navigation-icon--previous::before {
  transform: rotate(225deg);
  right: -7px;
}

.react-datepicker__month-container {
  float: left;
}

.react-datepicker__year {
  margin: 0.4rem;
  text-align: center;
}

.react-datepicker__year-wrapper {
  display: flex;
  flex-wrap: wrap;
  max-width: 180px;
}

.react-datepicker__year .react-datepicker__year-text {
  display: inline-block;
  width: 4rem;
  margin: 2px;
}

.react-datepicker__month {
  margin: 0.4rem;
  text-align: center;
}

.react-datepicker__month .react-datepicker__month-text,
.react-datepicker__month .react-datepicker__quarter-text {
  display: inline-block;
  width: 4rem;
  margin: 2px;
}

.react-datepicker__input-time-container {
  clear: both;
  width: 100% ;
  float: left;
  margin: 5px 0 10px 15px;
  text-align: left;
}

.react-datepicker__input-time-container .react-datepicker-time__caption {
  display: inline-block;
}

.react-datepicker__input-time-container .react-datepicker-time__input-container {
  display: inline-block;
}

.react-datepicker__input-time-container .react-datepicker-time__input-container .react-datepicker-time__input {
  display: inline-block;
  margin-left: 10px;
}

.react-datepicker__input-time-container .react-datepicker-time__input-container .react-datepicker-time__input input {
  width: auto;
}

.react-datepicker__input-time-container .react-datepicker-time__input-container .react-datepicker-time__input input[type="time"]::-webkit-inner-spin-button,
.react-datepicker__input-time-container .react-datepicker-time__input-container .react-datepicker-time__input input[type="time"]::-webkit-outer-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

.react-datepicker__input-time-container .react-datepicker-time__input-container .react-datepicker-time__input input[type="time"] {
  -moz-appearance: textfield;
}

.react-datepicker__input-time-container .react-datepicker-time__input-container .react-datepicker-time__delimiter {
  margin-left: 5px;
  display: inline-block;
}

.react-datepicker__time-container {
  float: right;
  border-left: 1px solid #aeaeae;
  width: 85px;
}

.react-datepicker__time-container--with-today-button {
  display: inline;
  border: 1px solid #aeaeae;
  border-radius: 0.3rem;
  position: absolute;
  right: -72px;
  top: 0;
}

.react-datepicker__time-container .react-datepicker__time {
  position: relative;
  background: white;
  border-bottom-right-radius: 0.3rem;
}

.react-datepicker__time-container .react-datepicker__time .react-datepicker__time-box {
  width: 85px;
  overflow-x: hidden;
  margin: 0 auto;
  text-align: center;
  border-bottom-right-radius: 0.3rem;
}

.react-datepicker__time-container .react-datepicker__time .react-datepicker__time-box ul.react-datepicker__time-list {
  list-style: none;
  margin: 0;
  height: calc(195px + (1.7rem / 2));
  overflow-y: scroll;
  padding-right: 0;
  padding-left: 0;
  width: 100%;
  box-sizing: content-box;
}

.react-datepicker__time-container .react-datepicker__time .react-datepicker__time-box ul.react-datepicker__time-list li.react-datepicker__time-list-item {
  height: 30px;
  padding: 5px 10px;
  white-space: nowrap;
}

.react-datepicker__time-container .react-datepicker__time .react-datepicker__time-box ul.react-datepicker__time-list li.react-datepicker__time-list-item:hover {
  cursor: pointer;
  background-color: #f0f0f0;
}

.react-datepicker__time-container .react-datepicker__time .react-datepicker__time-box ul.react-datepicker__time-list li.react-datepicker__time-list-item--selected {
  background-color: #216ba5;
  color: white;
  font-weight: bold;
}

.react-datepicker__time-container .react-datepicker__time .react-datepicker__time-box ul.react-datepicker__time-list li.react-datepicker__time-list-item--selected:hover {
  background-color: #216ba5;
}

.react-datepicker__time-container .react-datepicker__time .react-datepicker__time-box ul.react-datepicker__time-list li.react-datepicker__time-list-item--disabled {
  color: #ccc;
}

.react-datepicker__time-container .react-datepicker__time .react-datepicker__time-box ul.react-datepicker__time-list li.react-datepicker__time-list-item--disabled:hover {
  cursor: default;
  background-color: transparent;
}

.react-datepicker__week-number {
  color: #ccc;
  display: inline-block;
  width: 1.7rem;
  line-height: 1.7rem;
  text-align: center;
  margin: 0.166rem;
}

.react-datepicker__week-number.react-datepicker__week-number--clickable {
  cursor: pointer;
}

.react-datepicker__week-number.react-datepicker__week-number--clickable:hover {
  border-radius: 0.3rem;
  background-color: #f0f0f0;
}

.react-datepicker__day-names,
.react-datepicker__week {
  white-space: nowrap;
}

.react-datepicker__day-names {
  margin-bottom: -8px;
}

.react-datepicker__day-name,
.react-datepicker__day,
.react-datepicker__time-name {
  color: #000;
  display: inline-block;
  width: 1.7rem;
  line-height: 1.7rem;
  text-align: center;
  margin: 0.166rem;
}

.react-datepicker__month--selected, .react-datepicker__month--in-selecting-range, .react-datepicker__month--in-range,
.react-datepicker__quarter--selected,
.react-datepicker__quarter--in-selecting-range,
.react-datepicker__quarter--in-range {
  border-radius: 0.3rem;
  background-color: #216ba5;
  color: #fff;
}

.react-datepicker__month--selected:hover, .react-datepicker__month--in-selecting-range:hover, .react-datepicker__month--in-range:hover,
.react-datepicker__quarter--selected:hover,
.react-datepicker__quarter--in-selecting-range:hover,
.react-datepicker__quarter--in-range:hover {
  background-color: #1d5d90;
}

.react-datepicker__month--disabled,
.react-datepicker__quarter--disabled {
  color: #ccc;
  pointer-events: none;
}

.react-datepicker__month--disabled:hover,
.react-datepicker__quarter--disabled:hover {
  cursor: default;
  background-color: transparent;
}

.react-datepicker__day,
.react-datepicker__month-text,
.react-datepicker__quarter-text,
.react-datepicker__year-text {
  cursor: pointer;
}

.react-datepicker__day:hover,
.react-datepicker__month-text:hover,
.react-datepicker__quarter-text:hover,
.react-datepicker__year-text:hover {
  border-radius: 0.3rem;
  background-color: #f0f0f0;
}

.react-datepicker__day--today,
.react-datepicker__month-text--today,
.react-datepicker__quarter-text--today,
.react-datepicker__year-text--today {
  font-weight: bold;
}

.react-datepicker__day--highlighted,
.react-datepicker__month-text--highlighted,
.react-datepicker__quarter-text--highlighted,
.react-datepicker__year-text--highlighted {
  border-radius: 0.3rem;
  background-color: #3dcc4a;
  color: #fff;
}

.react-datepicker__day--highlighted:hover,
.react-datepicker__month-text--highlighted:hover,
.react-datepicker__quarter-text--highlighted:hover,
.react-datepicker__year-text--highlighted:hover {
  background-color: #32be3f;
}

.react-datepicker__day--highlighted-custom-1,
.react-datepicker__month-text--highlighted-custom-1,
.react-datepicker__quarter-text--highlighted-custom-1,
.react-datepicker__year-text--highlighted-custom-1 {
  color: magenta;
}

.react-datepicker__day--highlighted-custom-2,
.react-datepicker__month-text--highlighted-custom-2,
.react-datepicker__quarter-text--highlighted-custom-2,
.react-datepicker__year-text--highlighted-custom-2 {
  color: green;
}

.react-datepicker__day--selected, .react-datepicker__day--in-selecting-range, .react-datepicker__day--in-range,
.react-datepicker__month-text--selected,
.react-datepicker__month-text--in-selecting-range,
.react-datepicker__month-text--in-range,
.react-datepicker__quarter-text--selected,
.react-datepicker__quarter-text--in-selecting-range,
.react-datepicker__quarter-text--in-range,
.react-datepicker__year-text--selected,
.react-datepicker__year-text--in-selecting-range,
.react-datepicker__year-text--in-range {
  border-radius: 0.3rem;
  background-color: #216ba5;
  color: #fff;
}

.react-datepicker__day--selected:hover, .react-datepicker__day--in-selecting-range:hover, .react-datepicker__day--in-range:hover,
.react-datepicker__month-text--selected:hover,
.react-datepicker__month-text--in-selecting-range:hover,
.react-datepicker__month-text--in-range:hover,
.react-datepicker__quarter-text--selected:hover,
.react-datepicker__quarter-text--in-selecting-range:hover,
.react-datepicker__quarter-text--in-range:hover,
.react-datepicker__year-text--selected:hover,
.react-datepicker__year-text--in-selecting-range:hover,
.react-datepicker__year-text--in-range:hover {
  background-color: #1d5d90;
}

.react-datepicker__day--keyboard-selected,
.react-datepicker__month-text--keyboard-selected,
.react-datepicker__quarter-text--keyboard-selected,
.react-datepicker__year-text--keyboard-selected {
  border-radius: 0.3rem;
  background-color: #2a87d0;
  color: #fff;
}

.react-datepicker__day--keyboard-selected:hover,
.react-datepicker__month-text--keyboard-selected:hover,
.react-datepicker__quarter-text--keyboard-selected:hover,
.react-datepicker__year-text--keyboard-selected:hover {
  background-color: #1d5d90;
}

.react-datepicker__day--in-selecting-range ,
.react-datepicker__month-text--in-selecting-range ,
.react-datepicker__quarter-text--in-selecting-range ,
.react-datepicker__year-text--in-selecting-range {
  background-color: rgba(33, 107, 165, 0.5);
}

.react-datepicker__month--selecting-range .react-datepicker__day--in-range , .react-datepicker__month--selecting-range
.react-datepicker__month-text--in-range , .react-datepicker__month--selecting-range
.react-datepicker__quarter-text--in-range , .react-datepicker__month--selecting-range
.react-datepicker__year-text--in-range {
  background-color: #f0f0f0;
  color: #000;
}

.react-datepicker__day--disabled,
.react-datepicker__month-text--disabled,
.react-datepicker__quarter-text--disabled,
.react-datepicker__year-text--disabled {
  cursor: default;
  color: #ccc;
}

.react-datepicker__day--disabled:hover,
.react-datepicker__month-text--disabled:hover,
.react-datepicker__quarter-text--disabled:hover,
.react-datepicker__year-text--disabled:hover {
  background-color: transparent;
}

.react-datepicker__month-text.react-datepicker__month--selected:hover, .react-datepicker__month-text.react-datepicker__month--in-range:hover, .react-datepicker__month-text.react-datepicker__quarter--selected:hover, .react-datepicker__month-text.react-datepicker__quarter--in-range:hover,
.react-datepicker__quarter-text.react-datepicker__month--selected:hover,
.react-datepicker__quarter-text.react-datepicker__month--in-range:hover,
.react-datepicker__quarter-text.react-datepicker__quarter--selected:hover,
.react-datepicker__quarter-text.react-datepicker__quarter--in-range:hover {
  background-color: #216ba5;
}

.react-datepicker__month-text:hover,
.react-datepicker__quarter-text:hover {
  background-color: #f0f0f0;
}

.react-datepicker__input-container {
  position: relative;
  display: inline-block;
  width: 100%;
}

.react-datepicker__year-read-view,
.react-datepicker__month-read-view,
.react-datepicker__month-year-read-view {
  border: 1px solid transparent;
  border-radius: 0.3rem;
  position: relative;
}

.react-datepicker__year-read-view:hover,
.react-datepicker__month-read-view:hover,
.react-datepicker__month-year-read-view:hover {
  cursor: pointer;
}

.react-datepicker__year-read-view:hover .react-datepicker__year-read-view--down-arrow,
.react-datepicker__year-read-view:hover .react-datepicker__month-read-view--down-arrow,
.react-datepicker__month-read-view:hover .react-datepicker__year-read-view--down-arrow,
.react-datepicker__month-read-view:hover .react-datepicker__month-read-view--down-arrow,
.react-datepicker__month-year-read-view:hover .react-datepicker__year-read-view--down-arrow,
.react-datepicker__month-year-read-view:hover .react-datepicker__month-read-view--down-arrow {
  border-top-color: #b3b3b3;
}

.react-datepicker__year-read-view--down-arrow,
.react-datepicker__month-read-view--down-arrow,
.react-datepicker__month-year-read-view--down-arrow {
  transform: rotate(135deg);
  right: -16px;
  top: 0;
}

.react-datepicker__year-dropdown,
.react-datepicker__month-dropdown,
.react-datepicker__month-year-dropdown {
  background-color: #f0f0f0;
  position: absolute;
  width: 50%;
  left: 25%;
  top: 30px;
  z-index: 1;
  text-align: center;
  border-radius: 0.3rem;
  border: 1px solid #aeaeae;
}

.react-datepicker__year-dropdown:hover,
.react-datepicker__month-dropdown:hover,
.react-datepicker__month-year-dropdown:hover {
  cursor: pointer;
}

.react-datepicker__year-dropdown--scrollable,
.react-datepicker__month-dropdown--scrollable,
.react-datepicker__month-year-dropdown--scrollable {
  height: 150px;
  overflow-y: scroll;
}

.react-datepicker__year-option,
.react-datepicker__month-option,
.react-datepicker__month-year-option {
  line-height: 20px;
  width: 100%;
  display: block;
  margin-left: auto;
  margin-right: auto;
}

.react-datepicker__year-option:first-of-type,
.react-datepicker__month-option:first-of-type,
.react-datepicker__month-year-option:first-of-type {
  border-top-left-radius: 0.3rem;
  border-top-right-radius: 0.3rem;
}

.react-datepicker__year-option:last-of-type,
.react-datepicker__month-option:last-of-type,
.react-datepicker__month-year-option:last-of-type {
  -webkit-user-select: none;
  -ms-user-select: none;
  user-select: none;
  border-bottom-left-radius: 0.3rem;
  border-bottom-right-radius: 0.3rem;
}

.react-datepicker__year-option:hover,
.react-datepicker__month-option:hover,
.react-datepicker__month-year-option:hover {
  background-color: #ccc;
}

.react-datepicker__year-option:hover .react-datepicker__navigation--years-upcoming,
.react-datepicker__month-option:hover .react-datepicker__navigation--years-upcoming,
.react-datepicker__month-year-option:hover .react-datepicker__navigation--years-upcoming {
  border-bottom-color: #b3b3b3;
}

.react-datepicker__year-option:hover .react-datepicker__navigation--years-previous,
.react-datepicker__month-option:hover .react-datepicker__navigation--years-previous,
.react-datepicker__month-year-option:hover .react-datepicker__navigation--years-previous {
  border-top-color: #b3b3b3;
}

.react-datepicker__year-option--selected,
.react-datepicker__month-option--selected,
.react-datepicker__month-year-option--selected {
  position: absolute;
  left: 15px;
}

.react-datepicker__close-icon {
  cursor: pointer;
  background-color: transparent;
  border: 0;
  outline: 0;
  padding: 0 6px 0 0;
  position: absolute;
  top: 0;
  right: 0;
  height: 100%;
  display: table-cell;
  vertical-align: middle;
}

.react-datepicker__close-icon::after {
  cursor: pointer;
  background-color: #216ba5;
  color: #fff;
  border-radius: 50%;
  height: 16px;
  width: 16px;
  padding: 2px;
  font-size: 12px;
  line-height: 1;
  text-align: center;
  display: table-cell;
  vertical-align: middle;
  content: "\00d7";
}

.react-datepicker__today-button {
  background: #f0f0f0;
  border-top: 1px solid #aeaeae;
  cursor: pointer;
  text-align: center;
  font-weight: bold;
  padding: 5px 0;
  clear: left;
}

.react-datepicker__portal {
  position: fixed;
  width: 100vw;
  height: 100vh;
  background-color: rgba(0, 0, 0, 0.8);
  left: 0;
  top: 0;
  justify-content: center;
  align-items: center;
  display: flex;
  z-index: 2147483647;
}

.react-datepicker__portal .react-datepicker__day-name,
.react-datepicker__portal .react-datepicker__day,
.react-datepicker__portal .react-datepicker__time-name {
  width: 3rem;
  line-height: 3rem;
}

@media (max-width: 400px), (max-height: 550px) {
  .react-datepicker__portal .react-datepicker__day-name,
  .react-datepicker__portal .react-datepicker__day,
  .react-datepicker__portal .react-datepicker__time-name {
    width: 2rem;
    line-height: 2rem;
  }
}

.react-datepicker__portal .react-datepicker__current-month,
.react-datepicker__portal .react-datepicker-time__header {
  font-size: 1.44rem ;
}

/*
 Buttons style by http://nicolasgallagher.com/lab/css3-github-buttons/
 Changed by Afshin Mehrabani
*/
/* overrides extra padding on button elements in Firefox */
  @keyframes introjspulse {
    0% {
      transform: scale(0);
      opacity: 0; }
    25% {
      transform: scale(0);
      opacity: 0.1; }
    50% {
      transform: scale(0.1);
      opacity: 0.3; }
    75% {
      transform: scale(0.5);
      opacity: 0.5; }
    100% {
      transform: scale(1);
      opacity: 0; } } 
  
  .introjs-overlay {
    position: absolute;
    box-sizing: content-box;
    z-index: 999999;
    opacity: 0;
    transition: all 0.3s ease-out; }
  
  .introjs-showElement {
    z-index: 9999999 !important; }
  
  tr.introjs-showElement > td {
    z-index: 9999999 !important;
    position: relative; }
  
  tr.introjs-showElement > th {
    z-index: 9999999 !important;
    position: relative; }
  
  .introjs-disableInteraction {
    z-index: 99999999 !important;
    position: absolute;
    background-color: #202020;
    opacity: 0;
    filter: alpha(opacity=0); }
  
  .introjs-relativePosition {
    position: relative; }
  
  .introjs-helperLayer {
    box-sizing: content-box;
    position: absolute;
    z-index: 9999998;
    border-radius: 30px;
    transition: all 0.3s ease-out; }
    .introjs-helperLayer * {
      box-sizing: content-box; }
      .introjs-helperLayer *:before {
        box-sizing: content-box; }
      .introjs-helperLayer *:after {
        box-sizing: content-box; }
  
  .introjs-tooltipReferenceLayer {
    box-sizing: content-box;
    position: absolute;
    visibility: hidden;
    z-index: 100000000;
    background-color: transparent;
    transition: all 0.3s ease-out; }
   
  
  .introjs-helperNumberLayer {
  
    color: #9e9e9e;
    text-align: center;
    padding-top: 10px;
    padding-bottom: 10px; }
  
  .introjs-arrow {
    border: 5px solid transparent;
    content: "";
    position: absolute; }
  
  .introjs-arrow.top {
    top: -10px;
    left: 10px;
    border-bottom-color: #636363; }
  
  .introjs-arrow.top-right {
    top: -10px;
    right: 10px;
    border-bottom-color: #ffffff; }
  
  .introjs-arrow.top-middle {
    top: -10px;
    left: 50%;
    margin-left: -5px;
    border-bottom-color: #ffffff; }
  
  .introjs-arrow.right {
    right: -10px;
    top: 10px;
    border-left-color: #ffffff; }
  
  .introjs-arrow.right-bottom {
    bottom: 10px;
    right: -10px;
    border-left-color: #ffffff; }
  
  .introjs-arrow.bottom {
    bottom: -10px;
    left: 10px;
    border-top-color: #ffffff; }
  
  .introjs-arrow.bottom-right {
    bottom: -10px;
    right: 10px;
    border-top-color: #ffffff; }
  
  .introjs-arrow.bottom-middle {
    bottom: -10px;
    left: 50%;
    margin-left: -5px;
    border-top-color: #ffffff; }
  
  .introjs-arrow.left {
    left: -10px;
    top: 10px;
    border-right-color: #ffffff; }
  
  .introjs-arrow.left-bottom {
    left: -10px;
    bottom: 10px;
    border-right-color: #ffffff; }
  
  .introjs-tooltip {
    box-sizing: content-box;
    position: absolute;
    visibility: visible;
    background-color: #161616;
    padding: 1rem;
    width: 400px;
    border-radius: 20px;
    box-shadow: 0 3px 30px rgba(33, 33, 33, 0.3);
    transition: opacity 0.1s ease-out; }

    @media only screen and (max-width: 1200px) {
      .introjs-tooltip {
        width: 550px;
      }
    }

    @media only screen and (max-width: 900px) {
      .introjs-tooltip {
        width: 300px;
      }
    }
  
  .introjs-tooltiptext {
    padding: 20px; }
  
  .introjs-tooltip-title {
    font-size: 8px;
    font-family: 'Gilroy-Bold ☞';
    margin: 0;
    padding: 0;
    font-weight: 700;
    float: left;
    line-height: 32px; }
  
  .introjs-tooltip-header {
    padding-left: 20px;
    padding-right: 20px;
    padding-top: 10px; }
    .introjs-tooltip-header:after {
      content: ".";
      visibility: hidden;
      display: block;
      height: 0;
      clear: both; }
  
  .introjs-tooltipbuttons {
    border-top: 1px solid transparent;
    padding: 10px;
    text-align: right;
    white-space: nowrap; }
    .introjs-tooltipbuttons:after {
      content: "";
      visibility: hidden;
      display: block;
      height: 0;
      clear: both; }
  
  .introjs-button {
    box-sizing: content-box;
    position: relative;
    overflow: visible;
    display: inline-block;
    padding: 0.5rem 1rem;
   
    text-decoration: none;
    font-size: 18px;
    font-weight: bold;
    color: #000000;
    white-space: nowrap;
    cursor: pointer;
    outline: none;
    background: #fe5000;
    border-radius: 50px;
    zoom: 1;
    *display: inline; }
    .introjs-button:hover {
      outline: none;
      text-decoration: none;
      background-color: #000000;
      color: #fe5000; }
    .introjs-button:focus {
      outline: none;
      text-decoration: none;
      background-color: #000000;
      color: #fe5000; }
   
  
  .introjs-skipbutton {
    box-sizing: content-box;
    color: #616161;
    float: right;
    font-size: 20px;
    cursor: pointer;
    font-weight: bold;
    line-height: 1;
    text-align: center;
    padding: 7px 10px; }
    .introjs-skipbutton:hover, .introjs-skipbutton:focus {
      color: #212121;
      outline: none;
      text-decoration: none; }
  
  .introjs-prevbutton {
    float: left; }
  
  .introjs-nextbutton {
    float: right; }
  
  .introjs-disabled {
    color: #9e9e9e;
    border-color: #bdbdbd;
    box-shadow: none;
    cursor: default;
    background-color: #f4f4f4;
    background-image: none;
    text-decoration: none; }
    .introjs-disabled:hover, .introjs-disabled:focus {
      color: #9e9e9e;
      border-color: #bdbdbd;
      box-shadow: none;
      cursor: default;
      background-color: #f4f4f4;
      background-image: none;
      text-decoration: none; }
  
  .introjs-hidden {
    display: none; }
  
  .introjs-bullets {
    text-align: center;
    padding-top: 10px;
    padding-bottom: 10px; }
    .introjs-bullets ul {
      box-sizing: content-box;
      clear: both;
      margin: 0 auto 0;
      padding: 0;
      display: inline-block; }
      .introjs-bullets ul li {
        box-sizing: content-box;
        list-style: none;
        float: left;
        margin: 0 2px; }
        .introjs-bullets ul li a {
          transition: width 0.1s ease-in;
          box-sizing: content-box;
          display: block;
          width: 6px;
          height: 6px;
          background: #ccc;
          border-radius: 10px;
          text-decoration: none;
          cursor: pointer; }
          .introjs-bullets ul li a:hover, .introjs-bullets ul li a:focus {
            width: 15px;
            background: #999;
            text-decoration: none;
            outline: none; }
        .introjs-bullets ul li a.active {
          width: 15px;
          background: #999; }
  
  .introjs-progress {
    box-sizing: content-box;
    overflow: hidden;
    height: 10px;
    margin: 10px;
    border-radius: 4px;
    background-color: #e0e0e0; }
  
  .introjs-progressbar {
    box-sizing: content-box;
    float: left;
    width: 0%;
    height: 100%;
    font-size: 10px;
    line-height: 10px;
    text-align: center;
    background-color: #08c; }
  
  .introjsFloatingElement {
    position: absolute;
    height: 0;
    width: 0;
    left: 50%;
    top: 50%; }
  
  .introjs-fixedTooltip {
    position: fixed; }
  
  .introjs-hint {
    box-sizing: content-box;
    position: absolute;
    background: transparent;
    width: 20px;
    height: 15px;
    cursor: pointer; }
    .introjs-hint:focus {
      border: 0;
      outline: 0; }
    .introjs-hint:hover > .introjs-hint-pulse {
      border: 5px solid rgba(60, 60, 60, 0.57); }
  
  .introjs-hidehint {
    display: none; }
  
  .introjs-fixedhint {
    position: fixed; }
  
  .introjs-hint-pulse {
    box-sizing: content-box;
    width: 10px;
    height: 10px;
    border: 5px solid rgba(60, 60, 60, 0.27);
    border-radius: 30px;
    background-color: rgba(136, 136, 136, 0.24);
    z-index: 10;
    position: absolute;
    transition: all 0.2s ease-out; }
  
  .introjs-hint-no-anim .introjs-hint-dot {
    animation: none; }
  
  .introjs-hint-dot {
    box-sizing: content-box;
    border: 10px solid rgba(146, 146, 146, 0.36);
    background: transparent;
    border-radius: 60px;
    height: 50px;
    width: 50px;
    animation: introjspulse 3s ease-out;
    animation-iteration-count: infinite;
    position: absolute;
    top: -25px;
    left: -25px;
    z-index: 1;
    opacity: 0; }
  
.cs-main-container {
  position: relative;
  display: flex;
  flex-direction: row;
  height: 100%;
  overflow: auto;
  box-sizing: border-box;
  color: rgba(0, 0, 0, 0.87);
  background: #000000 !important;
  font-size: 12px !important; }
  .cs-main-container > .cs-conversation-list {
    order: 0;
    height: 100%;
    flex-basis: 300px;
    box-shadow: 2px 0 5px -2px rgba(0, 0, 0, 0.38);
    /* Hmm without this, box shadow is cut at bottom */
    z-index: 2; }
  .cs-main-container > .cs-sidebar.cs-sidebar--left {
    order: 0;
    height: 100%;
    max-width: 320px;
    flex-basis: 35%;
    background-color: #111113;
    border-right: 0px solid rgb(34, 34, 34);
    /* Hmm without this, box shadow is cut at bottom */
    z-index: 2; }
  .cs-main-container > .cs-sidebar.cs-sidebar--right {
    flex-basis: 25%;
    min-width: 150px;
    max-width: 320px;
    background-color: #171718 !important; }
  .cs-main-container > .cs-sidebar.cs-sidebar--left .cs-search {
    margin: 0.5em; }
  .cs-main-container .cs-chat-container {
    order: 1;
    z-index: 1;
    flex-grow: 1;
    flex-basis: 65%; }
  .cs-main-container .cs-sidebar {
    order: 2;
    height: 100%;
    z-index: 3; }
  .cs-main-container .cs-sidebar .cs-expansion-panel {
    border-left: 0;
    border-top: 0;
    border-right: 0; }
  .cs-main-container .cs-sidebar .cs-expansion-panel:nth-last-child(3) {
    border-bottom: 0; }
  .cs-main-container .cs-conversation-header {
    z-index: 1; }
  .cs-main-container--responsive .cs-chat-container .cs-conversation-header .cs-conversation-header__back {
    display: none; }
  .cs-main-container--responsive .cs-chat-container .cs-conversation-header .cs-conversation-header__actions .cs-button--info {
    display: none; }
  @media (max-width: 768px) {
    .cs-main-container--responsive {
      min-width: calc( 1.6em + 40px + 180px); }
      .cs-main-container--responsive .cs-search {
        display: none; }
      .cs-main-container--responsive > .cs-sidebar.cs-sidebar--left {
        flex-basis: calc(1.6em + 40px);
        min-width: calc(1.6em + 40px); }
      .cs-main-container--responsive > .cs-sidebar.cs-sidebar--right {
        display: none; }
      .cs-main-container--responsive > .cs-conversation-list {
        flex-basis: calc(1.6em + 40px); }
      .cs-main-container--responsive .cs-conversation-list .cs-conversation > .cs-avatar,
      .cs-main-container--responsive .cs-sidebar .cs-conversation-list .cs-conversation > .cs-avatar {
        margin-right: 0; }
      .cs-main-container--responsive .cs-conversation-list .cs-conversation__content,
      .cs-main-container--responsive .cs-sidebar .cs-conversation-list .cs-conversation__content {
        display: none; }
      .cs-main-container--responsive .cs-conversation-list .cs-conversation__operations,
      .cs-main-container--responsive .cs-sidebar .cs-conversation-list .cs-conversation__operations {
        display: none; }
      .cs-main-container--responsive .cs-conversation-list .cs-conversation__last-activity-time,
      .cs-main-container--responsive .cs-sidebar .cs-conversation-list .cs-conversation__last-activity-time {
        display: none; }
      .cs-main-container--responsive .cs-conversation-list .cs-conversation__unread-dot,
      .cs-main-container--responsive .cs-sidebar .cs-conversation-list .cs-conversation__unread-dot {
        position: absolute;
        top: 0.3em;
        right: 0.3em;
        margin-right: 0; }
      .cs-main-container--responsive .cs-conversation-header .cs-conversation-header__actions .cs-button--info {
        display: flex; } }
  @media (max-width: 576px) {
    .cs-main-container--responsive {
      min-width: auto; }
      .cs-main-container--responsive > .cs-sidebar.cs-sidebar--left {
        display: none; }
      .cs-main-container--responsive .cs-chat-container .cs-conversation-header .cs-conversation-header__back {
        display: flex; } }

.cs-message {
  box-sizing: border-box;
  font-size: 1em;
  font-family: Helvetica Neue, Segoe UI, Helvetica, Arial, sans-serif;
  color: rgba(136, 136, 136, 0.87);
  display: flex;
  flex-direction: row;
  padding: 0;
  background-color: transparent;
  overflow: hidden; }
  .cs-message:only-child {
    margin: 0.2em 0 0 0; }
  .cs-message:not(:only-child) {
    margin: 0.2em 0 0 0; }
  .cs-message__avatar {
    box-sizing: border-box;
    margin: 0 8px 0 0;
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
    width: 42px; }
  .cs-message__content-wrapper {
    box-sizing: border-box;
    display: flex;
    flex-direction: column; }
  .cs-message__header {
    box-sizing: border-box;
    color: rgba(77, 77, 77, 0.87);
    background-color: transparent;
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
    justify-content: space-between;
    font-size: 0.8em;
    margin: 0 0.2em 0.1em 0.2em; }
    .cs-message__header .cs-message__sender-name {
      box-sizing: border-box;
      color: rgba(77, 77, 77, 0.87);
      background-color: transparent; }
    .cs-message__header .cs-message__sent-time {
      box-sizing: border-box;
      color: rgba(77, 77, 77, 0.87);
      background-color: transparent;
      margin-left: auto;
      padding-left: 0.8em; }
  .cs-message__footer {
    box-sizing: border-box;
    color: rgba(77, 77, 77, 0.87);
    background-color: transparent;
    display: flex;
    font-size: 10px !important;
    margin: 0.1em 0.2em 0 0.2em; }
    .cs-message__footer .cs-message__sender-name {
      box-sizing: border-box;
      color: rgba(77, 77, 77, 0.87);
      background-color: transparent; }
    .cs-message__footer .cs-message__sent-time {
      box-sizing: border-box;
      color: rgba(77, 77, 77, 0.87);
      background-color: transparent;
      margin-left: auto;
      padding-left: 0.8em;
    font-size: 12px; }
  .cs-message__content {
    box-sizing: border-box;
    color: rgba(0, 0, 0, 0.87);
    background-color: #202024 ;
    margin-top: 0;
    padding: 0.6em 0.9em;
    border-radius: 0.7em 0.7em 0.7em 0.7em;
    white-space: pre-wrap;
    overflow-wrap: anywhere;
    word-break: break-word;
    font-family: Helvetica Neue, Segoe UI, Helvetica, Arial, sans-serif;
    font-weight: normal;
    font-size: 0.91em;
    font-feature-settings: normal;
    font-variant: normal; }
  .cs-message--incoming {
    color: rgba(0, 0, 0, 0.87);
    background-color: transparent;
    margin-right: auto; }
    .cs-message--incoming .cs-message__avatar {
      margin: 0 8px 0 0; }
    .cs-message--incoming .cs-message__sender-name {
      display: none; }
    .cs-message--incoming .cs-message__sent-time {
      display: none; }
    .cs-message--incoming .cs-message__content {
      color: rgba(0, 0, 0, 0.87);
      background-color: #202024;
      border-radius: 0 50px 50px 50px; }
  .cs-message--outgoing {
    color: rgba(0, 0, 0, 0.87);
    background-color: transparent;
    margin-left: auto;
    justify-content: flex-end; }
    .cs-message--outgoing .cs-message__avatar {
      order: 1;
      margin: 0 0 0 8px; }
    .cs-message--outgoing .cs-message__sender-name {
      display: none; }
    .cs-message--outgoing .cs-message__sent-time {
      display: none; }
    .cs-message--outgoing .cs-message__content {
      color: rgba(0, 0, 0, 0.87);
      background: linear-gradient(180deg, rgb(241, 97, 30) 0%, rgb(243, 37, 37) 100%);
      border-radius: 50px 0px 0 50px; }
  .cs-message.cs-message--incoming.cs-message--single {
    border-radius: 0; }
    .cs-message.cs-message--incoming.cs-message--single:not(:first-child) {
      margin-top: 0.4em; }
    .cs-message.cs-message--incoming.cs-message--single .cs-message__sender-name {
      display: block; }
    .cs-message.cs-message--incoming.cs-message--single .cs-message__sent-time {
      display: block; }
    .cs-message.cs-message--incoming.cs-message--single .cs-message__content {
      border-radius: 0 50px 50px 50px; }
  .cs-message.cs-message--incoming.cs-message--first {
    border-radius: 0 0 0 0; }
    .cs-message.cs-message--incoming.cs-message--first:not(:first-child) {
      margin-top: 0.4em; }
    .cs-message.cs-message--incoming.cs-message--first .cs-message__sender-name {
      display: block; }
    .cs-message.cs-message--incoming.cs-message--first .cs-message__sent-time {
      display: block; }
    .cs-message.cs-message--incoming.cs-message--first .cs-message__content {
      border-radius: 0 50px 50px 50px;
      background-color: #202024; }
  .cs-message.cs-message--incoming.cs-message--last {
    border-radius: 50px 50px 0 50px; }
    .cs-message.cs-message--incoming.cs-message--last .cs-message__sent-time {
      display: none; }
    .cs-message.cs-message--incoming.cs-message--last .cs-message__content {
      border-radius: 0 50px 50px 0; }
  .cs-message.cs-message--outgoing.cs-message--single {
    border-radius: 0; }
    .cs-message.cs-message--outgoing.cs-message--single:not(:first-child) {
      margin-top: 0.4em; }
    .cs-message.cs-message--outgoing.cs-message--single .cs-message__sent-time {
      display: block; }
    .cs-message.cs-message--outgoing.cs-message--single .cs-message__content {
      border-radius: 50px 50px 0 50px; }
  .cs-message.cs-message--outgoing.cs-message--first {
    border-radius: 0 0 0 0;
    margin-top: 0.4em; }
    .cs-message.cs-message--outgoing.cs-message--first .cs-message__sent-time {
      display: block; }
    .cs-message.cs-message--outgoing.cs-message--first .cs-message__content {
      border-radius: 50px 50px 0 50px;
      background-color: #202024; }
  .cs-message.cs-message--outgoing.cs-message--last {
    border-radius: 0 0 0 0; }
    .cs-message.cs-message--outgoing.cs-message--last .cs-message__sent-time {
      display: none; }
    .cs-message.cs-message--outgoing.cs-message--last .cs-message__content {
      border-radius: 50px 0 50px 50px; }
  .cs-message--incoming.cs-message--avatar-spacer {
    margin-left: 50px; }
  .cs-message--outgoing.cs-message--avatar-spacer {
    margin-right: 50px; }
  .cs-message--avatar-tl .cs-message__avatar {
    justify-content: flex-start;
    order: 0;
    margin-right: 8px;
    margin-left: 0; }
  .cs-message--avatar-tl .cs-message__message-wrapper {
    order: 1; }
  .cs-message--avatar-tr .cs-message__avatar {
    justify-content: flex-start;
    order: 1;
    margin-left: 8px;
    margin-right: 0; }
  .cs-message--avatar-tr .cs-message__message-wrapper {
    order: 0; }
  .cs-message--avatar-br .cs-message__avatar {
    justify-content: flex-end;
    order: 1;
    margin-left: 8px;
    margin-right: 0; }
  .cs-message--avatar-br .cs-message__message-wrapper {
    order: 0; }
  .cs-message--avatar-bl .cs-message__avatar {
    justify-content: flex-end;
    order: 0;
    margin-right: 8px;
    margin-left: 0; }
  .cs-message--avatar-bl .cs-message__message-wrapper {
    order: 1; }
  .cs-message--avatar-cl .cs-message__avatar {
    justify-content: center;
    order: 0;
    margin-right: 8px;
    margin-left: 0; }
  .cs-message--avatar-cl .cs-message__message-wrapper {
    order: 1; }
  .cs-message--avatar-cr .cs-message__avatar {
    justify-content: center;
    order: 1;
    margin-left: 8px;
    margin-right: 0; }
  .cs-message--avatar-cr .cs-message__message-wrapper {
    order: 0; }

/********** Styles **********/
.cs-message-group {
  box-sizing: border-box;
  display: flex;
  flex-direction: row;
  margin: 0.4em 0 0 0; }
  .cs-message-group__avatar {
    box-sizing: border-box;
    display: flex;
    flex-direction: column;
    justify-content: flex-end; }
  .cs-message-group__content {
    box-sizing: border-box;
    display: flex;
    flex-direction: column; }
  .cs-message-group__header {
    box-sizing: border-box;
    display: flex;
    font-size: 0.8em;
    font-family: Helvetica Neue, Segoe UI, Helvetica, Arial, sans-serif;
    color: rgba(0, 0, 0, 0.6);
    background-color: #202024 !;
    margin: 0 0 0.2em 0;
    padding: 0; }
  .cs-message-group__footer {
    box-sizing: border-box;
    display: flex;
    font-size: 0.8em;
    font-family: Helvetica Neue, Segoe UI, Helvetica, Arial, sans-serif;
    color: rgba(0, 0, 0, 0.6);
    background-color: transparent;
    margin: 0.2em 0 0 0;
    padding: 0; }
  .cs-message-group__messages {
    box-sizing: border-box; }
    .cs-message-group__messages .cs-message:first-child {
      margin-top: 0; }
    .cs-message-group__messages .cs-message .cs-message__content {
      background-color: #3d3d3d ;
      margin-top: 0; }
  .cs-message-group--incoming {
    justify-content: flex-start; }
    .cs-message-group--incoming .cs-message-group__avatar {
      margin-right: 8px;
      order: 0; }
    .cs-message-group--incoming .cs-message-group__content {
      order: 1; }
    .cs-message-group--incoming .cs-message-group__messages .cs-message:first-child .cs-message__content {
      border-radius: 0 0.7em 0.7em 0; }
    .cs-message-group--incoming .cs-message-group__messages .cs-message .cs-message__content {
      border-radius: 0 0.7em 0.7em 0;
      color: rgba(0, 0, 0, 0.87);
      background-color: #c6e3fa; }
    .cs-message-group--incoming .cs-message-group__messages .cs-message:last-child .cs-message__content {
      border-radius: 0 0.7em 0 0.7em; }
    .cs-message-group--incoming .cs-message-group__messages .cs-message:only-child .cs-message__content {
      border-radius: 0 0.7em 0.7em 0.7em; }
  .cs-message-group--outgoing {
    justify-content: flex-end;
    margin-left: auto; }
    .cs-message-group--outgoing .cs-message-group__avatar {
      margin-left: 8px;
      order: 1; }
    .cs-message-group--outgoing .cs-message-group__content {
      order: 0; }
    .cs-message-group--outgoing .cs-message-group__messages .cs-message {
      justify-content: flex-end; }
    .cs-message-group--outgoing .cs-message-group__messages .cs-message:first-child .cs-message__content {
      border-radius: 0.7em 0 0 0.7em; }
    .cs-message-group--outgoing .cs-message-group__messages .cs-message .cs-message__content {
      border-radius: 0.7em 0 0 0.7em;
      color: rgba(0, 0, 0, 0.87);
      background-color: #5d5d96; }
    .cs-message-group--outgoing .cs-message-group__messages .cs-message:last-child .cs-message__content {
      border-radius: 0.7em 0 0.7em 0.7em; }
    .cs-message-group--outgoing .cs-message-group__messages .cs-message:only-child .cs-message__content {
      border-radius: 0.7em 0.7em 0 0.7em; }
  .cs-message-group--avatar-tl .cs-message-group__avatar {
    justify-content: flex-start;
    order: 0;
    margin-right: 8px;
    margin-left: 0; }
  .cs-message-group--avatar-tl .cs-message-group__content {
    order: 1; }
  .cs-message-group--avatar-tr .cs-message-group__avatar {
    justify-content: flex-start;
    order: 1;
    margin-left: 8px;
    margin-right: 0; }
  .cs-message-group--avatar-tr .cs-message-group__content {
    order: 0; }
  .cs-message-group--avatar-bl .cs-message-group__avatar {
    justify-content: flex-end;
    order: 0;
    margin-right: 8px;
    margin-left: 0; }
  .cs-message-group--avatar-bl .cs-message-group__content {
    order: 1; }
  .cs-message-group--avatar-br .cs-message-group__avatar {
    justify-content: flex-end;
    order: 1;
    margin-left: 8px;
    margin-right: 0; }
  .cs-message-group--avatar-br .cs-message-group__content {
    order: 0; }
  .cs-message-group--avatar-cl .cs-message-group__avatar {
    justify-content: center;
    order: 0;
    margin-right: 8px;
    margin-left: 0; }
  .cs-message-group--avatar-cl .cs-message-group__content {
    order: 1; }
  .cs-message-group--avatar-cr .cs-message-group__avatar {
    justify-content: center;
    order: 1;
    margin-left: 8px;
    margin-right: 0; }
  .cs-message-group--avatar-cr .cs-message-group__content {
    order: 0; }

.cs-message-separator {
  padding-top: 1rem;
  box-sizing: border-box;
  color: #202024;
  background-color: transparent ;
  font-size: 12px !important;
  font-family: Helvetica Neue, Segoe UI, Helvetica, Arial, sans-serif;
  text-align: center;
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  justify-content: space-between;
  align-items: center; }
  .cs-message-separator::before, .cs-message-separator::after {
    box-sizing: border-box;
    content: "";
    background-color: transparent !important;
    display: block;
    flex-grow: 1;
    height: 1px; }
  .cs-message-separator:not(:empty)::before {
    margin: 0 1em 0 0; }
  .cs-message-separator:not(:empty)::after {
    margin: 0 0 0 1em; }

.cs-message-list {
  box-sizing: border-box;
  width: 100%;
  height: 100%;
  overflow: hidden;
  min-height: 1.25em;
  position: relative;
  color: rgba(0, 0, 0, 0.87);
  background-color: #000000 }
  .cs-message-list__scroll-wrapper {
    box-sizing: border-box;
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    overflow: hidden;
    /*overflow-y: scroll*/
    padding: 0 1.2em 0 0.8em; }
  .cs-message-list__scroll-to:first-child {
    box-sizing: border-box;
    float: left;
    clear: both;
    height: 0; }
  .cs-message-list__scroll-wrapper > .cs-message:nth-last-child(4) {
    margin-bottom: 2.65em; }
  .cs-message-list__scroll-wrapper > .cs-message-group:nth-last-child(4) {
    margin-bottom: 2.65em; }
  .cs-message-list .cs-typing-indicator {
    position: absolute;
    bottom: 0;
    left: 0;
    right: 15px;
    background-color: transparent;
    padding: 0.5em 0.5em;
    height: 1.25em;
    line-height: 1.25em; }
  .cs-message-list__scroll-wrapper > .cs-message,
  .cs-message-list__scroll-wrapper > .cs-message-group {
    max-width: 85%; }
  .cs-message-list .cs-message.cs-message--incoming,
  .cs-message-list .cs-message-group.cs-message-group--incoming {
    margin-right: auto; }
  .cs-message-list .cs-message.cs-message--outgoing,
  .cs-message-list .cs-message-group.cs-message-group--outgoing {
    margin-left: auto; }
  .cs-message-list .cs-message-separator:not(:first-child) {
    margin-top: 1.2em; }
  .cs-message-list__loading-more {
    box-sizing: content-box;
    display: flex;
    flex-direction: row;
    justify-content: center;
    position: absolute;
    background-color: #fff;
    padding: 2px 0;
    top: 0;
    left: 0;
    right: 0;
    height: 1.2em;
    z-index: 1; }
    .cs-message-list__loading-more .cs-loader {
      width: 1.2em;
      height: 1.2em; }
    .cs-message-list__loading-more .cs-loader::before,
    .cs-message-list__loading-more .cs-loader::after {
      width: 100%;
      height: 100%; }
    .cs-message-list__loading-more--bottom {
      top: auto;
      top: initial;
      bottom: 0; }
  .cs-message-list .ps__rail-y {
    z-index: 2; }

.cs-avatar {
  position: relative;
  width: 42px;
  height: 42px;
  border-radius: 50%;
  box-sizing: border-box; }
  .cs-avatar > img {
    box-sizing: border-box;
    width: 100%;
    height: 100%;
    border-radius: 50%; }
  .cs-avatar:hover > img {
    filter: brightness(115%); }
  .cs-avatar.cs-avatar--xs {
    width: 16px;
    height: 16px;
    min-width: 16px;
    min-height: 16px; }
  .cs-avatar.cs-avatar--sm {
    width: 26px;
    height: 26px;
    min-width: 26px;
    min-height: 26px; }
  .cs-avatar.cs-avatar--md {
    width: 42px;
    height: 42px;
    min-width: 42px;
    min-height: 42px; }
  .cs-avatar.cs-avatar--lg {
    width: 68px;
    height: 68px;
    min-width: 68px;
    min-height: 68px; }
  .cs-avatar.cs-avatar--fluid {
    width: 100%;
    height: 100%; }
    .cs-avatar.cs-avatar--fluid .cs-status {
      right: 5%;
      width: 22%;
      height: 22%; }
  .cs-avatar .cs-status {
    box-sizing: border-box;
    position: absolute;
    right: -1px;
    bottom: 3%; }
    .cs-avatar .cs-status__bullet {
      box-sizing: content-box;
      border: solid 2px #fff; }
    .cs-avatar .cs-status--xs, .cs-avatar .cs-status--xs:not(.cs-status--named) {
      font-size: 1em;
      width: 6px;
      height: 6px;
      right: 0;
      bottom: 0; }
      .cs-avatar .cs-status--xs .cs-status__bullet, .cs-avatar .cs-status--xs:not(.cs-status--named) .cs-status__bullet {
        width: 4px;
        min-width: 4px;
        height: 4px; }
    .cs-avatar .cs-status--sm, .cs-avatar .cs-status--sm:not(.cs-status--named) {
      font-size: 1em;
      width: 12px;
      height: 12px;
      right: -3px;
      bottom: -1px; }
      .cs-avatar .cs-status--sm .cs-status__bullet, .cs-avatar .cs-status--sm:not(.cs-status--named) .cs-status__bullet {
        width: 8px;
        min-width: 8px;
        height: 8px; }
    .cs-avatar .cs-status--md, .cs-avatar .cs-status--md:not(.cs-status--named) {
      font-size: 1em;
      width: 15px;
      height: 15px; }
      .cs-avatar .cs-status--md .cs-status__bullet, .cs-avatar .cs-status--md:not(.cs-status--named) .cs-status__bullet {
        width: 11px;
        min-width: 11px;
        height: 11px; }
    .cs-avatar .cs-status--lg, .cs-avatar .cs-status--lg:not(.cs-status--named) {
      font-size: 1em;
      width: 18px;
      height: 18px;
      bottom: 4%; }
      .cs-avatar .cs-status--lg .cs-status__bullet, .cs-avatar .cs-status--lg:not(.cs-status--named) .cs-status__bullet {
        width: 14px;
        min-width: 14px;
        height: 14px; }

.cs-avatar-group {
  display: flex;
  flex-direction: row-reverse;
  overflow: visible;
  position: relative;
  flex-wrap: wrap;
  box-sizing: border-box;
  min-width: 40px;
  align-content: flex-start;
  justify-content: flex-end; }
  .cs-avatar-group .cs-avatar--active {
    z-index: 1; }
  .cs-avatar-group .cs-avatar--active-on-hover:hover {
    z-index: 2; }
  .cs-avatar-group--xs {
    padding-left: 6.112px;
    padding-top: 6.112px; }
    .cs-avatar-group--xs .cs-avatar {
      margin-top: -6.112px;
      margin-left: -6.112px;
      width: 16px;
      height: 16px;
      min-width: 16px;
      min-height: 16px;
      border: none;
      border-right: 1px solid #f2f2f2; }
  .cs-avatar-group--sm {
    padding-left: 9.932px;
    padding-top: 9.932px; }
    .cs-avatar-group--sm .cs-avatar {
      margin-top: -9.932px;
      margin-left: -9.932px;
      width: 26px;
      height: 26px;
      min-width: 26px;
      min-height: 26px; }
  .cs-avatar-group--md {
    padding-left: 16.044px;
    padding-top: 16.044px; }
    .cs-avatar-group--md .cs-avatar {
      margin-top: -16.044px;
      margin-left: -16.044px;
      width: 42px;
      height: 42px;
      min-width: 42px;
      min-height: 42px;
      border: 2px solid #f2f2f2; }
  .cs-avatar-group--lg {
    padding-left: 25.976px;
    padding-top: 25.976px; }
    .cs-avatar-group--lg .cs-avatar {
      margin-top: -25.976px;
      margin-left: -25.976px;
      width: 68px;
      height: 68px;
      min-width: 68px;
      min-height: 68px;
      border: 2px solid #f2f2f2; }

.cs-message-input {
  border-top: 0px solid rgb(34, 34, 34);
  color: white;
  display: flex;
  flex-direction: row;
  margin-bottom: 1rem;
  bottom: 0.3rem;
  background-color: transparent !important;
  box-sizing: border-box;
  padding-top: 1rem !important;
  overflow: visible;
  flex-shrink: 0;
   }
  .cs-message-input__content-editor-wrapper {
    box-sizing: border-box;
    position: relative;
    background-color: #2b2b31 !important;
    padding: 0.6em 0.9em;
    border-radius: 50px;

    flex-grow: 1; }
    .cs-message-input--disabled .cs-message-input__content-editor-wrapper {
      background-color: #202024;
      color: rgba(255, 255, 255, 0.6); }
  .cs-message-input__content-editor-container {
    box-sizing: border-box;
    position: relative;
    background-color: transparent;
    display: flex;
    flex-direction: column;
    align-items: stretch;
    overflow: hidden;
    overflow-y: auto;
    font-size: 0.94em;
    line-height: 1.35em;
    min-height: 1.35em;
    max-height: 5.4em;
    padding: 0em 0em 0em 0em;
    scroll-padding: 0em; }
    .cs-message-input--disabled .cs-message-input__content-editor-container {
      color: rgba(0, 0, 0, 0.6);
      background-color: transparent; }
  .cs-message-input__content-editor {
    box-sizing: border-box;
    flex-grow: 1;
    color: white;
    background-color: transparent;
    font-family: Helvetica Neue, Segoe UI, Helvetica, Arial, sans-serif;
    outline: 0;
    overflow: visible;
    color: rgba(0, 0, 0, 0.87);
    overflow-wrap: anywhere;
    word-break: break-word; }
    .cs-message-input--disabled .cs-message-input__content-editor {
      background-color: transparent;
      color: rgba(94, 57, 57, 0.6); }
  .cs-message-input__content-editor[data-placeholder]:empty:before {
    box-sizing: border-box;
    content: attr(data-placeholder);
    color: rgba(255, 255, 255, 0.38);
    display: block;
    cursor: text; }
  .cs-message-input__tools {
    display: flex;
    flex-direction: row;
    box-sizing: border-box; }
    .cs-message-input__tools .cs-button {
      font-size: 1.2em;
      margin-top: 0;
      margin-bottom: 0; }
    .cs-message-input__tools .cs-button--send {
      align-self: flex-end; }
    .cs-message-input__tools .cs-button--attachment {
      align-self: flex-end; }
  .cs-message-input__tools:first-child .cs-button:first-child {
    margin-left: 0; }
  .cs-message-input__tools:first-child .cs-button:last-child {
    margin-right: 0; }
  .cs-message-input__tools:last-child .cs-button:first-child {
    margin-left: 0; }
  .cs-message-input__tools:last-child .cs-button:last-child {
    margin-right: 0; }

.cs-input-toolbox {
  box-sizing: border-box;
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  margin: 0;
  padding: 0 0 0.4em 0; }
  .cs-input-toolbox .cs-button {
    margin: 0;
    padding: 0;
    background: none;
    border: none;
    margin: 0 0.5em 0 0;
    font-size: 1.2em; }

.cs-chat-container {
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  height: 100%;
  min-width: 180px;
  color: white;
  background-color: transparent !important; }
  .cs-chat-container .cs-message-input {
    box-shadow: 0px -34px 84px -24px rgba(0,0,0,1);
    margin-top: auto;
    padding: 0.3em 0 0.3em 0em;
    color: rgba(0, 0, 0, 0.87);
    background-color: transparent; }
    .cs-chat-container .cs-message-input .cs-message-input__content-editor-wrapper:first-child {
      margin-left: 0.8em; }
    .cs-chat-container .cs-message-input .cs-message-input__content-editor-wrapper:last-child {
      margin-right: 0.8em; }
  .cs-chat-container .cs-input-toolbox {
    margin: 0;
    padding: 0 0.8em 0.17em 0;
    color: white !important;
    background-color: transparent; }

.cs-typing-indicator {
  box-sizing: content-box;
  font-size: inherit;
  font-family: Helvetica Neue, Segoe UI, Helvetica, Arial, sans-serif;
  display: flex;
  flex-direction: row;
  align-items: stretch; }
  .cs-typing-indicator__indicator {
    box-sizing: inherit;
    background-color: transparent;
    display: flex;
    flex-direction: row;
    align-items: center; }
  .cs-typing-indicator__dot {
    box-sizing: inherit;
    animation: cs-typing-indicator__typing-animation 1.5s infinite ease-in-out;
    border-radius: 100%;
    display: inline-block;
    height: 4px;
    width: 4px;
    background-color: #411a09; }
  .cs-typing-indicator__dot:not(:last-child) {
    margin-right: 3px; }
  .cs-typing-indicator__dot:nth-child(1) {
    box-sizing: inherit; }
  .cs-typing-indicator__dot:nth-child(2) {
    animation-delay: 300ms; }
  .cs-typing-indicator__dot:nth-child(3) {
    animation-delay: 400ms; }
  .cs-typing-indicator__text {
    box-sizing: inherit;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    color: #202024;
    background-color: transparent;
    margin: 0 0 0 0.5em;
    -webkit-user-select: none;
        -ms-user-select: none;
            user-select: none; }

@keyframes cs-typing-indicator__typing-animation {
  0% {
    transform: translateY(0px); }
  28% {
    transform: translateY(-5px); }
  44% {
    transform: translateY(0px); } }

.cs-conversation-header {
  margin: 1.2rem;
  border-radius: 50px;
  height: 3.7rem;
  box-sizing: border-box;
  color: rgba(0, 0, 0, 0.87);
  background-color: #1d1d1f !important;
  font-size: 1em;
  flex-shrink: 0;
  -webkit-user-select: none;
      -ms-user-select: none;
          user-select: none;
  display: flex;
  flex-direction: row;
  align-items: stretch;
  box-shadow: 0px 0px 300px 1px rgba(0,0,0,1) !important;
  padding: 0.6em 0.9em; }
  .cs-conversation-header__back {
    box-sizing: border-box;
    margin-right: 0.5em;
    display: flex;
    flex-direction: row;
    align-items: center;
    cursor: pointer;
    order: 1; }
    .cs-conversation-header__back button {
      font-size: 1.4em;
      padding: 0; }
  .cs-conversation-header__avatar {
    box-sizing: border-box;
    width: 42px;
    height: 42px;
    margin-right: 1em;
    margin-top: 0.2rem;
    order: 2; }
    .timers__chats {
      font-size: 10px !important;
    }
    .cs-conversation-header__avatar > .cs-avatar > .cs-status > .cs-status__bullet {
      border-color: #f6fbff; }
    .cs-conversation-header__avatar .cs-avatar-group {
      padding-right: 0 !important; }
  .cs-conversation-header__content {
    box-sizing: border-box;
    display: flex;
    flex-direction: column;
    order: 3;
    flex-grow: 2;
    justify-content: center;
    min-width: 0; }
    .cs-conversation-header__content .cs-conversation-header__user-name {
      box-sizing: border-box;
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;
      font-weight: bold;
      color: rgba(0, 0, 0, 0.87);
      background-color: transparent !important; }
    .cs-conversation-header__content .cs-conversation-header__info {
      box-sizing: border-box;
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;
      color: rgba(0, 0, 0, 0.6);
      background-color: transparent;
      font-weight: normal;
      font-size: 15px !important; }
  .cs-conversation-header__actions {
    box-sizing: border-box;
    display: flex;
    flex-direction: row;
    align-items: center;
    color: rgba(0, 0, 0, 0.87);
    background-color: transparent;
    margin: 0 0 0 0.5em;
    order: 4; }
    .cs-conversation-header__actions .cs-button:not(:first-child) {
      margin-left: 0.2em; }
    .cs-conversation-header__actions .cs-button {
      padding: 0; }
    .cs-conversation-header__actions .cs-button.cs-button--arrow,
    .cs-conversation-header__actions .cs-button.cs-button--info,
    .cs-conversation-header__actions .cs-button.cs-button--voicecall,
    .cs-conversation-header__actions .cs-button.cs-button--videocall,
    .cs-conversation-header__actions .cs-button.cs-button--star,
    .cs-conversation-header__actions .cs-button.cs-button--adduser,
    .cs-conversation-header__actions .cs-button.cs-button--ellipsis,
    .cs-conversation-header__actions .cs-button.cs-button--ellipsis {
      border: 0 solid!important;
      width: 40px !important;
      height: 40px !important;
      border-radius: 50% !important;
      padding-top: 2px;
      box-shadow: none !important;
      background: #2a2b2c;
      margin-left: 1rem;
      z-index: 2 !important;
      font-size: 15px !important;  }
    .cs-conversation-header__actions .cs-button.cs-button--ellipsis {
      border: 0 solid!important;
      width: 40px !important;
      height: 40px !important;
      border-radius: 50% !important;
      padding-top: 2px;
      box-shadow: none !important;
      background: #2a2b2c;
      margin-left: 1rem;
      z-index: 2 !important;
      font-size: 15px !important; }

.cs-conversation {
  display: flex;
  flex-direction: row;
  box-sizing: border-box;
  position: relative;
  font-family: Helvetica Neue, Segoe UI, Helvetica, Arial, sans-serif;
  padding: 0.675em 0.8em 0.675em 0.8em;
  cursor: pointer;
  margin: 0rem;
  border-bottom: 1px solid rgb(34, 34, 34);
  border-top: 0px solid rgb(34, 34, 34);
  border-radius: 0px;
  justify-content: flex-start;
  color: rgba(0, 0, 0, 0.87);
  background: #111113;
  -webkit-user-select: none;
      -ms-user-select: none;
          user-select: none; }
  .cs-conversation:hover {
    border-bottom: 1px solid rgba(34, 34, 34, 0);
    border-top: 1px solid rgba(34, 34, 34, 0);
    border-radius: 0px;
    background-color: #2b2b31; }
    .cs-conversation:hover > .cs-avatar > .cs-status > .cs-status__bullet {
      border-color: #f3f8fc; }
    .cs-conversation:hover > .cs-avatar > img {
      filter: none; }
  .cs-conversation.cs-conversation:active {
    color: rgba(0, 0, 0, 0.87);
    background-color: #1f1f24 !important; }
    .cs-conversation.cs-conversation:active > .cs-avatar > .cs-status > .cs-status__bullet {
      border-color: #d5e8f6; }
  .cs-conversation.cs-conversation--active {
    color: #2a6b9c ;
    background-color: #d5e8f6 ; }
    .cs-conversation.cs-conversation--active > .cs-avatar > .cs-status > .cs-status__bullet {
      border-color: #d5e8f6; }
    .cs-conversation.cs-conversation--active .cs-conversation__name {
      color: #2a6b9c; }
    .cs-conversation.cs-conversation--active .cs-conversation__info {
      font-size: 12px !important;
      color: #2a6b9c ; }
  .cs-conversation > .cs-avatar {
    width: 42.1px;
    height: 42.1px;
    margin-right: 1em; }
    .cs-conversation > .cs-avatar > .cs-status {
      border-color: #fff; }
  .cs-conversation > .cs-avatar-group {
    width: 42.1px;
    height: 42.1px;
    min-width: 42.1px;
    min-height: 42.1px;
    padding-right: 0 !important;
    margin-right: 1em; }
  .cs-conversation__content {
    box-sizing: border-box;
    display: flex;
    flex-direction: column;
    overflow: hidden;
    margin-right: 1.1em;
    flex-grow: 1; }
  .cs-conversation__operations {
    box-sizing: border-box;
    display: flex;
    flex-direction: row;
    align-items: center;
    margin: 0 0 0 auto;
    color: rgba(179, 179, 179, 0.87);
    visibility: hidden; }
  .cs-conversation__operations--visible {
    visibility: visible; }
  .cs-conversation__operations:hover {
    color: rgba(102, 102, 102, 0.87); }
  .cs-conversation:hover .cs-conversation__operations {
    visibility: visible; }
  .cs-conversation__name {
    box-sizing: border-box;
    color: rgba(0, 0, 0, 0.87);
    font-size: 1em;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap; }
  .cs-conversation__info {
    box-sizing: border-box;
    color: rgba(0, 0, 0, 0.6);
    font-size: 0.8em;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap; }
  .cs-conversation__last-sender {
    box-sizing: border-box;
    display: inline;
    font-weight: normal; }
  .cs-conversation__info-content {
    box-sizing: border-box;
    display: inline;
    font-weight: normal; }
  .cs-conversation__last-activity-time {
    box-sizing: border-box;
    margin: 0 0 0 auto;
    font-size: 0.8em;
    display: flex;
    flex-direction: row;
    align-items: center; }
  .cs-conversation__last-activity-time + .cs-conversation__operations {
    margin-left: 0.5em; }
  .cs-conversation__unread-dot {
    box-sizing: content-box;
    display: flex;
    flex-direction: row;
    align-items: center;
    margin-right: 0.5em;
    align-self: center;
    border-radius: 50%;
    width: 0.7em;
    min-width: 0.7em;
    height: 0.7em;
    box-shadow: 1px 1px 1px 0px #b3b3b3;
    perspective: 200px;
    perspective-origin: 50% 50%;
    background: radial-gradient(circle at 3px 3px, #7c9df1, #2e63e8); }
  .cs-conversation__unread-dot:hover::before {
    content: "";
    position: absolute;
    top: 1%;
    left: 5%;
    border-radius: 50%;
    z-index: 2;
    filter: blur(0);
    height: 80%;
    width: 40%;
    background: radial-gradient(circle at 130% 130%, rgba(255, 255, 255, 0) 0, rgba(255, 255, 255, 0) 46%, rgba(255, 255, 255, 0.8) 50%, rgba(255, 255, 255, 0.8) 58%, rgba(255, 255, 255, 0) 60%, rgba(255, 255, 255, 0) 100%);
    transform: translateX(131%) translateY(58%) rotateZ(168deg) rotateX(10deg); }
  .cs-conversation__unread-dot:hover::after {
    content: "";
    position: absolute;
    top: 5%;
    left: 10%;
    width: 80%;
    height: 80%;
    border-radius: 100%;
    filter: blur(1px);
    z-index: 2;
    transform: rotateZ(-30deg);
    display: block;
    background: radial-gradient(circle at 50% 80%, rgba(255, 255, 255, 0), rgba(255, 255, 255, 0) 74%, white 80%, white 84%, rgba(255, 255, 255, 0) 100%);
    animation: cs-unread-anim 2s ease-out infinite; }
  .cs-conversation__unread {
    box-sizing: border-box;
    position: absolute;
    right: 0.8em;
    top: 0.3em;
    padding: 0.01em 0.3em;
    border-radius: 0.3em;
    font-size: 0.75em;
    font-weight: 600;
    color: #fff;
    background-color: #ec1212;
    max-width: 30em;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    z-index: 100;
    /* For grouped Avatar */ }

@keyframes cs-unread-anim {
  0% {
    transform: scale(1); }
  20% {
    transform: scaleY(0.95) scaleX(1.05); }
  48% {
    transform: scaleY(1.1) scaleX(0.9); }
  68% {
    transform: scaleY(0.98) scaleX(1.02); }
  80% {
    transform: scaleY(1.02) scaleX(0.98); }
  97%,
  100% {
    transform: scale(1); } }

/********** Colors **********/
/********** Fonts **********/
/********** Styles **********/
.cs-conversation-list {
  box-sizing: border-box;
  position: relative;
  overflow: hidden;
  height: 100%;
  color: rgba(0, 0, 0, 0.87);
  background-color: transparent; }
  .cs-conversation-list > div > ul {
    margin: 0;
    padding: 0;
    list-style-type: none; }
  .cs-conversation-list__loading-more {
    box-sizing: content-box;
    display: flex;
    flex-direction: row;
    justify-content: center;
    position: absolute;
    background-color: transparent;
    padding: 2px 0;
    bottom: 0;
    left: 0;
    right: 0;
    height: 1.2em;
    z-index: 1; }
    .cs-conversation-list__loading-more .cs-loader {
      width: 1.2em;
      height: 1.2em; }
    .cs-conversation-list__loading-more .cs-loader::before,
    .cs-conversation-list__loading-more .cs-loader::after {
      width: 100%;
      height: 100%; }
  .cs-conversation-list .ps__rail-y {
    z-index: 2; }

.cs-status {
  box-sizing: border-box;
  position: relative;
  display: flex;
  flex-direction: row;
  align-items: center;
  flex-wrap: nowrap;
  font-size: 1em; }
  .cs-status .cs-status__bullet {
    box-sizing: content-box;
    width: 11px;
    height: 11px;
    border-radius: 50%;
    position: relative;
    perspective: 200px;
    perspective-origin: 50% 50%; }
  .cs-status--selected {
    color: #2a6b9c;
    background-color: #d5e8f6 ; }
  .cs-status--named {
    width: auto;
    height: auto; }
  .cs-status__name {
    margin: 0 0 0 0.58em;
    line-height: 1.5; }
  .cs-avatar:hover .cs-status__bullet:before,
  .cs-status:hover .cs-status__bullet:before {
    box-sizing: border-box;
    content: "";
    position: absolute;
    top: 1%;
    left: 5%;
    border-radius: 50%;
    z-index: 2;
    filter: blur(0);
    height: 80%;
    width: 40%;
    background: radial-gradient(circle at 130% 130%, rgba(255, 255, 255, 0) 0, rgba(255, 255, 255, 0) 46%, rgba(255, 255, 255, 0.8) 50%, rgba(255, 255, 255, 0.8) 58%, rgba(255, 255, 255, 0) 60%, rgba(255, 255, 255, 0) 100%);
    transform: translateX(131%) translateY(58%) rotateZ(168deg) rotateX(10deg); }
  .cs-avatar:hover .cs-status__bullet:after,
  .cs-status:hover .cs-status__bullet:after {
    box-sizing: border-box;
    content: "";
    position: absolute;
    top: 5%;
    left: 10%;
    width: 80%;
    height: 80%;
    border-radius: 100%;
    filter: blur(1px);
    z-index: 2;
    transform: rotateZ(-30deg);
    display: block;
    background: radial-gradient(circle at 50% 80%, rgba(255, 255, 255, 0), rgba(255, 255, 255, 0) 74%, white 80%, white 84%, rgba(255, 255, 255, 0) 100%);
    animation: cs-bubble-anim 2s ease-out infinite; }
  .cs-status--available .cs-status__bullet {
    background: radial-gradient(circle at 3px 3px, #00d5a6, #00a27e); }
  .cs-status--available .cs-status__name {
    color: rgba(0, 0, 0, 0.87); }
  .cs-status--unavailable .cs-status__bullet {
    background: radial-gradient(circle at 3px 3px, #ffb527, #a66d00); }
  .cs-status--unavailable .cs-status__name {
    color: rgba(0, 0, 0, 0.87); }
  .cs-status--away .cs-status__bullet {
    background: radial-gradient(circle at 3px 3px, #ffdbb0, #fc8b00); }
  .cs-status--away .cs-status__name {
    color: rgba(0, 0, 0, 0.87); }
  .cs-status--dnd .cs-status__bullet {
    background-color: #ec1212;
    background: radial-gradient(circle at 3px 3px, #f89f9f, #ec1212); }
  .cs-status--dnd .cs-status__name {
    color: rgba(0, 0, 0, 0.87); }
  .cs-status--invisible .cs-status__bullet {
    background-color: #c2d1d9;
    background: radial-gradient(circle at 3px 3px, white, #c2d1d9); }
  .cs-status--invisible .cs-status__name {
    color: rgba(0, 0, 0, 0.87); }
  .cs-status--eager .cs-status__bullet {
    background: radial-gradient(circle at 3px 3px, #fffccc, #ffee00); }
  .cs-status--eager .cs-status__name {
    color: rgba(0, 0, 0, 0.87); }
  .cs-status--xs:not(.cs-status--named) {
    font-size: 1em;
    width: 4px;
    height: 4px; }
    .cs-status--xs:not(.cs-status--named) .cs-status__bullet {
      width: 4px;
      min-width: 4px;
      height: 4px; }
  .cs-status--sm:not(.cs-status--named) {
    font-size: 1em;
    width: 8px;
    height: 8px; }
    .cs-status--sm:not(.cs-status--named) .cs-status__bullet {
      width: 8px;
      min-width: 8px;
      height: 8px; }
  .cs-status--md:not(.cs-status--named) {
    font-size: 1em;
    width: 11px;
    height: 11px; }
    .cs-status--md:not(.cs-status--named) .cs-status__bullet {
      width: 11px;
      min-width: 11px;
      height: 11px; }
  .cs-status--lg:not(.cs-status--named) {
    font-size: 1em;
    width: 14px;
    height: 14px; }
    .cs-status--lg:not(.cs-status--named) .cs-status__bullet {
      width: 14px;
      min-width: 14px;
      height: 14px; }
  .cs-status--fluid {
    width: 100%;
    height: 100%; }
    .cs-status--fluid .cs-status__bullet {
      width: 100%;
      min-width: 100%;
      height: 100%; }

@keyframes cs-bubble-anim {
  0% {
    transform: scale(1); }
  20% {
    transform: scaleY(0.95) scaleX(1.05); }
  48% {
    transform: scaleY(1.1) scaleX(0.9); }
  68% {
    transform: scaleY(0.98) scaleX(1.02); }
  80% {
    transform: scaleY(1.02) scaleX(0.98); }
  97%,
  100% {
    transform: scale(1); } }

.cs-sidebar {
  box-sizing: border-box;
  position: relative;
  overflow: hidden;
  height: 100%;
  display: flex;
  flex-direction: column; }
  .cs-sidebar--left {
    background-color: #171718;

  
  }
  .cs-sidebar--right {
    margin: 1rem;
    border-radius: 30px;
    height: 90% !important;
    background-color: #171718; }
  .cs-sidebar .cs-expansion-panel + .cs-expansion-panel {
    border-top: 0; }

.cs-expansion-panel {
  border-radius: 30px;
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  color: rgba(0, 0, 0, 0.87);
  background-color: #202024 !important;
  font-family: Helvetica Neue, Segoe UI, Helvetica, Arial, sans-serif;
  font-weight: normal;
  font-size: inherit;
  font-feature-settings: normal;
  font-variant: normal; }
  .cs-expansion-panel__header {
    box-sizing: border-box;
    display: flex;
    flex-direction: row;
    color: rgba(0, 0, 0, 0.6);
    background-color: #111113;
    font-family: Helvetica Neue, Segoe UI, Helvetica, Arial, sans-serif;
    font-weight: bold;
    font-size: 0.8em !important;
    font-feature-settings: normal;
    font-variant: normal;
    -webkit-user-select: none;
        -ms-user-select: none;
            user-select: none;
    padding: 0.8em 0.8em;
    align-items: center;
    user-select: none;
    cursor: pointer; }
  .cs-expansion-panel__header:hover {
    color: rgba(0, 0, 0, 0.6);
    background-color: #141416; }
  .cs-expansion-panel__title {
    font-size: 16px ;
    box-sizing: border-box;
    flex-grow: 1; }
  .cs-expansion-panel__icon {
    box-sizing: border-box;
    margin-left: 1em;
    margin-right: 0.5em; }
  .cs-expansion-panel__content {
    display: none;
    color: rgba(0, 0, 0, 0.87);
    background-color: #171718 !important;
    font-family: Helvetica Neue, Segoe UI, Helvetica, Arial, sans-serif;
    font-weight: normal;
    font-size: 0.8em;
    font-feature-settings: normal;
    font-variant: normal;
    padding: 0.4em 0.8em; }
  .cs-expansion-panel--open .cs-expansion-panel__content {
    display: block;
    color: rgba(0, 0, 0, 0.87);
    background-color: #171718; }
  .cs-expansion-panel--closed .cs-expansion-panel__content {
    display: none; }
  .cs-expansion-panel--open .cs-expansion-panel__header {
    color: rgba(0, 0, 0, 0.6);
    background-color: #202024; }

.cs-search {
  box-sizing: border-box;
  display: flex;
  flex-direction: row;
  background-color: transparent;
  align-items: center;
  position: relative;
  border-radius: 50px !important;
  padding: 0.6em 0.9em;
  font-size: inherit;
  font-family: inherit;}
  .cs-search__input {
    box-sizing: border-box;
    order: 1;
    color: white !important;
    border: none;
    width: 100%;
    min-width: 0;
    outline: 0;
    margin-right: 0.5em;
    background-color: transparent;
    font-size: 0.8em;
    font-family: inherit; }
  .cs-search__input:disabled {
    color: rgba(0, 0, 0, 0.38);
    background-color: #c6e3fa; }
  .cs-search__input:-ms-input-placeholder {
    color: rgba(160, 153, 153, 0.87); }
  .cs-search__input::placeholder {
    color: rgba(112, 112, 112, 0.87); }
  .cs-search__search-icon {
    box-sizing: border-box;
    order: 0;
    display: block;
    margin-right: 0.5em;
    color: #7c7c7c; }
  .cs-search__clear-icon {
    box-sizing: border-box;
    order: 2;
    color: #202024;
    visibility: hidden; }
    .cs-search__clear-icon:hover {
      color: rgba(110, 170, 216, 0.6);
      cursor: pointer; }
    .cs-search__clear-icon--active {
      visibility: visible; }
  .cs-search--disabled {
    opacity: 0.38;
    color: rgba(0, 0, 0, 0.38);
    background-color: #c6e3fa; }

.cs-button {
  box-sizing: border-box;
  display: inline-block;
  vertical-align: middle;
  text-align: center;
  color: #202024;
  background-color: transparent;
  border: none;
  border-radius: 0.7em;
  margin: 0.1em 0.2em;
  padding: 0.2em 0.7em;
  outline: none;
  font-size: 1em;
  line-height: 1.5; }
  .cs-button:focus {
    outline: none; }
  .cs-button:disabled {
    opacity: 0.38; }
  .cs-button:not(:disabled) {
    cursor: pointer; }
  .cs-button.cs-button--border {
    border: solid 1px #d1dbe4; }
  .cs-button:hover:not(:disabled) {
    opacity: 0.6; }
  .cs-button.cs-button--right > svg[data-icon]:first-child {
    margin-right: 0.5em; }
  .cs-button.cs-button--left > svg[data-icon]:last-child {
    margin-left: 0.5em; }
  .cs-button--adduser {
    color: #fe5000;
    background-color: transparent; }
  .cs-button--arrow {
    color: #fe5000;
    border: 0 solid!important;
    width: 40px !important;
    height: 40px !important;
    border-radius: 50% !important;
    padding-top: 2px;
    box-shadow: none !important;
    background: #2a2b2c;
    margin-left: 0rem;
    z-index: 2 !important;
    font-size: 15px !important; }
  .cs-button--ellipsis {
    color: #fe5000;
    background-color: transparent; }
  .cs-button--info {
    color: #fe5000;
    background-color: transparent; }
  .cs-button--star {
    color: #fe5000;
    background-color: transparent; }
  .cs-button--videocall {
    color: #fe5000;
    background-color: transparent; }
  .cs-button--voicecall {
    color: #fe5000;
    background-color: transparent; }
  .cs-button--send {
    color: #fe5000;
    background-color: transparent; }
  .cs-button--attachment {
    color: #fe5000;
    background-color: transparent; }

.cs-loader {
  box-sizing: border-box;
  display: inline-flex;
  position: relative;
  font-size: 1em;
  width: 1.8em;
  height: 1.8em;
  color: rgba(0, 0, 0, 0.87);
  background-color: transparent; }
  .cs-loader::before, .cs-loader::after {
    box-sizing: border-box;
    display: block;
    width: 1.8em;
    height: 1.8em;
    margin: 0 0 0 -0.9em; }
  .cs-loader::before {
    position: absolute;
    content: "";
    top: 0;
    left: 50%;
    border-radius: 50%;
    border-color: #c6e3fa;
    border-style: solid;
    border-width: 0.2em; }
  .cs-loader::after {
    position: absolute;
    content: "";
    top: 0;
    left: 50%;
    animation: loader-default .6s linear;
    animation-iteration-count: infinite;
    border-radius: 50%;
    border-color: #202024 transparent transparent transparent;
    border-style: solid;
    border-width: 0.2em;
    box-shadow: 0 0 0 transparent; }
  .cs-loader--content {
    width: auto;
    height: auto;
    padding-top: 2.6em; }

@keyframes loader-default {
  to {
    transform: rotate(360deg); } }

.cs-overlay {
  box-sizing: border-box;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  -webkit-backdrop-filter: blur(10%);
          backdrop-filter: blur(10%); }
  .cs-overlay__content {
    z-index: 220; }
  .cs-overlay::before {
    box-sizing: border-box;
    content: "";
    background-color: rgba(198, 227, 250, 0.38);
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    z-index: 200; }
  .cs-overlay--blur::before {
    -webkit-backdrop-filter: blur(2px);
            backdrop-filter: blur(2px); }
  .cs-overlay--grayscale::before {
    -webkit-backdrop-filter: grayscale(50%);
            backdrop-filter: grayscale(50%); }

.cs-status-list {
  box-sizing: border-box;
  list-style-type: none;
  margin: 0;
  padding: 1em;
  color: rgba(0, 0, 0, 0.87);
  background-color: #fff; }
  .cs-status-list > li {
    box-sizing: border-box;
    cursor: pointer;
    -webkit-user-select: none;
        -ms-user-select: none;
            user-select: none; }
    .cs-status-list > li > .cs-status {
      margin: 0;
      padding: 0.4em 0.8em;
      width: auto;
      height: auto; }
    .cs-status-list > li .cs-status--selected {
      color: #2a6b9c;
      background-color: #d5e8f6; }
      .cs-status-list > li .cs-status--selected .cs-status__name {
        color: #2a6b9c;
        background-color: #d5e8f6; }
    .cs-status-list > li:hover {
      color: rgba(0, 0, 0, 0.6);
      background-color: #f3f8fc; }
    .cs-status-list > li:focus {
      color: rgba(0, 0, 0, 0.87);
      background-color: #d5e8f6; }
    .cs-status-list > li:active {
      color: rgba(0, 0, 0, 0.87);
      background-color: #d5e8f6; }
  .cs-status-list--xs > li > .cs-status {
    font-size: 1em;
    width: auto;
    height: auto; }
    .cs-status-list--xs > li > .cs-status .cs-status__bullet {
      width: 4px;
      min-width: 4px;
      height: 4px; }
  .cs-status-list--sm > li > .cs-status {
    font-size: 1em;
    width: auto;
    height: auto; }
    .cs-status-list--sm > li > .cs-status .cs-status__bullet {
      width: 8px;
      min-width: 8px;
      height: 8px; }
  .cs-status-list--md > li > .cs-status {
    font-size: 1em;
    width: auto;
    height: auto; }
    .cs-status-list--md > li > .cs-status .cs-status__bullet {
      width: 11px;
      min-width: 11px;
      height: 11px; }
  .cs-status-list--lg > li > .cs-status {
    font-size: 1em;
    width: auto;
    height: auto; }
    .cs-status-list--lg > li > .cs-status .cs-status__bullet {
      width: 14px;
      min-width: 14px;
      height: 14px; }

.ps {
  overflow: hidden !important;
  overflow-anchor: none;
  -ms-overflow-style: none;
  touch-action: auto;
  -ms-touch-action: auto; }

.ps__rail-x {
  display: none;
  opacity: 0;
  transition: background-color .2s linear, opacity .2s linear;
  -webkit-transition: background-color .2s linear, opacity .2s linear;
  height: 15px;
  bottom: 0px;
  position: absolute; }

.ps__rail-y {
  display: none;
  opacity: 0;
  transition: background-color .2s linear, opacity .2s linear;
  -webkit-transition: background-color .2s linear, opacity .2s linear;
  width: 15px;
  right: 0;
  left: auto !important;
  position: absolute; }

.ps--active-x > .ps__rail-x,
.ps--active-y > .ps__rail-y {
  display: block;
  background-color: transparent; }

.ps:hover > .ps__rail-x,
.ps:hover > .ps__rail-y,
.ps--focus > .ps__rail-x,
.ps--focus > .ps__rail-y,
.ps--scrolling-x > .ps__rail-x,
.ps--scrolling-y > .ps__rail-y {
  opacity: 0.6; }

.ps .ps__rail-x:hover,
.ps .ps__rail-y:hover,
.ps .ps__rail-x:focus,
.ps .ps__rail-y:focus,
.ps .ps__rail-x.ps--clicking,
.ps .ps__rail-y.ps--clicking {
  background-color: #eee;
  background-color: #f6fbff;
  opacity: 0.9; }

.ps__thumb-x {
  background-color: #202024;
  border-radius: 6px;
  transition: background-color .2s linear, height .2s ease-in-out;
  -webkit-transition: background-color .2s linear, height .2s ease-in-out;
  height: 6px;
  bottom: 2px;
  position: absolute; }

.ps__thumb-y {
  background-color: #c6e3fa;
  border-radius: 6px;
  transition: background-color .2s linear, width .2s ease-in-out;
  -webkit-transition: background-color .2s linear, width .2s ease-in-out;
  width: 6px;
  right: 2px;
  position: absolute; }

.ps__rail-x:hover > .ps__thumb-x,
.ps__rail-x:focus > .ps__thumb-x,
.ps__rail-x.ps--clicking .ps__thumb-x {
  background-color: #c6e3fa;
  height: 11px; }

.ps__rail-y:hover > .ps__thumb-y,
.ps__rail-y:focus > .ps__thumb-y,
.ps__rail-y.ps--clicking .ps__thumb-y {
  background-color: #c6e3fa;
  width: 11px; }

@supports (-ms-overflow-style: none) {
  .ps {
    overflow: auto !important; } }

@media screen and (-ms-high-contrast: active), (-ms-high-contrast: none) {
  .ps {
    overflow: auto !important; } }

.scrollbar-container {
  position: relative;
  height: 100%; }
/*# sourceMappingURL=styles.css.map */
